import React, { useState } from "react"
import PropTypes from 'prop-types'
import { Container, Row, Col, Modal, ModalHeader, ModalBody,TabContent,TabPane,Label,Input,
    FormGroup} from "reactstrap"
import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
} from "availity-reactstrap-validation"
import { useSelector, useDispatch } from "react-redux"
import moment from 'moment'
import {
    addProcessListPayment as OnAddProcessListPayment,
    addPayment as OnAddPayment,
    getPaymentPlans_MyPlans as onGetMyPaymentPlans,
    
  } from "store/actions"

import { isEmpty, map } from "lodash"
import { Link, withRouter } from "react-router-dom"
//i18n
import { withTranslation } from "react-i18next"

const PaymentModal = (props, cb) => {
  
  let user = JSON.parse(localStorage.getItem("authUser"))
  const handleChangeInputBoolean = (i, e, inputName) => {
    const values = [...paymentPlanObjects]
    values[i][inputName] =
      e.target.value === "" ? false : e.target.value == "false" ? true : false
    setPaymentPlanObjects(values)
  }

  const handleChangeInput = (i, e, inputName) => {
    const values = [...paymentPlanObjects]
    values[i][inputName] = e.target.value
    setPaymentPlanObjects(values)
    console.log( "ğ",e.target.value)
  }
  const handleValidDateAvField = date => {
    if (date) {
        const date1 = moment(new Date(date)).format("YYYY-MM-DD");
        return date1;
    } else {
        return "";
    }
}
    const {
        error,
       
        myPaymentPlans,
      } = useSelector(state => ({
        error: state.Expenses?.error,
       
        myPaymentPlans: state.PaymentPlans?.data,
       
      }))
      const [paymentPlanObjects, setPaymentPlanObjects] = useState([])
    const [selectedPaymentPlanInput, setSelectedPaymentPlanInput] = useState()
    const [selectedExpense, setSelectedExpense] = useState()
    const [paymentPlanName, setPaymentPlanName] = useState([])
    const handleValidPaymentSubmit = (e, values) => {
        {
          const newPayment = {
          
            PaymentPlanObjects: JSON.stringify(values.paymentPlanObjects),
            amount: values.amount,
            name: paymentPlanName.name,
            description: values.description,
          }
          //console.log(newPayment)
          //dispatch(OnAddProcessListPayment(newPayment)) 
          
         // dispatch(OnAddPayment(newPayment)) 
        }
      }
 
  const dispatch = useDispatch()
  //const [modal, setModal] = useState(false)




  return (
    <Modal isOpen={props.modal} toggle={props.toggle}>
          <ModalHeader toggle={props.toggle} tag="h4">
            {props.t("PaymentMethod")}
          </ModalHeader>
          <ModalBody>
            <AvForm onValidSubmit={handleValidPaymentSubmit}>
              <Row form>
                <Col xs={12}>
                  <Container>
                    <div className="mb-3">
                      <AvField
                        name="planType"
                        label={props.t("Plan Type")}
                        type="select"
                        className="form-select"
                        errorMessage={props.t("Invalid Plan Type")}
                        validate={{
                          required: { value: true },
                        }}
                        value={selectedPaymentPlanInput?.planType || ""}
                        onChange={event => {
                          setSelectedPaymentPlanInput(prevState => ({
                            ...prevState,
                            planType: event.target.value,
                          }))

                          let selectedPlan = myPaymentPlans.find(
                            x => x._id === event.target.value
                          )

                          if (selectedPlan) {
                            setPaymentPlanName(selectedPlan)
                          } else {
                            setPaymentPlanName([]) 
                          }

                          if (selectedPlan) {
                            setPaymentPlanObjects(
                              selectedPlan.PaymentPlanObjects
                            )
                          } else {
                            setPaymentPlanObjects([])
                          }
                        }}
                      >
                        <option>{props.t("Select Payment Plan")}</option>
                        {map(myPaymentPlans, (item, index) => (
                          <option value={item._id} key={"d_" + index}>
                            {item.name}
                          </option>
                        ))}
                      </AvField>
                    </div>

                    {map(paymentPlanObjects, (item, index) => (
                      <div className="mb-3" key={"e_" + index}>
                        {item.isType === "string" ? (
                          <AvField
                            key={"e1_" + index} 
                            id={"paymentPlanObjects_" + index + "__isValue"}
                            name={"paymentPlanObjects[" + index + "].isValue"}
                            type="text"
                            label={item.title}
                            placeholder={item.title}
                            value={item.isValue}
                            onChange={e =>
                              
                              handleChangeInput(index, e, "isValue")
                            
                            }
                            validate={{
                              required: { value: false },
                            }}
                          />
                        ) : item.isType === "date" ? (
                          <AvField
                            key={"e1_" + index}
                            id={"paymentPlanObjects_" + index + "__isValue"}
                            name={"paymentPlanObjects[" + index + "].isValue"}
                            type="date"
                            label={item.title}
                            placeholder={item.title}
                            value={handleValidDateAvField(item.isValue)}
                            onChange={e =>
                              handleChangeInput(index, e, "isValue")
                            }
                            validate={{
                              required: { value: false },
                            }}
                          />
                        ) : item.isType === "number" ? (
                          <AvField
                            key={"e1_" + index}
                            id={"paymentPlanObjects_" + index + "__isValue"}
                            name={"paymentPlanObjects[" + index + "].isValue"}
                            type="number"
                            label={item.title}
                            placeholder={item.title}
                            value={item.isValue}
                            onChange={e =>
                              handleChangeInput(index, e, "isValue")
                            }
                            validate={{
                              required: { value: false },
                            }}
                          />
                        ) : item.isType === "boolean" ? (
                          <AvField
                            key={"e1_" + index}
                            id={"paymentPlanObjects_" + index + "__isValue"}
                            name={"paymentPlanObjects[" + index + "].isValue"}
                            type="checkbox"
                            label={item.title}
                            placeholder={item.title}
                            value={item.isValue == "" ? false : item.isValue}
                            onChange={e =>
                              handleChangeInputBoolean(index, e, "isValue")
                            }
                            validate={{
                              required: { value: false },
                            }}
                          />
                        ) : item.isType === "dropdown" ? (
                          <AvField
                            key={"e1_" + index}
                            id={"paymentPlanObjects_" + index + "__isValue"}
                            name={"paymentPlanObjects[" + index + "].isValue"}
                            type="select"
                            className="form-select"
                            label={item.title}
                            placeholder={item.title}
                            value={item.isValue}
                            onChange={e =>
                              handleChangeInput(index, e, "isValue")
                            }
                            validate={{
                              required: { value: false },
                            }}
                          >
                            {item.isOptions.split(",")?.map((item, index) => (
                              <option key={"f_" + index} value={item}>
                                {item}
                              </option>
                            ))}
                          </AvField>
                        ) : (
                          <AvField
                            key={"e1_" + index}
                            id={"paymentPlanObjects_" + index + "__isValue"}
                            name={"paymentPlanObjects[" + index + "].isValue"}
                            type="text"
                            label={item.title}
                            placeholder={item.title}
                            value={item.isValue}
                            onChange={e =>
                              handleChangeInput(index, e, "isValue")
                            }
                            validate={{
                              required: { value: false },
                            }}
                          />
                        )}
                      </div>
                    ))}
                    <div className="mb-3">
                      <AvField
                        name="amount"
                        label={props.t("Amount")}
                        type="text"
                        errorMessage={props.t("InvalidInput")}
                        validate={{
                          required: { value: false },
                        }}
                        value={ ""}
                      />
                    </div>

                    <div className="mb-3">
                      <AvField
                        name="description"
                        label={props.t("Description")}
                        type="textarea"
                        errorMessage={props.t("InvalidInput")}
                        validate={{
                          required: { value: false },
                        }}
                        value={""}
                      />
                    </div>
                  </Container>
                </Col>
              </Row>
              <Row className="mt-3"></Row>
              <Row className="mt-3">
                <Col md="12">
                  <div className="text-end">
                    <button
                      type="submit"
                      onClick={() => {
                        let _body = {
                          PaymentPlanObjects: JSON.stringify(paymentPlanObjects),
                          name: paymentPlanName.name,
                          amount: amount.value,
                          description: description.value,
                          
                        };
                        
                        //console.log("payment bu",_body);

                        props.save(true,_body)
                      }}
                      className="btn btn-success save-user col-4"
                    >
                      {props.t("Save")}
                    </button>
                    &nbsp;&nbsp;
                    <button
                      className="btn btn-secondary col-4 "
                      type="button"
                      onClick={() => {
                        props.cancel(true)
                      }}
                    >
                      {props.t("Cancel")}
                    </button>
                  </div>
                </Col>
              </Row>
            </AvForm>
          </ModalBody>
        </Modal>
  )
}


PaymentModal.propTypes = {
 
  modal: PropTypes.bool,
  save:PropTypes.func,
   cancel:PropTypes.func,
  
   t: PropTypes.any,
   toggle: PropTypes.func,

}


export default withRouter(
    (withTranslation()(PaymentModal))
  )
