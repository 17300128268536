/** Get Payment */
export const GET_SUBCATEGORIES = "GET_SUBCATEGORIES";
export const GET_SUBCATEGORIES_SUCCESS = "GET_SUBCATEGORIES_SUCCESS";
export const GET_SUBCATEGORIES_FAIL = "GET_SUBCATEGORIES_FAIL";

export const GET_SUBCATEGORY_DETAIL = "GET_SUBCATEGORY_DETAIL";
export const GET_SUBCATEGORY_DETAIL_SUCCESS = "GET_SUBCATEGORY_DETAIL_SUCCESS";
export const GET_SUBCATEGORY_DETAIL_FAIL = "GET_SUBCATEGORY_DETAIL_FAIL";


export const ADD_SUBCATEGORY = "ADD_SUBCATEGORY";
export const ADD_SUBCATEGORY_SUCCESS = "ADD_SUBCATEGORY_SUCCESS";
export const ADD_SUBCATEGORY_FAIL = "ADD_SUBCATEGORY_FAIL";

export const UPDATE_SUBCATEGORY = "UPDATE_SUBCATEGORY";
export const UPDATE_SUBCATEGORY_SUCCESS = "UPDATE_SUBCATEGORY_SUCCESS";
export const UPDATE_SUBCATEGORY_FAIL = "UPDATE_SUBCATEGORY_FAIL";



export const DELETE_SUBCATEGORY = "DELETE_SUBCATEGORY";
export const DELETE_SUBCATEGORY_SUCCESS = "DELETE_SUBCATEGORY_SUCCESS";
export const DELETE_SUBCATEGORY_FAIL = "DELETE_SUBCATEGORY_FAIL";

export const RESET_SUBCATEGORY = "RESET_SUBCATEGORY";