/** Get Payment */
export const GET_EXPENSES = "GET_EXPENSES";
export const GET_EXPENSES_SUCCESS = "GET_EXPENSES_SUCCESS";
export const GET_EXPENSES_FAIL = "GET_EXPENSES_FAIL";

export const GET_EXPENSE_DETAIL = "GET_EXPENSE_DETAIL";
export const GET_EXPENSE_DETAIL_SUCCESS = "GET_EXPENSE_DETAIL_SUCCESS";
export const GET_EXPENSE_DETAIL_FAIL = "GET_EXPENSE_DETAIL_FAIL";


export const ADD_EXPENSE = "ADD_EXPENSE";
export const ADD_EXPENSE_SUCCESS = "ADD_EXPENSE_SUCCESS";
export const ADD_EXPENSE_FAIL = "ADD_EXPENSE_FAIL";

export const UPDATE_EXPENSE = "UPDATE_EXPENSE";
export const UPDATE_EXPENSE_SUCCESS = "UPDATE_EXPENSE_SUCCESS";
export const UPDATE_EXPENSE_FAIL = "UPDATE_EXPENSE_FAIL";



export const DELETE_EXPENSE = "DELETE_EXPENSE";
export const DELETE_EXPENSE_SUCCESS = "DELETE_EXPENSE_SUCCESS";
export const DELETE_EXPENSE_FAIL = "DELETE_EXPENSE_FAIL";

export const GET_TYPE_LIST_BY_CATEGORY_ID = "GET_TYPE_LIST_BY_CATEGORY_ID" 
export const GET_TYPE_LIST_BY_CATEGORY_ID_SUCCESS = "GET_TYPE_LIST_BY_CATEGORY_ID_SUCCESS"
export const GET_TYPE_LIST_BY_CATEGORY_ID_FAIL = "GET_TYPE_LIST_BY_CATEGORY_ID_FAIL"

export const RESET_EXPENSE = "RESET_EXPENSE";