import React, { useState, useEffect } from "react"
import PropTypes from 'prop-types'
import {
  Container, Row, Col, Modal, Alert, ModalHeader, ModalBody, TabContent, TabPane, Label, Input,
  FormGroup, Button
} from "reactstrap"

import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
} from "availity-reactstrap-validation"
import { useSelector, useDispatch } from "react-redux"
//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert"
import {
  addProcessListPayment as OnAddProcessListPayment,
  getProductsByBarcode as onGetProductsByBarcode,
  addPayment as onAddPayment
} from "store/actions"
import { isEmpty, map } from "lodash"

import { Link, withRouter } from "react-router-dom"
//i18n
import { withTranslation } from "react-i18next"
import { productComments } from "common/data"
import { Search } from "react-bootstrap-table2-toolkit"

const SaleModal = (props, cb) => {
  useEffect(() => {

    setProductAlert(false)

  }, [props.modal])
  const [serialNumberIsExist, setSerialNumberIsExist] = useState(false)

  const [serialNumbers, setSerialNumbers] = useState([])
  const [productAlert, setProductAlert] = useState(false)
  const [serialNoInfoModal, setSerialNoInfoModal] = useState(false)
  const [productTypeAlert, setProductTypeAlert] = useState(false)
  const [productSerialNumber, setProductSerialNumber] = useState()
  const [serialArr, setSerialArr] = useState([
    {
      id: "serialNumber[0]",
      value: "",

    }
  ]);
  const {
    error,
    Expenses,
    Collections,
    Product,
    ProductBySerialNo

  } = useSelector(state => ({
    error: state.Expenses?.error,
    Expenses: state.Expenses?.data,
    Collections: state.Collections?.data,
    Product: state.ProcessList?.Product,

    ProductBySerialNo: state.ProcessList?.ProductId,
  }))
  useEffect(() => {
    if (props.searchData?.isType == "serialNo") {
      setSerialNumberIsExist(true)
    }
    else { setSerialNumberIsExist(false) }
  }, [props.searchData]);
  const dispatch = useDispatch()
  const handleValidSaleSubmit = (e, values) => {
    {
      const Sale = {
        ProductBySerialNo: Product?._id,
        amount: values.amount,
        salesAmount: values.salesAmount,
        serialNumbers: JSON.stringify(serialNumbers),

      }
      //props.handleChange(Sale)
      // props.handleChange(props.saleState)

      // dispatch(OnAddProcessListPayment(Sale))

    }
  }
  const serialNoToggle = () => {
    setSerialNoInfoModal(!serialNoInfoModal)
  }

  return (
    <React.Fragment>
      <Modal isOpen={props.modal} toggle={props.toggle}>
        <ModalHeader toggle={props.toggle} tag="h4">
          {props.t("Sale")}
        </ModalHeader>
        <ModalBody>
          <AvForm onValidSubmit={handleValidSaleSubmit} autoComplete={"off"}>
            <Row form>
              <Col md={12}>

                {props.t("Product Name")} :{props.searchData?.isType == "serialNo" ? (ProductBySerialNo ? ProductBySerialNo[0]?.name : null) : Product?.name}

                <br />
                {props.t("Product Barcode")}::{props.searchData?.isType == "serialNo" ? (ProductBySerialNo ? ProductBySerialNo[0]?.barcode : null) : Product?.barcode}
                <br />

                <TabContent className="crypto-buy-sell-nav-content p-4">
                  <TabPane id="buy">


                    <Label>{props.t("Process Type")}</Label>
                    <Row>
                      {Product?.productType.map((item, i) => (

                        <Col key={i} md="6" >

                          {Product?.productType[i].checked == true ?

                            <div>
                              <label className="card-radio-label mb-2">
                                <input
                                  type="radio"
                                  name="productType"
                                  className="card-radio-input"
                                  value={Product?.productType[i].name}
                                />

                                <div className="card-radio">
                                  <span>
                                    {Product?.productType[i].name}
                                  </span>
                                </div>
                              </label>
                            </div>


                            :
                            null}

                        </Col>
                      ))}

                    </Row>

                    <hr />
                    <Row>
                      <Col className="mb-3">
                        <AvField
                          name="count"
                          label={props.t("Number")}
                          type="number"
                          step={"1"}
                          errorMessage={props.t("InvalidInput")}
                          validate={{
                            required: { value: false },
                          }}
                          value={1}

                          onChange={event => {
                            let tempSerialListArr = []

                            if (event.target.value != "" && event.target.value > 0) {
                              for (
                                let i = 0;
                                i < parseInt(event.target.value);
                                i++
                              ) {
                                tempSerialListArr.push({
                                  id: "serialNumber[" + i + "]",
                                  value: "",

                                })

                              }

                            }
                            setSerialArr(tempSerialListArr)

                          }}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col className="mb-3">
                        <AvField
                          name="salesAmount"
                          label={props.t("Sales Amount")}
                          type="number"
                          step={"0.01"}
                          errorMessage={props.t("InvalidInput")}
                          validate={{
                            required: { value: false },
                          }}
                          value={""}

                          onChange={
                            props.handleChange
                          }

                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col md="8">
                        <div className="form-check form-switch form-switch-lg mb-3">
                          <label
                            className="form-check-label"
                            htmlFor="customSwitchsizelg"
                          >
                            {props.t("The product has a serial number")}
                          </label>
                          <input
                            type="checkbox"
                            name="customSwitchsizelg"
                            className="form-check-input"
                            id="customSwitchsizelg"
                            checked={serialNumberIsExist}
                            onChange={e => {
                              setSerialNumberIsExist(!serialNumberIsExist)
                            }}
                          />
                        </div>
                      </Col>
                      <Col md="4">
                      {serialNumberIsExist == true ? (
                         < Button  onClick={() => {
                          console.log("item", Product.serialNo)
                          setProductSerialNumber(Product.serialNo)
                          serialNoToggle()
                        }} className="btn btn-success me-2">

                          {props.t("Serial Numbers")}
                        </Button>
                      ):null}
                     
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                      
                        {serialNumberIsExist == true ? (
                           
                          map(serialArr, (item, index) => (
                            <div className="mb-3" key={"a1_" + index}>
                              <Label>{props.t("Serial No")}</Label>
                              <Input
                                name={item.id}
                                type="text"
                                className="form-control"
                                value={props.searchData.isType == "serialNo" ? props.searchData?.txt : ""}
                                onChange={event => {

                                  serialArr[index].value = (event.target.value)
                                  setSerialNumbers(serialNumbers => ({
                                    ...serialNumbers,
                                    name: item.id,
                                    value: event.target.value,
                                  }))

                                }}

                              />
                              
                            </div>

                          ))
                         

                        ) : (
                          <div className="mb-3">
                            <Label>{props.t("Serial No")}</Label>
                            <Input
                              disabled
                              type="text"
                              className="form-control"
                            />
                          </div>
                        )}
                        
                      </Col>
                    </Row>

                  </TabPane>
                </TabContent>
              </Col>
              {productAlert == true ?
                <Alert color="danger">
                  {props.t("Product Not Found")}
                </Alert>
                : null
              }
              {productTypeAlert == true ?
                <Alert color="danger">
                  {props.t("You Have To Select a Product Type")}
                </Alert>
                : null
              }

            </Row>
            <Row className="mt-3">
              <Col xl="4" xs="3">
                <button
                  type="button"
                  className="btn btn-primary  col-9  mt-3"
                  onClick={() => {
                    props.back(true)
                  }}
                >
                  {props.t("Back")}
                </button>
              </Col>
              <Col xl="4" xs="4">
                <button
                  type="submit"
                  className="btn btn-success col-9  mt-3"
                  onClick={() => {
                    if (Product || ProductBySerialNo) {
                      setProductAlert(false)
                      if (!isEmpty(Product?.productType)) {
                        if (!isEmpty(document.querySelector("input[name=productType]:checked")?.value)) {
                          let _body = {
                            productType: document.querySelector("input[name=productType]:checked")?.value,
                            count: count.value,
                            amount: salesAmount.value,
                            serialNumbers: serialArr

                          };
                          setProductTypeAlert(false)
                          props.continue(true, _body)
                        }
                        else { setProductTypeAlert(true) }

                      } else {
                        let _body = {
                          productType: document.querySelector("input[name=productType]:checked")?.value,
                          count: count.value,
                          amount: salesAmount.value,
                          serialNumbers: serialArr
                        };
                        setProductTypeAlert(false)
                        props.continue(true, _body)

                      }

                    }
                    else {
                      setProductAlert(true)
                    }
                  }}
                >
                  {props.t("Continue")}
                </button>
              </Col>
              <Col xl="4" xs="5" >
                <button
                  type="button"
                  onClick={() => {
                    if (Product || ProductBySerialNo) {
                      setProductAlert(false)
                      if (!isEmpty(Product?.productType)) {
                        if (!isEmpty(document.querySelector("input[name=productType]:checked")?.value)) {
                          let _body = {
                            productType: document.querySelector("input[name=productType]:checked")?.value,
                            count: count.value,
                            amount: salesAmount.value,
                            serialNumbers: serialArr

                          };
                          setProductTypeAlert(false)
                          props.payment(true, _body)
                        }
                        else { setProductTypeAlert(true) }

                      } else {
                        let _body = {
                          productType: document.querySelector("input[name=productType]:checked")?.value,
                          count: count.value,
                          amount: salesAmount.value,
                          serialNumbers: serialArr
                        };
                        setProductTypeAlert(false)
                        props.payment(true, _body)

                      }

                    }
                    else {
                      setProductAlert(true)
                    }
                  }}
                  className="btn btn-success col-12 mt-3"
                >
                  {props.t("Final Costumer")}
                </button>
              </Col>
            </Row>
          </AvForm>
        </ModalBody>
      </Modal >
      <Modal isOpen={serialNoInfoModal} toggle={serialNoToggle}>
        <ModalHeader toggle={serialNoToggle} tag="h4">
          {props.t("Serial Numbers")}
        </ModalHeader>
        <ModalBody>
          <AvForm>
            <Row form>
              <Col xs={12}>
                {productSerialNumber?.map((item, i) => (
                  <div key={i}>
                    <input
                      id={"productTypes" + (i) + "__isValue"}
                      name={"productTypes[" + (i) + "].isValue"}
                      type="hidden"
                    />
                    <Row className="mt-2">
                      <Col md="8" >
                        <AvField style={{ cursor: "pointer" }} 
                          type="text"
                          id={"productTypes" + (i) + "__name"}
                          name={"productTypes[" + (i) + "].name"}
                          placeholder={props.t("Serial No")}
                          disabled
                          value={item.name}
                          onChange={e => handleChangeInput(i, e, "name")}
                          validate={{
                            required: { value: false },
                          }}
                        />
                       
                      </Col>




                    </Row>
                  </div>
                ))}
              </Col>
            </Row>
            <Row>
              <Col>


                <div className="text-end">

                  &nbsp;&nbsp;
                  <Button onClick={() => { serialNoToggle() }}>
                    {props.t("Cancel")}
                  </Button>

                </div>
              </Col>
            </Row>
          </AvForm>
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}


SaleModal.propTypes = {

  modal: PropTypes.bool,
  searchData: PropTypes.any,
  continue: PropTypes.func,
  payment: PropTypes.func,
  back: PropTypes.func,
  t: PropTypes.any,
  toggle: PropTypes.func,


}


export default withRouter(
  (withTranslation()(SaleModal))
)
