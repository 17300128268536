import {
    GET_PRODUCTSALES,
    GET_PRODUCTSALES_SUCCESS,
    GET_PRODUCTSALES_FAIL,
    GET_PRODUCTSALE_DETAIL,
    GET_PRODUCTSALE_DETAIL_SUCCESS,
    GET_PRODUCTSALE_DETAIL_FAIL,
    ADD_PRODUCTSALE,
    ADD_PRODUCTSALE_SUCCESS,
    ADD_PRODUCTSALE_FAIL,
    UPDATE_PRODUCTSALE,
    UPDATE_PRODUCTSALE_SUCCESS,
    UPDATE_PRODUCTSALE_FAIL,
    DELETE_PRODUCTSALE,
    DELETE_PRODUCTSALE_SUCCESS,
    DELETE_PRODUCTSALE_FAIL,
    RESET_PRODUCTSALE
} from "./actionTypes";

export const resetProductSale = () => ({
    type: RESET_PRODUCTSALE,
});

export const getProductSales = () => ({
    type: GET_PRODUCTSALES,
});

export const getProductSalesSuccess = (productSales) => ({
    type: GET_PRODUCTSALES_SUCCESS,
    payload: productSales,
});


export const getProductSalesFail = (error) => ({
    type: GET_PRODUCTSALES_FAIL,
    payload: error,
});

export const deleteProductSale = (id) => ({
    type:  DELETE_PRODUCTSALE,
    payload: id
});

export const deleteProductSaleSuccess = (data) => ({
    type: DELETE_PRODUCTSALE_SUCCESS,
    payload: data
});

export const deleteProductSaleFail = (error) => ({
    type: DELETE_PRODUCTSALE_FAIL,
    payload: error,
});


export const addProductSale = (productSale) => ({
    type: ADD_PRODUCTSALE,
    payload: productSale
});

export const addProductSaleSuccess = (data) => ({
    type: ADD_PRODUCTSALE_SUCCESS,
    payload: data
});

export const addProductSaleFail = (error) => ({
    type: ADD_PRODUCTSALE_FAIL,
    payload: error,
});


export const updateProductSale = (productSale) => ({
    type: UPDATE_PRODUCTSALE,
    payload: productSale
});

export const updateProductSaleSuccess = (data) => ({
    type: UPDATE_PRODUCTSALE_SUCCESS,
    payload: data
});

export const updateProductSaleFail = (error) => ({
    type: UPDATE_PRODUCTSALE_FAIL,
    payload: error,
});