/** Get Companies */
export const GET_SALEMODELS = "GET_SALEMODELS";
export const GET_SALEMODELS_SUCCESS = "GET_SALEMODELS_SUCCESS";
export const GET_SALEMODELS_FAIL = "GET_SALEMODELS_FAIL";

export const GET_SALEMODEL_DETAIL = "GET_SALEMODEL_DETAIL";
export const GET_SALEMODEL_DETAIL_SUCCESS = "GET_SALEMODEL_DETAIL_SUCCESS";
export const GET_SALEMODEL_DETAIL_FAIL = "GET_SALEMODEL_DETAIL_FAIL";


export const POST_SALEMODEL = "POST_SALEMODEL";
export const POST_SALEMODEL_SUCCESS = "POST_SALEMODEL_SUCCESS";
export const POST_SALEMODEL_FAIL = "POST_SALEMODEL_FAIL"; 

export const DELETE_SALEMODEL = "DELETE_SALEMODEL";
export const DELETE_SALEMODEL_SUCCESS = "DELETE_SALEMODEL_SUCCESS";
export const DELETE_SALEMODEL_FAIL = "DELETE_SALEMODEL_FAIL";

export const GET_MODAL_BY_USER_ID = "GET_MODAL_BY_USER_ID";
export const GET_MODAL_BY_USER_ID_SUCCESS = "GET_MODAL_BY_USER_ID_SUCCESS";
export const GET_MODAL_BY_USER_ID_FAIL = "GET_MODAL_BY_USER_ID_FAIL";

export const RESET_SALEMODEL = "RESET_SALEMODEL"; 