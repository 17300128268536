import {
    GET_CUSTOMERS,
    GET_CUSTOMERS_SUCCESS,
    GET_CUSTOMERS_FAIL,
    GET_CUSTOMER_DETAIL,
    GET_CUSTOMER_DETAIL_SUCCESS,
    GET_CUSTOMER_DETAIL_FAIL,
    ADD_CUSTOMER,
    ADD_CUSTOMER_SUCCESS,
    ADD_CUSTOMER_FAIL,
    UPDATE_CUSTOMER,
    UPDATE_CUSTOMER_SUCCESS,
    UPDATE_CUSTOMER_FAIL,
    DELETE_CUSTOMER,
    DELETE_CUSTOMER_SUCCESS,
    DELETE_CUSTOMER_FAIL,
    RESET_CUSTOMER
} from "./actionTypes";

export const resetCustomer = () => ({
    type: RESET_CUSTOMER,
});
export const getCustomerById = (id) => ({ 
    type:  GET_CUSTOMER_DETAIL,
    payload: id
});

export const getCustomerByIdSuccess = (data) => ({
    type: GET_CUSTOMER_DETAIL_SUCCESS,
    payload: data
});

export const getCustomerByIdFail = (error) => ({
    type: GET_CUSTOMER_DETAIL_FAIL,
    payload: error,
});
export const getCustomers = () => ({
    type: GET_CUSTOMERS,
});

export const getCustomersSuccess = (customers) => ({
    type: GET_CUSTOMERS_SUCCESS,
    payload: customers,
});


export const getCustomersFail = (error) => ({
    type: GET_CUSTOMERS_FAIL,
    payload: error,
});

export const deleteCustomer = (id) => ({
    type:  DELETE_CUSTOMER,
    payload: id
});

export const deleteCustomerSuccess = (data) => ({
    type: DELETE_CUSTOMER_SUCCESS,
    payload: data
});

export const deleteCustomerFail = (error) => ({
    type: DELETE_CUSTOMER_FAIL,
    payload: error,
});


export const addCustomer = (customer) => ({
    type: ADD_CUSTOMER,
    payload: customer
});

export const addCustomerSuccess = (data) => ({
    type: ADD_CUSTOMER_SUCCESS,
    payload: data
});

export const addCustomerFail = (error) => ({
    type: ADD_CUSTOMER_FAIL,
    payload: error,
});


export const updateCustomer = (customer) => ({
    type: UPDATE_CUSTOMER,
    payload: customer
});

export const updateCustomerSuccess = (data) => ({
    type: UPDATE_CUSTOMER_SUCCESS,
    payload: data
});

export const updateCustomerFail = (error) => ({
    type: UPDATE_CUSTOMER_FAIL,
    payload: error,
});