import { call, put, takeEvery } from "redux-saga/effects";

// Crypto Redux States
import { GET_ENDOFDAYBALANCINGS, DELETE_ENDOFDAYBALANCING, ADD_ENDOFDAYBALANCING, UPDATE_ENDOFDAYBALANCING} from "./actionTypes";
import {
    getEndOfDayBalancingsSuccess, getEndOfDayBalancingsFail,
    deleteEndOfDayBalancingSuccess, deleteEndOfDayBalancingFail,
    updateEndOfDayBalancingSuccess, updateEndOfDayBalancingFail,
    addEndOfDayBalancingSuccess, addEndOfDayBalancingFail
 } from "./actions";

//Include Both Helper File with needed methods
import {
    getEndOfDayBalancings, //API CONNECTION
    delEndOfDayBalancing,
    addEndOfDayBalancing,
    updateEndOfDayBalancing
}
    from "../../helpers/fakebackend_helper"; 

function* fetchEndOfDayBalancings() {
    try {
        const response = yield call(getEndOfDayBalancings)
       
        yield put(getEndOfDayBalancingsSuccess(response));
    } catch (error) {
        yield put(getEndOfDayBalancingsFail(error));
    }
}

function* onDeleteEndOfDayBalancings({payload:id}){
    try{
        const response = yield call(delEndOfDayBalancing, id)
        yield put(deleteEndOfDayBalancingSuccess(response));
    } catch (error) {
        yield put(deleteEndOfDayBalancingFail(error));
    }
}

function* onAddEndOfDayBalancing({payload:profitLoss}){
    try{
        const response = yield call(addEndOfDayBalancing, profitLoss)
        yield put(addEndOfDayBalancingSuccess(response));
    } catch (error) {
        yield put(addEndOfDayBalancingFail(error));
    }
}

function* onUpdateEndOfDayBalancing({payload:endOfDayBalancing}){
    try{
        const response = yield call(updateEndOfDayBalancing, endOfDayBalancing)
        yield put(updateEndOfDayBalancingSuccess(response));
    } catch (error) {
        yield put(updateEndOfDayBalancingFail(error));
    }
}

export function* endOfDayBalancingsSaga() {
    yield takeEvery(GET_ENDOFDAYBALANCINGS, fetchEndOfDayBalancings);
    yield takeEvery(DELETE_ENDOFDAYBALANCING, onDeleteEndOfDayBalancings);
    yield takeEvery(ADD_ENDOFDAYBALANCING, onAddEndOfDayBalancing);
    yield takeEvery(UPDATE_ENDOFDAYBALANCING, onUpdateEndOfDayBalancing);
}

export default endOfDayBalancingsSaga;
