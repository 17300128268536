import {
  CHANGE_SELECTED_BRANCH,
  CHANGE_SELECTED_BRANCH_SUCCESS,
  CHANGE_SELECTED_BRANCH_FAIL,
  GET_USER_DETAIL,
  GET_USER_DETAIL_SUCCESS,
  GET_USER_DETAIL_FAIL,
} from "./actionTypes"   


export const changeSelectedBranch = (branch) => ({ 
  type:  CHANGE_SELECTED_BRANCH,
  payload: branch
});

export const changeSelectedBranchSuccess = (data) => ({
  type: CHANGE_SELECTED_BRANCH_SUCCESS,
  payload: data
});

export const changeSelectedBranchFail = (error) => ({ 
  type: CHANGE_SELECTED_BRANCH_FAIL,
  payload: error,
});
export const getUserById = (id) => ({
  type: GET_USER_DETAIL,
  payload:id
});
export const getUserByIdSuccess = (data) => ({
  type: GET_USER_DETAIL_SUCCESS,
  payload: data
}); 
export const getUserByIdFail = (error) => ({
  type: GET_USER_DETAIL_FAIL,
  payload: error, 
});