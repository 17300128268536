import {
    GET_PRODUCTSSERIES,
    GET_PRODUCTSSERIES_SUCCESS,
    GET_PRODUCTSSERIES_FAIL,
    GET_PRODUCTSERIES_DETAIL,
    GET_PRODUCTSERIES_DETAIL_SUCCESS,
    GET_PRODUCTSERIES_DETAIL_FAIL,
    ADD_PRODUCTSERIES,
    ADD_PRODUCTSERIES_SUCCESS,
    ADD_PRODUCTSERIES_FAIL,
    UPDATE_PRODUCTSERIES,
    UPDATE_PRODUCTSERIES_SUCCESS,
    UPDATE_PRODUCTSERIES_FAIL,
    DELETE_PRODUCTSERIES,
    DELETE_PRODUCTSERIES_SUCCESS,
    DELETE_PRODUCTSERIES_FAIL,
    RESET_PRODUCTSERIES
} from "./actionTypes";

export const resetProductSeries = () => ({
    type: RESET_PRODUCTSERIES,
});

export const getProductsSeries = () => ({
    type: GET_PRODUCTSSERIES,
});

export const getProductsSeriesSuccess = (productSeries) => ({
    type: GET_PRODUCTSSERIES_SUCCESS,
    payload: productSeries,
});


export const getProductsSeriesFail = (error) => ({
    type: GET_PRODUCTSSERIES_FAIL,
    payload: error,
});

export const deleteProductSeries = (id) => ({
    type:  DELETE_PRODUCTSERIES,
    payload: id
});

export const deleteProductSeriesSuccess = (data) => ({
    type: DELETE_PRODUCTSERIES_SUCCESS,
    payload: data
});

export const deleteProductSeriesFail = (error) => ({
    type: DELETE_PRODUCTSERIES_FAIL,
    payload: error,
});


export const addProductSeries = (productSeries) => ({
    type: ADD_PRODUCTSERIES,
    payload: productSeries
});

export const addProductSeriesSuccess = (data) => ({
    type: ADD_PRODUCTSERIES_SUCCESS,
    payload: data
});

export const addProductSeriesFail = (error) => ({
    type: ADD_PRODUCTSERIES_FAIL,
    payload: error,
});


export const updateProductSeries = (productSeries) => ({
    type: UPDATE_PRODUCTSERIES,
    payload: productSeries
});

export const updateProductSeriesSuccess = (data) => ({
    type: UPDATE_PRODUCTSERIES_SUCCESS,
    payload: data
});

export const updateProductSeriesFail = (error) => ({
    type: UPDATE_PRODUCTSERIES_FAIL,
    payload: error,
});