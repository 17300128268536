import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { Container, Col, Label, Row } from "reactstrap"
import { useHistory } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

// import images
import logodark from "../../assets/images/kassa.png"
import logolight from "../../assets/images/kassa.png"
import CarouselPage from "./CarouselPage"


//redux
import { useSelector, useDispatch } from "react-redux"

import { Link } from "react-router-dom"

//branchs
import { 
  getUserBranch as onGetUserBranch,
  getCompanyBranches as onGetCompanyBranches,
  changeSelectedBranch as onChangeSelectedBranch

} from "store/actions"


const SelectBranch = props => {
  const history = useHistory();
  const dispatch = useDispatch()
  const [selectedBranchId, setSelectedBranchId,] = useState()
  const [show, setShow] = useState(false)
  const {
    error, Branches,UserBranch,selectedBranch

  } = useSelector(state => ({
    error: state.Expenses?.error,
    UserBranch:state.Branchs?.userBranch,
    Branches: state.Branchs?.Branches,
    selectedBranch:state.Branchs?.selectedBranch?.name,
  }))
  useEffect(() => {
    dispatch(onGetUserBranch(user.uid))
    dispatch(onGetCompanyBranches(user.companyId))
  }, [dispatch])

  const handleModal = (e, values) => {
    if (show) {
      localStorage.setItem("BranchId", values?.BranchId)
    } 
    setShow(!show)
  }
  // handleValidSubmit
  const handleValidSubmit = values => {
    if(branchId.value =="Şube Seç"){

    }else{
       const newSelectedBranch = {
          id: user.uid,  
          selectedBranch: branchId.value, 
         
      }

      dispatch(onChangeSelectedBranch(newSelectedBranch))
      
      history.push("/processList");
      
    }
   
  }
  let user = JSON.parse(localStorage.getItem("authUser"))
  return (
    <React.Fragment>
      <div> 
        <MetaTags>
          <title>Register 2 | Skote - React Admin & Dashboard Template</title>
        </MetaTags>
        <Container fluid className="p-0">
          <Row className="g-0"> 
            <CarouselPage />
            <Col xl={3}>
              <div className="auth-full-page-content p-md-5 p-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="mb-4 mb-md-5">
                      <Link to="dashboard" className="d-block auth-logo">
                        <img
                          src={logodark}
                          alt=""
                          height="18"
                          className="auth-logo-dark"
                        />
                        <img
                          src={logolight}
                          alt=""
                          height="18"
                          className="auth-logo-light"
                        />
                      </Link>
                    </div>
                    <div className="my-auto">
                      <div>
                        <h5 className="text-primary">{props.t("Hello")}</h5>
                        <p className="text-muted">
                        {props.t("Lets Start")}
                        </p>
                      </div>

                      <div className="mt-4">

                        <AvForm
                          className="form-horizontal"
                          onValidSubmit={(e, v) => {
                            handleValidSubmit(v)
                          }}
                        >

              {user.roles[0].name =="User"?(
                   <div className="mb-3">
                   <AvField
                     type="select"
                     name="branchId"
                     value={selectedBranchId} 
                     onChange={e => {
                      console.log("a",e.target.value)
                    }}
                     className="form-select"
                     label={props.t("Select Branch")}>
                     <option>{props.t("Select Branch")}</option>
                     {UserBranch?.map((item, index) => (
                       <option key={index} value={item._id}>
                         {item.name}
                       </option> 
                     ))}
                   </AvField>
                 </div>
                ):(
                  <div className="mb-3">
                  <AvField
                    type="select"
                    name="branchId"
                    value={selectedBranchId} 
                    // onChange={e => {
                    //   dispatch(onGetBranchById(e.target.value))
                    // }}
                    className="form-select"
                    label={props.t("Select Branch")}>
                    <option>{props.t("Select Branch")}</option>
                    {Branches?.map((item, index) => (
                      <option key={index} value={[item._id]}>
                        {item.name}
                      </option> 
                    ))}
                  </AvField>
                </div>
                )}  

                          <div className="mt-4 text-end">
                            <button
                              className="btn btn-primary btn-block "
                              type="submit"
                            >
                             {props.t("Login")}
                            </button>
                          </div>

                        </AvForm>

                        
                      </div>
                    </div>

                    <div className="mt-4 mt-md-5 text-center">
                      <p className="mb-0">
                        ©{" "}
                        <script>
                          document.write(new Date().getFullYear())
                        </script>{" "}
                        2020. Aviyiva Tarafından{" "}
                        <i className="mdi mdi-heart text-danger"></i> Hazırlanmıştır.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(SelectBranch)
