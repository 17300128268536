import {
    GET_BANKACCOUNTS,
    GET_BANKACCOUNTS_SUCCESS,
    GET_BANKACCOUNTS_FAIL,
    GET_BANKACCOUNT_DETAIL,
    GET_BANKACCOUNT_DETAIL_SUCCESS,
    GET_BANKACCOUNT_DETAIL_FAIL,
    ADD_BANKACCOUNT,
    ADD_BANKACCOUNT_SUCCESS,
    ADD_BANKACCOUNT_FAIL,
    UPDATE_BANKACCOUNT,
    UPDATE_BANKACCOUNT_SUCCESS,
    UPDATE_BANKACCOUNT_FAIL,
    DELETE_BANKACCOUNT,
    DELETE_BANKACCOUNT_SUCCESS,
    DELETE_BANKACCOUNT_FAIL,
    RESET_BANKACCOUNT
} from "./actionTypes";

export const resetBankAccount = () => ({
    type: RESET_BANKACCOUNT,
});

export const getBankAccounts = () => ({
    type: GET_BANKACCOUNTS,
});

export const getBankAccountsSuccess = (bankAccounts) => ({
    type: GET_BANKACCOUNTS_SUCCESS,
    payload: bankAccounts,
});


export const getBankAccountsFail = (error) => ({
    type: GET_BANKACCOUNTS_FAIL,
    payload: error,
});

export const deleteBankAccount = (id) => ({
    type:  DELETE_BANKACCOUNT,
    payload: id
});

export const deleteBankAccountSuccess = (data) => ({
    type: DELETE_BANKACCOUNT_SUCCESS,
    payload: data
});

export const deleteBankAccountFail = (error) => ({
    type: DELETE_BANKACCOUNT_FAIL,
    payload: error,
});


export const addBankAccount = (bankAccount) => ({
    type: ADD_BANKACCOUNT,
    payload: bankAccount
});

export const addBankAccountSuccess = (data) => ({
    type: ADD_BANKACCOUNT_SUCCESS,
    payload: data
});

export const addBankAccountFail = (error) => ({
    type: ADD_BANKACCOUNT_FAIL,
    payload: error,
});


export const updateBankAccount = (bankAccount) => ({
    type: UPDATE_BANKACCOUNT,
    payload: bankAccount
});

export const updateBankAccountSuccess = (data) => ({
    type: UPDATE_BANKACCOUNT_SUCCESS,
    payload: data
});

export const updateBankAccountFail = (error) => ({
    type: UPDATE_BANKACCOUNT_FAIL,
    payload: error,
});