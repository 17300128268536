import {
    GET_ERPS,
    GET_ERPS_SUCCESS,
    GET_ERPS_FAIL,
    GET_ERP_DETAIL,
    GET_ERP_DETAIL_SUCCESS,
    GET_ERP_DETAIL_FAIL,
    ADD_ERP,
    ADD_ERP_SUCCESS,
    ADD_ERP_FAIL,
    UPDATE_ERP,
    UPDATE_ERP_SUCCESS,
    UPDATE_ERP_FAIL,
    DELETE_ERP,
    DELETE_ERP_SUCCESS,
    DELETE_ERP_FAIL,
    RESET_ERP
} from "./actionTypes";

const INIT_STATE = {
    data: [],
    error: null
};

const Erps = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_ERPS_SUCCESS:
            return {
                ...state,
                data: action.payload.data
            }
        case DELETE_ERP_SUCCESS:
            return {
                ...state,
                data: state.data.filter(erp => erp._id.toString() !== action.payload.data.toString())
            }
        case ADD_ERP_SUCCESS:
            return {
                ...state,
                data: [action.payload.data, ...state.data]
            }
        case UPDATE_ERP_SUCCESS:
            return {
                ...state,
                data: state.data.map(erp =>
                    erp._id.toString() === action.payload.data._id.toString() ?
                        { erp, ...action.payload.data } :
                        erp
                )
            }

        //FAILS
        case GET_ERPS_FAIL:
        case DELETE_ERP_FAIL:
        case ADD_ERP_FAIL:
        case UPDATE_ERP_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case RESET_ERP: return {
            ...state,
            error: null
        }
        default:
            return state;
    }
}


export default Erps;