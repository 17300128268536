import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useState, useEffect } from "react"
import {
    Container,
    Row,
    Col,
    Card,
    Alert,
    CardBody,
    Button,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Modal,
    ModalHeader,
    ModalBody,

} from "reactstrap"
//redux
import { useSelector, useDispatch } from "react-redux"

import { withRouter } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"


//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"

import {
    getBankAccounts as onGetBankAccounts,
    deleteBankAccount as onDeleteBankAccount,
    addBankAccount as onAddBankAccount,
    updateBankAccount as onUpdateBankAccount,
    resetBankAccount as onResetBankAccount,
    getUserBranch as onGetUserBranch,
    getCompanyBranches as onGetCompanyBranches,
} from "store/actions"

import { isEmpty, map } from "lodash"
import moment from 'moment'

import { AvForm, AvField, AvGroup, AvInput, AvFeedback, AvRadioGroup, AvRadio, AvCheckboxGroup, AvCheckbox } from "availity-reactstrap-validation"

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
    PaginationProvider, PaginationListStandalone,
    SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert"

import "./datatables.scss"

const BankAccounts = props => {
    const dispatch = useDispatch()

    const [selectedId, setSelectedId] = useState();
    const [selectedBankAccount, setSelectedBankAccount] = useState();
    const [success_dlg, setsuccess_dlg] = useState(false);
    const [confirm_alert, setconfirm_alert] = useState(false);
    const [dynamic_title, setdynamic_title] = useState("");
    const [dynamic_description, setdynamic_description] = useState("");
    const [modal, setModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    
    const [bankNameAlert, setBankNameAlert] = useState(false)


    const { error, BankAccounts, UserBranch, Branches } = useSelector(state => ({
        error: state.BankAccounts?.error,
        BankAccounts: state.BankAccounts?.data,
        UserBranch: state.Branchs?.userBranch[0]?.branchId,
        Branches: state.Branchs?.Branches
    }))

    useEffect(() => {
        dispatch(onGetBankAccounts());
        dispatch(onGetUserBranch(user.uid));
        dispatch(onGetCompanyBranches(user.companyId))


    }, [dispatch]);

    const toggle = () => {
        setModal(!modal)
    }


    let user = JSON.parse(localStorage.getItem("authUser"))

    const columns = [

        {
            dataField: 'bank',
            text: props.t("BankName"),
            sort: true

        },
        {
            dataField: 'branchCode',
            text: props.t("Branch Code"),
            sort: true

        },
        {
            dataField: 'branchAccountNo',
            text: props.t("Branch Account No"),
            sort: true,

        },
        {
            dataField: 'iban',
            text: props.t("IBAN"),
            sort: true,

        },
        {
            dataField: 'description',
            text: props.t("Description"),
            sort: true,

        },



        {
            dataField: '_id',
            text: props.t("Action"),
            sort: false,
            formatter: (cell, row) => (
                <UncontrolledDropdown style={{ position: "unset" }}>
                    <DropdownToggle href="#" className="card-drop" tag="i">
                        <i className="mdi mdi-dots-horizontal font-size-18"></i>
                    </DropdownToggle>
                    <div className="drop-absolute">
                        <DropdownMenu className="dropdown-menu-end">
                            <DropdownItem href="#"
                                onClick={() => {
                                    setSelectedBankAccount(row);
                                    setBankNameAlert(true)
                                    setIsEdit(true);
                                    toggle();
                                }}
                            >
                                <i className="mdi mdi-pencil font-size-16 text-success me-1"></i>
                                {props.t("Edit")}
                            </DropdownItem>
                            {/* <DropdownItem href="#"
                                onClick={() => {
                                    setSelectedId(cell);
                                    setconfirm_alert(true);
                                }}>
                                <i className="mdi mdi-trash-can font-size-16 text-danger me-1"></i>
                                {props.t("Delete")}
                            </DropdownItem> */}
                        </DropdownMenu>
                    </div>
                </UncontrolledDropdown>
            )
        }];

    const defaultSorted = [{
        dataField: 'createdAt',
        order: 'desc'
    }];

    const pageOptions = {
        sizePerPage: 10,
        totalSize: BankAccounts ? BankAccounts.length : 0, // replace later with size(BankAccount),
        custom: true,
    }

    // Custom Pagination Toggle
    const sizePerPageList = [
        { text: '5', value: 5 },
        { text: '10', value: 10 },
        { text: '15', value: 15 },
        { text: '20', value: 20 },
        { text: '25', value: 25 },
        { text: 'All', value: BankAccounts ? BankAccounts.length : 0 }];


    // Select All Button operation
    const selectRow = {
        mode: 'checkbox'
    }

    const { SearchBar } = Search;

    const handleValidBankAccountSubmit = (e, values) => {
        if (values.bank == "Select Bank") {
            setBankNameAlert(true)
        }
        else {
            if (isEdit) {
                const updateBankAccount = {
                    _id: selectedBankAccount._id,
                    bank: values.bank,
                    branchCode: values.branchCode,
                    branchAccountNo: values["branchAccountNo"],
                    iban: values.iban,
                    description: values.description,
                    CompanyId: user.companyId,

                };
                // updateBankAccount
                dispatch(onUpdateBankAccount(updateBankAccount));
            } else {
                const newBankAccount = {
                    bank: values.bank,
                    branchCode: values.branchCode,
                    branchAccountNo: values["branchAccountNo"],
                    iban: values.iban,
                    description: values.description,
                    CompanyId: user.companyId,

                }
                // save new BankAccount
                dispatch(onAddBankAccount(newBankAccount))
                //console.log(newBankAccount)
            }
            toggle()
        }

    }

    const handleValidDate = date => {
        if (date) {
            const date1 = moment(new Date(date)).format("DD.MM.YYYY");
            return date1;
        } else {
            return "";
        }
    }
    const handleValidDateAvField = date => {
        if (date) {
            const date1 = moment(new Date(date)).format("YYYY-MM-DD");
            return date1;
        } else {
            return "";
        }
    }
    let branchCode = false
    let branchNo = false

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>BankAccount</title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumb title={props.t("Bank Accounts")} breadcrumbItem={props.t("Bank Accounts")} addBtn={true} addBtnCallBack={() => {

                        setIsEdit(false);
                        setBankNameAlert(false)
                        setSelectedBankAccount(
                            {
                                bank: "",
                                branchCode: "",
                                branchAccountNo: "",
                            }
                        );
                        toggle();

                    }} />

                    {/* {error && error ? <Alert color="danger">{error}</Alert> : null} */}

                    <Card>
                        <CardBody>
                            <PaginationProvider
                                pagination={paginationFactory(pageOptions)}
                                keyField='id'
                                columns={columns}
                                data={BankAccounts}
                            >
                                {({ paginationProps, paginationTableProps }) => (
                                    <ToolkitProvider
                                        keyField='_id'
                                        columns={columns}
                                        data={BankAccounts}
                                        search
                                    >
                                        {toolkitProps => (
                                            <React.Fragment>

                                                <Row className="mb-2">
                                                    <Col md="4">
                                                        <div className="search-box me-2 mb-2 d-inline-block">
                                                            <div className="position-relative">
                                                                <SearchBar
                                                                    {...toolkitProps.searchProps}
                                                                />
                                                                <i className="bx bx-search-alt search-icon" />
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col xl="12">
                                                        <div className="table-responsive">
                                                            <BootstrapTable
                                                                keyField={"_id"}
                                                                responsive
                                                                bordered={false}
                                                                striped={false}
                                                                defaultSorted={defaultSorted}
                                                                classes={
                                                                    "table align-middle table-nowrap"
                                                                }
                                                                headerWrapperClasses={"thead-light"}
                                                                {...toolkitProps.baseProps}
                                                                {...paginationTableProps}
                                                            />

                                                        </div>
                                                    </Col>
                                                </Row>

                                                <Row className="align-items-md-center mt-30">
                                                    <Col className="inner-custom-pagination d-flex">
                                                        <div className="d-inline">
                                                            <SizePerPageDropdownStandalone
                                                                {...paginationProps}
                                                            />
                                                        </div>
                                                        <div className="text-md-right ms-auto">
                                                            <PaginationListStandalone
                                                                {...paginationProps}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </React.Fragment>
                                        )
                                        }
                                    </ToolkitProvider>
                                )
                                }</PaginationProvider>

                        </CardBody>
                    </Card>


                    <Modal isOpen={modal} toggle={toggle}>
                        <ModalHeader toggle={toggle} tag="h4">
                            {!!isEdit ? props.t("Edit Bank Account") : props.t("Add Bank Account")}
                        </ModalHeader>
                        <ModalBody>
                            <AvForm onValidSubmit={handleValidBankAccountSubmit}>
                                <Row form>
                                    <Col xs={12}>




                                       
                                        <div className="mb-3">
                                            <AvField
                                                name="bank"
                                                label={props.t("BankName")}
                                                type="select"
                                                className="form-select"
                                                errorMessage={props.t("Invalid Customer Type")}
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                value={selectedBankAccount?.bank || "Select Bank"}


                                            >
                                                <option value="Select Bank">{props.t("Select Bank")}</option>
                                                <option value="Akbank">Akbank</option>

                                                <option value="Anadolubank">Anadolubank</option>
                                                <option value="Asya Katılım Bankası">Asya Katılım Bankası</option>
                                                <option value="Burgan Bank">Burgan Bank</option>
                                                <option value="Citibank">Citibank</option>
                                                <option value="Denizbank">Denizbank</option>
                                                <option value="Fiba Banka">Fiba Banka</option>
                                                <option value="FIBABANKA, A.S.">FIBABANKA, A.S.</option>
                                                <option value="Finansbank">Finansbank</option>
                                                <option value="Garanti Bankası">Garanti Bankası</option>
                                                <option value="Halkbank">Halkbank</option>
                                                <option value="HSBC Bank">HSBC Bank</option>
                                                <option value="ICBC Bank">ICBC Bank</option>
                                                <option value="ING Bank">ING Bank</option>
                                                <option value="İninal">İninal</option>
                                                <option value="İş Bankası">İş Bankası</option>
                                                <option value="Kuveyt Türk">Kuveyt Türk</option>
                                                <option value="OdeaBank">OdeaBank</option>
                                                <option value="Papara Kart">Papara Kart</option>
                                                <option value="Param Kart">Param Kart</option>
                                                <option value="PayCore">PayCore</option>
                                                <option value="PTT">PTT</option>
                                                <option value="Şekerbank">Şekerbank</option>
                                                <option value="TEB">TEB</option>
                                                <option value="Turkish Bank">Turkish Bank</option>
                                                <option value="Türkiye Finans">Türkiye Finans</option>
                                                <option value="TURKIYE VAKIFLAR BANKASI" >TURKIYE VAKIFLAR BANKASI</option>
                                                <option value="Turkland Bank">Turkland Bank</option>
                                                <option value="Vakıf Katılım">Vakıf Katılım</option>
                                                <option value="Vakıfbank">Vakıfbank</option>
                                                <option value="Yapı Kredi">Yapı Kredi</option>
                                                <option value="Ziraat Bankası">Ziraat Bankası</option>
                                                <option value="Ziraat Katılım">Ziraat Katılım</option>


                                            </AvField>
                                        </div>

                                        <div className="mb-3">
                                            <AvField
                                                name="branchCode"
                                                label={props.t("Branch Code")}
                                                type="text"
                                                errorMessage={props.t("InvalidInput")}
                                                validate={{
                                                    required: { value: true },
                                                }}
                                               
                                                value={selectedBankAccount?.branchCode || ""}
                                            />
                                        </div>

                                        <div className="mb-3">
                                            <AvField
                                                name="branchAccountNo"
                                                id="branch_serialNo" 
                                                label={props.t("Branch Account No")}
                                                type="text"
                                                errorMessage={props.t("InvalidInput")}
                                                validate={{
                                                    required: { value: false },
                                                }}
                                                onChange={(e)=>
                                                    {
                                                      e.target.value.length>0 ? document.getElementById("branch_iban").setAttribute("disabled","disabled") : document.getElementById("branch_iban").removeAttribute("disabled");
                                                    }}
                                                value={selectedBankAccount?.branchAccountNo || ""}
                                            />
                                        </div>

                                       <div className="mb-3">
                                                <AvField
                                                    name="iban"
                                                    id="branch_iban" 
                                                    label={props.t("IBAN")}
                                                    type="text"

                                                    errorMessage={props.t("InvalidInput")}
                                                    validate={{
                                                        required: { value: false },
                                                    }}
                                                    value={selectedBankAccount?.iban || ""}
                                                    onChange={(e)=>
                                                        {
                                                          e.target.value.length>0 ? document.getElementById("branch_serialNo").setAttribute("disabled","disabled") : document.getElementById("branch_serialNo").removeAttribute("disabled");
                                                        }}
                                                />
                                            </div>

                                       
                                        <div className="mb-3">
                                                <AvField
                                                    name="description"
                                                    label={props.t("Description")}
                                                    type="text"

                                                    errorMessage={props.t("InvalidInput")}
                                                    validate={{
                                                        required: { value: false },
                                                    }}
                                                    value={selectedBankAccount?.description || ""}
                                                />
                                            </div>




                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                    {bankNameAlert == true ?
                                                <Alert color="danger">
                                                    {props.t("You Have To Select a Bank Name")}
                                                </Alert>
                                                : null
                                            }
                                        <div className="text-end">
                                            
                                            <button
                                                type="submit"
                                                className="btn btn-success save-user"
                                            >
                                                {props.t("Save")}
                                            </button>
                                            &nbsp;&nbsp;
                                            <Button onClick={() => { toggle() }}>
                                                {props.t("Cancel")}
                                            </Button>

                                        </div>
                                    </Col>
                                </Row>
                            </AvForm>
                        </ModalBody>
                    </Modal>
                    {success_dlg ? (
                        <SweetAlert
                            success
                            title={dynamic_title}
                            confirmBtnText={props.t("Ok")}
                            onConfirm={() => {
                                setsuccess_dlg(false)
                            }}
                        >
                            {dynamic_description}
                        </SweetAlert>
                    ) : null}

                    {confirm_alert ? (
                        <SweetAlert
                            title={props.t("Are You Sure")}
                            warning
                            showCancel
                            confirmBtnBsStyle="success"
                            cancelBtnBsStyle="danger"
                            cancelBtnText={props.t("Cancel")}
                            confirmBtnText={props.t("Delete")}
                            onConfirm={() => {
                                setconfirm_alert(false);
                                setsuccess_dlg(true);
                                setdynamic_title(props.t("Deleted"));
                                setdynamic_description("");
                                dispatch(onDeleteBankAccount(selectedId));
                                setSelectedId(null);
                            }}
                            onCancel={() => setconfirm_alert(false)}
                        >
                            {props.t("DeleteWarning")}
                        </SweetAlert>
                    ) : null}


                    {error && error ? (
                        <SweetAlert
                            title={props.t("Error")}
                            warning
                            confirmBtnText={props.t("Ok")}
                            onConfirm={() => {
                                dispatch(onResetBankAccount())
                            }}
                        >
                            {JSON.stringify(error)}
                        </SweetAlert>
                    ) : null}

                </Container>
            </div>
        </React.Fragment >
    )
}

export default withRouter(withTranslation()(BankAccounts))
