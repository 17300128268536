import { call, put, takeEvery } from "redux-saga/effects";

// Crypto Redux States
import { GET_PAYMENT_PLANS, DELETE_PAYMENT_PLAN, ADD_PAYMENT_PLAN, UPDATE_PAYMENT_PLAN,GET_PAYMENT_PLANS_MY_PLANS} from "./actionTypes";
import {
    getPaymentPlansSuccess, getPaymentPlansFail,
    deletePaymentPlanSuccess, deletePaymentPlanFail,
    updatePaymentPlanSuccess, updatePaymentPlanFail,
    addPaymentPlanSuccess, addPaymentPlanFail,
    getPaymentPlans_MyPlansSuccess,getPaymentPlans_MyPlansFail
 } from "./actions";

//Include Both Helper File with needed methods
import {
    getPaymentPlans, //API CONNECTION
    delPaymentPlan,
    addPaymentPlan,
    updatePaymentPlan,
    getPaymentPlans_MyPlans
}
    from "../../helpers/fakebackend_helper"; 

function* fetchPaymentPlans_myPlans() {
        try {
            const response = yield call(getPaymentPlans_MyPlans)
            yield put(getPaymentPlans_MyPlansSuccess(response));
        } catch (error) {
            yield put(getPaymentPlans_MyPlansFail(error));
        }
    }
function* fetchPaymentPlans() {
    try {
        const response = yield call(getPaymentPlans)
        yield put(getPaymentPlansSuccess(response));
    } catch (error) {
        yield put(getPaymentPlansFail(error));
    }
}

function* onDeletePaymentPlans({payload:id}){
    try{
        const response = yield call(delPaymentPlan, id)
        yield put(deletePaymentPlanSuccess(response));
    } catch (error) {
        yield put(deletePaymentPlanFail(error));
    }
}

function* onAddPaymentPlan({payload:paymentPlan}){
    try{
        const response = yield call(addPaymentPlan, paymentPlan)
        yield put(addPaymentPlanSuccess(response));
    } catch (error) {
        yield put(addPaymentPlanFail(error));
    }
}

function* onUpdatePaymentPlan({payload:paymentPlan}){
    try{
        const response = yield call(updatePaymentPlan, paymentPlan)
        yield put(updatePaymentPlanSuccess(response));
    } catch (error) {
        yield put(updatePaymentPlanFail(error));
    }
}

export function* paymentPlansSaga() {
    yield takeEvery(GET_PAYMENT_PLANS, fetchPaymentPlans);
    yield takeEvery(DELETE_PAYMENT_PLAN, onDeletePaymentPlans);
    yield takeEvery(ADD_PAYMENT_PLAN, onAddPaymentPlan);
    yield takeEvery(UPDATE_PAYMENT_PLAN, onUpdatePaymentPlan);
    yield takeEvery(GET_PAYMENT_PLANS_MY_PLANS, fetchPaymentPlans_myPlans);
    
}

export default paymentPlansSaga;
