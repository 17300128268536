import {
    GET_BANKACCOUNTS,
    GET_BANKACCOUNTS_SUCCESS,
    GET_BANKACCOUNTS_FAIL,
    GET_BANKACCOUNT_DETAIL,
    GET_BANKACCOUNT_DETAIL_SUCCESS,
    GET_BANKACCOUNT_DETAIL_FAIL,
    ADD_BANKACCOUNT,
    ADD_BANKACCOUNT_SUCCESS,
    ADD_BANKACCOUNT_FAIL,
    UPDATE_BANKACCOUNT,
    UPDATE_BANKACCOUNT_SUCCESS,
    UPDATE_BANKACCOUNT_FAIL,
    DELETE_BANKACCOUNT,
    DELETE_BANKACCOUNT_SUCCESS,
    DELETE_BANKACCOUNT_FAIL,
    RESET_BANKACCOUNT
} from "./actionTypes";

const INIT_STATE = {
    data: [],
    error: null
};

const BankAccounts = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_BANKACCOUNTS_SUCCESS:
            return {
                ...state,
                data: action.payload.data
            }
        case DELETE_BANKACCOUNT_SUCCESS:
            return {
                ...state,
                data: state.data.filter(bankAccount => bankAccount._id.toString() !== action.payload.data.toString())
            }
        case ADD_BANKACCOUNT_SUCCESS:
            return {
                ...state,
                data: [action.payload.data, ...state.data]
            }
        case UPDATE_BANKACCOUNT_SUCCESS:
            return {
                ...state,
                data: state.data.map(bankAccount =>
                    bankAccount._id.toString() === action.payload.data._id.toString() ?
                        { bankAccount, ...action.payload.data } :
                        bankAccount
                )
            }

        //FAILS
        case GET_BANKACCOUNTS_FAIL:
        case DELETE_BANKACCOUNT_FAIL:
        case ADD_BANKACCOUNT_FAIL:
        case UPDATE_BANKACCOUNT_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case RESET_BANKACCOUNT: return {
            ...state,
            error: null
        }
        default:
            return state;
    }
}


export default BankAccounts;