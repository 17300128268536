import {
    GET_MAINCATEGORIES,
    GET_MAINCATEGORIES_SUCCESS,
    GET_MAINCATEGORIES_FAIL,
    GET_MAINCATEGORY_DETAIL,
    GET_MAINCATEGORY_DETAIL_SUCCESS,
    GET_MAINCATEGORY_DETAIL_FAIL,
    ADD_MAINCATEGORY,
    ADD_MAINCATEGORY_SUCCESS,
    ADD_MAINCATEGORY_FAIL,
    UPDATE_MAINCATEGORY,
    UPDATE_MAINCATEGORY_SUCCESS,
    UPDATE_MAINCATEGORY_FAIL,
    DELETE_MAINCATEGORY,
    DELETE_MAINCATEGORY_SUCCESS,
    DELETE_MAINCATEGORY_FAIL,
    RESET_MAINCATEGORY
} from "./actionTypes";

export const resetMainCategory = () => ({
    type: RESET_MAINCATEGORY,
});

export const getMainCategories = () => ({
    type: GET_MAINCATEGORIES,
});

export const getMainCategoriesSuccess = (MainCategory) => ({
    type: GET_MAINCATEGORIES_SUCCESS,
    payload: MainCategory,
});


export const getMainCategoriesFail = (error) => ({
    type: GET_MAINCATEGORIES_FAIL,
    payload: error,
});

export const deleteMainCategory = (id) => ({
    type:  DELETE_MAINCATEGORY,
    payload: id
});

export const deleteMainCategorySuccess = (data) => ({
    type: DELETE_MAINCATEGORY_SUCCESS,
    payload: data
});

export const deleteMainCategoryFail = (error) => ({
    type: DELETE_MAINCATEGORY_FAIL,
    payload: error,
});


export const addMainCategory = (mainCategory) => ({
    type: ADD_MAINCATEGORY,
    payload: mainCategory
});

export const addMainCategorySuccess = (data) => ({
    type: ADD_MAINCATEGORY_SUCCESS,
    payload: data
});

export const addMainCategoryFail = (error) => ({
    type: ADD_MAINCATEGORY_FAIL,
    payload: error,
});


export const updateMainCategory = (mainCategory) => ({
    type: UPDATE_MAINCATEGORY,
    payload: mainCategory
});

export const updateMainCategorySuccess = (data) => ({
    type: UPDATE_MAINCATEGORY_SUCCESS,
    payload: data
});

export const updateMainCategoryFail = (error) => ({
    type: UPDATE_MAINCATEGORY_FAIL,
    payload: error, 
});