import {
    GET_SALEMODELS,
    GET_SALEMODELS_SUCCESS,
    GET_SALEMODELS_FAIL,
    GET_SALEMODEL_DETAIL,
    GET_SALEMODEL_DETAIL_SUCCESS,
    GET_SALEMODEL_DETAIL_FAIL,
    POST_SALEMODEL,
    POST_SALEMODEL_SUCCESS,
    POST_SALEMODEL_FAIL, 
    DELETE_SALEMODEL,
    DELETE_SALEMODEL_SUCCESS,
    DELETE_SALEMODEL_FAIL,
    RESET_SALEMODEL,
    GET_MODAL_BY_USER_ID,
    GET_MODAL_BY_USER_ID_SUCCESS,
    GET_MODAL_BY_USER_ID_FAIL,
} from "./actionTypes";

export const resetSaleModel = () => ({
    type: RESET_SALEMODEL,
});

export const getSaleModels = () => ({
    type: GET_SALEMODELS,
});

export const getSaleModelsSuccess = (saleModels) => ({
    type: GET_SALEMODELS_SUCCESS,
    payload: saleModels,
});


export const getSaleModelsFail = (error) => ({
    type: GET_SALEMODELS_FAIL,
    payload: error,
});

export const deleteSaleModel = (id) => ({
    type:  DELETE_SALEMODEL,
    payload: id
});

export const deleteSaleModelSuccess = (data) => ({
    type: DELETE_SALEMODEL_SUCCESS,
    payload: data
});

export const deleteSaleModelFail = (error) => ({
    type: DELETE_SALEMODEL_FAIL,
    payload: error,
});


export const postSaleModel = (saleModel) => ({
    type: POST_SALEMODEL,
    payload: saleModel
});

export const postSaleModelSuccess = (data) => ({
    type: POST_SALEMODEL_SUCCESS,
    payload: data
});

export const postSaleModelFail = (error) => ({
    type: POST_SALEMODEL_FAIL,
    payload: error,
}); 

export const getModalByUserId = (UserId) => ({
    type: GET_MODAL_BY_USER_ID,
    payload: UserId
});

export const getModalByUserIdSuccess = (data) => ({
    type: GET_MODAL_BY_USER_ID_SUCCESS,
    payload: data
});

export const getModalByUserIdFail = (error) => ({
    type: GET_MODAL_BY_USER_ID_FAIL,
    payload: error, 
});