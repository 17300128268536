import {
    GET_SALEMODELS,
    GET_SALEMODELS_SUCCESS,
    GET_SALEMODELS_FAIL,
    GET_SALEMODEL_DETAIL,
    GET_SALEMODEL_DETAIL_SUCCESS,
    GET_SALEMODEL_DETAIL_FAIL, 
    POST_SALEMODEL_SUCCESS,
    POST_SALEMODEL_FAIL, 
    DELETE_SALEMODEL,
    DELETE_SALEMODEL_SUCCESS,
    DELETE_SALEMODEL_FAIL,
    RESET_SALEMODEL,
    GET_MODAL_BY_USER_ID_SUCCESS,
    GET_MODAL_BY_USER_ID_FAIL,
} from "./actionTypes";

const INIT_STATE = {
    data: [],
    error: null
};

const SaleModels = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_SALEMODELS_SUCCESS:
            return {
                ...state,
                data: action.payload.data
            }
         case GET_MODAL_BY_USER_ID_SUCCESS:
             return {
                 ...state,
                 data: action.payload.data
             }
        case DELETE_SALEMODEL_SUCCESS:
            return {
                ...state,
                data: state.data.filter(saleModel => saleModel._id.toString() !== action.payload.data.toString())
            }
        case POST_SALEMODEL_SUCCESS:
            return {
                ...state,
                data: [action.payload.data, ...state.data]
            } 

        //FAILS
        case GET_SALEMODELS_FAIL:
        case DELETE_SALEMODEL_FAIL:
        case POST_SALEMODEL_FAIL: 
         case GET_MODAL_BY_USER_ID_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case RESET_SALEMODEL: return {
            ...state,
            error: null
        }
        default:
            return state;
    }
}


export default SaleModels;