import React, { useState } from "react"
import PropTypes from 'prop-types'
import { Container, Row, Col, Modal, ModalHeader, ModalBody,TabContent,TabPane,Label,Input,
    FormGroup} from "reactstrap"
import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
} from "availity-reactstrap-validation"
import { useSelector, useDispatch } from "react-redux"
import { FormatTL, FormatTLBasic } from "./../../../components/turkishMoneyFormat"
import { isEmpty, map } from "lodash"
import { Link, withRouter } from "react-router-dom"
//i18n
import { withTranslation } from "react-i18next"

import {

  addEndOfDayBalancing as OnAddEndOfDayBalancing,

} from "store/actions"

const EndOfDayBalancingModal = (props, cb) => {
  const [branchNum200, setbranchNum200] = useState()
  const [branchNum100, setbranchNum100] = useState()
  const [branchNum50, setbranchNum50] = useState()
  const [branchNum20, setbranchNum20] = useState()
  const [branchNum10, setbranchNum10] = useState()
  const [branchNum5, setbranchNum5] = useState()
  const [branchNum1, setbranchNum1] = useState()
   
  const clearEndOfDayBalancingStates = () => {
    setbranchNum200() 
    setbranchNum100()
    setbranchNum50()
    setbranchNum20()
    setbranchNum10()
    setbranchNum5()
    setbranchNum1()
  }
  const dispatch = useDispatch()
  let totalCash = 200
  let result = 0
  return (
    <Modal isOpen={props.modal} toggle={props.toggle}>
          <ModalHeader toggle={props.toggle} tag="h4">
            {props.t("End Of Day Balancing")}
          </ModalHeader>
          <ModalBody>
            <AvForm>
              <Row form>
                <Col xs={12}>
                  <Container>
                    <div>
                      <Row>
                        <Col md="7" xs="5" className="text-end">
                          <h4>{props.t("Total Money")}</h4>
                        </Col>
                        <Col md="1" xs="1">
                          <h4>=</h4>
                        </Col>
                        <Col md="3" xs="4" className="text-center">
                          <h4>{FormatTLBasic(totalCash.toFixed(2))}</h4>
                        </Col>
                      </Row>
                    </div>

                    <div className="text-center mt-1">
                      <Row>
                        <Col md="4" xs="3" className="text-end">
                          <div className="mt-1">
                            <h4>200 x </h4>
                          </div>
                        </Col>

                        <Col md="3" xs="5" className="text-center">
                          <Input
                            type="text"
                            className="form-control"
                            id="billing-name"
                            defaultValue={0}
                            onChange={event => {
                              setbranchNum200(prevState => ({
                                ...prevState,
                                amount: event.target.value,
                              }))
                            }}
                          />
                        </Col>
                        <Col md="1" xs="1" className="mt-1">
                          <h4>=</h4>
                        </Col>
                        <Col md="3" xs="3">
                          <div className="mt-1 ml-1 mr-1">
                            {branchNum200?.amount == null ? (
                              setbranchNum200(prevState => ({
                                ...prevState,
                                amount: 0,
                              }))
                            ) : (
                              <h4>
                                {FormatTLBasic(branchNum200?.amount * 200)}
                              </h4>
                            )}
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <div className="text-center mt-1">
                      <Row>
                        <Col md="4" xs="3" className="text-end">
                          <div className="mt-1">
                            <h4>100 x</h4>
                          </div>
                        </Col>

                        <Col md="3" xs="5" className="text-center">
                          <Input
                            type="text"
                            className="form-control"
                            id="billing-name"
                            defaultValue={0}
                            onChange={event => {
                              setbranchNum100(prevState => ({
                                ...prevState,
                                amount: event.target.value,
                              }))
                            }}
                          />
                        </Col>
                        <Col md="1" xs="1" className="mt-1">
                          <h4>=</h4>
                        </Col>
                        <Col md="3" xs="3">
                          <div className="mt-1 ml-1 mr-1">
                            {branchNum100?.amount == null ? (
                              setbranchNum100(prevState => ({
                                ...prevState,
                                amount: 0,
                              }))
                            ) : (
                              <h4>
                                {FormatTLBasic(branchNum100?.amount * 100)}
                              </h4>
                            )}
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <div className="text-center mt-1">
                      <Row>
                        <Col md="4" xs="3" className="text-end">
                          <div className="mt-1">
                            <h4>50 x</h4>
                          </div>
                        </Col>

                        <Col md="3" xs="5" className="text-center">
                          <Input
                            type="text"
                            className="form-control"
                            id="billing-name"
                            defaultValue={0}
                            onChange={event => {
                              setbranchNum50(prevState => ({
                                ...prevState,
                                amount: event.target.value,
                              }))
                            }}
                          />
                        </Col>
                        <Col md="1" xs="1" className="mt-1">
                          <h4>=</h4>
                        </Col>
                        <Col md="3" xs="3">
                          <div className="mt-1 ml-1 mr-1">
                            {branchNum50?.amount == null ? (
                              setbranchNum50(prevState => ({
                                ...prevState,
                                amount: 0,
                              }))
                            ) : (
                              <h4>{FormatTLBasic(branchNum50?.amount * 50)}</h4>
                            )}
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <div className="text-center mt-1">
                      <Row>
                        <Col md="4" xs="3" className="text-end">
                          <div className="mt-1">
                            <h4>20 x </h4>
                          </div>
                        </Col>

                        <Col md="3" xs="5" className="text-center">
                          <Input
                            type="text"
                            className="form-control"
                            id="billing-name"
                            defaultValue={0}
                            onChange={event => {
                              setbranchNum20(prevState => ({
                                ...prevState,
                                amount: event.target.value,
                              }))
                            }}
                          />
                        </Col>
                        <Col md="1" xs="1" className="mt-1">
                          <h4>=</h4>
                        </Col>
                        <Col md="3" xs="3">
                          <div className="mt-1 ml-1 mr-1">
                            {branchNum20?.amount == null ? (
                              setbranchNum20(prevState => ({
                                ...prevState,
                                amount: 0,
                              }))
                            ) : (
                              <h4>{FormatTLBasic(branchNum20?.amount * 20)}</h4>
                            )}
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <div className="text-center mt-1">
                      <Row>
                        <Col md="4" xs="3" className="text-end">
                          <div className="mt-1">
                            <h4>10 x </h4>
                          </div>
                        </Col>

                        <Col md="3" xs="5" className="text-center">
                          <Input
                            type="text"
                            className="form-control"
                            id="billing-name"
                            defaultValue={0}
                            onChange={event => {
                              setbranchNum10(prevState => ({
                                ...prevState,
                                amount: event.target.value,
                              }))
                            }}
                          />
                        </Col>
                        <Col md="1" xs="1" className="mt-1">
                          <h4>=</h4>
                        </Col>
                        <Col md="3" xs="3">
                          <div className="mt-1 ml-1 mr-1">
                            {branchNum10?.amount == null ? (
                              setbranchNum10(prevState => ({
                                ...prevState,
                                amount: 0,
                              }))
                            ) : (
                              <h4>{FormatTLBasic(branchNum10?.amount * 10)}</h4>
                            )}
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <div className="text-center mt-1">
                      <Row>
                        <Col md="4" xs="3" className="text-end">
                          <div className="mt-1">
                            <h4>5 x {"  "} </h4>
                          </div>
                        </Col>

                        <Col md="3" xs="5" className="text-center">
                          <Input
                            type="text"
                            className="form-control"
                            id="billing-name"
                            defaultValue={0}
                            onChange={event => {
                              setbranchNum5(prevState => ({
                                ...prevState,
                                amount: event.target.value,
                              }))
                            }}
                          />
                        </Col>
                        <Col md="1" xs="1" className="mt-1">
                          <h4>=</h4>
                        </Col>
                        <Col md="3" xs="3">
                          <div className="mt-1 ml-1 mr-1">
                            {branchNum5?.amount == null ? (
                              setbranchNum5(prevState => ({
                                ...prevState,
                                amount: 0,
                              }))
                            ) : (
                              <h4>{FormatTLBasic(branchNum5?.amount * 5)}</h4>
                            )}
                          </div>
                        </Col>
                      </Row>
                    </div>

                    <div className="text-center  mt-3">
                      <Row>
                        <Col md="7" xs="6" className="mt-1 text-end">
                          <h4>{props.t("Total Change")} </h4>
                        </Col>
                        <Col md="1" xs="1" className="mt-1">
                          <h4>=</h4>
                        </Col>

                        <Col md="3" xs="5" >
                          <Input
                            type="text"
                            className="form-control"
                            id="billing-name"
                            onChange={event => {
                              setbranchNum1(prevState => ({
                                ...prevState,
                                amount: event.target.value,
                              }))
                            }}
                          />
                          <div className="mt-1 ml-1 mr-1">
                            {branchNum1?.amount == null
                              ? setbranchNum1(prevState => ({
                                ...prevState,
                                amount: 0,
                              }))
                              : null}
                          </div>
                        </Col>
                      </Row>
                    </div>

                    <hr />
                    <div className="mt-3">
                      <Row>
                        <Col md="7" xs="8" className="text-end  ">
                          <h4>{props.t("Total")}</h4>
                        </Col>
                        <Col md="1" xs="1">
                          <h4>=</h4>
                        </Col>

                        <Col md="4" xs="3">
                          <h4>
                            {" "}
                            {FormatTL(
                              branchNum200?.amount * 200 +
                              branchNum100?.amount * 100 +
                              branchNum50?.amount * 50 +
                              branchNum20?.amount * 20 +
                              branchNum10?.amount * 10 +
                              branchNum5?.amount * 5 +
                              branchNum1?.amount * 1,
                              2,
                              ",",
                              ".",
                              " TL"
                            )}{" "}
                          </h4>
                        </Col>
                      </Row>
                    </div>
                    <hr />

                    <div>
                      <Row>
                        <Col md="7" xs="8"></Col>
                        <Col md="1" xs="1">
                          <h4>=</h4>
                        </Col>
                        <Col md="4" xs="3">
                          <h4>
                            {" "}
                            {FormatTLBasic(
                              Math.abs(
                                (result =
                                  totalCash -
                                  (branchNum200?.amount * 200 +
                                    branchNum100?.amount * 100 +
                                    branchNum50?.amount * 50 +
                                    branchNum20?.amount * 20 +
                                    branchNum10?.amount * 10 +
                                    branchNum5?.amount * 5 +
                                    branchNum1?.amount * 1))
                              )
                            )}
                          </h4>

                          {totalCash -
                            (branchNum200?.amount * 200 +
                              branchNum100?.amount * 100 +
                              branchNum50?.amount * 50 +
                              branchNum20?.amount * 20 +
                              branchNum10?.amount * 10 +
                              branchNum5?.amount * 5 +
                              branchNum1?.amount * 1) >
                            0 ? (
                            <h4> Eksik</h4>
                          ) : totalCash -
                            (branchNum200?.amount * 200 +
                              branchNum100?.amount * 100 +
                              branchNum50?.amount * 50 +
                              branchNum20?.amount * 20 +
                              branchNum10?.amount * 10 +
                              branchNum5?.amount * 5 +
                              branchNum1?.amount * 1) <
                            0 ? (
                            <h4> Fazla</h4>
                          ) : (
                            <h4>Tam</h4>
                          )}
                        </Col>
                      </Row>
                    </div>
                  </Container>
                </Col>
              </Row>
              <Row className="mt-3"></Row>
              <Row className="mt-3">
                <Col md="12">
                  <div className="text-end">
                    <button
                      className="btn btn-success col-4"
                      onClick={() => {
                        let user = JSON.parse(localStorage.getItem("authUser"))
                        const endOfDay = {
                          profitLoss: result * -1,
                          CompanyId: user.companyId,
                        }

                        dispatch(OnAddEndOfDayBalancing(endOfDay))
                        props.save(true)

                        clearEndOfDayBalancingStates()
                      }}
                    >
                      {props.t("Save")}
                    </button>
                    &nbsp;&nbsp;
                    <button
                      className="btn btn-secondary col-4 "
                      onClick={() => {
                        props.save(true)
                        clearEndOfDayBalancingStates()
                      }}
                    >
                      {props.t("Cancel")}
                    </button>
                  </div>
                </Col>
              </Row>
            </AvForm>
          </ModalBody>
        </Modal>
  )
}


EndOfDayBalancingModal.propTypes = {
 
  modal: PropTypes.bool,
 save:PropTypes.func,
   t: PropTypes.any,
   toggle: PropTypes.func,

}


export default withRouter(
    (withTranslation()(EndOfDayBalancingModal))
  )
