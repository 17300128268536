import React, { useState } from "react"
import PropTypes from 'prop-types'
import { Container, Row, Col, Modal, ModalHeader, ModalBody,TabContent,TabPane,Label,
    FormGroup,
    CardImgOverlay,Input} from "reactstrap"
import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
} from "availity-reactstrap-validation"
import InputMask from "react-input-mask"
import { useSelector, useDispatch } from "react-redux"
import moment from "moment"
import {
  addCustomer as onAddCustomer,
  addErpSuccess,
  getCustomerByIdentity as OnGetCustomerByIdentity,
  getCustomerByPhone as OnGetCustomerByPhone,
  getCustomerByTaxNo as OnGetCustomerByTaxNo,
  
} from "store/actions"

import { isEmpty, map } from "lodash"
import { Link, withRouter } from "react-router-dom"
//i18n
import { withTranslation } from "react-i18next"

const CustomerModal = (props, cb) => {
  const [selectedCustomerType, setSelectedCustomerType] = useState(false)
  const {
    error,
    CustomerInfo
  } = useSelector(state => ({
    error: state.Expenses?.error,
    CustomerInfo: state.Customers?.customerInfo,
    
  }))
  const handleValidDateAvField = date => {
    if (date) {
      const date1 = moment(new Date(date)).format("YYYY-MM-DD")
      return date1
    } else {
      return ""
    }
  }
 
  const dispatch = useDispatch()
 
  return (
    <Modal size="xl" isOpen={props.modal} toggle={props.toggle}>
    <ModalHeader toggle={props.toggle} tag="h4">
            {props.t("Customer Info")}
          </ModalHeader>
          <ModalBody>
            <AvForm >
              <Row form>
                <Col xl={12}>
                  <Row>
                    <Col xl={6}>
                      <div className="mb-3">
                        <AvField
                          name="isType"
                          label={props.t("isType")}
                          type="select"
                          disabled
                          className="form-select"
                          errorMessage={"Invalid Customer Type"}
                          validate={{
                            required: { value: false },
                          }}
                          value={selectedCustomerType?.isType || "individual"}
                          onChange={event => {
                            setSelectedCustomerType(prevState => ({
                              ...prevState,
                              isType: event.target.value,
                            }))
                          }}
                        >
                          <option value="individual">
                            {props.t("Individual")}
                          </option>
                          <option value="company">{props.t("Company")}</option>
                        </AvField>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xl={6}>
                      {selectedCustomerType?.isType === "individual" ||
                        selectedCustomerType?.isType == null ? (
                        <div className="mb-3">
                          <AvField
                            name="identificationNumber"
                            label={props.t("IdentificationNumber")}
                            disabled
                            type="text"
                            errorMessage="invalid Identification Number "
                            value={CustomerInfo?.identityNumber || ""}
                            
                          />
                        </div>
                      ) : null}
                    </Col>
                    <Col xl={6}>
                      {selectedCustomerType?.isType === "individual" ||
                        selectedCustomerType?.isType == null ? (
                          <div className="mb-3">
                          <AvField
                              name="phoneNumber"
                              label={props.t("Phone")}
                              type="text"
                              disabled
                              errorMessage={props.t("InvalidInput")}
                              mask="(999) 999-9999"
                              maskChar="-"
                              tag={[Input, InputMask]}
                              placeholder="(999) 999-9999"
                              validate={{
                                  required: { value: false },
                              }}
                             
                              value={CustomerInfo?.phoneNumber || ""}
                          />
                      </div>
                      ) : null}
                    </Col>
                  </Row>
                  <Row>
                    <Col xl={6}>
                      {selectedCustomerType?.isType === "company" ? (
                        <div className="mb-3">
                          <AvField
                            name="taxNumber"
                            label={props.t("Tax No")}
                            disabled
                            type="text"
                            errorMessage="invalid Tax office"
                            value={""}
                           
                          />
                        </div>
                      ) : null}
                    </Col>
                    <Col xl={6}>
                      {selectedCustomerType?.isType === "company" ? (
                        <div className="mb-3">
                          <AvField
                            name="taxAdministration"
                            label={props.t("Tax Office Name")}
                            disabled
                            type="text"
                            errorMessage="invalid Tax No"
                            value={CustomerInfo?.taxAdministration}
                          />
                        </div>
                      ) : null} 
                    </Col>
                  </Row>
                  <Row>
                    <Col xl={12}>
                      {selectedCustomerType?.isType === "company" ? (
                        <div className="mb-3">
                          <AvField
                            name="customerName"
                            label={props.t("Company Name")}
                            disabled
                            type="text"
                            errorMessage="invalid Company Name"
                            value={CustomerInfo?.name}
                          />
                        </div>
                      ) : null}
                    </Col>
                  </Row>
                  <Row>
                    <Col xl={6}>
                      {selectedCustomerType?.isType === "individual" ||
                        selectedCustomerType?.isType == null ? (
                        <div className="mb-3">
                          <AvField
                            name="customerName"
                            label={props.t("Name")}
                            disabled
                            type="text"
                            errorMessage="invalid Name"
                            value={
                              CustomerInfo?.customerName ||
                            
                              ""
                            }
                          />
                        </div>
                      ) : null}
                    </Col>
                    <Col xl={6}>
                      {selectedCustomerType?.isType === "individual" ||
                        selectedCustomerType?.isType == null ? (
                        <div className="mb-3">
                          <AvField
                            name="lastName"
                            label={props.t("LastName")}
                            disabled
                            type="text"
                            errorMessage="invalid Last Name"
                            value={
                              CustomerInfo?.lastName ||
                             
                              ""
                            }
                          />
                        </div>
                      ) : null}
                    </Col>
                  </Row>
                  <Row>
                    <Col xl={6}>
                      {selectedCustomerType?.isType === "individual" ||
                        selectedCustomerType?.isType == null ? (
                        <div className="mb-3">
                          <AvField
                            name="email"
                            label={props.t("Email")}
                            type="text"
                            disabled
                            errorMessage="invalid Email"
                            value={
                              CustomerInfo?.email ||
                              
                              ""
                            }
                          />
                        </div>
                      ) : null}
                    </Col>

                    <Col xl={6}>
                      {selectedCustomerType?.isType === "individual" ||
                        selectedCustomerType?.isType == null ? (
                        <div className="mb-3">
                          <AvField
                            name="birthday"
                            label={props.t("DateOfBirth")}
                            disabled
                            type="date"
                            errorMessage="invalid Date Of Birth"
                            value={
                              handleValidDateAvField(CustomerInfo?.birthday) ||
                          
                              ""
                            }
                          />
                        </div>
                      ) : null}
                    </Col>
                  </Row>

                  <Row>
                    <Col xl={6}>
                      {selectedCustomerType?.isType === "company" ? (
                       <div className="mb-3">
                       <AvField
                           name="phoneNumber"
                           label={props.t("Phone")}
                           disabled
                           type="text"
                           errorMessage={props.t("InvalidInput")}
                           mask="(999) 999-9999"
                           maskChar="-"
                           tag={[Input, InputMask]}
                           placeholder="(999) 999-9999"
                           validate={{
                               required: { value: false },
                           }}
                           value={CustomerInfo?.phoneNumber}
                       />
                   </div>
                      ) : null}
                    </Col>

                    <Col xl={6}>
                      {selectedCustomerType?.isType === "company" ? (
                        <div className="mb-3">
                          <AvField
                            name="email"
                            label="e-Mail Adresi"
                            disabled
                            type="text"
                            errorMessage="Geçerli bir Email Girin"
                            value={CustomerInfo?.email}
                          />
                        </div>
                      ) : null}
                    </Col>
                  </Row>
                  <Col xl={6}>
                    {selectedCustomerType?.isType === "individual" ||
                      selectedCustomerType?.isType == null ? (
                      <div className="mb-3">
                        <AvField
                          name="gender"
                          label={props.t("Gender")}
                          type="select"
                          disabled
                          className="form-select"
                          errorMessage={"Invalid Gender"}
                          validate={{
                            required: { value: false },
                          }}
                          value={
                            CustomerInfo?.gender ||
                          
                            ""
                          }
                          onChange={event => { }}
                        >
                          <option value="Unspecified">
                            {props.t("Unspecified")}
                          </option>
                          <option value="kadın">{props.t("Female")}</option>
                          <option value="erkek">{props.t("Male")}</option>
                        </AvField>
                      </div>
                    ) : null}
                  </Col>

                  <Col xl={12}>
                    <div className="mb-3">
                      <AvField
                        name="adress"
                        label={props.t("Address")}
                        type="textarea"
                        disabled
                        errorMessage="invalid Address"
                        value={
                          CustomerInfo?.adress ||
                         
                          ""
                        }
                      />
                    </div>
                  </Col>
                  <Row>
                    <Col xl={6}>
                      <div className="mb-3">
                        <AvField
                          name="city"
                          label={props.t("City")}
                          type="text"
                          disabled
                          errorMessage="invalid City"
                          value={
                            CustomerInfo?.city ||
                           
                            ""
                          }
                        />
                      </div>
                    </Col>
                    <Col xl={6}>
                      <div className="mt-4 text-end">
                        {selectedCustomerType?.isType === "company" ? (
                          <AvGroup check>
                            <AvInput
                              type="checkbox"
                              label="eFaturaMukellef"
                              disabled
                              value="false"
                              name="eFaturaMukellef"
                            />
                            <Label check for="checkbox">
                              {" "}
                              {props.t("e fatura Mükellefiyim")}
                            </Label>
                          </AvGroup>
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col md="12" xs="12" className="text-end">
                  
                  <button
                    type="button"
                    className="btn btn-secondary col-md-2 col-xs-5"
                    onClick={() => {
                      props.Cancel(true)
                    }}
                  >
                    {props.t("Cancel")}
                  </button>
                </Col>
              </Row>
            </AvForm>
          </ModalBody>
        </Modal>
  )
}


CustomerModal.propTypes = {
 
  modal: PropTypes.bool,
   payment:PropTypes.func,
   Cancel:PropTypes.func,
   back:PropTypes.func,
   t: PropTypes.any,
   toggle: PropTypes.func,
}


export default withRouter(
    (withTranslation()(CustomerModal))
  )
