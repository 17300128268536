import {
    GET_COLLECTIONS,
    GET_COLLECTIONS_SUCCESS,
    GET_COLLECTIONS_FAIL,
    GET_COLLECTION_DETAIL,
    GET_COLLECTION_DETAIL_SUCCESS,
    GET_COLLECTION_DETAIL_FAIL,
    ADD_COLLECTION,
    ADD_COLLECTION_SUCCESS,
    ADD_COLLECTION_FAIL,
    UPDATE_COLLECTION,
    UPDATE_COLLECTION_SUCCESS,
    UPDATE_COLLECTION_FAIL,
    DELETE_COLLECTION,
    DELETE_COLLECTION_SUCCESS,
    DELETE_COLLECTION_FAIL,
    RESET_COLLECTION
} from "./actionTypes";

const INIT_STATE = {
    data: [],
    error: null
};

const Collections = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_COLLECTIONS_SUCCESS:
            return {
                ...state,
                data: action.payload.data
            }
        case DELETE_COLLECTION_SUCCESS:
            return {
                ...state,
                data: state.data.filter(collections => collections._id.toString() !== action.payload.data.toString())
            }
        case ADD_COLLECTION_SUCCESS:
            return {
                ...state,
                data: [action.payload.data, ...state.data]
            }
        case UPDATE_COLLECTION_SUCCESS:
           return {
                  ...state, 
                 
                  data: state.data.map(x =>
                    x._id.toString() === action.payload.data._id.toString()
                      ? { x, ...action.payload.data }
                      : x
                  ),
                }

        //FAILS
        case GET_COLLECTIONS_FAIL:
        case DELETE_COLLECTION_FAIL:
        case ADD_COLLECTION_FAIL:
        case UPDATE_COLLECTION_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case RESET_COLLECTION: return {
            ...state,
            error: null
        }
        default:
            return state;
    }
}


export default Collections;