import React, { useState } from "react"
import PropTypes from 'prop-types'
import { Container, Row, Col, Modal, ModalHeader, ModalBody,TabContent,TabPane,Label,Input,
    FormGroup} from "reactstrap"
import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
} from "availity-reactstrap-validation"
import { useSelector, useDispatch } from "react-redux"
import {

  getProductsByBarcode as onGetProductsByBarcode,

} from "store/actions"
import { isEmpty, map } from "lodash"
import { Link, withRouter } from "react-router-dom"
//i18n
import { withTranslation } from "react-i18next"

const SaleScreenModal = (props, cb) => {
  const {
    error,
    
    Product, 
    ProductId,
    Customer
  
  } = useSelector(state => ({
    error: state.Expenses?.error, 
   
    Product: state.ProcessList?.Product,
    ProductId: state.ProcessList?.ProductId,
    
  }))
  const dispatch = useDispatch()
 
  return (
    <Modal isOpen={props.modal} toggle={props.toggle}>
    <ModalHeader toggle={props.toggle} tag="h4">
      {props.t("Sale")}
    </ModalHeader>
    <ModalBody>
      <AvForm>
        <Row form>
          <Col xs={12}>
            <Container>
            {props.t("Product Name")} :{Product?.name}   <br />
            {props.t("Product Barcode")} : {Product?.barcode}
              <br />
             {props.t("Customer Title")} : {props.customerName?props.customerName:"Nihai Tüketici"}
              <br />
             
              
            </Container>
          </Col>
        </Row>
        <Row className="mt-3">
          <div className="text-end mt-3">
            <button
              className="btn btn-success "
              onClick={() => {
                props.payment(true)
              }}
            >
              + {props.t("AddPayment")}
            </button>
          </div>
        </Row>
        <Row className="mt-3">
          <Col md="8">
            <div className="text-center">
              <button
                className="btn btn-primary col-4 "
                onClick={() => {
                    props.back(true)
                }}
              >
                {props.t("Back")} 
              </button>
              
            </div>
          </Col>
        </Row>
      </AvForm>
    </ModalBody>
  </Modal>
  )
}


SaleScreenModal.propTypes = {
 
  modal: PropTypes.bool,
  continue:PropTypes.func,
   payment:PropTypes.func,
   back:PropTypes.func,
   t: PropTypes.any,
   toggle: PropTypes.func,
   customerName :PropTypes.string,
}


export default withRouter(
    (withTranslation()(SaleScreenModal))
  )
