import {
    GET_BRANCHS,
    GET_BRANCHS_SUCCESS,
    GET_BRANCHS_FAIL,
    GET_BRANCH_DETAIL,
    GET_BRANCH_DETAIL_SUCCESS,
    GET_BRANCH_DETAIL_FAIL,
    ADD_BRANCH,
    ADD_BRANCH_SUCCESS,
    ADD_BRANCH_FAIL,
    UPDATE_BRANCH,
    UPDATE_BRANCH_SUCCESS,
    UPDATE_BRANCH_FAIL,
    DELETE_BRANCH,
    DELETE_BRANCH_SUCCESS,
    DELETE_BRANCH_FAIL,
    GET_COMPANY_BRANCHES,
    GET_COMPANY_BRANCHES_SUCCESS,
    GET_COMPANY_BRANCHES_FAIL,
    RESET_BRANCH,
    GET_USER_BRANCH,
    GET_USER_BRANCH_SUCCESS,
    GET_USER_BRANCH_FAIL
} from "./actionTypes";
 
export const resetBranch = () => ({
    type: RESET_BRANCH,
});
export const getBranchById = (id) => ({
    type: GET_BRANCH_DETAIL,
    payload:id
});
export const getBranchByIdSuccess = (data) => ({
    type: GET_BRANCH_DETAIL_SUCCESS,
    payload: data
}); 
export const getBranchByIdFail = (error) => ({
    type: GET_BRANCH_DETAIL_FAIL,
    payload: error,
});
export const getCompanyBranches = (id) => ({
    type: GET_COMPANY_BRANCHES,
    payload:id
});
export const getCompanyBranchesSuccess = (data) => ({
    type: GET_COMPANY_BRANCHES_SUCCESS,
    payload: data
});
export const getCompanyBranchesFail = (error) => ({
    type: GET_COMPANY_BRANCHES_FAIL,
    payload: error,
});
export const getUserBranch = (id) => ({
    type: GET_USER_BRANCH,
    payload:id
});
export const getUserBranchSuccess = (data) => ({
    type: GET_USER_BRANCH_SUCCESS,
    payload: data
});
export const getUserBranchFail = (error) => ({
    type: GET_USER_BRANCH_FAIL,
    payload: error,
});
export const getBranchs = () => ({
    type: GET_BRANCHS,
});

export const getBranchsSuccess = (branchs) => ({
    type: GET_BRANCHS_SUCCESS,
    payload: branchs,
});


export const getBranchsFail = (error) => ({
    type: GET_BRANCHS_FAIL,
    payload: error,
});

export const deleteBranch = (id) => ({
    type:  DELETE_BRANCH,
    payload: id
});

export const deleteBranchSuccess = (data) => ({
    type: DELETE_BRANCH_SUCCESS,
    payload: data
});

export const deleteBranchFail = (error) => ({
    type: DELETE_BRANCH_FAIL,
    payload: error,
});


export const addBranch = (branch, file) => ({
    type: ADD_BRANCH,
    payload: branch, 
    file: file
});

export const addBranchSuccess = (data) => ({
    type: ADD_BRANCH_SUCCESS,
    payload: data
});

export const addBranchFail = (error) => ({
    type: ADD_BRANCH_FAIL,
    payload: error,
});


export const updateBranch = (branch, file) => ({
    type: UPDATE_BRANCH,
    payload: branch,
    file: file
});

export const updateBranchSuccess = (data) => ({
    type: UPDATE_BRANCH_SUCCESS,
    payload: data
});

export const updateBranchFail = (error) => ({
    type: UPDATE_BRANCH_FAIL,
    payload: error,
});