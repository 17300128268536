import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useState, useEffect } from "react"
import {
    Container,
    Row,
    Col,
    Card,
    Alert,
    CardBody,
    Media,
    Button,
    UncontrolledDropdown,
    UncontrolledTooltip,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Modal,
    ModalHeader,
    ModalBody,
    Label
} from "reactstrap"
//redux
import { useSelector, useDispatch } from "react-redux"
import { withRouter } from "react-router-dom"
//i18n
import { withTranslation } from "react-i18next"
//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"

import {
    getPaymentPlans as onGetPaymentPlans,
    deletePaymentPlan as onDeletePaymentPlan,
    addPaymentPlan as onAddPaymentPlan,
    updatePaymentPlan as onUpdatePaymentPlan,
    resetPaymentPlan as onResetPaymentPlan,
    getCompanyBranches as onGetCompanyBranches,
} from "store/actions"

import { isEmpty, map } from "lodash"
import moment from 'moment'

import { AvForm, AvField, AvGroup, AvInput, AvFeedback, AvRadioGroup, AvRadio, AvCheckboxGroup, AvCheckbox } from "availity-reactstrap-validation"

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
    PaginationProvider, PaginationListStandalone,
    SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert"
import "./datatables.scss"

const PaymentPlanList = props => {
    const dispatch = useDispatch()

    const [selectedId, setSelectedId] = useState();
    const [selectedPaymentPlan, setSelectedPaymentPlan] = useState();
    const [success_dlg, setsuccess_dlg] = useState(false);
    const [confirm_alert, setconfirm_alert] = useState(false);
    const [dynamic_title, setdynamic_title] = useState("");
    const [dynamic_description, setdynamic_description] = useState("");
    const [modal, setModal] = useState(false);
    const [modalInputs, setModalInputs] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [selectedBranchId, setSelectedBranchId] = useState()
    const [inputVariables, setInputVariables] = useState([
        {
            name: "string",
            text: "Text"
        },
        {
            name: "date",
            text: "Tarih"
        },
        {
            name: "number",
            text: "Sayı"
        },
        {
            name: "boolean",
            text: "Doğru / Yanlış"
        },
        {
            name: "dropdown",
            text: "Liste"
        }
    ])
    const [paymentPlanObjects, setPaymentPlanObjects] = useState([]);



    const { error, PaymentPlans,Branches } = useSelector(state => ({
        error: state.PaymentPlans?.error,
        PaymentPlans: state.PaymentPlans?.data,
        Branches : state.Branchs?.Branches
    }))
    let user = JSON.parse(localStorage.getItem("authUser"))
    useEffect(() => {
        dispatch(onGetPaymentPlans())
        dispatch(onGetCompanyBranches(user.companyId))
    }, [dispatch])



    const toggle = () => {
        setModal(!modal)
    }

    const toggleInputs = () => {
        setModalInputs(!modalInputs);
    }


    //Dynamic Inputs START 

    const handleChangeInput = (i, e, inputName) => {
        const values = [...paymentPlanObjects]
        values[i][inputName] = e.target.value
        setPaymentPlanObjects(values)
    }


    const handleAdd = () => {
        setPaymentPlanObjects([...paymentPlanObjects,
        {
            title: "",
            isType: "string",
            isValue: ""
        }])
    }

    const handleSubtract = (i) => {
        const values = [...paymentPlanObjects]
        values.splice(i, 1)
        setPaymentPlanObjects([...values])
    }

    const InputAddReset = () => {
        let tempInput = [
          
        ];
        setPaymentPlanObjects(tempInput);
    }

    //Dynamic Inputs END


    const columns = [
        {
            dataField: 'name',
            text: props.t("Name"),
            sort: true,
        },
        {
            dataField: 'description',
            text: props.t("Description"),
            sort: true,
 
        },
        
        {
            dataField: '_id',
            text: props.t("Action"),
            sort: false,
            formatter: (cell, row) => (
                <UncontrolledDropdown style={{ position: "unset" }}>
                    <DropdownToggle href="#" className="card-drop" tag="i">
                        <i className="mdi mdi-dots-horizontal font-size-18"></i>
                    </DropdownToggle>
                    <div className="drop-absolute">
                    <DropdownMenu className="dropdown-menu-end">
                        <DropdownItem href="#"
                            onClick={() => {
                                setSelectedPaymentPlan(row); 
                                setSelectedBranchId(row.branchId?._id)
                                if (isEmpty(row.PaymentPlanObjects) || row.PaymentPlanObjects.length == 0) {
                                    InputAddReset();
                                }
                                else {
                                    setPaymentPlanObjects(row.PaymentPlanObjects);
                                }
                                setIsEdit(true);
                                toggle();
                            }}
                        >
                            <i className="mdi mdi-pencil font-size-16 text-success me-1"></i>
                            {props.t("Edit")}
                        </DropdownItem>
                        <DropdownItem href="#"
                            onClick={() => {
                                setSelectedId(cell);
                                setconfirm_alert(true);
                            }}>
                            <i className="mdi mdi-trash-can font-size-16 text-danger me-1"></i>
                            {props.t("Delete")}
                        </DropdownItem>
                    </DropdownMenu>
                    </div>
                </UncontrolledDropdown>
            )
        }];
 
    const defaultSorted = [{
        dataField: 'createdAt',
        order: 'desc'
    }];

    const pageOptions = {
        sizePerPage: 10,
        totalSize: PaymentPlans ? PaymentPlans.length : 0, // replace later with size(PaymentPlanInputs),
        custom: true,
    }

    // Custom Pagination Toggle
    const sizePerPageList = [
        { text: '5', value: 5 },
        { text: '10', value: 10 },
        { text: '15', value: 15 },
        { text: '20', value: 20 },
        { text: '25', value: 25 },
        { text: 'All', value: PaymentPlans ? PaymentPlans.length : 0 }];


    // Select All Button operation
    const selectRow = {
        mode: 'checkbox'
    }

    const { SearchBar } = Search;

    const handleValidPaymentPlanSubmit = (e, values) => {
        if (isEdit) {
            const updatePaymentPlan = {
                _id: selectedPaymentPlan._id,
                name: values.name,
                description: values.description,
                PaymentPlanObjects: document.getElementById("PaymentPlanObjects").value,
                CompanyId: user.companyId,
               
            };
            // update PaymentPlan
            dispatch(onUpdatePaymentPlan(updatePaymentPlan));
        } else {
            const newPaymentPlan = {
                name: values.name,
                description: values.description,
                PaymentPlanObjects: document.getElementById("PaymentPlanObjects").value,
                CompanyId: user.companyId,
               
            }
            // save new PaymentPlan
           
            dispatch(onAddPaymentPlan(newPaymentPlan))
        }
        toggle()
    }

    const handleValidDate = date => {
        if (date) {
            const date1 = moment(new Date(date)).format("DD.MM.YYYY");
            return date1;
        } else {
            return "";
        }
    }
    const handleValidDateAvField = date => {
        if (date) {
            const date1 = moment(new Date(date)).format("YYYY-MM-DD");
            return date1;
        } else {
            return "";
        }
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>{props.t("PaymentMethod")}</title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumb title={props.t("PaymentMethod")} breadcrumbItem={props.t("PaymentMethod")} addBtn={true} addBtnCallBack={() => {
                        setIsEdit(false);
                        setSelectedPaymentPlan(
                            {
                                name: "",
                                description: "",
                                PaymentPlanObjects: [],
                            }
                        );
                        InputAddReset()
                        toggle();

                    }} />

                    {/* {error && error ? <Alert color="danger">{error}</Alert> : null} */}

                    <Card>
                        <CardBody>

                            <PaginationProvider
                                pagination={paginationFactory(pageOptions)}
                                keyField='_id'
                                columns={columns}
                                data={PaymentPlans}
                            >
                                {({ paginationProps, paginationTableProps }) => (
                                    <ToolkitProvider
                                        keyField='_id'
                                        columns={columns}
                                        data={PaymentPlans}
                                        search
                                    >
                                        {toolkitProps => (
                                            <React.Fragment>

                                                <Row className="mb-2">
                                                    <Col md="4">
                                                        <div className="search-box me-2 mb-2 d-inline-block">
                                                            <div className="position-relative">
                                                                <SearchBar
                                                                    {...toolkitProps.searchProps}
                                                                />
                                                                <i className="bx bx-search-alt search-icon" />
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col xl="12">
                                                        <div className="table-responsive">
                                                            <BootstrapTable
                                                                keyField={"_id"}
                                                                responsive
                                                                bordered={false}
                                                                striped={false}
                                                                defaultSorted={defaultSorted}
                                                                classes={
                                                                    "table align-middle table-nowrap"
                                                                }
                                                                headerWrapperClasses={"thead-light"}
                                                                {...toolkitProps.baseProps}
                                                                {...paginationTableProps}
                                                            />

                                                        </div>
                                                    </Col>
                                                </Row>

                                                <Row className="align-items-md-center mt-30">
                                                    <Col className="inner-custom-pagination d-flex">
                                                        <div className="d-inline">
                                                            <SizePerPageDropdownStandalone
                                                                {...paginationProps}
                                                            />
                                                        </div>
                                                        <div className="text-md-right ms-auto">
                                                            <PaginationListStandalone
                                                                {...paginationProps}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </React.Fragment>
                                        )
                                        }
                                    </ToolkitProvider>
                                )
                                }</PaginationProvider>

                        </CardBody>
                    </Card>


                    <Modal isOpen={modal} toggle={toggle}>
                        <ModalHeader toggle={toggle} tag="h4">
                            {!!isEdit ? props.t("EditPaymentMethod") : props.t("AddPaymentMethod")}
                        </ModalHeader>
                        <ModalBody>
                            <AvForm onValidSubmit={handleValidPaymentPlanSubmit}>
                                <Row form>
                                    <Col xs={12}>

                                        <input
                                            id="PaymentPlanObjects"
                                            name={props.t("Payment Plan")}
                                            type="hidden"
                                        />
                                       
                                        <div className="mb-3">
                                            <AvField
                                                name="name"
                                                label={props.t("Name")}
                                                type="text"
                                                errorMessage={props.t("Invalid Name")}
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                value={selectedPaymentPlan?.name || ""}
                                            >
                                            </AvField>
                                        </div>
 

                                        <div className="mb-3">
                                            <AvField
                                                name="description"
                                                label={props.t("Description")}
                                                type="text"
                                                errorMessage={props.t("Invalid Description")}
                                                validate={{
                                                    required: { value: false },
                                                }}
                                                value={selectedPaymentPlan?.description || ""}
                                            />
                                        </div>

                                    </Col>
                                </Row>
                                <Row>
                                    <Col >
                                        <div className="text-end mb-3">
                                            <Button
                                                className="btn btn-success"
                                                onClick={() => { toggleInputs() }}

                                            >
                                                {props.t("Payment Plan")}
                                            </Button>

                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div className="text-end">
                                            <button
                                                type="submit"
                                                className="btn btn-success save-user"
                                            >
                                                {props.t("Save")}
                                            </button>
                                            &nbsp;&nbsp;
                                            <Button onClick={() => { toggle() }}>
                                                {props.t("Cancel")}
                                            </Button>

                                        </div>

                                    </Col>
                                </Row>
                            </AvForm>
                        </ModalBody>
                    </Modal>

                    <Modal isOpen={modalInputs} toggle={toggleInputs} size="lg">
                        <ModalHeader toggle={toggleInputs} tag="h4">
                            {props.t("Payment Plan")}
                        </ModalHeader>
                        <ModalBody>
                            <Row>
                                <Col md="3">
                                <small className="mb-3" style={{position:"relative",top:"10px"}}>
                                {props.t("Please Enter PaymentPlanObjects")}

                            </small >
                                </Col>
                                <Col md="6">
                                {paymentPlanObjects.length == 0 ? <Button onClick={() => handleAdd()} className="btn btn-success me-2">
                                                                    <i className="fas fa-plus"></i>
                                                                </Button> : null}
                                </Col>
                            </Row>
                            
                            <hr/>
                           
                            
                            <AvForm>
                                <Row form>
                                    <Col xs={12} className="pb-4">
                                        <Row className="mt-2">
                                            <Col md="4">{props.t("Title")}</Col>
                                            <Col md="3">{props.t("isType")}</Col>
                                            <Col md="3"></Col>
                                            <Col md="2"></Col>
                                        </Row>
                                        {paymentPlanObjects.map((item, i) => (
                                            <div key={i}>
                                                <input
                                                    id={"paymentPlanObjects_" + (i) + "__isValue"}
                                                    name={"paymentPlanObjects[" + (i) + "].isValue"}
                                                    type="hidden"
                                                />
                                                <Row className="mt-2">
                                                    <Col md="4">
                                                        <AvField
                                                            type="text"
                                                            id={"paymentPlanObjects_" + (i) + "__title"}
                                                            name={"paymentPlanObjects[" + (i) + "].title"}
                                                            placeholder={props.t("Title")}
                                                            value={item.title}
                                                            onChange={e => handleChangeInput(i, e, "title")}
                                                            validate={{
                                                                required: { value: false },
                                                            }}
                                                        />
                                                    </Col>
                                                    <Col md="3">
                                                        <AvField type="select"
                                                            id={"paymentPlanObjects_" + (i) + "__isType"}
                                                            name={"paymentPlanObjects[" + (i) + "].isType"}
                                                            value={item.isType} 
                                                            className="form-select"
                                                            onChange={e => handleChangeInput(i, e, "isType")}
                                                        >
                                                            <option>Veri Tipi Seç</option>
                                                            {inputVariables?.map((item, index) => (
                                                                <option key={index} value={item.name} >{item.text}</option>
                                                            ))}
                                                        </AvField>
                                                    </Col>
                                                    {item.isType === "dropdown" ?
                                                        <Col md="3">
                                                            <AvField
                                                                type="text"
                                                                id={"paymentPlanObjects_" + (i) + "__isOptions"}
                                                                name={"paymentPlanObjects[" + (i) + "].isOptions"}
                                                                placeholder={props.t("Options") + " (Virgül ile ayır)"}
                                                                value={item.isOptions}
                                                                onChange={e => handleChangeInput(i, e, "isOptions")}
                                                                validate={{
                                                                    required: { value: false },
                                                                }}
                                                            />
                                                        </Col> : null}
                                                    <Col md="2">
                                                        {i == paymentPlanObjects.length - 1 ?
                                                            <div>
                                                                <Button onClick={() => handleAdd()} className="btn btn-success me-2">
                                                                    <i className="fas fa-plus"></i>
                                                                </Button>
                                                                 <Button onClick={() => handleSubtract(i)}>
                                                                    <i className="fas fa-minus"></i>
                                                                </Button> 
                                                                {/* {paymentPlanObjects.length == 0 ? <Button onClick={() => handleAdd()}>
                                                                    <i className="fas fa-plus"></i>
                                                                </Button> : null}
                                                                {console.log("uzunluğu",paymentPlanObjects.length)} */}
                                                            </div> : null
                                                        }
                                                    </Col>
                                                    {console.log("uzunluğu",paymentPlanObjects)}

                                                </Row>
                                            </div>
                                        ))}
                                    </Col>
                                </Row>

                                <Row>
                                    <Col >
                                        <div className="text-end">
                                            <Button onClick={() => {
                                                document.getElementById("PaymentPlanObjects").value = JSON.stringify(paymentPlanObjects);
                                               
                                                toggleInputs();
                                            }} className="btn btn-success save-user">
                                                {props.t("Save")}
                                            </Button>
                                            &nbsp;&nbsp;
                                            <Button onClick={() => {
                                                toggleInputs();
                                            }}
                                            >
                                                {props.t("Cancel")}
                                            </Button>

                                        </div>
                                    </Col>
                                </Row>
                            </AvForm>
                        </ModalBody>
                    </Modal>

                    {success_dlg ? (
                        <SweetAlert
                            success
                            title={dynamic_title}
                            confirmBtnText={props.t("Ok")}
                            onConfirm={() => {
                                setsuccess_dlg(false)
                            }}
                        >
                            {dynamic_description}
                        </SweetAlert>
                    ) : null}

                    {confirm_alert ? (
                        <SweetAlert
                            title={props.t("Are You Sure")}
                            warning
                            showCancel
                            confirmBtnBsStyle="success"
                            cancelBtnBsStyle="danger"
                            cancelBtnText={props.t("Cancel")}
                            confirmBtnText={props.t("Delete")}
                            onConfirm={() => {
                                setconfirm_alert(false);
                                setsuccess_dlg(true);
                                setdynamic_title(props.t("Deleted"));
                                setdynamic_description("");
                                dispatch(onDeletePaymentPlan(selectedId));
                                setSelectedId(null);
                            }}
                            onCancel={() => setconfirm_alert(false)}
                        >
                            {props.t("DeleteWarning")}
                        </SweetAlert>
                    ) : null}


                    {error && error ? (
                        <SweetAlert
                            title={props.t("Error")}
                            warning
                            confirmBtnText={props.t("Ok")}
                            onConfirm={() => {
                                dispatch(onResetPaymentPlan())
                            }}
                        >
                            {JSON.stringify(error)}
                        </SweetAlert>
                    ) : null}

                </Container>
            </div>
        </React.Fragment >
    )
}

export default withRouter(withTranslation()(PaymentPlanList))
