import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useState, useEffect } from "react"
import {
    Container,
    Row,
    Col,
    Card,
    Alert,
    CardBody,
    Media,
    Button,
    UncontrolledDropdown,
    UncontrolledTooltip,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Modal,
    ModalHeader,
    ModalBody,
    Label,
    Input
} from "reactstrap"
//redux
import { useSelector, useDispatch } from "react-redux"
import InputMask from "react-input-mask"
import { withRouter } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"



//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"

import {
    getCustomers as onGetCustomers,
    deleteCustomer as onDeleteCustomer,
    addCustomer as onAddCustomer,
    updateCustomer as onUpdateCustomer,
    resetCustomer as onResetCustomer,
    getCompanyBranches as onGetCompanyBranches,
} from "store/actions"

import { isEmpty, map } from "lodash"
import moment from 'moment'

import { AvForm, AvField, AvGroup, AvInput, AvFeedback, AvRadioGroup, AvRadio, AvCheckboxGroup, AvCheckbox } from "availity-reactstrap-validation"

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
    PaginationProvider, PaginationListStandalone,
    SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert"

import "./datatables.scss"

const Customers = props => {
    const dispatch = useDispatch()

    const [selectedId, setSelectedId] = useState();
    const [selectedCustomer, setSelectedCustomer] = useState();
    const [success_dlg, setsuccess_dlg] = useState(false);
    const [confirm_alert, setconfirm_alert] = useState(false);
    const [dynamic_title, setdynamic_title] = useState("");
    const [dynamic_description, setdynamic_description] = useState("");
    const [modal, setModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    


    let user = JSON.parse(localStorage.getItem("authUser"))
    const { error, Customers, Branches } = useSelector(state => ({
        error: state.Customers?.error,
        Customers: state.Customers?.data,
        Branches: state.Branchs?.Branches
    }))

    useEffect(() => {
        dispatch(onGetCustomers());
        dispatch(onGetCompanyBranches(user.companyId))
    }, [dispatch]);

    const toggle = () => {
        setModal(!modal)
    }



    const columns = [
        {
            dataField: 'isType',
            text: props.t("isType"),
            sort: true,
            formatter: (cell, row) => {
                switch (cell) {
                    case "individual": return <span>{props.t("Individual")}</span>;
                    case "corporate": return <span>{props.t("Corporate")}</span>;
                    default: return <span>?</span>;
                }
            }
        },
        {
            dataField: 'customerName',
            text: props.t("Name"),
            sort: true

        },
        {
            dataField: 'lastName',
            text: props.t("LastName"),
            sort: true

        },
        {
            dataField: 'birthday',
            text: props.t("DateOfBirth"),
            sort: true,
            formatter: (cell, row) => (
                <span>{handleValidDate(cell)}</span>
            )
        },
        {
            dataField: 'phoneNumber',
            text: props.t("Phone"),
            sort: true
        },
        {
            dataField: 'email',
            text: "email",
            sort: true
        },
        {
            dataField: 'identityNumber',
            text: props.t("IdentificationNumber"),
            sort: true
        },
        {
            dataField: 'gender',
            text: props.t("Gender"),
            sort: true
        },
        {
            dataField: 'adress',
            text: props.t("Address"),
            sort: true
        },
        {
            dataField: 'city',
            text: props.t("City"),
            sort: true
        },
        {
            dataField: 'taxAdministration',
            text: props.t("TaxAdministration"),
            sort: true
        },
        {
            dataField: 'taxNumber',
            text: props.t("TaxNo"),
            sort: true
        },
        
        {
            dataField: 'eFaturaMukellef',
            text: "eFaturaMukellef",
            sort: true,
            formatter: (cell, row) => (
                <span>{cell === true ? "Evet" : "Hayır"}</span>
            )
        },
       

        {
            dataField: '_id',
            text: props.t("Action"),
            sort: false,
            formatter: (cell, row) => (
                <UncontrolledDropdown style={{ position: "unset" }}>
                    <DropdownToggle href="#" className="card-drop" tag="i">
                        <i className="mdi mdi-dots-horizontal font-size-18"></i>
                    </DropdownToggle>
                    <div className="drop-absolute">
                        <DropdownMenu className="dropdown-menu-end">
                            <DropdownItem href="#"
                                onClick={() => {
                                    setSelectedCustomer(row);
                                    setIsEdit(true);
                                  
                                    toggle();
                                }}
                            >
                                <i className="mdi mdi-pencil font-size-16 text-success me-1"></i>
                                {props.t("Edit")}
                            </DropdownItem>
                            <DropdownItem href="#"
                                onClick={() => {
                                    setSelectedId(cell);
                                    setconfirm_alert(true);
                                }}>
                                <i className="mdi mdi-trash-can font-size-16 text-danger me-1"></i>
                                {props.t("Delete")}
                            </DropdownItem>
                        </DropdownMenu>
                    </div>
                </UncontrolledDropdown>
            )
        }];

    const defaultSorted = [{
        dataField: 'createdAt',
        order: 'desc'
    }];

    const pageOptions = {
        sizePerPage: 10,
        totalSize: Customers ? Customers.length : 0, // replace later with size(customers),
        custom: true,
    }

    // Custom Pagination Toggle
    const sizePerPageList = [
        { text: '5', value: 5 },
        { text: '10', value: 10 },
        { text: '15', value: 15 },
        { text: '20', value: 20 },
        { text: '25', value: 25 },
        { text: 'All', value: Customers ? Customers.length : 0 }];


    // Select All Button operation
    const selectRow = {
        mode: 'checkbox'
    }

    const { SearchBar } = Search;

    const handleValidCustomerSubmit = (e, values) => {
        if (isEdit) {
            const updateCustomer = {
                _id: selectedCustomer._id,
                customerName: values.customerName,
                lastName: values.lastName,
                isType: values["isType"],
                birthday: values["birthday"],
                phoneNumber: values["phoneNumber"],
                email: values["email"],
                identityNumber: values["identityNumber"],
                gender: values["gender"],
                adress: values["adress"],
                city: values["city"],
                taxAdministration: values["taxAdministration"],
                taxNumber: values["taxNumber"],
               
                eFaturaMukellef: isEmpty(values.eFaturaMukellef) ? true : values.eFaturaMukellef,
                CompanyId: user.companyId,
              
            };
            // update Customer
            dispatch(onUpdateCustomer(updateCustomer));
        } else {
            const newCustomer = {
                customerName: values.customerName,
                lastName: values.lastName,
                isType: values["isType"],
                birthday: values["birthday"],
                phoneNumber: values["phoneNumber"],
                email: values["email"],
                identityNumber: values["identityNumber"],
                gender: values["gender"],
                adress: values["adress"],
                city: values["city"],
                taxAdministration: values["taxAdministration"],
                taxNumber: values["taxNumber"],
              
                eFaturaMukellef: isEmpty(values.eFaturaMukellef) ? true : values.eFaturaMukellef,
                CompanyId: user.companyId,
              

            }
            // save new Customer
            dispatch(onAddCustomer(newCustomer))
        }
        toggle()
    }

    const handleValidDate = date => {
        if (date) {
            const date1 = moment(new Date(date)).format("DD.MM.YYYY");
            return date1;
        } else {
            return "";
        }
    }
    const handleValidDateAvField = date => {
        if (date) {
            const date1 = moment(new Date(date)).format("YYYY-MM-DD");
            return date1;
        } else {
            return "";
        }
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>{props.t("Customers")}</title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumb title={props.t("Customers")} breadcrumbItem={props.t("Customers")} addBtn={true} addBtnCallBack={() => {
                        setIsEdit(false);
                        setSelectedCustomer(
                            {
                                customerName: "",
                                lastName: "",
                                isType: "individual",
                                birthday: "",
                                phoneNumber: "",
                                email: "",
                                identityNumber: "",
                                gender: "",
                                adress: "",
                                city: "",
                                taxAdministration: "",
                                taxNumber: "",
                                nihaiTuketici: "",
                                eFaturaMukellef: "",

                            }
                        );
                        toggle();

                    }} />

                    {/* {error && error ? <Alert color="danger">{error}</Alert> : null} */}

                    <Card>
                        <CardBody>
                            <PaginationProvider
                                pagination={paginationFactory(pageOptions)}
                                keyField='id'
                                columns={columns}
                                data={Customers}
                            >
                                {({ paginationProps, paginationTableProps }) => (
                                    <ToolkitProvider
                                        keyField='_id'
                                        columns={columns}
                                        data={Customers}
                                        search
                                    >
                                        {toolkitProps => (
                                            <React.Fragment>

                                                <Row className="mb-2">
                                                    <Col md="12">
                                                        <div className="search-box me-2 mb-2 d-inline-block">
                                                            <div className="position-relative">
                                                                <SearchBar
                                                                    {...toolkitProps.searchProps}
                                                                />
                                                                <i className="bx bx-search-alt search-icon" />
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col xl="12">
                                                        <div className="table-responsive">
                                                            <BootstrapTable
                                                                keyField={"_id"}
                                                                responsive
                                                                bordered={false}
                                                                striped={false}
                                                                defaultSorted={defaultSorted}
                                                                classes={
                                                                    "table align-middle table-nowrap"
                                                                }
                                                                headerWrapperClasses={"thead-light"}
                                                                {...toolkitProps.baseProps}
                                                                {...paginationTableProps}
                                                            />

                                                        </div>
                                                    </Col>
                                                </Row>

                                                <Row className="align-items-md-center mt-30">
                                                    <Col className="inner-custom-pagination d-flex">
                                                        <div className="d-inline">
                                                            <SizePerPageDropdownStandalone
                                                                {...paginationProps}
                                                            />
                                                        </div>
                                                        <div className="text-md-right ms-auto">
                                                            <PaginationListStandalone
                                                                {...paginationProps}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </React.Fragment>
                                        )
                                        }
                                    </ToolkitProvider>
                                )
                                }</PaginationProvider>

                        </CardBody>
                    </Card>


                    <Modal isOpen={modal} toggle={toggle}>

                        <ModalHeader toggle={toggle} tag="h4" >
                            {!!isEdit ? props.t("EditCustomer") : props.t("AddCustomer")}
                        </ModalHeader>
                        <ModalBody>

                            <AvForm onValidSubmit={handleValidCustomerSubmit}>
                                <Row form>
                                    <Col xs={12}>
                                       
                                        <div className="mb-3">
                                            <AvField
                                                name="isType"
                                                label={props.t("Customer Type")}
                                                type="select"
                                                className="form-select"
                                                errorMessage={props.t("Invalid Customer Type")}
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                value={selectedCustomer?.isType || "individual"}
                                                onChange={(event) => {
                                                    setSelectedCustomer(prevState => ({
                                                        ...prevState,
                                                        isType: event.target.value
                                                    }));

                                                }}

                                            >
                                                <option value="individual">{props.t("Individual")}</option>
                                                <option value="corporate">{props.t("Corporate")}</option>
                                            </AvField>
                                        </div>

                                        <Row>
                                            <div className="mb-4 form-group">
                                                <AvField
                                                    name="customerName"
                                                    label={props.t("Name")}
                                                    type="text"
                                                    errorMessage={props.t("InvalidInput")}
                                                    validate={{
                                                        required: { value: true },
                                                    }}
                                                    value={selectedCustomer?.customerName || ""}
                                                />
                                            </div>
                                            {selectedCustomer?.isType === "individual" ?
                                                <div className="mb-3">
                                                    <AvField
                                                        name="lastName"
                                                        label={props.t("LastName")}
                                                        type="text"
                                                        errorMessage={props.t("InvalidInput")}
                                                        validate={{
                                                            required: { value: true },
                                                        }}
                                                        value={selectedCustomer?.lastName || ""}
                                                    />
                                                </div> : null}

                                        </Row>
                                       
                                        <div className="mb-3">
                                            <AvField
                                                name="phoneNumber"
                                                label={props.t("Phone")}
                                                type="text"
                                                errorMessage={props.t("InvalidInput")}
                                                mask="(999) 999-9999"
                                                maskChar="-"
                                                tag={[Input, InputMask]}
                                                placeholder="(999) 999-9999"
                                                validate={{
                                                    required: { value: false },
                                                }}
                                                value={selectedCustomer?.phoneNumber || ""}
                                            />
                                        </div>

                                        <div className="mb-3">
                                            <AvField
                                                name="email"
                                                label="Email"
                                                type="text"
                                                errorMessage="Enter a Valid Email"
                                                validate={{
                                                    required: { value: true },
                                                    email: { value: true },
                                                }}
                                                value={selectedCustomer?.email || ""}
                                            />
                                        </div>
                                        {selectedCustomer?.isType === "individual" ?
                                            <div className="mb-3">
                                                <AvField
                                                    name="identityNumber"
                                                    label={props.t("IdentificationNumber")}
                                                    type="text"
                                                    errorMessage={props.t("InvalidInput")}
                                                    validate={{

                                                        required: { value: false },
                                                        minLength: { value: 11, errorMessage: "Enter a Valid Identity Number" },
                                                        maxLength: { value: 11, errorMessage: "Enter a Valid Identity Number" },
                                                    }}
                                                    value={selectedCustomer?.identityNumber || ""}
                                                />
                                            </div> : null}
                                        {selectedCustomer?.isType === "individual" ?
                                            <div className="mb-3">
                                                <AvField
                                                    name="birthday"
                                                    label={props.t("DateOfBirth")}
                                                    type="date"
                                                    errorMessage={props.t("InvalidInput")}
                                                    validate={{
                                                        required: { value: false },
                                                    }}
                                                    value={
                                                        handleValidDateAvField(selectedCustomer?.birthday)
                                                    }
                                                />
                                            </div> : null}
                                        {selectedCustomer?.isType === "individual" ?
                                            <div className="mb-3">
                                                <AvField
                                                    name="gender"
                                                    label={props.t("Gender")}
                                                    type="select"
                                                    className="form-select"
                                                    errorMessage={props.t("Invalid Customer Type")}
                                                    validate={{
                                                        required: { value: true },
                                                    }}
                                                    value={selectedCustomer?.gender || "Unspecified"}


                                                >
                                                    <option value="Unspecified">{props.t("Unspecified")}</option>
                                                    <option value={props.t("Male")}>{props.t("Male")}</option>
                                                    <option value={props.t("Female")}>{props.t("Female")}</option>
                                                </AvField>
                                            </div> : null}
                                        {/* // <div className="mb-3">

                                        //             <AvRadioGroup name="gender" label={props.t("Gender")}>
                                        //                 <AvRadio label={props.t("Unspecified")} value="" />
                                        //                 <AvRadio label={props.t("Male")} value="male" />
                                        //                 <AvRadio label={props.t("Female")} value="female" /> 
                                        //             </AvRadioGroup>

                                        //         </div>: null} */}
                                        {selectedCustomer?.isType === "corporate" ?
                                            <div className="mb-3">
                                                <AvField
                                                    name="taxAdministration"
                                                    label={props.t("TaxAdministration")}
                                                    type="text"
                                                    errorMessage={props.t("InvalidInput")}
                                                    validate={{
                                                        required: { value: false },
                                                    }}
                                                    value={selectedCustomer?.taxAdministration || ""}
                                                />
                                            </div> : null}
                                        {selectedCustomer?.isType === "corporate" ?
                                            <div className="mb-3">
                                                <AvField
                                                    name="taxNumber"
                                                    label={props.t("TaxNo")}
                                                    type="text"
                                                    errorMessage={props.t("InvalidInput")}
                                                    validate={{
                                                        required: { value: true },

                                                    }}
                                                    value={selectedCustomer?.taxNumber || ""}
                                                />
                                            </div> : null}
                                        <div className="mb-3">
                                            <AvField
                                                name="adress"
                                                label={props.t("Address")}
                                                type="textarea"
                                                errorMessage={props.t("InvalidInput")}
                                                validate={{
                                                    required: { value: false },
                                                }}
                                                rows={3}
                                                value={selectedCustomer?.adress || ""}
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <AvField
                                                name="city"
                                                label={props.t("City")}
                                                type="text"
                                                errorMessage={props.t("InvalidInput")}
                                                validate={{
                                                    required: { value: false },
                                                }}
                                                value={selectedCustomer?.city || ""}
                                            />
                                        </div>

                                        
                                        {selectedCustomer?.isType === "corporate" ?
                                            <div className="mb-3">

                                                <h6>{props.t("I am Bill Payer")}</h6>
                                                <AvGroup check>
                                                    <AvInput type="checkbox" label="eFaturaMukellef" value="false" name="eFaturaMukellef" />
                                                    <Label check for="checkbox">{props.t("Yes")}</Label>

                                                </AvGroup>

                                            </div> : null}


                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div className="text-end">
                                            <button
                                                type="submit"
                                                className="btn btn-success save-user"
                                            >
                                                {props.t("Save")}
                                            </button>
                                            &nbsp;&nbsp;
                                            <Button onClick={() => { toggle() }}>
                                                {props.t("Cancel")}
                                            </Button>

                                        </div>
                                    </Col>
                                </Row>
                            </AvForm>
                        </ModalBody>

                    </Modal>

                    {success_dlg ? (
                        <SweetAlert
                            success
                            title={dynamic_title}
                            confirmBtnText={props.t("Ok")}
                            onConfirm={() => {
                                setsuccess_dlg(false)
                            }}
                        >
                            {dynamic_description}
                        </SweetAlert>
                    ) : null}

                    {confirm_alert ? (
                        <SweetAlert
                            title={props.t("Are You Sure")}
                            warning
                            showCancel
                            confirmBtnBsStyle="success"
                            cancelBtnBsStyle="danger"
                            cancelBtnText={props.t("Cancel")}
                            confirmBtnText={props.t("Delete")}
                            onConfirm={() => {
                                setconfirm_alert(false);
                                setsuccess_dlg(true);
                                setdynamic_title(props.t("Deleted"));
                                setdynamic_description("");
                                dispatch(onDeleteCustomer(selectedId));
                                setSelectedId(null);
                            }}
                            onCancel={() => setconfirm_alert(false)}
                        >
                            {props.t("DeleteWarning")}
                        </SweetAlert>
                    ) : null}


                    {error && error ? (
                        <SweetAlert
                            title={props.t("Error")}
                            warning
                            confirmBtnText={props.t("Ok")}
                            onConfirm={() => {
                                dispatch(onResetCustomer())
                            }}
                        >
                            {JSON.stringify(error)}
                        </SweetAlert>
                    ) : null}

                </Container>
            </div>
        </React.Fragment >
    )
}

export default withRouter(withTranslation()(Customers))
