import { call, put, takeEvery } from "redux-saga/effects";

// Crypto Redux States
import { GET_MAINCATEGORIES, DELETE_MAINCATEGORY, ADD_MAINCATEGORY, UPDATE_MAINCATEGORY} from "./actionTypes";
import {
    getMainCategoriesSuccess, getMainCategoriesFail,
    deleteMainCategorySuccess, deleteMainCategoryFail,
    updateMainCategorySuccess, updateMainCategoryFail,
    addMainCategorySuccess, addMainCategoryFail
 } from "./actions";

//Include Both Helper File with needed methods
import {
    getMainCategories, //API CONNECTION
    delMainCategory,
    addMainCategory,
    updateMainCategory
}
    from "../../helpers/fakebackend_helper"; 

function* fetchMainCategories() {
    try {
        const response = yield call(getMainCategories)
       
        yield put(getMainCategoriesSuccess(response));
    } catch (error) {
        yield put(getMainCategoriesFail(error));
    }
}

function* onDeleteMainCategories({payload:id}){
    try{
        const response = yield call(delMainCategory, id)
        yield put(deleteMainCategorySuccess(response));
    } catch (error) {
        yield put(deleteMainCategoryFail(error));
    }
}

function* onAddMainCategory({payload:mainCategory}){
    try{
        const response = yield call(addMainCategory, mainCategory)
        yield put(addMainCategorySuccess(response));
    } catch (error) {
        yield put(addMainCategoryFail(error));
    }
}

function* onUpdateMainCategory({payload:mainCategory}){
    try{
        const response = yield call(updateMainCategory, mainCategory)
        yield put(updateMainCategorySuccess(response));
    } catch (error) {
        yield put(updateMainCategoryFail(error));
    }
}

export function* mainCategoriesSaga() {
    yield takeEvery(GET_MAINCATEGORIES, fetchMainCategories);
    yield takeEvery(DELETE_MAINCATEGORY, onDeleteMainCategories);
    yield takeEvery(ADD_MAINCATEGORY, onAddMainCategory);
    yield takeEvery(UPDATE_MAINCATEGORY, onUpdateMainCategory);
}

export default mainCategoriesSaga;
