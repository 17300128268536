/** Get ENDOFDAYBALANCING */
export const GET_ENDOFDAYBALANCINGS = "GET_ENDOFDAYBALANCINGS";
export const GET_ENDOFDAYBALANCINGS_SUCCESS = "GET_ENDOFDAYBALANCINGS_SUCCESS";
export const GET_ENDOFDAYBALANCINGS_FAIL = "GET_ENDOFDAYBALANCINGS_FAIL";

export const GET_ENDOFDAYBALANCING_DETAIL = "GET_ENDOFDAYBALANCING_DETAIL";
export const GET_ENDOFDAYBALANCING_DETAIL_SUCCESS = "GET_ENDOFDAYBALANCING_DETAIL_SUCCESS";
export const GET_ENDOFDAYBALANCING_DETAIL_FAIL = "GET_ENDOFDAYBALANCING_DETAIL_FAIL";


export const ADD_ENDOFDAYBALANCING = "ADD_ENDOFDAYBALANCING";
export const ADD_ENDOFDAYBALANCING_SUCCESS = "ADD_ENDOFDAYBALANCING_SUCCESS";
export const ADD_ENDOFDAYBALANCING_FAIL = "ADD_ENDOFDAYBALANCING_FAIL";

export const UPDATE_ENDOFDAYBALANCING = "UPDATE_ENDOFDAYBALANCING";
export const UPDATE_ENDOFDAYBALANCING_SUCCESS = "UPDATE_ENDOFDAYBALANCING_SUCCESS";
export const UPDATE_ENDOFDAYBALANCING_FAIL = "UPDATE_ENDOFDAYBALANCING_FAIL";



export const DELETE_ENDOFDAYBALANCING = "DELETE_ENDOFDAYBALANCING";
export const DELETE_ENDOFDAYBALANCING_SUCCESS = "DELETE_ENDOFDAYBALANCING_SUCCESS";
export const DELETE_ENDOFDAYBALANCING_FAIL = "DELETE_ENDOFDAYBALANCING_FAIL";

export const RESET_ENDOFDAYBALANCING= "RESET_ENDOFDAYBALANCING";