import React, { useState } from "react"
import PropTypes from 'prop-types'
import { Container, Row, Col, Modal, ModalHeader, ModalBody,TabContent,TabPane,Label,Input,
    FormGroup} from "reactstrap"
import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
} from "availity-reactstrap-validation"
import { useSelector, useDispatch } from "react-redux"
import { FormatTL, FormatTLBasic } from "./../../../components/turkishMoneyFormat"
import { isEmpty, map } from "lodash"
import { Link, withRouter } from "react-router-dom"
//i18n
import { withTranslation } from "react-i18next"
import {
  getBranchById as onGetBranchById,
 
} from "store/actions"


const SelectBranch = (props, cb) => {
    const [selectedBranchId, setSelectedBranchId,] = useState()
    const [selectedBranchName,setSelectedBranchName] =useState()
    const {
        error, Branches,UserBranch,selectedBranch
    
      } = useSelector(state => ({
        error: state.Expenses?.error,
        UserBranch:state.Branchs?.userBranch,
        Branches: state.Branchs?.Branches,
        selectedBranch:state.Branchs?.selectedBranch?.name,
      }))
  const dispatch = useDispatch()
  let user = JSON.parse(localStorage.getItem("authUser"))
  console.log("seçilen isim",selectedBranch)
  return (
    <Modal isOpen={props.modal} toggle={props.toggle}>
          <ModalHeader toggle={props.toggle} tag="h4">
            {props.t("Select the branch that you will use")}
          </ModalHeader>
          <ModalBody>
          <AvForm > 
          <Row form>
            <Col xs={12}>
              <Container>
                {user.roles[0].name =="User"?(
                   <div className="mb-3">
                   <AvField
                     type="select"
                     name="branchId"
                     value={selectedBranchId}
                     onChange={e => {
                      console.log("a",e.target.value)
                    }}
                     className="form-select"
                     label={props.t("Select Branch")}>
                     <option>{props.t("Select Branch")}</option>
                     {UserBranch?.map((item, index) => (
                       <option key={index} value={item._id}>
                         {item.name}
                       </option> 
                     ))}
                   </AvField>
                 </div>
                ):(
                  <div className="mb-3">
                  <AvField
                    type="select"
                    name="branchId"
                    value={selectedBranchId} 
                    // onChange={e => {
                    //   dispatch(onGetBranchById(e.target.value))
                    // }}
                    className="form-select"
                    label={props.t("Select Branch")}>
                    <option>{props.t("Select Branch")}</option>
                    {Branches?.map((item, index) => (
                      <option key={index} value={[item._id]}>
                        {item.name}
                      </option> 
                    ))} 
                  </AvField>
                </div>
                )}
                 
                   
               

               
              </Container>
            </Col>
          </Row>
          <Row className="mt-3"></Row>
          <Row className="mt-3">
            <Col md="12">
              <div className="text-end">
              <button
                      className="btn btn-success col-4" 
                      onClick={() => {
                          console.log(branchId.value)
                          
                          if(branchId.value =="Şube Seç"){
                            console.log("şube seç")
                          }else{
                            let user = JSON.parse(localStorage.getItem("authUser"))
                            user.selectedBranch=branchId.value
                           
                            localStorage.setItem("authUser", JSON.stringify(user))
                            props.save(true)
                            window.location.reload();
                             
                          }
                        

                        
                      }}
                    >
                      {props.t("Save")}
                    </button>
               
              </div>
            </Col>
          </Row>
        </AvForm>
          </ModalBody>
        </Modal>
  )
}


SelectBranch.propTypes = {
 
  modal: PropTypes.bool,
 save:PropTypes.func,
   t: PropTypes.any,
   toggle: PropTypes.func,
   cancel: PropTypes.func,

}


export default withRouter(
    (withTranslation()(SelectBranch))
  )
