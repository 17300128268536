import { call, put, takeEvery } from "redux-saga/effects";

// Crypto Redux States
import {  GET_PRODUCTS_BY_BARCODE,GET_PRODUCTID_BY_SERIALNO,GET_PRODUCT_BY_ID,GET_CUSTOMER_BY_IDENTITY,GET_CUSTOMER_BY_PHONE,GET_CUSTOMER_BY_TAXNO} from "./actionTypes";
import {
   
    getProductsByBarcodeSuccess, getProductsByBarcodeFail,
    getProductIdBySerialNoSuccess,getProductIdBySerialNoFail,
    getProductByIdSuccess,getProductByIdFail,
    getCustomerByIdentitySuccess,getCustomerByIdentityFail,
    getCustomerByPhoneSuccess,getCustomerByPhoneFail,
    getCustomerByTaxNoSuccess,getCustomerByTaxNoFail
 } from "./actions";

//Include Both Helper File with needed methods
import {
    
    getProductsByBarcode,getProductIdBySerialNo,getProductById,getCustomerByIdentity,getCustomerByPhone,getCustomerByTaxNo
}
    from "../../helpers/fakebackend_helper"; 


    function*  onGetGetCustomerByTaxNo({payload:taxNumber}){
        try{
            const response = yield call(getCustomerByTaxNo, taxNumber)
            yield put(getCustomerByTaxNoSuccess(response));
        } catch (error) {
            yield put(getCustomerByTaxNoFail(error));
        }
    }
    function*  onGetGetCustomerByIdentity({payload:identityNumber}){
        try{
            const response = yield call(getCustomerByIdentity, identityNumber)
            yield put(getCustomerByIdentitySuccess(response));
        } catch (error) {
            yield put(getCustomerByIdentityFail(error));
        }
    }
    function*  onGetGetCustomerByPhone({payload:phoneNumber}){
        try{
            const response = yield call(getCustomerByPhone, phoneNumber)
            yield put(getCustomerByPhoneSuccess(response));
        } catch (error) {
            yield put(getCustomerByPhoneFail(error));
        }
    }
function*  onGetProductsByBarcode({payload:barcode}){
    try{
        const response = yield call(getProductsByBarcode, barcode)
        yield put(getProductsByBarcodeSuccess(response));
    } catch (error) {
        yield put(getProductsByBarcodeFail(error));
    }
}

function*  onGetProductIdBySerialNo({payload:serialNo}){
    try{
        const response = yield call(getProductIdBySerialNo, serialNo)
        yield put(getProductIdBySerialNoSuccess(response));
    } catch (error) {
        yield put(getProductIdBySerialNoFail(error));
    }
}
function*  onGetProductById({payload:id}){
    try{
        const response = yield call(getProductById, id)
        yield put(getProductByIdSuccess(response));
    } catch (error) {
        yield put(getProductByIdFail(error));
    }
}

export function* processListSaga() {
   
    yield takeEvery(GET_PRODUCTS_BY_BARCODE, onGetProductsByBarcode);
    yield takeEvery(GET_PRODUCTID_BY_SERIALNO, onGetProductIdBySerialNo);
    yield takeEvery(GET_PRODUCT_BY_ID, onGetProductById);
    yield takeEvery(GET_CUSTOMER_BY_IDENTITY, onGetGetCustomerByIdentity);
    yield takeEvery(GET_CUSTOMER_BY_PHONE, onGetGetCustomerByPhone);
    yield takeEvery(GET_CUSTOMER_BY_TAXNO, onGetGetCustomerByTaxNo);
}

export default processListSaga;
