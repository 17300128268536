import {
    GET_PRODUCTCATEGORIES,
    GET_PRODUCTCATEGORIES_SUCCESS,
    GET_PRODUCTCATEGORIES_FAIL,
    GET_PRODUCTCATEGORY_DETAIL,
    GET_PRODUCTCATEGORY_DETAIL_SUCCESS,
    GET_PRODUCTCATEGORY_DETAIL_FAIL,
    ADD_PRODUCTCATEGORY,
    ADD_PRODUCTCATEGORY_SUCCESS,
    ADD_PRODUCTCATEGORY_FAIL,
    UPDATE_PRODUCTCATEGORY,
    UPDATE_PRODUCTCATEGORY_SUCCESS,
    UPDATE_PRODUCTCATEGORY_FAIL,
    DELETE_PRODUCTCATEGORY,
    DELETE_PRODUCTCATEGORY_SUCCESS,
    DELETE_PRODUCTCATEGORY_FAIL,
    RESET_PRODUCTCATEGORY
} from "./actionTypes";

const INIT_STATE = {
    data: [],
    error: null
};

const ProductCategories = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_PRODUCTCATEGORIES_SUCCESS:
            return {
                ...state,
                data: action.payload.data
            }
        case DELETE_PRODUCTCATEGORY_SUCCESS:
            return {
                ...state,
                data: state.data.filter(payments => payments._id.toString() !== action.payload.data.toString())
            }
        case ADD_PRODUCTCATEGORY_SUCCESS:
            return {
                ...state,
                data: [action.payload.data, ...state.data]
            }
        case UPDATE_PRODUCTCATEGORY_SUCCESS:
            return {
                ...state,
                data: state.data.map(payments =>
                    payments._id.toString() === action.payload.data._id.toString() ?
                        { payments, ...action.payload.data } :
                        payments
                )
            }

        //FAILS
        case GET_PRODUCTCATEGORIES_FAIL:
        case DELETE_PRODUCTCATEGORY_FAIL:
        case ADD_PRODUCTCATEGORY_FAIL:
        case UPDATE_PRODUCTCATEGORY_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case RESET_PRODUCTCATEGORY: return {
            ...state,
            error: null
        }
        default:
            return state;
    }
}


export default ProductCategories;