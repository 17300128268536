import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  UncontrolledDropdown,
  UncontrolledTooltip,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
} from "reactstrap"
//redux
import { useSelector, useDispatch } from "react-redux"

import { withRouter } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"

import {
  getProducts as onGetProducts,
  deleteProduct as onDeleteProduct,
  addProduct as onAddProduct,
  updateProduct as onUpdateProduct,
  resetProduct as onResetProduct,
  updateProductType as onUpdateProductType,
  getProductTypes as onGetProductTypes,
  getCompanyBranches as onGetCompanyBranches,
  getProductCategories as onGetProductCategories
} from "store/actions" 

import { isEmpty, map } from "lodash"

import {
  AvForm,
  AvField,

} from "availity-reactstrap-validation"

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"

//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert"

import "./datatables.scss"

const Products = props => {
  const dispatch = useDispatch()

  const [selectedId, setSelectedId] = useState()
  const [selectedProduct, setSelectedProduct] = useState()
  const [success_dlg, setsuccess_dlg] = useState(false)
  const [confirm_alert, setconfirm_alert] = useState(false)
  const [dynamic_title, setdynamic_title] = useState("")
  const [dynamic_description, setdynamic_description] = useState("")
  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [productTypeModal, setProductTypeModal] = useState(false)
  const [addProductTypeModal, setAddProductTypeModal] = useState(false);
  const [isValue, setIsValue] = useState([]);
  const [selectedBranchId, setSelectedBranchId] = useState()
  const [productTypes, setProductTypes] = useState([]);
  const [serialNumbers,setSerialNumbers] =useState([])
  const [serialNoForModal,setSerialNoForModal] =useState([])
  const [serialNoInfoModal,setSerialNoInfoModal] =useState(false)
  const [subCategoriesObjects, setSubCategoriesObjects] = useState([])
  const [selectedMainCategory, setSelectedMainCategory] = useState()
 
  const handleChangeInput = (i, e, inputName) => {
    const values = [...productTypes]
    values[i][inputName] = e.target.value
    setProductTypes(values)
  }
  const handleSubtract = (i) => {
    const values = [...productTypes]
    values.splice(i, 1)
    setProductTypes([...values])
  }
  const handleAdd = () => {
    setProductTypes([...productTypes,
    {
      name: "",
      checked :false
    }])
  }
 
  const handleChangeInputSerialNumbers = (i, e, inputName) => {
    const values = [...serialNumbers]
    values[i][inputName] = e.target.value
    setSerialNumbers(values)
  }
  const handleSubtractSerialNumbers = (i) => {
    const values = [...serialNumbers]
    values.splice(i, 1)
    setSerialNumbers([...values])
  }
  const handleAddSerialNumbers = () => {
    setSerialNumbers([...serialNumbers,
    {
      name: ""
     
    }])
  }
  let user = JSON.parse(localStorage.getItem("authUser"))
  const { error, Products,ProductTypes,Branches,ProductCategories} = useSelector(state => ({
    error: state.Products?.error,
    Products: state.Products?.data,
    ProductTypes :state.Products?.productType?.data,
    Branches : state.Branchs?.Branches,
    ProductCategories :state.ProductCategories?.data
  }));

  useEffect(() => {
    dispatch(onGetProductCategories());
    dispatch(onGetProducts())
    dispatch(onGetCompanyBranches(user.companyId))
  }, [dispatch])

  const findSubCategoryName = (arr, id) => {
    if (!isEmpty(arr) && !isEmpty(id)) {
      let index = arr.findIndex(function (item, i) {
        return item._id === id
      });
      if (index !== -1) {
        return arr[index].name;
      }
      else {
        return "--";
      }
    }
    return "--";
  }
  const toggle = () => {
    setModal(!modal)
  }
  const serialNoToggle = () => {
    setSerialNoInfoModal(!serialNoInfoModal)
}
  const addProductTypeToggle = () => {
    setAddProductTypeModal(!addProductTypeModal);
  }

  const columns = [
    {
      dataField: "name",
      text: props.t("Name"),
      sort: true,
    },
    {
      dataField: "sku",
      text:  props.t("Stock Code"),
       sort: true,
    },
    {
      dataField: "barcode",
      text: props.t("Barcode"),
      sort: true,
    },
    { 
      dataField: 'serialNo',
      text: props.t("Serial No"),
      sort: true ,
      formatter: (cell, row) => <span>{console.log(cell.length)} 
      {cell.length>0?(
        <Button  onClick={() => {
          setSerialNoForModal(cell)
          serialNoToggle()

      }} className="btn btn-success me-2">
        
                     <i className="bx bx-info-circle" style={{font:"18px"}}></i>
                   </Button>
      ):null}
       
      </span>, 



  },
    { 
      dataField: "brandName",
      text: props.t("BrandName"),
      sort: true,
    },
    {  
      dataField: "category",
      text: props.t("Category Name"),
      sort: true, 
      formatter: (cell, row) => <span>{cell?.category}</span>,  
      
    },
    {
      dataField: "subCategoryName",
      text: props.t("Subcategory Name"), 
      sort: true,
       
    },
    {
      dataField: "price",
      text: props.t("Price"),
      sort: true,
     
    },
    {
      dataField: "priceUnit",
      text: props.t("Price Unit"),
      sort: true,
    },

    {
      dataField: "score",
      text: props.t("Score"),
      sort: true,
    },
   
    {
      dataField: "_id",
      text: props.t("Action"),
      sort: false,
      formatter: (cell, row) => (
        <UncontrolledDropdown style={{ position: "unset" }}>
          <DropdownToggle href="#" className="card-drop" tag="i">
            <i className="mdi mdi-dots-horizontal font-size-18"></i>
          </DropdownToggle>
          <div className="drop-absolute">
          <DropdownMenu className="dropdown-menu-end">
            <DropdownItem
              href="#"
              onClick={() => { 
                console.log(row.serialNo)
                setProductTypes(row.productType);
                setSerialNumbers(row.serialNo)
                setSelectedProduct(row);
                setSelectedBranchId(row.branchId?._id)
                //dispatch(onGetProductTypes())
                setIsEdit(true) 
                toggle()
               
                

              }}
            >
              <i className="mdi mdi-pencil font-size-16 text-success me-1"></i>
              {props.t("Edit")}
            </DropdownItem>
            <DropdownItem
              href="#"
              onClick={() => {
                setSelectedId(cell)
                setconfirm_alert(true)
              }}
            >
              <i className="mdi mdi-trash-can font-size-16 text-danger me-1"></i>
              {props.t("Delete")}
            </DropdownItem>
            
          </DropdownMenu>
          </div>
        </UncontrolledDropdown>
      ),
    },
  ]

  const defaultSorted = [
    {
      dataField: "createdAt",
      order: "desc",
    },
  ]

  const pageOptions = {
    sizePerPage: 10,
    totalSize: Products ? Products.length : 0, // replace later with size(customers),
    custom: true,
  }

  // Custom Pagination Toggle
  const sizePerPageList = [
    { text: "5", value: 5 },
    { text: "10", value: 10 },
    { text: "15", value: 15 },
    { text: "20", value: 20 },
    { text: "25", value: 25 },
    { text: "All", value: Products ? Products.length : 0 },
  ]

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
  }

  const { SearchBar } = Search

 

  const handleValidProductSubmit = (e, values) => {
    if (isEdit) { 
      const updateProduct = {
        _id: selectedProduct._id,

        name: values.name,
        sku: values["sku"],
        barcode: values["barcode"],
        brandName: values["brandName"],
        category: values.category,
        subCategoryName: values["subCategoryName"],
        price: values["price"],
        priceUnit: values["priceUnit"],
        score: values["score"],
        serialNo: JSON.stringify(serialNumbers),
        CompanyId: user.companyId,
       
         productType : JSON.stringify(productTypes)
       
      }
      // update Product
      dispatch(onUpdateProduct(updateProduct))
      console.log(updateProduct)
    
    } else {
      const newProduct = {
        name: values["name"],
        sku: values["sku"],
        barcode: values["barcode"],
        brandName: values["brandName"],
        category: values["category"],
        subCategoryName: values["subCategoryName"],
        price: values["price"],
        priceUnit: values["priceUnit"], 
        serialNo: JSON.stringify(serialNumbers),
        score: values["score"],
        CompanyId: user.companyId,
       
        productType : JSON.stringify(productTypes)
       
      }
      // save new Product
      dispatch(onAddProduct(newProduct))
      
    }
    toggle()
  }
console.log("tip,",productTypes)
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{props.t("Product")}</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb
            title={props.t("Product")}
            breadcrumbItem={props.t("Product")}
            addBtn={true}
            addBtnCallBack={() => {
              setIsEdit(false)
              setSelectedProduct({
                name: "",
                sku: "",
                barcode: "",
                brandName: "",
                categoryName: "",
                subCategoryName: "",
                price: "",
                priceUnit: "",
                //spesificCategories: "",
                score: "",
                CompanyId: "",
                productType: "",
              })
              toggle()
            }}
          />

          {/* {error && error ? <Alert color="danger">{error}</Alert> : null} */}

          <Card>
            <CardBody>
              <PaginationProvider
                pagination={paginationFactory(pageOptions)}
                keyField="id"
                columns={columns}
                data={Products}
              >
                {({ paginationProps, paginationTableProps }) => (
                  <ToolkitProvider
                    keyField="_id"
                    columns={columns}
                    data={Products}
                    search
                  >
                    {toolkitProps => (
                      <React.Fragment>
                        <Row className="mb-2">
                          <Col md="4">
                            <div className="search-box me-2 mb-2 d-inline-block">
                              <div className="position-relative">
                                <SearchBar {...toolkitProps.searchProps} />
                                <i className="bx bx-search-alt search-icon" />
                              </div>
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col xl="12">
                            <div className="table-responsive">
                              <BootstrapTable
                                keyField={"_id"}
                                responsive
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                classes={"table align-middle table-nowrap"}
                                headerWrapperClasses={"thead-light"}
                                {...toolkitProps.baseProps}
                                {...paginationTableProps}
                              />
                            </div>
                          </Col>
                        </Row>

                        <Row className="align-items-md-center mt-30">
                          <Col className="inner-custom-pagination d-flex">
                            <div className="d-inline">
                              <SizePerPageDropdownStandalone
                                {...paginationProps}
                              />
                            </div>
                            <div className="text-md-right ms-auto">
                              <PaginationListStandalone {...paginationProps} />
                            </div>
                          </Col>
                        </Row>
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                )}
              </PaginationProvider>

              <Modal isOpen={modal} toggle={toggle}> 
                <ModalHeader toggle={toggle} tag="h4">
                  {!!isEdit ? props.t("Edit Product") : props.t("Add Product")}
                </ModalHeader>
                <ModalBody>
                  <AvForm onValidSubmit={handleValidProductSubmit}>
                    <Row form>
                      <Col xs={12}>
                      <input
                          id="ProductTypes"
                          name="ProductTypes"
                          type ="hidden"
                        /> 
                       
                        <div className="mb-3">
                          <AvField
                            name="name"
                            label={props.t("Name")}
                            type="text"
                            errorMessage={props.t("InvalidInput")}
                            validate={{
                              required: { value: true },
                            }}
                            value={selectedProduct?.name || ""}
                          />
                        </div>
                        
                        <div className="mb-3">
                          <AvField
                            name="sku"
                            label={props.t("Stock Code")}
                            type="text"
                            errorMessage={props.t("InvalidInput")}
                            validate={{
                              required: { value: false },
                            }}
                            value={selectedProduct?.sku || ""}
                          />
                        </div>

                        <div className="mb-3">
                          <AvField
                            name="barcode"
                            label={props.t("Barcode")}
                            type="text"
                            errorMessage={props.t("InvalidInput")}
                            validate={{
                              required: { value: false },
                            }}
                            value={selectedProduct?.barcode || ""}
                          />
                        </div>
                        <Row className="mt-2">
                          <Col md="4">{props.t("Serial No")}</Col>
                          <Col md="3"> {serialNumbers.length == 0 ?(
                           <Button style={{position:"relative", bottom:"10px" ,right:"88px"}} onClick={() => handleAddSerialNumbers()} className="btn btn-success me-2">
                           <i className="fas fa-plus"></i>
                         </Button>
                        ):null}</Col>
                          <Col md="3"></Col>
                          <Col md="2">
                         </Col>
                       
                       
                        </Row>
                        {serialNumbers.map((item, i) => (
                          <div key={i}>
                            <input
                              id={"serialNumbers" + (i) + "__isValue"}
                              name={"serialNumbers[" + (i) + "].isValue"}
                              type="hidden"
                            />
                            <Row className="mt-2">
                              <Col md="8">
                                <AvField
                                  type="text"
                                  id={"serialNumbers" + (i) + "__name"}
                                  name={"serialNumbers[" + (i) + "].name"}
                                  placeholder={props.t("Serial No")}
                                  value={item.name}
                                  onChange={e => handleChangeInputSerialNumbers(i, e, "name")}
                                  validate={{
                                    required: { value: false },
                                  }}
                                />
                              </Col>


                              <Col md="4">
                                {i == serialNumbers.length - 1 ?
                                  <div>
                                    <Button onClick={() => handleAddSerialNumbers()} className="btn btn-success me-2">
                                      <i className="fas fa-plus"></i>
                                    </Button>
                                   <Button onClick={() => handleSubtractSerialNumbers(i)}>
                                      <i className="fas fa-minus"></i>
                                    </Button> 
                                  </div> : null
                                }
                              </Col>

                            </Row>
                          </div>
                        ))}
                        <div className="mb-3">
                          <AvField
                            name="brandName"
                            label={props.t("BrandName")}
                            type="text"
                            errorMessage={props.t("InvalidInput")}
                            validate={{
                              required: { value: false },
                            }}
                            value={selectedProduct?.brandName || ""}
                          />
                        </div>
                        <div className="mb-3">
                <AvField
                  type="select"
                  name="category"
                  className="form-select"
                  label={props.t("Select Category")}
                  onChange={event => {
                    setSelectedMainCategory(prevState => ({
                      ...prevState,
                      category: event.target.value,
                    }))
                    let sub = ProductCategories.find(
                      x => x._id === event.target.value
                    )

                    let tempSubListArr = []

                    tempSubListArr.push(sub.subCategories)

                    if (sub) {
                      setSubCategoriesObjects(tempSubListArr)
                    } else {
                      setSubCategoriesObjects([])
                    }
                  }}
                >
                  <option>{props.t("Select Category")}</option>
                  {ProductCategories?.map((item, index) => (
                    <option key={"b_" + index} value={item._id}>
                      {item.category}
                    </option>
                  ))}
                </AvField>
              </div>
              <div className="mb-3">
                <AvField
                  type="select"
                  name="subCategoryName"
                  className="form-select"
                  label={props.t("Select Type")}
                  onChange={e => {

                  }}
                >
                  <option>{props.t("Select Type")}</option>
                  {subCategoriesObjects[0]?.map((item, index) => (
                    <option key={"c_" + index} value={item.name}>
                      {item.name}
                    </option>
                  ))}
                </AvField>
              </div>
                        <div className="mb-3">
                          <AvField
                            name="price"
                            label={props.t("Price")}
                            type="text"
                            errorMessage={props.t("InvalidInput")}
                            validate={{
                              required: { value: true },
                              pattern: {
                                value: "^[0-9]+$",
                                errorMessage: "Geçerli Bir Fiyat Girin",
                              },
                            }}
                            value={selectedProduct?.price || ""}
                          />
                        </div>

                        <div className="mb-3">
                          <AvField
                            name="priceUnit"
                            label={props.t("Price Unit")}
                            type="text"
                            errorMessage={props.t("InvalidInput")}
                            validate={{
                              required: { value: false },
                            }}
                            value={selectedProduct?.priceUnit || ""}
                          />
                        </div>

                        <div className="mb-3">
                          <AvField
                            name="score"
                            label={props.t("Score")}
                            type="text"
                            errorMessage={props.t("InvalidInput")}
                            validate={{
                              required: { value: false },
                            }}
                            value={selectedProduct?.score || ""}
                          />
                        </div>
                     
                        <h4>{props.t("Product Type")}</h4>
                        {!isEmpty(productTypes) ?
                       productTypes.map((item, i) => (
                          
                        <div key={i}
                        className="form-check form-switch form-switch-lg mb-3"
                      >
                        <label
                          className="form-check-label"
                          htmlFor={"productTypes" + (i) + "__id"}
                        >
                          {productTypes[i].name}
                        </label>
                        <input

                          type="checkbox"
                          name={"productTypes" + (i) + "__name"}
                          id={"productTypes" + (i) + "__id"}
                          className="form-check-input"
                          checked={item.checked}
                            
                          onChange={event => {
                           
                          
                            item.checked = !item.checked
                            setProductTypes([...productTypes]);
                            
                          }}
                        
                        />
                         
                      </div> 
                     )) 
                        :
                        <small>{props.t("You do not have a product Type")}</small>
                        }
                        <Col className="mb-4" >
                          <div className="text-end mb-3">
                            <Button
                              className="btn btn-success"
                              onClick={() => { addProductTypeToggle() }}

                            >
                              {props.t("Add Product Type")}
                            </Button>

                          </div>
                        </Col>

                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="text-end">
                          <button
                            type="submit"
                            className="btn btn-success save-user"
                          >
                            {props.t("Save")}
                          </button>
                          &nbsp;&nbsp;
                          <Button
                            onClick={() => {
                              toggle()
                            }}
                          >
                            {props.t("Cancel")}
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </ModalBody>
              </Modal>

            

              <Modal isOpen={addProductTypeModal} toggle={addProductTypeToggle} >
                <ModalHeader toggle={addProductTypeToggle} tag="h4">
                  {props.t("Add Product Type")}
                </ModalHeader>
                <ModalBody>
                  <small className="mb-3">
                    {props.t("Please Enter Product Type")}

                  </small>
                  <br /> <br />

                  <AvForm>
                    <Row form>
                      <Col xs={12} className="pb-4">
                        <Row className="mt-2">
                          <Col md="4">{props.t("Product Type")}</Col>
                          <Col md="3"> {productTypes?.length == 0 ?(
                           <Button style={{position:"relative", bottom:"10px" ,right:"88px"}} onClick={() => handleAdd()} className="btn btn-success me-2">
                           <i className="fas fa-plus"></i>
                         </Button>
                        ):null}</Col>
                          <Col md="3"></Col>
                          <Col md="2">
                         </Col>
                       
                       
                        </Row>
                        {productTypes?.map((item, i) => (
                          <div key={i}>
                            <input
                              id={"productTypes" + (i) + "__isValue"}
                              name={"productTypes[" + (i) + "].isValue"}
                              type="hidden"
                            />
                            <Row className="mt-2">
                              <Col md="8">
                                <AvField
                                  type="text"
                                  id={"productTypes" + (i) + "__name"}
                                  name={"productTypes[" + (i) + "].name"}
                                  placeholder={props.t("Product Type")}
                                  value={item.name}
                                  onChange={e => handleChangeInput(i, e, "name")}
                                  validate={{
                                    required: { value: false },
                                  }}
                                />
                              </Col>


                              <Col md="4">
                                {i == productTypes.length - 1 ?
                                  <div>
                                    <Button onClick={() => handleAdd()} className="btn btn-success me-2">
                                      <i className="fas fa-plus"></i>
                                    </Button>
                                   <Button onClick={() => handleSubtract(i)}>
                                      <i className="fas fa-minus"></i>
                                    </Button> 
                                  </div> : null
                                }
                              </Col>

                            </Row>
                          </div>
                        ))}
                      </Col>
                    </Row>

                    <Row>
                      <Col >
                        <div className="text-end">
                          <Button onClick={() => {
                            document.getElementById("ProductTypes").value = JSON.stringify(productTypes);


                            addProductTypeToggle();
                          }} className="btn btn-success save-user">
                            {props.t("Save")}
                          </Button>
                          &nbsp;&nbsp;
                          <Button onClick={() => {
                            addProductTypeToggle();
                          }}
                          >
                            {props.t("Cancel")}
                          </Button>

                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </ModalBody>
              </Modal>
              <Modal isOpen={serialNoInfoModal} toggle={serialNoToggle}>
                        <ModalHeader toggle={serialNoToggle} tag="h4">
                            {props.t("Serial Numbers")}
                        </ModalHeader>
                        <ModalBody>
                            <AvForm>
                                <Row form>
                                    <Col xs={12}>
                                        {serialNoForModal?.map((item, i) => (
                                            <div key={i}>
                                                <input
                                                    id={"productTypes" + (i) + "__isValue"}
                                                    name={"productTypes[" + (i) + "].isValue"}
                                                    type="hidden"
                                                />
                                                <Row className="mt-2">
                                                    <Col md="8">
                                                        <AvField
                                                            type="text"
                                                            id={"productTypes" + (i) + "__name"}
                                                            name={"productTypes[" + (i) + "].name"}
                                                            placeholder={props.t("Serial No")}
                                                            disabled
                                                            value={item.name}
                                                            onChange={e => handleChangeInput(i, e, "name")}
                                                            validate={{
                                                                required: { value: false },
                                                            }}
                                                        />
                                                    </Col>


                                                   

                                                </Row>
                                            </div>
                                        ))}
                                           </Col>
                                </Row>
                                <Row>
                                    <Col>
                                       
                                       
                                    <div className="text-end"> 
                                            
                                            &nbsp;&nbsp;
                                            <Button onClick={()=>{ serialNoToggle() }}>
                                                    {props.t("Cancel")}
                                                    </Button>       
                                            
                                        </div>
                                    </Col>
                                </Row>
                            </AvForm>
                        </ModalBody>
                    </Modal>

            </CardBody>
          </Card>

          {success_dlg ? (
            <SweetAlert
              success
              title={dynamic_title}
              confirmBtnText={props.t("Ok")}
              onConfirm={() => {
                setsuccess_dlg(false)
              }}
            >
              {dynamic_description}
            </SweetAlert>
          ) : null}

          {confirm_alert ? (
            <SweetAlert
              title={props.t("Are You Sure")}
              warning
              showCancel
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="danger"
              cancelBtnText={props.t("Cancel")}
              confirmBtnText={props.t("Delete")}
              onConfirm={() => {
                setconfirm_alert(false)
                setsuccess_dlg(true)
                setdynamic_title(props.t("Deleted"))
                setdynamic_description("")
                dispatch(onDeleteProduct(selectedId))
                setSelectedId(null)
              }}
              onCancel={() => setconfirm_alert(false)}
            >
              {props.t("DeleteWarning")}
            </SweetAlert>
          ) : null}

          {error && error ? (
            <SweetAlert
              title={props.t("Error")}
              warning
              confirmBtnText={props.t("Ok")}
              onConfirm={() => {
                dispatch(onResetProduct())
              }}
            >
              {JSON.stringify(error)}
            </SweetAlert>
          ) : null}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(Products))
