/** Get ProcessListPayment */
export const GET_PROCESS_LIST_PAYMENTS = "GET_PROCESS_LIST_PAYMENTS";
export const GET_PROCESS_LIST_PAYMENTS_SUCCESS = "GET_PROCESS_LIST_PAYMENTS_SUCCESS";
export const GET_PROCESS_LIST_PAYMENTS_FAIL = "GET_PROCESS_LIST_PAYMENTS_FAIL";

export const GET_PROCESS_LIST_PAYMENT_DETAIL = "GET_PROCESS_LIST_PAYMENT_DETAIL";
export const GET_PROCESS_LIST_PAYMENT_DETAIL_SUCCESS = "GET_PROCESS_LIST_PAYMENT_DETAIL_SUCCESS";
export const GET_PROCESS_LIST_PAYMENT_DETAIL_FAIL = "GET_PROCESS_LIST_PAYMENT_DETAIL_FAIL";


export const ADD_PROCESS_LIST_PAYMENT = "ADD_PROCESS_LIST_PAYMENT";
export const ADD_PROCESS_LIST_PAYMENT_SUCCESS = "ADD_PROCESS_LIST_PAYMENT_SUCCESS";
export const ADD_PROCESS_LIST_PAYMENT_FAIL = "ADD_PROCESS_LIST_PAYMENT_FAIL";

export const UPDATE_PROCESS_LIST_PAYMENT = "UPDATE_PROCESS_LIST_PAYMENT";
export const UPDATE_PROCESS_LIST_PAYMENT_SUCCESS = "UPDATE_PROCESS_LIST_PAYMENT_SUCCESS";
export const UPDATE_PROCESS_LIST_PAYMENT_FAIL = "UPDATE_PROCESS_LIST_PAYMENT_FAIL";



export const DELETE_PROCESS_LIST_PAYMENT = "DELETE_PROCESS_LIST_PAYMENT";
export const DELETE_PROCESS_LIST_PAYMENT_SUCCESS = "DELETE_PROCESS_LIST_PAYMENT_SUCCESS";
export const DELETE_PROCESS_LIST_PAYMENT_FAIL = "DELETE_PROCESS_LIST_PAYMENT_FAIL";

export const RESET_PROCESS_LIST_PAYMENT = "RESET_PROCESS_LIST_PAYMENT";