//REGISTER
export const POST_REGISTER = "/api/users"
export const POST_FAKE_REGISTER = "/api/users"

//LOGIN
export const POST_LOGIN = "/auth/token"
export const POST_FAKE_LOGIN = "/post-fake-login"
export const POST_FAKE_JWT_LOGIN = "/post-jwt-login"
export const POST_FAKE_PASSWORD_FORGET = "/fake-forget-pwd"
export const POST_FAKE_JWT_PASSWORD_FORGET = "/jwt-forget-pwd"
export const SOCIAL_LOGIN = "/social-login"

//PROFILE
export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile"
export const POST_EDIT_PROFILE = "/post-fake-profile"
 

//COMPANIES
export const GET_COMPANIES = "/api/companies"
export const GET_COMPANY_DETAIL = "/api/companies"
export const ADD_NEW_COMPANY = "/api/companies"
export const UPDATE_COMPANY = "/api/companies"
export const DELETE_COMPANY = "/api/companies"

//CUSTOMERS
export const GET_CUSTOMERS = "/api/customers"
export const GET_CUSTOMER_DETAIL = "/api/customers"
export const ADD_NEW_CUSTOMER = "/api/customers"
export const UPDATE_CUSTOMER = "/api/customers"
export const DELETE_CUSTOMER = "/api/customers"

//ERPS
export const GET_ERPS = "/api/erps"
export const GET_ERP_DETAIL = "/api/erps"
export const ADD_NEW_ERP = "/api/erps"
export const UPDATE_ERP = "/api/erps"
export const DELETE_ERP = "/api/erps"

//BRANCHS
export const GET_BRANCHS = "/api/branchs"
export const GET_BRANCH_DETAIL = "/api/branchs"
export const ADD_NEW_BRANCH = "/api/branchs"
export const UPDATE_BRANCH = "/api/branchs"
export const DELETE_BRANCH = "/api/branchs"

//PAYMENTS
export const GET_PAYMENTS = "/api/payments"
export const GET_PAYMENT_DETAIL = "/api/payments"
export const ADD_NEW_PAYMENT = "/api/payments"
export const UPDATE_PAYMENT = "/api/payments"
export const DELETE_PAYMENT = "/api/payments"

//COLLECTION
export const GET_COLLECTIONS = "/api/collections"
export const GET_COLLECTION_DETAIL = "/api/collections"
export const ADD_NEW_COLLECTION = "/api/collections"
export const UPDATE_COLLECTION = "/api/collections"
export const DELETE_COLLECTION = "/api/collections"

//COLLECTION
export const GET_PROCESS_LIST_PAYMENTS = "/api/processListPayment"
export const GET_PROCESS_LIST_PAYMENT_DETAIL = "/api/processListPayment"
export const ADD_NEW_PROCESS_LIST_PAYMENT = "/api/processListPayment"
export const UPDATE_PROCESS_LIST_PAYMENT = "/api/processListPayment"
export const DELETE_PROCESS_LIST_PAYMENT = "/api/processListPayment"


//PRODUCTSALE
export const GET_PRODUCTSALES = "/api/productSales"
export const GET_PRODUCTSALE_DETAIL = "/api/productSales"
export const ADD_NEW_PRODUCTSALE = "/api/productSales"
export const UPDATE_PRODUCTSALE = "/api/productSales"
export const DELETE_PRODUCTSALE = "/api/productSales"

//PAYMENT_PLAN
export const GET_PAYMENT_PLAN = "/api/paymentPlan"
export const GET_PAYMENT_PLAN_DETAIL = "/api/paymentPlan"
export const ADD_NEW_PAYMENT_PLAN = "/api/paymentPlan"
export const UPDATE_PAYMENT_PLAN = "/api/paymentPlan"
export const DELETE_PAYMENT_PLAN= "/api/paymentPlan"
export const GET_PAYMENT_PLANS_MY_PLANS = "/api/paymentPlan/getMyPlans"

//PRODUCT
export const GET_PRODUCTS = "/api/products"
export const GET_PRODUCT_DETAIL = "/api/products"
export const ADD_NEW_PRODUCT = "/api/products"
export const UPDATE_PRODUCT = "/api/products"
export const UPDATE_PRODUCT_TYPE = "/api/products/productType"
export const DELETE_PRODUCT = "/api/products"
export const GET_PRODUCT_TYPES = "/api/products/productTypes"
//usercompany
export const UPDATE_USERCOMPANY = "/api/users/addCompany"

export const GET_ROLES = "/api/roles"
export const GET_USER = "/api/users"
export const GET_USER_DETAIL = "/api/users"
export const ADD_NEW_USERS = "/api/users"
export const UPDATE_USERS = "/api/users"
export const DELETE_USERS = "/api/users"
export const GET_COMPANY_BY_EMAIL = "/api/users"
export const FORGOT_SEND_MAIL = "/auth/forgotPassword"
export const SEND_MAIL = "/auth/mailtest"
export const EMAIL_CONFIRM = "/auth/confirm"


//PRODUCTSSERIES
export const GET_PRODUCTSSERIES = "/api/productsSeries"
export const GET_PRODUCTSERIES_DETAIL = "/api/productsSeries"
export const ADD_NEW_PRODUCTSERIES = "/api/productsSeries"
export const UPDATE_PRODUCTSERIES = "/api/productsSeries"
export const DELETE_PRODUCTSERIES = "/api/productsSeries"

//POSMACHINES
export const GET_POSMACHINES = "/api/posMachines"
export const GET_POSMACHINE_DETAIL = "/api/posMachines"
export const ADD_NEW_POSMACHINE = "/api/posMachines"
export const ADD_NEW_POSMACHINE_POSRATIO = "/api/posMachines/posRatio"
export const UPDATE_POSMACHINE = "/api/posMachines"
export const UPDATE_POSMACHINE_POSRATIO = "/api/posMachines/posRatio"
export const DELETE_POSMACHINE = "/api/posMachines"

//BANKACCOUNT
export const GET_BANKACCOUNTS = "/api/bankAccounts"
export const GET_BANKACCOUNT_DETAIL = "/api/bankAccounts"
export const ADD_NEW_BANKACCOUNT = "/api/bankAccounts"
export const UPDATE_BANKACCOUNT = "/api/bankAccounts"
export const DELETE_BANKACCOUNT = "/api/bankAccounts"

//EXPENSE
export const GET_EXPENSES = "/api/expenses"
export const GET_EXPENSE_DETAIL = "/api/expenses"
export const ADD_NEW_EXPENSE = "/api/expenses"
export const UPDATE_EXPENSE = "/api/expenses"
export const DELETE_EXPENSE = "/api/expenses" 
export const GET_TYPE_LIST_BY_CATEGORY_ID = "/api/expenses/getTypeListByCategoryId"

//PROCESSLIST
export const GET_PRODUCTS_BY_BARCODE = "/api/products/getProductsByBarcode"
export const GET_PRODUCTID_BY_SERIALNO = "/api/productsSeries/getProductIdBySerialNo"
export const GET_PRODUCT_BY_ID = "/api/productsSeries/getProductsById"
export const GET_CUSTOMER_BY_IDENTITY = "/api/customers/getCustomersByIdentitiy"
export const GET_CUSTOMER_BY_PHONE= "/api/customers/getCustomersByPhone"
export const GET_CUSTOMER_BY_TAXNO= "/api/customers/getCustomersByTaxNo"


//MAINCATEGORY
export const GET_MAINCATEGORIES = "/api/mainCategories"
export const GET_MAINCATEGORY_DETAIL = "/api/mainCategories"
export const ADD_NEW_MAINCATEGORY = "/api/mainCategories"
export const UPDATE_MAINCATEGORY = "/api/mainCategories"
export const DELETE_MAINCATEGORY = "/api/mainCategories"
//PRODUCTCATEGORY
export const GET_PRODUCTCATEGORIES = "/api/productCategories" 
export const GET_PRODUCTCATEGORY_DETAIL = "/api/productCategories"
export const ADD_NEW_PRODUCTCATEGORY = "/api/productCategories"
export const UPDATE_PRODUCTCATEGORY = "/api/productCategories"
export const DELETE_PRODUCTCATEGORY = "/api/productCategories"
 
//SUBCATEGORY
export const GET_SUBCATEGORIES = "/api/subCategories"
export const GET_SUBCATEGORY_DETAIL = "/api/subCategories"
export const ADD_NEW_SUBCATEGORY = "/api/subCategories"
export const UPDATE_SUBCATEGORY = "/api/subCategories"
export const DELETE_SUBCATEGORY = "/api/subCategories"

//SUBCATEGORY
export const GET_ENDOFDAYBALANCINGS = "/api/EndOfDayBalancing"
export const GET_SENDOFDAYBALANCING_DETAIL = "/api/EndOfDayBalancing"
export const ADD_NEW_ENDOFDAYBALANCING = "/api/EndOfDayBalancing"
export const UPDATE_ENDOFDAYBALANCING = "/api/EndOfDayBalancing"
export const DELETE_ENDOFDAYBALANCING = "/api/EndOfDayBalancing"

//SALESMODEL
export const GET_SALEMODELS = "/api/salesModels"
export const GET_SALEMODEL_DETAIL = "/api/salesModels"
export const POST_SALEMODEL = "/api/salesModels" 
export const DELETE_SALEMODEL = "/api/salesModels"
export const GET_MODAL_BY_USER_ID = "/api/salesModels"


//CONTACTS
export const GET_USERS = "/users"
export const GET_USER_PROFILE = "/user"
export const ADD_NEW_USER = "/add/user"
export const UPDATE_USER = "/update/user"
export const DELETE_USER = "/del/user"