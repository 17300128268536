import { call, put, takeEvery } from "redux-saga/effects";

// Crypto Redux States
import { GET_PRODUCTSSERIES, DELETE_PRODUCTSERIES, ADD_PRODUCTSERIES, UPDATE_PRODUCTSERIES} from "./actionTypes";
import {
    getProductsSeriesSuccess, getProductsSeriesFail,
    deleteProductSeriesSuccess, deleteProductSeriesFail,
    updateProductSeriesSuccess, updateProductSeriesFail,
    addProductSeriesSuccess, addProductSeriesFail
 } from "./actions";

//Include Both Helper File with needed methods
import {
    getProductsSeries, //API CONNECTION
    delProductSeries,
    addProductSeries,
    updateProductSeries
}
    from "../../helpers/fakebackend_helper"; 

function* fetchProductsSeries() {
    try {
        const response = yield call(getProductsSeries)
        yield put(getProductsSeriesSuccess(response));
    } catch (error) {
        yield put(getProductsSeriesFail(error));
    }
}

function* onDeleteProductsSeries({payload:id}){
    try{
        const response = yield call(delProductSeries, id)
        yield put(deleteProductSeriesSuccess(response));
    } catch (error) {
        yield put(deleteProductSeriesFail(error));
    }
}

function* onAddProductSeries({payload:productSeries}){
    try{
        const response = yield call(addProductSeries, productSeries)
        yield put(addProductSeriesSuccess(response));
    } catch (error) {
        yield put(addProductSeriesFail(error));
    }
}

function* onUpdateProductSeries({payload:productSeries}){
    try{
        const response = yield call(updateProductSeries, productSeries)
        yield put(updateProductSeriesSuccess(response));
    } catch (error) {
        yield put(updateProductSeriesFail(error));
    }
}

export function*ProductsSeriesSaga() {
    yield takeEvery(GET_PRODUCTSSERIES, fetchProductsSeries);
    yield takeEvery(DELETE_PRODUCTSERIES, onDeleteProductsSeries);
    yield takeEvery(ADD_PRODUCTSERIES, onAddProductSeries);
    yield takeEvery(UPDATE_PRODUCTSERIES, onUpdateProductSeries);
}

export default ProductsSeriesSaga;
