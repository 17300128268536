import {
    GET_ERPS,
    GET_ERPS_SUCCESS,
    GET_ERPS_FAIL,
    GET_ERP_DETAIL,
    GET_ERP_DETAIL_SUCCESS,
    GET_ERP_DETAIL_FAIL,
    ADD_ERP,
    ADD_ERP_SUCCESS,
    ADD_ERP_FAIL,
    UPDATE_ERP,
    UPDATE_ERP_SUCCESS,
    UPDATE_ERP_FAIL,
    DELETE_ERP,
    DELETE_ERP_SUCCESS,
    DELETE_ERP_FAIL,
    RESET_ERP
} from "./actionTypes";

export const resetErp = () => ({
    type: RESET_ERP,
});

export const getErps = () => ({
    type: GET_ERPS,
});

export const getErpsSuccess = (erps) => ({
    type: GET_ERPS_SUCCESS,
    payload: erps,
});


export const getErpsFail = (error) => ({
    type: GET_ERPS_FAIL,
    payload: error,
});

export const deleteErp = (id) => ({
    type:  DELETE_ERP,
    payload: id
});

export const deleteErpSuccess = (data) => ({
    type: DELETE_ERP_SUCCESS,
    payload: data
});

export const deleteErpFail = (error) => ({
    type: DELETE_ERP_FAIL,
    payload: error,
});


export const addErp = (erp) => ({
    type: ADD_ERP,
    payload: erp
});

export const addErpSuccess = (data) => ({
    type: ADD_ERP_SUCCESS,
    payload: data
});

export const addErpFail = (error) => ({
    type: ADD_ERP_FAIL,
    payload: error,
});


export const updateErp = (erp) => ({
    type: UPDATE_ERP,
    payload: erp
});

export const updateErpSuccess = (data) => ({
    type: UPDATE_ERP_SUCCESS,
    payload: data
});

export const updateErpFail = (error) => ({
    type: UPDATE_ERP_FAIL,
    payload: error,
});