import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useState, useEffect } from "react"
import {
    Container,
    Row,
    Col,
    Card,
    Alert,
    CardBody,
    Media,
    Button,
    UncontrolledDropdown,
    UncontrolledTooltip,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Modal,
    ModalHeader,
    ModalBody,
    Label
} from "reactstrap"
//redux
import { useSelector, useDispatch } from "react-redux"

import { withRouter } from "react-router-dom"



//i18n
import { withTranslation } from "react-i18next"

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"

import {
    getProductsSeries as onGetProductsSeries,
    deleteProductSeries as onDeleteProductSeries,
    addProductSeries as onAddProductSeries,
    updateProductSeries as onUpdateProductSeries, 
    resetProductSeries as onResetProductSeries,
    getProducts as onGetProducts
} from "store/actions"

import { isEmpty, map } from "lodash"
import moment from 'moment'

import { AvForm, AvField, AvGroup, AvInput, AvFeedback, AvRadioGroup, AvRadio, AvCheckboxGroup, AvCheckbox } from "availity-reactstrap-validation"

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
    PaginationProvider, PaginationListStandalone,
    SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert"

import "./datatables.scss"

const ProductsSeries = props => {
    const dispatch = useDispatch()

    const [selectedId, setSelectedId] = useState();
    const [selectedProductSeries, setSelectedProductSeries] = useState();
    const [success_dlg, setsuccess_dlg] = useState(false);
    const [confirm_alert, setconfirm_alert] = useState(false);
    const [dynamic_title, setdynamic_title] = useState("");
    const [dynamic_description, setdynamic_description] = useState("");
    const [modal, setModal] = useState(false);
    const [serialNoModal, setSerialNoModal] = useState(false);
    const [productTypes, setProductTypes] = useState([]);
    const [serialNumbers, setSerialNumber] = useState([]);
    const [isEdit, setIsEdit] = useState(false);
    const [selectedProductId, setSelectedProductId] = useState()
    const [productAlert,setProductAlert] =useState(false)
    
    useEffect(() => {
        dispatch(onGetProducts())
    }, [dispatch])
    const { error, ProductsSeries,Products } = useSelector(state => ({
        error: state.ProductsSeries?.error,
        ProductsSeries: state.ProductsSeries?.data,
        Products: state.Products?.data,
    }))

    useEffect(() => {
        dispatch(onGetProductsSeries());
    }, [dispatch]);

    
    let user = JSON.parse(localStorage.getItem("authUser"))

    const toggle = () => {
        setModal(!modal)
    }
    const serialNoToggle = () => {
        setSerialNoModal(!serialNoModal)
    }
    const handleChangeInput = (i, e, inputName) => {
        const values = [...productTypes]
        values[i][inputName] = e.target.value
        setProductTypes(values)
      }
    const handleSubtract = (i) => {
        const values = [...productTypes]
        values.splice(i, 1)
        setProductTypes([...values])
      }
      const handleAdd = () => {
        setProductTypes([...productTypes,
        {
          name: "",
         
        }])
      }
    const columns = [
        
        {
            dataField: 'productId',
            text:props.t("Product Name"),
            sort: true,
            formatter: (cell, row) => (
                <span>{cell?.name}</span>
            )

        }, 
        { 
            dataField: 'serialNo',
            text: props.t("Serial No"),
            sort: true ,
            formatter: (cell, row) => <span>{console.log(cell)} 
             <Button  onClick={() => {
                setSerialNumber(cell)
                serialNoToggle()

            }} className="btn btn-success me-2">
                           <i className="bx bx-info-circle" style={{font:"18px"}}></i>
                         </Button>
            </span>, 

    

        },
        {
            dataField: 'UserId',
            text: props.t("CreatorUserName"),
            sort: true,
            formatter: (cell, row) => (
                <span>{cell?.name}</span>
            )

        },
        {
            dataField: 'price',
            text:props.t("Recommended Selling Price"),
            sort: true,
           
        }, {
            dataField: '_id',
            text: props.t("Action"),
            sort: false,
            formatter: (cell, row) => (
                <UncontrolledDropdown style={{ position: "unset" }}>
                    <DropdownToggle href="#" className="card-drop" tag="i">
                        <i className="mdi mdi-dots-horizontal font-size-18"></i>
                    </DropdownToggle>
                    <div className="drop-absolute">
                    <DropdownMenu className="dropdown-menu-end">
                        <DropdownItem href="#"
                            onClick={() => {
                                setSelectedProductSeries(row);
                                setSelectedProductId(row.productId?._id)
                                setIsEdit(true);
                                toggle();
                                setProductAlert(false)
                            }}
                        >
                            <i className="mdi mdi-pencil font-size-16 text-success me-1"></i>
                            {props.t("Edit")}
                        </DropdownItem>
                        <DropdownItem href="#"
                            onClick={() => {
                                setSelectedId(cell);
                                setconfirm_alert(true);
                            }}>
                            <i className="mdi mdi-trash-can font-size-16 text-danger me-1"></i>
                            {props.t("Delete")}
                        </DropdownItem>
                    </DropdownMenu>
                    </div>
                </UncontrolledDropdown>
            )
        }];

    const defaultSorted = [{
        dataField: 'createdAt',
        order: 'desc'
    }];

    const pageOptions = {
        sizePerPage: 10,
        totalSize: ProductsSeries ? ProductsSeries.length : 0, // replace later with size(ProductsSeries),
        custom: true,
    }

    // Custom Pagination Toggle
    const sizePerPageList = [
        { text: '5', value: 5 },
        { text: '10', value: 10 },
        { text: '15', value: 15 },
        { text: '20', value: 20 },
        { text: '25', value: 25 },
        { text: 'All', value: ProductsSeries ? ProductsSeries.length : 0 }];


    // Select All Button operation
    const selectRow = {
        mode: 'checkbox'
    }

    const { SearchBar } = Search;

    const handleValidProductSeriesSubmit = (e, values) => {
        if(isEmpty(values.productId) || values.productId=="Ürün Seç"){
            setProductAlert(true)
        }
        else{
            if (isEdit) {
                const updateProductSeries = {
                    _id: selectedProductSeries._id,
                    productId: values.productId,
                    serialNo: JSON.stringify(productTypes),
                    UserId: user.uid,
                    price: values["price"],
                    CompanyId: user.companyId,
                   
                    
                };
                // update ProductSeries
                dispatch(onUpdateProductSeries(updateProductSeries));
            } else {
                const newProductSeries = {
                    productId: values.productId,
                    serialNo: JSON.stringify(productTypes),
                    UserId: user.uid,
                    price: values["price"],
                    CompanyId: user.companyId,
    
                }
                // save new ProductSeries
               dispatch(onAddProductSeries(newProductSeries))
             
            }
            toggle()
        }
        
    }

    const handleValidDate = date => {
        if (date) {
            const date1 = moment(new Date(date)).format("DD.MM.YYYY");
            return date1;
        } else {
            return "";
        }
    }
    const handleValidDateAvField = date => {
        if (date) {
            const date1 = moment(new Date(date)).format("YYYY-MM-DD");
            return date1;
        } else {
            return "";
        }
    }
     
    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>{props.t("Product Series")}</title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumb title={props.t("Product Series")} breadcrumbItem={props.t("Product Series")} addBtn={true} addBtnCallBack={() => {
                        setIsEdit(false);
                        setProductAlert(false)
                        setSelectedProductSeries(
                            {
                                productId: "",
                                serialNo: "",
                                UserId: "",
                                price: "",
                               

                            }
                        );
                        toggle();

                    }} />

                    {/* {error && error ? <Alert color="danger">{error}</Alert> : null} */}

                    <Card>
                        <CardBody>
                            <PaginationProvider
                                pagination={paginationFactory(pageOptions)}
                                keyField='id'
                                columns={columns}
                                data={ProductsSeries}
                            >
                                {({ paginationProps, paginationTableProps }) => (
                                    <ToolkitProvider
                                        keyField='_id'
                                        columns={columns}
                                        data={ProductsSeries}
                                        search
                                    >
                                        {toolkitProps => (
                                            <React.Fragment>

                                                <Row className="mb-2">
                                                    <Col md="4">
                                                        <div className="search-box me-2 mb-2 d-inline-block">
                                                            <div className="position-relative">
                                                                <SearchBar
                                                                    {...toolkitProps.searchProps}
                                                                />
                                                                <i className="bx bx-search-alt search-icon" />
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col xl="12">
                                                        <div className="table-responsive">
                                                            <BootstrapTable
                                                                keyField={"_id"}
                                                                responsive
                                                                bordered={false}
                                                                striped={false}
                                                                defaultSorted={defaultSorted}
                                                                classes={
                                                                    "table align-middle table-nowrap"
                                                                }
                                                                headerWrapperClasses={"thead-light"}
                                                                {...toolkitProps.baseProps}
                                                                {...paginationTableProps}
                                                            />

                                                        </div>
                                                    </Col>
                                                </Row>

                                                <Row className="align-items-md-center mt-30">
                                                    <Col className="inner-custom-pagination d-flex">
                                                        <div className="d-inline">
                                                            <SizePerPageDropdownStandalone
                                                                {...paginationProps}
                                                            />
                                                        </div>
                                                        <div className="text-md-right ms-auto">
                                                            <PaginationListStandalone
                                                                {...paginationProps}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </React.Fragment>
                                        )
                                        }
                                    </ToolkitProvider>
                                )
                                }</PaginationProvider>

                        </CardBody>
                    </Card>


                    <Modal isOpen={modal} toggle={toggle}>
                        <ModalHeader toggle={toggle} tag="h4">
                            {!!isEdit ? props.t("Edit Product") : props.t("Add Product")}
                        </ModalHeader>
                        <ModalBody>
                            <AvForm onValidSubmit={handleValidProductSeriesSubmit}>
                                <Row form>
                                    <Col xs={12}>

                                       

                                    <div className="mb-3">
                                            <AvField type="select" name="productId" label={props.t("Select Product")}  id="productSelection" value={selectedProductId}>
                                                <option>{props.t("Select Product")}</option>
                                                {Products?.map((item, index) => (
                                                    <option key={index} value={item._id}>{item.name}</option>
                                                   
                                                ))}
                                            </AvField>
                                        </div>
                                       
                                       

                                       
                                        {/* <div className="mb-3">
                                            <AvField
                                                name="serialNo"
                                                label={props.t("Serial No")}
                                                type="text"
                                                errorMessage={props.t("InvalidInput")}
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                value={selectedProductSeries?.serialNo || ""}
                                            />
                                        </div> */}
                                        <Row className="mt-2">
                          <Col md="4">{props.t("Serial No")}</Col>
                          <Col md="3"> {productTypes.length == 0 ?(
                           <Button style={{position:"relative", bottom:"10px" ,right:"88px"}} onClick={() => handleAdd()} className="btn btn-success me-2">
                           <i className="fas fa-plus"></i>
                         </Button>
                        ):null}</Col>
                          <Col md="3"></Col>
                          <Col md="2">
                         </Col>
                       
                       
                        </Row>
                        {productTypes.map((item, i) => (
                          <div key={i}>
                            <input
                              id={"productTypes" + (i) + "__isValue"}
                              name={"productTypes[" + (i) + "].isValue"}
                              type="hidden"
                            />
                            <Row className="mt-2">
                              <Col md="8">
                                <AvField
                                  type="text"
                                  id={"productTypes" + (i) + "__name"}
                                  name={"productTypes[" + (i) + "].name"}
                                  placeholder={props.t("Serial No")}
                                  value={item.name}
                                  onChange={e => handleChangeInput(i, e, "name")}
                                  validate={{
                                    required: { value: false },
                                  }}
                                />
                              </Col>


                              <Col md="4">
                                {i == productTypes.length - 1 ?
                                  <div>
                                    <Button onClick={() => handleAdd()} className="btn btn-success me-2">
                                      <i className="fas fa-plus"></i>
                                    </Button>
                                   <Button onClick={() => handleSubtract(i)}>
                                      <i className="fas fa-minus"></i>
                                    </Button> 
                                  </div> : null
                                }
                              </Col>

                            </Row>
                          </div>
                        ))}
                                       
                                       
                                        
                                            <div className="mb-3">
                                                <AvField
                                                    name="price"
                                                    label={props.t("Recommended Selling Price")}
                                                    type="text"
                                                    errorMessage={props.t("InvalidInput")}
                                                    validate={{
                                                        required: { value: false },
                                                    }}
                                                    value={selectedProductSeries?.price || ""}
                                                />
                                            </div> 
                                        
                                       
                                       
                                        
                                      
                                               

                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        {productAlert == true ?
                                            <Alert color="danger">
                                                {props.t("You Have To Select a Product")}
                                            </Alert>
                                            : null
                                        }
                                    <div className="text-end"> 
                                            <button 
                                                type="submit"
                                                className="btn btn-success save-user"
                                            >
                                                {props.t("Save")}
                                            </button>
                                            &nbsp;&nbsp;
                                            <Button onClick={()=>{ toggle() }}>
                                                    {props.t("Cancel")}
                                                    </Button>       
                                            
                                        </div>
                                    </Col>
                                </Row>
                            </AvForm>
                        </ModalBody>
                    </Modal>
                    <Modal isOpen={serialNoModal} toggle={serialNoToggle}>
                        <ModalHeader toggle={serialNoToggle} tag="h4">
                            {props.t("Serial Numbers")}
                        </ModalHeader>
                        <ModalBody>
                            <AvForm>
                                <Row form>
                                    <Col xs={12}>
                                        {serialNumbers.map((item, i) => (
                                            <div key={i}>
                                                <input
                                                    id={"productTypes" + (i) + "__isValue"}
                                                    name={"productTypes[" + (i) + "].isValue"}
                                                    type="hidden"
                                                />
                                                <Row className="mt-2">
                                                    <Col md="8">
                                                        <AvField
                                                            type="text"
                                                            id={"productTypes" + (i) + "__name"}
                                                            name={"productTypes[" + (i) + "].name"}
                                                            placeholder={props.t("Serial No")}
                                                            value={item.name}
                                                            onChange={e => handleChangeInput(i, e, "name")}
                                                            validate={{
                                                                required: { value: false },
                                                            }}
                                                        />
                                                    </Col>


                                                   

                                                </Row>
                                            </div>
                                        ))}
                                           </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        {productAlert == true ?
                                            <Alert color="danger">
                                                {props.t("You Have To Select a Product")}
                                            </Alert>
                                            : null
                                        }
                                    <div className="text-end"> 
                                            
                                            &nbsp;&nbsp;
                                            <Button onClick={()=>{ toggle() }}>
                                                    {props.t("Cancel")}
                                                    </Button>       
                                            
                                        </div>
                                    </Col>
                                </Row>
                            </AvForm>
                        </ModalBody>
                    </Modal>
                    {success_dlg ? (
                        <SweetAlert
                            success
                            title={dynamic_title}
                            confirmBtnText={props.t("Ok")}
                            onConfirm={() => {
                                setsuccess_dlg(false)
                            }}
                        >
                            {dynamic_description}
                        </SweetAlert>
                    ) : null}

                    {confirm_alert ? (
                        <SweetAlert
                            title={props.t("Are You Sure")}
                            warning
                            showCancel
                            confirmBtnBsStyle="success"
                            cancelBtnBsStyle="danger"
                            cancelBtnText={props.t("Cancel")}
                            confirmBtnText={props.t("Delete")}
                            onConfirm={() => {
                                setconfirm_alert(false);
                                setsuccess_dlg(true);
                                setdynamic_title(props.t("Deleted"));
                                setdynamic_description("");
                                dispatch(onDeleteProductSeries(selectedId));
                                setSelectedId(null);
                            }}
                            onCancel={() => setconfirm_alert(false)}
                        >
                            {props.t("DeleteWarning")}
                        </SweetAlert>
                    ) : null}


                    {error && error ? (
                        <SweetAlert
                            title={props.t("Error")}
                            warning
                            confirmBtnText={props.t("Ok")}
                            onConfirm={() => {
                                dispatch(onResetProductSeries())
                            }}
                        >
                            {JSON.stringify(error)}
                        </SweetAlert>
                    ) : null}

                </Container>
            </div>
        </React.Fragment >
    )
}

export default withRouter(withTranslation()(ProductsSeries))
