module.exports = {
  google: {
    API_KEY: "",
    CLIENT_ID: "",
    SECRET: "",
  },
  facebook: {
    APP_ID: "",
  },
  baseApi: (process.env.REACT_APP_TEST=='true'?(process.env.REACT_APP_BASE_API_TEST) : (process.env.REACT_APP_BASE_API)), 
  //baseApi:process.env.REACT_APP_BASE_API,
  baseImg:  (process.env.REACT_APP_TEST=='true'?(process.env.REACT_APP_BASE_API_TEST) : (process.env.REACT_APP_BASE_API)) +'/static/uploads/'
}
