import {
    GET_PAYMENT_PLANS,
    GET_PAYMENT_PLANS_SUCCESS,
    GET_PAYMENT_PLANS_FAIL,
    GET_PAYMENT_PLAN_DETAIL,
    GET_PAYMENT_PLAN_DETAIL_SUCCESS,
    GET_PAYMENT_PLAN_DETAIL_FAIL,
    ADD_PAYMENT_PLAN,
    ADD_PAYMENT_PLAN_SUCCESS,
    ADD_PAYMENT_PLAN_FAIL,
    UPDATE_PAYMENT_PLAN,
    UPDATE_PAYMENT_PLAN_SUCCESS,
    UPDATE_PAYMENT_PLAN_FAIL,
    DELETE_PAYMENT_PLAN,
    DELETE_PAYMENT_PLAN_SUCCESS,
    DELETE_PAYMENT_PLAN_FAIL,
    RESET_PAYMENT_PLAN,
    GET_PAYMENT_PLANS_MY_PLANS_SUCCESS,
    GET_PAYMENT_PLANS_MY_PLANS_FAIL
} from "./actionTypes";

const INIT_STATE = {
    data: [], 
    error: null,
    myPaymentPlans: []
};

const PaymentPlans = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_PAYMENT_PLANS_MY_PLANS_SUCCESS:
            return {
                ...state,
                myPaymentPlans: action.payload.data
            }
        case GET_PAYMENT_PLANS_SUCCESS:
            return {
                ...state,
                data: action.payload.data
            }
        case DELETE_PAYMENT_PLAN_SUCCESS:
            return {
                ...state,
                data: state.data.filter(paymentPlan => paymentPlan._id.toString() !== action.payload.data.toString())
            }
        case ADD_PAYMENT_PLAN_SUCCESS:
            return {
                ...state,
                data: [action.payload.data, ...state.data]
            }
        case UPDATE_PAYMENT_PLAN_SUCCESS:
            return {
                ...state,
                data: state.data.map(paymentPlan =>
                    paymentPlan._id.toString() === action.payload.data._id.toString() ?
                        { paymentPlan, ...action.payload.data } :
                        paymentPlan
                )
            }

        //FAILS
        case GET_PAYMENT_PLANS_FAIL:
        case DELETE_PAYMENT_PLAN_FAIL:
        case ADD_PAYMENT_PLAN_FAIL:
        case UPDATE_PAYMENT_PLAN_FAIL:
        case GET_PAYMENT_PLANS_MY_PLANS_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case RESET_PAYMENT_PLAN: return {
            ...state,
            error: null
        }
        default:
            return state;
    }
}


export default PaymentPlans;