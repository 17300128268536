import React, { useState, useEffect } from "react"

import { useSelector, useDispatch } from "react-redux"
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Button,
} from "reactstrap"

import { isEmpty } from "lodash"

//i18n
import { withTranslation } from "react-i18next"
import { AvForm, AvField, AvGroup, AvInput, AvFeedback, AvRadioGroup, AvRadio, AvCheckboxGroup, AvCheckbox } from "availity-reactstrap-validation"
import {

  postSaleModel as onPostSaleModel,
  getModalByUserId as onGetModalByUserId
} from "store/actions";

let user = JSON.parse(localStorage.getItem("authUser"))
  
const SaleList= props => {

  const dispatch = useDispatch();

  const [cashSaleIsExist, setCashSaleIsExist] = useState(false);
  const [creditCardIsExist, setCreditCardIsExist] = useState(false);
  const [openAccountSaleIsExist, setOpenAccountSaleIsExist] = useState(false);
  const [transferSaleIsExist, setTransferSaleIsExist] = useState(false);
  const [chequeSaleIsExist, setChequeSaleIsExist] = useState(false);
  const [billSaleIsExist, setBillSaleIsExist] = useState(false);
  const [turkcellCommitmentSaleIsExist, setTurkcellCommitmentSaleIsExist] = useState(false);
  const [turkcellContractSaleIsExist, setTurkcellContractSaleIsExist] = useState(false);
  const [turkcellPrePaidSaleIsExist, setTurkcellPrePaidSaleIsExist] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const { error, SaleModels } = useSelector(state => ({
    error: state.SaleModels?.error,
    SaleModels: state.SaleModels?.data,
  }));

  useEffect(() => {
    dispatch(onGetModalByUserId(user.uid));
  }, [dispatch]);

  useEffect(() => {
    if(!isEmpty(SaleModels)){
     
      setCashSaleIsExist(SaleModels.cashSale);
      setCreditCardIsExist(SaleModels.creditCard);
      setOpenAccountSaleIsExist(SaleModels.openAccountSale);
      setTransferSaleIsExist(SaleModels.transferSale);
      setChequeSaleIsExist(SaleModels.chequeSale);
      setBillSaleIsExist(SaleModels.billSale);
      setTurkcellCommitmentSaleIsExist(SaleModels.turkcellCommitmentSale);
      setTurkcellContractSaleIsExist(SaleModels.turkcellContractSale);
      setTurkcellPrePaidSaleIsExist(SaleModels.turkcellPrePaidSale);
    }
  }, [SaleModels]);


  const handleValidSaleModelSubmit = (event, values) => {
   
    const newSaleModel = {
      UserId: user.uid,
      cashSale: cashSaleIsExist,
      creditCard: creditCardIsExist,
      openAccountSale: openAccountSaleIsExist,
      transferSale: transferSaleIsExist,
      chequeSale: chequeSaleIsExist,
      billSale: billSaleIsExist,
      turkcellCommitmentSale: turkcellCommitmentSaleIsExist,
      turkcellContractSale: turkcellContractSaleIsExist,
      turkcellPrePaidSale: turkcellPrePaidSaleIsExist,
    }
    // save new ProductSeries
    dispatch(onPostSaleModel(newSaleModel))
  }

  return (
    <React.Fragment>
      <div className="page-content">


        <Container fluid>
          <h4 >{props.t("HomePage")}</h4>
          <AvForm onValidSubmit={handleValidSaleModelSubmit}>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <CardTitle>{props.t("Sales Models")}</CardTitle>
                  <small>
                    {props.t("select the sales models that you want to use")}
                  </small>
                  <Row form>
                    <Col xl={3} sm={4}>
                      <div className="mt-4">
                        <div
                          className="form-check form-switch form-switch-lg mb-3"
                        >
                          <label
                            className="form-check-label"
                            htmlFor="cashSale"
                          >
                            Nakit Satış
                          </label>
                          <input
                            type="checkbox"
                            name="cashSale"
                            id="cashSale"
                            className="form-check-input"
                            checked={cashSaleIsExist}
                            onChange={(e) => {
                              setCashSaleIsExist(!cashSaleIsExist);
                             
                            }}

                          />

                        </div>

                        <div
                          className="form-check form-switch form-switch-lg mb-3"
                        >
                          <label
                            className="form-check-label"
                            htmlFor="creditCard"
                          >
                            Kredikartı ile Satış
                          </label>
                          <input

                            type="checkbox"
                            name="creditCard"
                            id="creditCard"
                            className="form-check-input"
                            checked={creditCardIsExist}
                            onChange={(e) => {
                              setCreditCardIsExist(!creditCardIsExist);
                            }}

                          />

                        </div>

                        <div
                          className="form-check form-switch form-switch-lg mb-3"
                        >
                          <label
                            className="form-check-label"
                            htmlFor="openAccountSale"
                          >
                            Açık Hesap Satış
                          </label>
                          <input

                            type="checkbox"
                            name="openAccountSale"
                            id="openAccountSale"
                            className="form-check-input"
                            checked={openAccountSaleIsExist}
                            onChange={(e) => {
                              setOpenAccountSaleIsExist(!openAccountSaleIsExist);


                            }}

                          />

                        </div>

                        <div
                          className="form-check form-switch form-switch-lg mb-3"
                        >
                          <label
                            className="form-check-label"
                            htmlFor="transferSale"
                          >
                            Havale / EFT ile Satış
                          </label>
                          <input

                            type="checkbox"
                            name="transferSale"
                            id="transferSale"
                            className="form-check-input"
                            checked={transferSaleIsExist}
                            onChange={(e) => {
                              setTransferSaleIsExist(!transferSaleIsExist);


                            }}

                          />

                        </div>

                        <div
                          className="form-check form-switch form-switch-lg mb-3"
                        >
                          <label
                            className="form-check-label"
                            htmlFor="chequeSale"
                          >
                            Çek ile Satış
                          </label>
                          <input

                            id="chequeSale"
                            name="chequeSale"
                            type="checkbox"
                            className="form-check-input"
                            checked={chequeSaleIsExist}
                            onChange={(e) => {
                              setChequeSaleIsExist(!chequeSaleIsExist);


                            }}

                          />

                        </div>

                        <div
                          className="form-check form-switch form-switch-lg mb-3"
                        >
                          <label
                            className="form-check-label"
                            htmlFor="billSale"
                          >
                            Senet ile Satış
                          </label>
                          <input

                            type="checkbox"
                            name="billSale"
                            id="billSale"
                            className="form-check-input"
                            checked={billSaleIsExist}
                            onChange={(e) => {
                              setBillSaleIsExist(!billSaleIsExist);


                            }}

                          />

                        </div>

                        <hr />

                        <div
                          className="form-check form-switch form-switch-lg mb-3"
                        >
                          <label
                            className="form-check-label"
                            htmlFor="turkcellCommitmentSale"
                          >
                            Turkcell Taahhütlü Satış
                          </label>
                          <input

                            type="checkbox"
                            name="turkcellCommitmentSale"
                            className="form-check-input"
                            checked={turkcellCommitmentSaleIsExist}
                            onChange={(e) => {
                              setTurkcellCommitmentSaleIsExist(!turkcellCommitmentSaleIsExist);


                            }}

                          />

                        </div>

                        <div
                          className="form-check form-switch form-switch-lg mb-3"
                        >
                          <label
                            className="form-check-label"
                            htmlFor="turkcellContractSale"
                          >
                            Turkcell Peşine Kontratlı Satış
                          </label>
                          <input
                            type="checkbox"
                            name="turkcellContractSale"
                            id="turkcellContractSale"
                            className="form-check-input"
                            checked={turkcellContractSaleIsExist}
                            onChange={(e) => {
                              setTurkcellContractSaleIsExist(!turkcellContractSaleIsExist);


                            }}

                          />

                        </div>

                        <div
                          className="form-check form-switch form-switch-lg mb-3"
                        >
                          <label
                            className="form-check-label"
                            htmlFor="turkcellPrePaidSale"
                          >
                            Turkcell Ön Ödemeli Satış
                          </label>
                          <input

                            type="checkbox"
                            name="turkcellPrePaidSale"
                            id="turkcellPrePaidSale"
                            className="form-check-input"
                            checked={turkcellPrePaidSaleIsExist}
                            onChange={(e) => {
                              setTurkcellPrePaidSaleIsExist(!turkcellPrePaidSaleIsExist);
                            }}

                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div>
                        <button
                          type="submit"
                          className="btn btn-success save-user"

                        >
                          {props.t("Save")}
                        </button>



                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </AvForm>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(SaleList)
