import React from "react"

import PropTypes from 'prop-types'
 
const HomeButton = (props) => ( 
    <button
      type="button"
      className="btn btn-primary col-12 col-md-9 mb-3"
      onClick={()=>
        { 
          props._cb();
        }
      }
    >
      {props.name}
    </button> 
) 


HomeButton.propTypes = { 
  name: PropTypes.string,
  _cb:PropTypes.func
}


export default HomeButton;
