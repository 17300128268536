import {
    GET_SUBCATEGORIES,
    GET_SUBCATEGORIES_SUCCESS,
    GET_SUBCATEGORIES_FAIL,
    GET_SUBCATEGORY_DETAIL,
    GET_SUBCATEGORY_DETAIL_SUCCESS,
    GET_SUBCATEGORY_DETAIL_FAIL,
    ADD_SUBCATEGORY,
    ADD_SUBCATEGORY_SUCCESS,
    ADD_SUBCATEGORY_FAIL,
    UPDATE_SUBCATEGORY,
    UPDATE_SUBCATEGORY_SUCCESS,
    UPDATE_SUBCATEGORY_FAIL,
    DELETE_SUBCATEGORY,
    DELETE_SUBCATEGORY_SUCCESS,
    DELETE_SUBCATEGORY_FAIL,
    RESET_SUBCATEGORY
} from "./actionTypes";

export const resetSubCategory = () => ({
    type: RESET_SUBCATEGORY,
});

export const getSubCategories = () => ({
    type: GET_SUBCATEGORIES,
}); 

export const getSubCategoriesSuccess = (SubCategory) => ({
    type: GET_SUBCATEGORIES_SUCCESS,
    payload: SubCategory,
});


export const getSubCategoriesFail = (error) => ({
    type: GET_SUBCATEGORIES_FAIL,
    payload: error, 
});

export const deleteSubCategory = (id) => ({
    type:  DELETE_SUBCATEGORY,
    payload: id
});

export const deleteSubCategorySuccess = (data) => ({
    type: DELETE_SUBCATEGORY_SUCCESS,
    payload: data
});

export const deleteSubCategoryFail = (error) => ({
    type: DELETE_SUBCATEGORY_FAIL,
    payload: error,
});


export const addSubCategory = (subCategory) => ({
    type: ADD_SUBCATEGORY,
    payload: subCategory
});

export const addSubCategorySuccess = (data) => ({
    type: ADD_SUBCATEGORY_SUCCESS,
    payload: data
});

export const addSubCategoryFail = (error) => ({
    type: ADD_SUBCATEGORY_FAIL,
    payload: error,
});


export const updateSubCategory = (subCategory) => ({
    type: UPDATE_SUBCATEGORY,
    payload: subCategory
});

export const updateSubCategorySuccess = (data) => ({
    type: UPDATE_SUBCATEGORY_SUCCESS,
    payload: data
});

export const updateSubCategoryFail = (error) => ({
    type: UPDATE_SUBCATEGORY_FAIL,
    payload: error, 
});