import React, { useState,useEffect } from "react"
import PropTypes from 'prop-types'
import { Container, Row, Col, Modal, ModalHeader, ModalBody,TabContent,TabPane,Label,
    FormGroup,
    CardImgOverlay,Input, Card, CardBody} from "reactstrap"
import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
} from "availity-reactstrap-validation"
import InputMask from "react-input-mask"
import { useSelector, useDispatch } from "react-redux"
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';
import moment from "moment"
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

import {
  addCustomer as onAddCustomer,
  addErpSuccess,
  getCustomerByIdentity as OnGetCustomerByIdentity,
  getCustomerByPhone as OnGetCustomerByPhone,
  getCustomerByTaxNo as OnGetCustomerByTaxNo,
  
} from "store/actions"
import BootstrapTable from 'react-bootstrap-table-next';
import { isEmpty, map } from "lodash"
import { Link, withRouter } from "react-router-dom"
//i18n
import { withTranslation } from "react-i18next"

const CustomerModal = (props, cb) => {
  const { SearchBar } = Search;
  const dispatch = useDispatch()
  let user = JSON.parse(localStorage.getItem("authUser"))
  const [selectCustomerModal, setSelectCustomerModal] = useState(false)
  const [selectedCustomerName, setSelectedCustomerName] = useState()
  const [selectedCustomerId, setSelectedCustomerId] = useState()
  const [addCustomerModal,setAddCustomerModal] =useState(false)
  const [selectedCustomerType, setSelectedCustomerType] = useState(false)
  const {
    error,
    Expenses,
    MainCategories,
    Product,
    ProductId,
    Payments,
    CustomerIdentity,
    CustomerPhone,
    CustomerTaxNo,
    Customers,
    Collections,
    myPaymentPlans,
  } = useSelector(state => ({
    error: state.Expenses?.error,
    Expenses: state.Expenses?.data,
    Payments: state.Payments?.data,
    MainCategories: state.MainCategories?.data,
    Customers : state.Customers?.data,
    Product: state.ProcessList?.Product,

    ProductId: state.ProcessList?.ProductId,
    CustomerIdentity: state.ProcessList?.identityNumber,
    CustomerPhone: state.ProcessList?.phoneNumber,
    CustomerTaxNo: state.ProcessList?.taxNumber,

    myPaymentPlans: state.PaymentPlans?.myPaymentPlans,
    Collections: state.Collections?.data,
  }))
 
  const selectCustomerToggle = () => {
    setSelectCustomerModal(!selectCustomerModal)

  }
  const addCustomerToggle = () => {
    setAddCustomerModal(!addCustomerModal) 
}
  const handleValidDateAvField = date => {
    if (date) {
      const date1 = moment(new Date(date)).format("YYYY-MM-DD")
      return date1
    } else {
      return ""
    }
  }
  const handleValidDate = date => {
    if (date) {
      const date1 = moment(new Date(date)).format("DD.MM.YYYY");
      return date1;
    } else {
      return "";
    }
  }
  const defaultSorted = [{
    dataField: 'createdAt',
    order: 'desc'
  }];
  const pageOptions = {
    sizePerPage: 10,
    totalSize: Customers ? Customers.length : 0, // replace later with size(customers),
    custom: true,
  }
  const columns = [
    {
      dataField: "_id",
      text: props.t("Action"),
      sort: false,
      formatter: (cell, row) => (
        <button style={{ width: "100%" }}

          onClick={() => {
            setSelectedCustomerName(row.customerName)
            setSelectedCustomerId(row._id)

            selectCustomerToggle()
          }}
          className="btn btn-success  col-4"
        >
          {props.t("Choose")}
        </button>
      ),
    },
    {
      dataField: 'isType',
      text: props.t("isType"),
      sort: true,
      formatter: (cell, row) => {
        switch (cell) {
          case "individual": return <span>{props.t("Individual")}</span>;
          case "corporate": return <span>{props.t("Corporate")}</span>;
          default: return <span>?</span>;
        }
      }
    },
    {
      dataField: 'customerName',
      text: props.t("Name"),
      sort: true

    },
    {
      dataField: 'lastName',
      text: props.t("LastName"),
      sort: true

    },
    {
      dataField: 'birthday',
      text: props.t("DateOfBirth"),
      sort: true,
      formatter: (cell, row) => (
        <span>{handleValidDate(cell)}</span>
      )
    },
    {
      dataField: 'phoneNumber',
      text: props.t("Phone"),
      sort: true
    },
    {
      dataField: 'email',
      text: "email",
      sort: true
    },
    {
      dataField: 'identityNumber',
      text: props.t("IdentificationNumber"),
      sort: true
    },
    {
      dataField: 'gender',
      text: props.t("Gender"),
      sort: true
    },
    {
      dataField: 'adress',
      text: props.t("Address"),
      sort: true
    },
    {
      dataField: 'city',
      text: props.t("City"),
      sort: true
    },
    {
      dataField: 'taxAdministration',
      text: props.t("TaxAdministration"),
      sort: true
    },
    {
      dataField: 'taxNumber',
      text: props.t("TaxNo"),
      sort: true
    },

    {
      dataField: 'eFaturaMukellef',
      text: "eFaturaMukellef",
      sort: true,
      formatter: (cell, row) => (
        <span>{cell === true ? "Evet" : "Hayır"}</span>
      )
    },

  ];
  const handleValidCustomerSubmit = (e, values) => {
    {
      const newCustomer = {
        customerName: values.customerName,
        lastName: values.lastName,
        isType: isEmpty(values["isType"])?"individual": values["isType"],
        birthday: values["birthday"],
        phoneNumber: values["phoneNumber"],
        email: values["email"],
        identityNumber: values["identityNumber"],
        gender: values["gender"],
        adress: values["adress"],
        city: values["city"],
        taxAdministration: values["taxAdministration"],
        taxNumber: values["taxNumber"],
        
        eFaturaMukellef: isEmpty(values.eFaturaMukellef)
          ? true
          : values.eFaturaMukellef,
          CompanyId: user.companyId,
         
      }
      // save new Customer 
     
        dispatch(onAddCustomer(newCustomer))
        addCustomerToggle()
    }
  }
 
 
  return (
    <React.Fragment>
 <Modal isOpen={props.modal} toggle={props.toggle}>
        <ModalHeader toggle={props.toggle} tag="h4">
          {props.t("Customer")}
        </ModalHeader>
        <ModalBody>
          <AvForm>
            <Row form>
              <Col xs={12}>
                <Container>

                  

                  
                <div className="mb-3">
                    <AvField
                      name="customerName"
                      label={props.t("Customer Name")}
                      disabled
                      type="text"
                      errorMessage={props.t("InvalidInput")}
                      validate={{
                        required: { value: false },
                      }}
                      value={selectedCustomerName || ""}
                    />
                  </div>
                  <Row>
                    <Col md="6">
                     
                    </Col>
                    <Col md="6">
                     
                        <button style={{ width: "100%" }}
                          type="button"
                          onClick={() => {
                            selectCustomerToggle()
                          }}
                          className="btn btn-success  col-4"
                        >
                          {props.t("Müşteri Ara")}
                        </button>
                      

                    </Col>

                  </Row>



                  

                 
                </Container>
              </Col>
            </Row>
            <Row className="mt-3"></Row>
            <Row className="mt-3">
              <Col md="12">
                <div className="text-end">
                  <button style={{width:"34%"}}
                    type="submit" 
                    onClick={() => {
                      let _body = {
                        customerName: selectedCustomerName,
                        customerId:selectedCustomerId
                        
                      };
                      
                      //console.log("payment bu",_body);

                      props.payment(true,_body)
                    }}
                    className="btn btn-success save-user col-4"
                  >
                    {props.t("Save And Continue")}
                  </button>
                  &nbsp;&nbsp;
                  <button 
                    className="btn btn-secondary col-4 "
                    type="button"
                    onClick={() => {
                      props.finalCustomer(true)
                    }}
                  >
                    {props.t("Final Costumer")}
                  </button>
                </div>
              </Col>
            </Row>
          </AvForm>
        </ModalBody>
      </Modal>

 <Modal isOpen={selectCustomerModal} size="xl" toggle={selectCustomerToggle}>

<Card>

  <CardBody>

    <PaginationProvider
      pagination={paginationFactory(pageOptions)}
      keyField='id'
      columns={columns}
      data={Customers}
    >
      {({ paginationProps, paginationTableProps }) => (
        <ToolkitProvider
          keyField='_id'
          columns={columns}
          data={Customers}
          search
        >
          {toolkitProps => (
            <React.Fragment>

              <Row className="mb-2">
                <Col md="3">
                  <div className="search-box me-2 mb-2 d-inline-block">
                    <div className="position-relative">
                      <SearchBar
                        {...toolkitProps.searchProps}
                      />
                      <i className="bx bx-search-alt search-icon" />
                    </div>
                  </div>
                </Col>
                <Col md="3">
                  <button
                    className="btn btn-success col-12 "
                    type="button"
                    onClick={() => {
                      addCustomerToggle()
                    }}
                  >
                    +{props.t("Add Customer")}
                  </button>
                </Col>
              </Row>

              <Row>
                <Col xl="12">
                  <div className="table-responsive">
                    <BootstrapTable
                      keyField={"_id"}
                      responsive
                      bordered={false}
                      striped={false}
                      noDataIndication={props.t("You Don't Have a Customer Yet")}

                      defaultSorted={defaultSorted}
                      classes={
                        "table align-middle table-nowrap"
                      }
                      headerWrapperClasses={"thead-light"}
                      {...toolkitProps.baseProps}
                      {...paginationTableProps}
                    />

                  </div>
                </Col>
              </Row>

              <Row className="align-items-md-center mt-30">
                <Col className="inner-custom-pagination d-flex">
                  <div className="d-inline">
                    <SizePerPageDropdownStandalone
                      {...paginationProps}
                    />
                  </div>
                  <div className="text-md-right ms-auto">
                    <PaginationListStandalone
                      {...paginationProps}
                    />
                  </div>
                </Col>
              </Row>
            </React.Fragment>
          )
          }
        </ToolkitProvider>
      )
      }</PaginationProvider>


  </CardBody>
</Card>
</Modal>
<Modal size="xl" isOpen={addCustomerModal} toggle={addCustomerToggle}>
    <ModalHeader toggle={addCustomerToggle} tag="h4">
            {props.t("AddCustomer")}
          </ModalHeader>
          <ModalBody>
            <AvForm onValidSubmit={handleValidCustomerSubmit}>
              <Row form>
                <Col xl={12}>
                  <Row>
                    <Col xl={6}>
                      <div className="mb-3">
                        <AvField
                          name="isType"
                          label={props.t("isType")}
                          type="select"
                          className="form-select"
                          errorMessage={"Invalid Customer Type"}
                          validate={{
                            required: { value: false },
                          }}
                          value={""}
                          onChange={event => {
                            setSelectedCustomerType(prevState => ({
                              ...prevState,
                              isType: event.target.value,
                            }))
                          }}
                        >
                          <option value="individual">
                            {props.t("Individual")}
                          </option>
                          <option value="company">{props.t("Company")}</option>
                        </AvField>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xl={6}>
                      {selectedCustomerType?.isType === "individual" ||
                        selectedCustomerType?.isType == null ? (
                        <div className="mb-3">
                          <AvField
                            name="identificationNumber"
                            label={props.t("IdentificationNumber")}
                            type="text"
                            errorMessage="invalid Identification Number "
                            value={ ""}
                            
                          />
                        </div>
                      ) : null}
                    </Col>
                    <Col xl={6}>
                      {selectedCustomerType?.isType === "individual" ||
                        selectedCustomerType?.isType == null ? (
                          <div className="mb-3">
                          <AvField
                              name="phoneNumber"
                              label={props.t("Phone")}
                              type="text"
                              errorMessage={props.t("InvalidInput")}
                              mask="(999) 999-9999"
                              maskChar="-" 
                              tag={[Input, InputMask]}
                              placeholder="(999) 999-9999"
                              validate={{
                                  required: { value: false },
                              }}
                             
                              value={ ""}
                          />
                      </div>
                      ) : null}
                    </Col>
                  </Row>
                  <Row>
                    <Col xl={6}>
                      {selectedCustomerType?.isType === "company" ? (
                        <div className="mb-3">
                          <AvField
                            name="taxNumber"
                            label={props.t("Tax No")}
                            type="text"
                            errorMessage="invalid Tax office"
                            value={""}
                          
                          />
                        </div>
                      ) : null}
                    </Col>
                    <Col xl={6}>
                      {selectedCustomerType?.isType === "company" ? (
                        <div className="mb-3">
                          <AvField
                            name="taxAdministration"
                            label={props.t("Tax Office Name")}
                            type="text"
                            errorMessage="invalid Tax No"
                            value={""}
                          />
                        </div>
                      ) : null} 
                    </Col>
                  </Row>
                  <Row>
                    <Col xl={12}>
                      {selectedCustomerType?.isType === "company" ? (
                        <div className="mb-3">
                          <AvField
                            name="customerName"
                            label={props.t("Company Name")}
                            type="text"
                            errorMessage="invalid Company Name"
                            value={""}
                          />
                        </div>
                      ) : null}
                    </Col>
                  </Row>
                  <Row>
                    <Col xl={6}>
                      {selectedCustomerType?.isType === "individual" ||
                        selectedCustomerType?.isType == null ? (
                        <div className="mb-3">
                          <AvField
                            name="customerName"
                            label={props.t("Name")}
                            type="text"
                            errorMessage="invalid Name"
                            value={ "" }
                          />
                        </div>
                      ) : null}
                    </Col>
                    <Col xl={6}>
                      {selectedCustomerType?.isType === "individual" ||
                        selectedCustomerType?.isType == null ? (
                        <div className="mb-3">
                          <AvField
                            name="lastName"
                            label={props.t("LastName")}
                            type="text"
                            errorMessage="invalid Last Name"
                            value={"" }
                          />
                        </div>
                      ) : null}
                    </Col>
                  </Row>
                  <Row>
                    <Col xl={6}>
                      {selectedCustomerType?.isType === "individual" ||
                        selectedCustomerType?.isType == null ? (
                        <div className="mb-3">
                          <AvField
                            name="email"
                            label={props.t("Email")}
                            type="text"
                            errorMessage="invalid Email"
                            value={ "" }
                          />
                        </div>
                      ) : null}
                    </Col>

                    <Col xl={6}>
                      {selectedCustomerType?.isType === "individual" ||
                        selectedCustomerType?.isType == null ? (
                        <div className="mb-3">
                          <AvField
                            name="birthday"
                            label={props.t("DateOfBirth")}
                            type="date"
                            errorMessage="invalid Date Of Birth"
                            value={"" }
                          />
                        </div>
                      ) : null}
                    </Col>
                  </Row>

                  <Row>
                    <Col xl={6}>
                      {selectedCustomerType?.isType === "company" ? (
                       <div className="mb-3">
                       <AvField
                           name="phoneNumber"
                           label={props.t("Phone")}
                           type="text"
                           errorMessage={props.t("InvalidInput")}
                           mask="(999) 999-9999"
                           maskChar="-"
                           tag={[Input, InputMask]}
                           placeholder="(999) 999-9999"
                           validate={{
                               required: { value: false },
                           }}
                           value={""}
                       />
                   </div>
                      ) : null}
                    </Col>

                    <Col xl={6}>
                      {selectedCustomerType?.isType === "company" ? (
                        <div className="mb-3">
                          <AvField
                            name="email"
                            label="e-Mail Adresi"
                            type="text"
                            errorMessage="Geçerli bir Email Girin"
                            value={""}
                          />
                        </div>
                      ) : null}
                    </Col>
                  </Row>
                  <Col xl={6}>
                    {selectedCustomerType?.isType === "individual" ||
                      selectedCustomerType?.isType == null ? (
                      <div className="mb-3">
                        <AvField
                          name="gender"
                          label={props.t("Gender")}
                          type="select"
                          className="form-select"
                          errorMessage={"Invalid Gender"}
                          validate={{
                            required: { value: false },
                          }}
                          value={""
                          }
                          onChange={event => { }}
                        >
                          <option value="Unspecified">
                            {props.t("Unspecified")}
                          </option>
                          <option value="kadın">{props.t("Female")}</option>
                          <option value="erkek">{props.t("Male")}</option>
                        </AvField>
                      </div>
                    ) : null}
                  </Col>

                  <Col xl={12}>
                    <div className="mb-3">
                      <AvField
                        name="adress"
                        label={props.t("Address")}
                        type="textarea"
                        errorMessage="invalid Address"
                        value={
                         
                          ""
                        }
                      />
                    </div>
                  </Col>
                  <Row>
                    <Col xl={6}>
                      <div className="mb-3">
                        <AvField
                          name="city"
                          label={props.t("City")}
                          type="text"
                          errorMessage="invalid City"
                          value={
                            
                            ""
                          }
                        />
                      </div>
                    </Col>
                    <Col xl={6}>
                      <div className="mt-4 text-end">
                        {selectedCustomerType?.isType === "company" ? (
                          <AvGroup check>
                            <AvInput
                              type="checkbox"
                              label="eFaturaMukellef"
                              value="false"
                              name="eFaturaMukellef"
                            />
                            <Label check for="checkbox">
                              {" "}
                              {props.t("e fatura Mükellefiyim")}
                            </Label>
                          </AvGroup>
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col md="12" xs="12" className="text-end">
                  <button
                    type="submit"
                    className="btn btn-success save-user col-md-2 col-xs-5"
                   
                  >
                    {props.t("Save")}
                  </button>
                  &nbsp;&nbsp;
                  <button
                    type="button"
                    className="btn btn-secondary col-md-2 col-xs-5"
                    onClick={() => {
                     addCustomerToggle()
                    }}
                  >
                    {props.t("Cancel")}
                  </button>
                </Col>
              </Row>
            </AvForm>
          </ModalBody>
        </Modal>
    </React.Fragment>
   
  )
}


CustomerModal.propTypes = {
 
  modal: PropTypes.bool,
   payment:PropTypes.func,
   finalCustomer:PropTypes.func,
   back:PropTypes.func,
   t: PropTypes.any,
   toggle: PropTypes.func,
}


export default withRouter(
    (withTranslation()(CustomerModal))
  )
