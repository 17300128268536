import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useState, useEffect } from "react"
import {
    Container,
    Row,
    Col,
    Card,
    Alert,
    CardBody,
    Button,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Modal,
    ModalHeader,
    ModalBody,

} from "reactstrap"
//redux
import { useSelector, useDispatch } from "react-redux"

import { withRouter } from "react-router-dom"

import CustomerInfoModal from "./../ProcessList/Modals/CustomerInfoModal"
import PaymentPlanInfoModal from "./../ProcessList/Modals/PaymentPlanInfoModal"

//i18n
import { withTranslation } from "react-i18next"


//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"

import {
    getCollections as onGetCollections,
    getPaymentPlans_MyPlans as onGetMyPaymentPlans,
    addCollection as onAddCollection,
    updateCollection as onUpdateCollection,
    deleteCollection as onDeleteCollection,
    getPaymentPlans as onGetPaymentPlans,
    getCompanyBranches as onGetCompanyBranches,
    getCustomerById as onGetCustomerById,

} from "store/actions"

import { isEmpty, map } from "lodash"



import { AvForm, AvField, AvGroup, AvInput, AvFeedback, AvRadioGroup, AvRadio, AvCheckboxGroup, AvCheckbox } from "availity-reactstrap-validation"

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
    PaginationProvider, PaginationListStandalone,
    SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert"

import "./datatables.scss"

const BankAccounts = props => {
    const dispatch = useDispatch()

    const [selectedId, setSelectedId] = useState();
    const [selectedCollection, setSelectedCollection] = useState();
    const [success_dlg, setsuccess_dlg] = useState(false);
    const [confirm_alert, setconfirm_alert] = useState(false);
    const [dynamic_title, setdynamic_title] = useState("");
    const [dynamic_description, setdynamic_description] = useState("");
    const [modal, setModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [branchNum, setbranchNum] = useState();
    const [branchAccount, setbranchAccount] = useState();
    const [selectedPaymentPlanInput, setSelectedPaymentPlanInput] = useState()
    const [paymentPlanName, setPaymentPlanName] = useState([])
    const [paymentPlanObjects, setPaymentPlanObjects] = useState([])
    const [selectedExpense, setSelectedExpense] = useState()
    const [selectedBranchId, setSelectedBranchId] = useState()
    const [customerInfoModal, setCustomerInfoModal] = useState(false)
    const [collectionPlanList, setCollectionPlanList] = useState([])
    const [paymentPlanInfoModal, setPaymentPlanInfoModal] = useState(false)

    const { error, Collections, myPaymentPlans, PaymentPlans, Branches } = useSelector(state => ({
        error: state.BankAccounts?.error,
        Collections: state.Collections?.data,
        myPaymentPlans: state.PaymentPlans?.myPaymentPlans,
        Branches: state.Branchs?.Branches,
        PaymentPlans: state.PaymentPlans?.data,
    }))

    useEffect(() => {
        dispatch(onGetCollections())
        dispatch(onGetMyPaymentPlans())
        dispatch(onGetPaymentPlans())
        dispatch(onGetCompanyBranches(user.companyId))

    }, [dispatch]);

    const toggle = () => {
        setModal(!modal)
    }
    const customerInfoToggle = () => {
        setCustomerInfoModal(!customerInfoModal)
    }
    const paymentPlanInfoToggle = () => {
        setPaymentPlanInfoModal(!paymentPlanInfoModal)
    }

    let user = JSON.parse(localStorage.getItem("authUser"))

    const columns = [
        {
            dataField: 'createdAt',
            text: props.t("Created At"),
            sort: true,
            formatter: (cell, row) => (
                <span>{handleValidDate(cell)}</span>
            )

        },
        {
            dataField: "CustomerId",
            text: props.t("PayerName"),
            sort: true,
            formatter: (cell, row) => (

                <span>{cell?.customerName ? cell?.customerName : "Nihai Tüketici"} {cell?.customerName ? (<i style={{ fontSize: "18px", position: "relative", top: "3px" }} className="bx bx-info-circle"
                    onClick={e => {
                        dispatch(onGetCustomerById(row.CustomerId?._id))
                        customerInfoToggle()

                    }} />) : null} </span>
            )
        },
        {
            dataField: "name",
            text: props.t("isType"),
            sort: true,
        },
        {
            dataField: "PaymentPlanObjects",
            text: props.t("Payment Detail"),
            sort: false,
            formatter: (cell, row) => <span>{ } <i style={{ fontSize: "18px", position: "relative", top: "3px" }} className="bx bx-info-circle"
                onClick={e => {
                    setCollectionPlanList(cell)
                    paymentPlanInfoToggle()

                }} /></span>,
        },
        {
            dataField: "description",
            text: props.t("Description"),
            sort: true,
        },
        {
            dataField: "amount",
            text: props.t("Amount"),
            sort: true,
        },
        {
            dataField: 'BranchId',
            text: props.t("Branch Name"),
            sort: true,

            formatter: (cell, row) => (

                <span>{cell?.name}</span>
            )

        },

        {
            dataField: '_id',
            text: props.t("Action"),
            sort: false,
            formatter: (cell, row) => (
                <UncontrolledDropdown style={{ position: "unset" }}>
                    <DropdownToggle href="#" className="card-drop" tag="i">
                        <i className="mdi mdi-dots-horizontal font-size-18"></i>
                    </DropdownToggle>
                    <div className="drop-absolute">
                        <DropdownMenu className="dropdown-menu-end">
                            <DropdownItem href="#"
                                onClick={() => {
                                    setSelectedCollection(row);
                                    console.log(row)
                                    setSelectedExpense(row?.name)
                                    setSelectedBranchId(row.branchId?._id)
                                    setIsEdit(true);
                                    toggle();
                                }}
                            >
                                <i className="mdi mdi-pencil font-size-16 text-success me-1"></i>
                                {props.t("Edit")}
                            </DropdownItem>
                            <DropdownItem href="#"
                                onClick={() => {
                                    setSelectedId(cell);
                                    setconfirm_alert(true);
                                }}>
                                <i className="mdi mdi-trash-can font-size-16 text-danger me-1"></i>
                                {props.t("Delete")}
                            </DropdownItem>
                        </DropdownMenu>
                    </div>
                </UncontrolledDropdown>
            )
        }];

    const defaultSorted = [{
        dataField: 'createdAt',
        order: 'desc'
    }];

    const pageOptions = {
        sizePerPage: 10,
        totalSize: BankAccounts ? BankAccounts.length : 0, // replace later with size(BankAccount),
        custom: true,
    }

    // Custom Pagination Toggle
    const sizePerPageList = [
        { text: '5', value: 5 },
        { text: '10', value: 10 },
        { text: '15', value: 15 },
        { text: '20', value: 20 },
        { text: '25', value: 25 },
        { text: 'All', value: BankAccounts ? BankAccounts.length : 0 }];


    // Select All Button operation
    const selectRow = {
        mode: 'checkbox'
    }

    const { SearchBar } = Search;

    const handleChangeInputBoolean = (i, e, inputName) => {
        const values = [...paymentPlanObjects]
        values[i][inputName] =
            e.target.value === "" ? false : e.target.value == "false" ? true : false
        setPaymentPlanObjects(values)
    }
    const handleChangeInput = (i, e, inputName) => {
        const values = [...paymentPlanObjects]
        values[i][inputName] = e.target.value
        setPaymentPlanObjects(values)
    }
    const handleValidCollectionSubmit = (e, values) => {
        if (isEdit) {
            const newCollection = {
                _id: selectedCollection._id,
                UserId: user.uid,
                amount: values.amount,
                description: values.description,
                PaymentPlanObjects: JSON.stringify(values.paymentPlanObjects),
                name: paymentPlanName.name,
                payerName: paymentPlanObjects[0].isValue,
                CompanyId: user.companyId,
                BranchId: values.branchId
            }

            dispatch(onUpdateCollection(newCollection))

        } else {
            const newCollection = {
                UserId: user.uid,
                amount: values.amount,
                description: values.description,
                PaymentPlanObjects: JSON.stringify(values.paymentPlanObjects),
                name: paymentPlanName.name,
                payerName: paymentPlanObjects[0].isValue,
                CompanyId: user.companyId,
                BranchId: values.branchId
            }

            dispatch(onAddCollection(newCollection))

        }
        toggle()
    }

    const handleValidDate = date => {
        if (date) {
            const date1 = moment(new Date(date)).format("DD.MM.YYYY");
            return date1;
        } else {
            return "";
        }
    }
    const handleValidDateAvField = date => {
        if (date) {
            const date1 = moment(new Date(date)).format("YYYY-MM-DD");
            return date1;
        } else {
            return "";
        }
    }



    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>{props.t("Collection")}</title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumb breadcrumbItem={props.t("Collection")} addBtnCallBack={() => {

                        setIsEdit(false);
                        setSelectedCollection(
                            {
                                amount: "",
                                description: "",
                                branchAccountNo: "",
                                payerName: "",
                                PaymentPlanObjects: ""
                            }
                        );
                        toggle();

                    }} />

                    {/* {error && error ? <Alert color="danger">{error}</Alert> : null} */}

                    <Card>
                        <CardBody>
                            <PaginationProvider
                                pagination={paginationFactory(pageOptions)}
                                keyField='id'
                                columns={columns}
                                data={Collections}
                            >
                                {({ paginationProps, paginationTableProps }) => (
                                    <ToolkitProvider
                                        keyField='_id'
                                        columns={columns}
                                        data={Collections}
                                        search
                                    >
                                        {toolkitProps => (
                                            <React.Fragment>

                                                <Row className="mb-2">
                                                    <Col md="4">
                                                        <div className="search-box me-2 mb-2 d-inline-block">
                                                            <div className="position-relative">
                                                                <SearchBar
                                                                    {...toolkitProps.searchProps}
                                                                />
                                                                <i className="bx bx-search-alt search-icon" />
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col xl="12">
                                                        <div className="table-responsive">
                                                            <BootstrapTable
                                                                keyField={"_id"}
                                                                responsive
                                                                bordered={false}
                                                                striped={false}
                                                                noDataIndication={props.t("You Don't Have a Collections Yet")}

                                                                defaultSorted={defaultSorted}
                                                                classes={
                                                                    "table align-middle table-nowrap"
                                                                }
                                                                headerWrapperClasses={"thead-light"}
                                                                {...toolkitProps.baseProps}
                                                                {...paginationTableProps}
                                                            />

                                                        </div>
                                                    </Col>
                                                </Row>

                                                <Row className="align-items-md-center mt-30">
                                                    <Col className="inner-custom-pagination d-flex">
                                                        <div className="d-inline">
                                                            <SizePerPageDropdownStandalone
                                                                {...paginationProps}
                                                            />
                                                        </div>
                                                        <div className="text-md-right ms-auto">
                                                            <PaginationListStandalone
                                                                {...paginationProps}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </React.Fragment>
                                        )
                                        }
                                    </ToolkitProvider>
                                )
                                }</PaginationProvider>

                        </CardBody>
                    </Card>
                    <CustomerInfoModal modal={customerInfoModal} toggle={() => { customerInfoToggle() }}


                        Cancel={(e) => {

                            if (e == true) {
                                customerInfoToggle()
                            }
                        }}
                    />
                    <PaymentPlanInfoModal modal={paymentPlanInfoModal} PaymentPlan={collectionPlanList} toggle={() => { paymentPlanInfoToggle() }}


                        Cancel={(e) => {

                            if (e == true) {
                                paymentPlanInfoToggle()
                            }
                        }}
                    />

                    <Modal isOpen={modal} toggle={toggle}>
                        <ModalHeader toggle={toggle} tag="h4">
                            {!!isEdit ? props.t("Edit Bank Account") : props.t("Add Bank Account")}
                        </ModalHeader>
                        <ModalBody>
                            <AvForm onValidSubmit={handleValidCollectionSubmit}>
                                <Row form>
                                    <Col xs={12}>
                                        <Container>

                                            <div className="mb-3">
                                                <AvField
                                                    name="planType"
                                                    label={props.t("Plan Type")}
                                                    type="select"
                                                    className="form-select"
                                                    errorMessage={props.t("Invalid Plan Type")}
                                                    validate={{
                                                        required: { value: true },
                                                    }}
                                                    value={selectedExpense || ""}
                                                    onChange={event => {
                                                        setSelectedPaymentPlanInput(prevState => ({
                                                            ...prevState,
                                                            planType: event.target.value,
                                                        }))

                                                        let selectedPlan = PaymentPlans.find(
                                                            x => x._id === event.target.value
                                                        )

                                                        if (selectedPlan) {
                                                            setPaymentPlanName(selectedPlan)
                                                        } else {
                                                            setPaymentPlanName([])
                                                        }

                                                        if (selectedPlan) {
                                                            setPaymentPlanObjects(
                                                                selectedPlan.PaymentPlanObjects
                                                            )
                                                        } else {
                                                            setPaymentPlanObjects([])
                                                        }
                                                    }}
                                                >
                                                    <option>{props.t("Select Payment Plan")}</option>
                                                    {map(PaymentPlans, (item, index) => (
                                                        <option value={item._id} key={"g_" + index}>
                                                            {item.name}
                                                        </option>
                                                    ))}
                                                </AvField>
                                            </div>

                                            {map(paymentPlanObjects, (item, index) => (
                                                <div className="mb-3" key={"h_" + index}>
                                                    {item.isType === "string" ? (
                                                        <AvField
                                                            key={"h1_" + index}
                                                            id={"paymentPlanObjects_" + index + "__isValue"}
                                                            name={"paymentPlanObjects[" + index + "].isValue"}
                                                            type="text"
                                                            label={item.title}
                                                            placeholder={item.title}
                                                            value={item.isValue}
                                                            onChange={e =>
                                                                handleChangeInput(index, e, "isValue")
                                                            }
                                                            validate={{
                                                                required: { value: false },
                                                            }}
                                                        />
                                                    ) : item.isType === "date" ? (
                                                        <AvField
                                                            key={"h1_" + index}
                                                            id={"paymentPlanObjects_" + index + "__isValue"}
                                                            name={"paymentPlanObjects[" + index + "].isValue"}
                                                            type="date"
                                                            label={item.title}
                                                            placeholder={item.title}
                                                            value={handleValidDateAvField(item.isValue)}
                                                            onChange={e =>
                                                                handleChangeInput(index, e, "isValue")
                                                            }
                                                            validate={{
                                                                required: { value: false },
                                                            }}
                                                        />
                                                    ) : item.isType === "number" ? (
                                                        <AvField
                                                            key={"h1_" + index}
                                                            id={"paymentPlanObjects_" + index + "__isValue"}
                                                            name={"paymentPlanObjects[" + index + "].isValue"}
                                                            type="number"
                                                            label={item.title}
                                                            placeholder={item.title}
                                                            value={item.isValue}
                                                            onChange={e =>
                                                                handleChangeInput(index, e, "isValue")
                                                            }
                                                            validate={{
                                                                required: { value: false },
                                                            }}
                                                        />
                                                    ) : item.isType === "boolean" ? (
                                                        <AvField
                                                            key={"h1_" + index}
                                                            id={"paymentPlanObjects_" + index + "__isValue"}
                                                            name={"paymentPlanObjects[" + index + "].isValue"}
                                                            type="checkbox"
                                                            label={item.title}
                                                            placeholder={item.title}
                                                            value={item.isValue == "" ? false : item.isValue}
                                                            onChange={e =>
                                                                handleChangeInputBoolean(index, e, "isValue")
                                                            }
                                                            validate={{
                                                                required: { value: false },
                                                            }}
                                                        />
                                                    ) : item.isType === "dropdown" ? (
                                                        <AvField
                                                            key={"h1_" + index}
                                                            id={"paymentPlanObjects_" + index + "__isValue"}
                                                            name={"paymentPlanObjects[" + index + "].isValue"}
                                                            type="select"
                                                            className="form-select"
                                                            label={item.title}
                                                            placeholder={item.title}
                                                            value={item.isValue}
                                                            onChange={e =>
                                                                handleChangeInput(index, e, "isValue")
                                                            }
                                                            validate={{
                                                                required: { value: false },
                                                            }}
                                                        >
                                                            {item.isOptions.split(",")?.map((item, index) => (
                                                                <option key={"j_" + index} value={item}>
                                                                    {item}
                                                                </option>
                                                            ))}
                                                        </AvField>
                                                    ) : (
                                                        <AvField
                                                            key={"h1_" + index}
                                                            id={"paymentPlanObjects_" + index + "__isValue"}
                                                            name={"paymentPlanObjects[" + index + "].isValue"}
                                                            type="text"
                                                            label={item.title}
                                                            placeholder={item.title}
                                                            value={item.isValue}
                                                            onChange={e =>
                                                                handleChangeInput(index, e, "isValue")
                                                            }
                                                            validate={{
                                                                required: { value: false },
                                                            }}
                                                        />
                                                    )}
                                                </div>
                                            ))}

                                            <div className="mb-3">
                                                <AvField
                                                    name="amount"
                                                    label={props.t("Amount")}
                                                    type="Number"
                                                    errorMessage={props.t("InvalidInput")}
                                                    validate={{
                                                        required: { value: false },
                                                    }}
                                                    value={selectedCollection?.amount || ""}
                                                />
                                            </div>

                                            <div className="mb-3">
                                                <AvField
                                                    name="description"
                                                    label={props.t("Description")}
                                                    type="textarea"
                                                    errorMessage={props.t("InvalidInput")}
                                                    validate={{
                                                        required: { value: false },
                                                    }}
                                                    value={selectedCollection?.description || ""}
                                                />
                                            </div>
                                        </Container>
                                    </Col>
                                </Row>
                                <Row className="mt-3"></Row>
                                <Row className="mt-3">
                                    <Col md="12">
                                        <div className="text-end">
                                            <button
                                                type="submit"

                                                className="btn btn-success save-user col-4"
                                            >
                                                {props.t("Save")}
                                            </button>
                                            &nbsp;&nbsp;
                                            <button
                                                className="btn btn-secondary col-4 "
                                                type="button"
                                                onClick={() => {
                                                    toggle()
                                                }}
                                            >
                                                {props.t("Cancel")}
                                            </button>
                                        </div>
                                    </Col>
                                </Row>
                            </AvForm>
                        </ModalBody>
                    </Modal>
                    {success_dlg ? (
                        <SweetAlert
                            success
                            title={dynamic_title}
                            confirmBtnText={props.t("Ok")}
                            onConfirm={() => {
                                setsuccess_dlg(false)
                            }}
                        >
                            {dynamic_description}
                        </SweetAlert>
                    ) : null}

                    {confirm_alert ? (
                        <SweetAlert
                            title={props.t("Are You Sure")}
                            warning
                            showCancel
                            confirmBtnBsStyle="success"
                            cancelBtnBsStyle="danger"
                            cancelBtnText={props.t("Cancel")}
                            confirmBtnText={props.t("Delete")}
                            onConfirm={() => {
                                setconfirm_alert(false);
                                setsuccess_dlg(true);
                                setdynamic_title(props.t("Deleted"));
                                setdynamic_description("");
                                dispatch(onDeleteCollection(selectedId));
                                setSelectedId(null);
                            }}
                            onCancel={() => setconfirm_alert(false)}
                        >
                            {props.t("DeleteWarning")}
                        </SweetAlert>
                    ) : null}


                    {error && error ? (
                        <SweetAlert
                            title={props.t("Error")}
                            warning
                            confirmBtnText={props.t("Ok")}
                            onConfirm={() => {
                                dispatch(onResetBankAccount())
                            }}
                        >
                            {JSON.stringify(error)}
                        </SweetAlert>
                    ) : null}

                </Container>
            </div>
        </React.Fragment >
    )
}

export default withRouter(withTranslation()(BankAccounts))
