import { call, put, takeEvery } from "redux-saga/effects";

// Crypto Redux States
import { GET_PRODUCTS, DELETE_PRODUCT, ADD_PRODUCT, UPDATE_PRODUCT,UPDATE_PRODUCT_TYPE,GET_PRODUCT_TYPES,GET_PRODUCT_DETAIL} from "./actionTypes";
import {
    getProductsSuccess, getProductsFail,
    deleteProductSuccess, deleteProductFail,
    updateProductSuccess, updateProductFail,
    addProductSuccess, addProductFail, 
    updateProductTypeSuccess,updateProductTypeFail,
    getProductTypesSuccess,getProductTypesFail,
    getProductByIdSuccess,getProductByIdFail
 } from "./actions";

//Include Both Helper File with needed methods
import {
    getProducts, //API CONNECTION
    delProduct,
    addProduct,
    updateProduct,
    updateProductType,
    getProductTypes,
    getProductDetail
}
    from "../../helpers/fakebackend_helper"; 

function* fetchProducts() {
    try {
        const response = yield call(getProducts)
       
        yield put(getProductsSuccess(response));
    } catch (error) {
        yield put(getProductsFail(error));
    }
}

function* onGetProductById({payload:id}){
    try{
        const response = yield call(getProductDetail, id)
        yield put(getProductByIdSuccess(response));
    } catch (error) {
        yield put(getProductByIdFail(error));
    }
}
function* onGetProductTypes({payload:id}){
    try{
        const response = yield call(getProductTypes, id)
        yield put(getProductTypesSuccess(response));
    } catch (error) {
        yield put(getProductTypesFail(error));
    }
}
function* onDeleteProducts({payload:id}){
    try{
        const response = yield call(delProduct, id)
        yield put(deleteProductSuccess(response));
    } catch (error) {
        yield put(deleteProductFail(error));
    }
}

function* onAddProduct({payload:product}){
    try{
        const response = yield call(addProduct, product)
        yield put(addProductSuccess(response));
    } catch (error) {
        yield put(addProductFail(error));
    }
}

function* onUpdateProduct({payload:product}){
    try{
        const response = yield call(updateProduct, product)
        yield put(updateProductSuccess(response));
    } catch (error) {
        yield put(updateProductFail(error));
    }
}
function* onUpdateProductType({payload:product}){
    try{
        const response = yield call(updateProductType, product)
        yield put(updateProductTypeSuccess(response));
    } catch (error) {
        yield put(updateProductTypeFail(error));
    }
}

export function* productsSaga() {
    yield takeEvery(GET_PRODUCTS, fetchProducts);
    yield takeEvery(DELETE_PRODUCT, onDeleteProducts);
    yield takeEvery(ADD_PRODUCT, onAddProduct);
    yield takeEvery(UPDATE_PRODUCT, onUpdateProduct);
    yield takeEvery(UPDATE_PRODUCT_TYPE, onUpdateProductType);
    yield takeEvery(GET_PRODUCT_TYPES, onGetProductTypes);
    yield takeEvery(GET_PRODUCT_DETAIL, onGetProductById);
}

export default productsSaga;
