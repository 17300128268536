import {
    GET_POSMACHINES,
    GET_POSMACHINES_SUCCESS,
    GET_POSMACHINES_FAIL,
    GET_POSMACHINE_DETAIL,
    GET_POSMACHINE_DETAIL_SUCCESS,
    GET_POSMACHINE_DETAIL_FAIL,
    ADD_POSMACHINE,
    ADD_POSMACHINE_SUCCESS,
    ADD_POSMACHINE_FAIL,
    ADD_POSMACHINE_POSRATIO,
    ADD_POSMACHINE_POSRATIO_SUCCESS,
    ADD_POSMACHINE_POSRATIO_FAIL,
    UPDATE_POSMACHINE,
    UPDATE_POSMACHINE_SUCCESS,
    UPDATE_POSMACHINE_FAIL,
    UPDATE_POSMACHINE_POSRATIO,
    UPDATE_POSMACHINE_POSRATIO_SUCCESS,
    UPDATE_POSMACHINE_POSRATIO_FAIL,
    DELETE_POSMACHINE,
    DELETE_POSMACHINE_SUCCESS,
    DELETE_POSMACHINE_FAIL,
    RESET_POSMACHINE
} from "./actionTypes";

const INIT_STATE = {
    data: [],
    error: null,
    posMachineDetail: null,
};

const PosMachines = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_POSMACHINES_SUCCESS:
            return {
                ...state,
                data: action.payload.data
            }
        case DELETE_POSMACHINE_SUCCESS:
            return {
                ...state,
                data: state.data.filter(posMachine => posMachine._id.toString() !== action.payload.data.toString())
            }
        case ADD_POSMACHINE_SUCCESS:
            return {
                ...state,
                data: [action.payload.data, ...state.data]
            }
        case ADD_POSMACHINE_POSRATIO_SUCCESS:
            return {
                ...state,
                data: [action.payload.data, ...state.data]
            }
        case UPDATE_POSMACHINE_SUCCESS:
            return {
                ...state,
                data: state.data.map(posMachine =>
                    posMachine._id.toString() === action.payload.data._id.toString() ?
                        { posMachine, ...action.payload.data } :
                        posMachine
                )
            }
        case GET_POSMACHINE_DETAIL_SUCCESS:
            return {
                ...state, 
                posMachineDetail: action.payload.data
            }
        case UPDATE_POSMACHINE_POSRATIO_SUCCESS:
            return {
                ...state,
                data: state.data.map(posMachine =>
                    posMachine._id.toString() === action.payload.data._id.toString() ?
                        { posMachine, ...action.payload.data } :
                        posMachine
                )
            }

        //FAILS
        case GET_POSMACHINES_FAIL:
        case DELETE_POSMACHINE_FAIL:
        case ADD_POSMACHINE_FAIL:
        case UPDATE_POSMACHINE_FAIL:
        case ADD_POSMACHINE_POSRATIO_FAIL:
        case UPDATE_POSMACHINE_POSRATIO_FAIL:
        case GET_POSMACHINE_DETAIL_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case RESET_POSMACHINE: return {
            ...state,
            error: null,
            posMachineDetail: null
        }
        default:
            return state;
    }
}


export default PosMachines;