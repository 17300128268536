import React, { useState,useEffect } from "react"
import PropTypes from 'prop-types'
import { Container, Row, Col, Modal, ModalHeader, ModalBody,TabContent,TabPane,Label,Input,
    FormGroup} from "reactstrap"
import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
} from "availity-reactstrap-validation"
import { useSelector, useDispatch } from "react-redux"
import {
  addProcessListPayment as OnAddProcessListPayment,
 
} from "store/actions"
import { isEmpty, map } from "lodash"
import { Link, withRouter } from "react-router-dom"
//i18n
import { withTranslation } from "react-i18next"

const FinalSaleModal = (props, cb) => {
  const {
    error,
    CustomerId,
    Product, 
    ProductId,
    amount
  
  } = useSelector(state => ({
    error: state.Expenses?.error, 
    CustomerId:state.Customer?.data._id, 
    amounts : state.addProcessListPayment?.data[0].amount,
    Product: state.ProcessList?.Product,
    ProductId: state.ProcessList?.ProductId,
  }))
    const [serialNumberIsExist, setSerialNumberIsExist] = useState(false)
    const [paymentList, setPaymentList] = useState([])
  const dispatch = useDispatch()
  //const [modal, setModal] = useState(false)
  let TotalMoney =0
  for(let i=0;i<props.paymentName.length;i++){
     TotalMoney +=parseFloat(props.paymentName[i]?.amount);
  }

  useEffect(() => { 

   //Getirilen Sube Datalarini Set Eder
    
     
      let tempPaymentList = [];
      if(!isEmpty( props.paymentName)){
        props.paymentName.forEach(payment => {
          tempPaymentList.push({
            PaymentPlanObjects: payment.PaymentPlanObjects,
            name: payment.name,
            description: payment.description,
            amount: payment.amount,
           
          });
        });
      }
     
    
      setPaymentList(tempPaymentList);
   
  }, [props.paymentName])
  
  const handleSubtract = (index) => {

    const values = [...paymentList]
   
    values.splice(index, 1)
    setPaymentList([...values])
    
    
    props.paymentName.splice(index, 1);
    
  }
  return (
    <Modal size="l" isOpen={props.modal} toggle={props.toggle}>
    <ModalHeader toggle={props.toggle} tag="h4">
      {props.t("Sale")}
    </ModalHeader>
    <ModalBody>
    <AvForm >
        <Row form>
          <Col xs={12}>
            <Container>
            {props.t("Product Name")} :{Product?.name} <br />
            {props.t("Product Barcode")} : {Product?.barcode}
              <br />
             
              {props.t("Customer Title")} : {props.customerName?props.customerName:"Nihai Tüketici"}
              <br />
              
            </Container>
          </Col>
        </Row>
        <hr />  
        
        {paymentList?.map((item, index) => (
                                <Row className="mt-4" key={"g_" + index}> 
                                <Col xs="3">
                                  <div>{paymentList[index]?.name}</div>
                                  
                                </Col>
                      
                                <Col xs="6">
                                <div>{paymentList[index]?.description}</div>
                                </Col>
                                
                                
                                <Col xs="2">
                                  <div>{paymentList[index]?.amount} TL</div>
                                  
                                </Col> 
                                <Col xs="1">
                                <i style={{fontSize:"18px",cursor:"pointer" ,color:"red"}} className="bx bx-x"    onClick={() => {
                                  // props.paymentName.splice(index, 1);
                                  
                                 
                                  handleSubtract(index)
                                }}></i> 
                                  
                                </Col> 
                               
                              </Row>
                              ))}
                                <hr /> 
                                <Row className="mt-4" >
                                <Col xs="2">
                                
                                  
                                </Col>
                      
                                <Col xs="2">
                              
                                </Col>
                                
                                <Col xs="3">
                                  
                                </Col>
                                <Col xs="4" >
                                <div style={{position:"relative",left:"39px"}}className="text-end"> {props.t("Sales Amount")}:{props.description} TL</div>
                                  
                                </Col>
                               
                              </Row>
                              {parseFloat(props.description)-TotalMoney==0?null:
                              (
                                <Row className="mt-4" >
                                <Col xs="2">
                                
                                  
                                </Col>
                      
                                <Col xs="2">
                              
                                </Col>
                                
                                <Col xs="3">
                                  
                                </Col>
                                <Col xs="4" >
                                <div style={{position:"relative",left:"39px"}}className="text-end"> {props.t("Eksik Tutar")}:{parseFloat(props.description)-TotalMoney} TL</div>
                                  
                                </Col>
                               
                              </Row>
                              )}
                              
                              
                               
                            
       
      

        <Row className="mt-3">
          <div className="text-end mt-3">
            <button
              className="btn btn-success "
              onClick={() => {
                props.payment(true) 
              }}
            >
              + {props.t("AddPayment")}
            </button>
          </div>
        </Row>
        <hr />

        <Row className="mt-3">
          <Col md="12">
            <div className="text-end">
             
              &nbsp;&nbsp;
             
                <button 
                type="submit"
                
               
                className="btn btn-success col-4"
                onClick={() => {
                  let _body = {
                    PaymentPlanObjects: JSON.stringify(paymentList),
                   
                    
                  };
                  props.save(true,_body)
              }}>
                  
                  {props.t("Save")}
                </button>
            
               
             
            
            </div>
          </Col>
        </Row>
      </AvForm>
    </ModalBody>
  </Modal>
  )
}


FinalSaleModal.propTypes = {
 
  modal: PropTypes.bool,
  payment:PropTypes.func,
  save:PropTypes.func,
   t: PropTypes.any,
   toggle: PropTypes.func,
   customerName :PropTypes.string,
   paymentName : PropTypes.array,
  
   description : PropTypes.string,
   amount : PropTypes.string
}


export default withRouter(
    (withTranslation()(FinalSaleModal))
  )
