import { CHANGE_SELECTED_BRANCH_SUCCESS,
   CHANGE_SELECTED_BRANCH_FAIL,
   GET_USER_DETAIL_SUCCESS,
  GET_USER_DETAIL_FAIL, } from "./actionTypes"

const initialState = {
  data: [],
  error: null,
  user :[]
}

const changePassword = (state = initialState, action) => {
  switch (action.type) { 
    case CHANGE_SELECTED_BRANCH_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
      }
      case GET_USER_DETAIL_SUCCESS:
        return {
            ...state,
            user: action.payload.data,
          }
    case CHANGE_SELECTED_BRANCH_FAIL:
    case GET_USER_DETAIL_FAIL:
      return { 
        ...state,
        error: action.payload,
      } 
      default:
        return state;
  } 
}

export default changePassword 
