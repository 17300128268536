import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useState, useEffect } from "react"
import {
    Container,
    Row,
    Col,
    Card,
    Alert,
    CardBody,
    Media,
    Button,
    UncontrolledDropdown,
    UncontrolledTooltip,
    DropdownToggle,
    DropdownMenu,
    DropdownItem, 
    Modal,
    ModalHeader,
    ModalBody,
    Form,
    FormGroup,
    Label,
    Input
} from "reactstrap"
//redux
import { useSelector, useDispatch } from "react-redux"

import { withRouter } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"


//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"

import {
    getPosMachines as onGetPosMachines,
    deletePosMachine as onDeletePosMachine,
    addPosMachine as onAddPosMachine,
    updatePosMachine as onUpdatePosMachine,
    resetPosMachine as onResetPosMachine,
    updatePosMachinePosRatio as onUpdatePosMachinePosRatio,
    addPosMachinePosRatio as onAddPosMachinePosRatio,
    getCompanyBranches as onGetCompanyBranches,
    getBankAccounts as onGetBankAccounts
} from "store/actions"


import moment from 'moment'

import { AvForm, AvField, } from "availity-reactstrap-validation"

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
    PaginationProvider, PaginationListStandalone,
    SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert"

import "./datatables.scss"

const PosMachineList = props => {
    const dispatch = useDispatch()

    const [selectedId, setSelectedId] = useState();
    const [selectedPosMachine, setSelectedPosMachine] = useState();
    const [success_dlg, setsuccess_dlg] = useState(false);
    const [confirm_alert, setconfirm_alert] = useState(false);
    const [dynamic_title, setdynamic_title] = useState("");
    const [dynamic_description, setdynamic_description] = useState("");
    const [modal, setModal] = useState(false);
    const [PosModal, setPosModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    
    const [selectedBankId, setSelectedBankId] = useState()

    //Dynamic Inputs START
    const [installments, setInstallments] = useState([{
        installment: 1,
        rate: 0
    },
    {
        installment: 2,
        rate: 0
    }])

    const handleChangeInput = (i, e) => {
       
        const values = [...installments]
        values[i].rate = e.target.value
        setInstallments(values)
    }


    const handleAdd = (installment) => {
        setInstallments([...installments, { installment: installment + 1, rate: 0 }])
    }

    const handleSubtract = (i) => {
        const values = [...installments]
        values.splice(i, 1)
        setInstallments([...values])
    }

    const InstallmentAddReset = () => {
        let installments = [
            {
                "installment": 1,
                "rate": 0
            },
            {
                "installment": 2,
                "rate": 0
            }
        ];
        setInstallments(installments);
    }

    //Dynamic Inputs END

    const { error, PosMachines,Branches,BankAccounts} = useSelector(state => ({
        error: state.PosMachines?.error,
        PosMachines: state.PosMachines?.data,
        Branches : state.Branchs?.Branches,
        BankAccounts : state.BankAccounts?.data
        
    }))
    let user = JSON.parse(localStorage.getItem("authUser"))
    useEffect(() => {
        dispatch(onGetPosMachines());
        dispatch(onGetBankAccounts());
        dispatch(onGetCompanyBranches(user.companyId))
        
    }, [dispatch]);

    const toggle = () => { 
        setModal(!modal)
    }
    const PosToggle = () => {
        setPosModal(!PosModal)
    }


    const columns = [ 
        {
            dataField: 'name',
            text: props.t("Pos Machine Name"), 
            sort: true,
        
        },
        {
            dataField: 'BankAccountId',
            text: props.t("Bank"), 
            sort: true,
            formatter: (cell, row) => (
               
                <span>{cell?.bank}</span>
            )
        },
        {
            dataField: 'terminalNo',
            text: props.t("terminalNo"),
            sort: true

        },
       
      
        {
            dataField: 'pos',
            text: props.t("posRatio"),
            sort: true,

             formatter: (cell, row) => (
                <Button
                        type="submit"
                        className="btn-success me-2"
                        onClick={() => {
                            setInstallments(row.posRates);    
                            PosToggle()
                        }}
                      >
                        {props.t("posRatio")}
                      </Button>
                )

        },

        {
            dataField: '_id',
            text: props.t("Action"),
            sort: false,
            formatter: (cell, row) => (
                <UncontrolledDropdown style={{ position: "unset" }}>
                    <DropdownToggle href="#" className="card-drop" tag="i">
                        <i className="mdi mdi-dots-horizontal font-size-18"></i>
                    </DropdownToggle>
                    <div className="drop-absolute"> 
                    <DropdownMenu className="dropdown-menu-end">
                        <DropdownItem href="#"
                            onClick={() => {
                                setSelectedPosMachine(row);
                               
                                setSelectedBankId(row.bank?._id)
                                setInstallments(row.posRates);    
                                console.log(row)
                                setIsEdit(true);
                                toggle();
                            }}
                        >
                            <i className="mdi mdi-pencil font-size-16 text-success me-1"></i>
                            {props.t("Edit")}
                        </DropdownItem>
                        {/* <DropdownItem href="#"
                            onClick={() => {
                                setSelectedId(cell);
                                setconfirm_alert(true);
                            }}>
                            <i className="mdi mdi-trash-can font-size-16 text-danger me-1"></i>
                            {props.t("Delete")}
                        </DropdownItem> */}
                    </DropdownMenu>
                    </div>
                </UncontrolledDropdown>
            )
        }];

    const defaultSorted = [{
        dataField: 'createdAt',
        order: 'desc'
    }];

    const pageOptions = {
        sizePerPage: 10,
        totalSize: PosMachines ? PosMachines.length : 0, // replace later with size(PosMachines),
        custom: true,
    }

    // Custom Pagination Toggle
    const sizePerPageList = [
        { text: '5', value: 5 },
        { text: '10', value: 10 },
        { text: '15', value: 15 },
        { text: '20', value: 20 },
        { text: '25', value: 25 },
        { text: 'All', value: PosMachines ? PosMachines.length : 0 }];


    // Select All Button operation
    const selectRow = {
        mode: 'checkbox'
    }

    const { SearchBar } = Search;

    const handleValidPosMachineSubmit = (e, values) => {
        if (isEdit) {
           
            const updatePosMachine = {
                _id: selectedPosMachine._id,
                BankAccountId: values.bank,
                terminalNo: values.terminalNo,
                posRates: document.getElementById("posRates").value ,
                CompanyId: user.companyId,
                name:values.name
               
            }; 
           
            // update PosMachine
            dispatch(onUpdatePosMachine(updatePosMachine));
           
        } else {
           
            const newPosMachine = {
                BankAccountId: values.bank,
                terminalNo: values.terminalNo,
                posRates: document.getElementById("posRates").value ,  
                CompanyId: user.companyId,
                name:values.name
               
            }
           
            // save new PosMachine
            dispatch(onAddPosMachine(newPosMachine))
            
            
        }
        toggle()
    }

    

    
    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>{props.t("Pos Machine")}</title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumb title={props.t("Pos Machine")} breadcrumbItem={props.t("Pos Machine")} addBtn={true} addBtnCallBack={() => {
                        setIsEdit(false);
                        setSelectedPosMachine(
                            {
                                bank: "",
                                terminalNo: "",
                                linkedAccountNo: "",
                                posRatio: "",  
                            }
                        );
                        InstallmentAddReset();
                        toggle();

                    }} />

                    {/* {error && error ? <Alert color="danger">{error}</Alert> : null} */}

                    <Card>
                        <CardBody>
                            <PaginationProvider
                                pagination={paginationFactory(pageOptions)}
                                keyField='id'
                                columns={columns}
                                data={PosMachines}
                            >
                                {({ paginationProps, paginationTableProps }) => (
                                    <ToolkitProvider
                                        keyField='_id'
                                        columns={columns}
                                        data={PosMachines}
                                        search
                                    >
                                        {toolkitProps => (
                                            <React.Fragment>

                                                <Row className="mb-2">
                                                    <Col md="4">
                                                        <div className="search-box me-2 mb-2 d-inline-block">
                                                            <div className="position-relative">
                                                                <SearchBar
                                                                    {...toolkitProps.searchProps}
                                                                />
                                                                <i className="bx bx-search-alt search-icon" />
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col xl="12">
                                                        <div className="table-responsive">
                                                            <BootstrapTable
                                                                keyField={"_id"}
                                                                responsive
                                                                bordered={false}
                                                                striped={false}
                                                                defaultSorted={defaultSorted}
                                                                classes={
                                                                    "table align-middle table-nowrap"
                                                                }
                                                                headerWrapperClasses={"thead-light"}
                                                                {...toolkitProps.baseProps}
                                                                {...paginationTableProps}
                                                            />

                                                        </div>
                                                    </Col>
                                                </Row>

                                                <Row className="align-items-md-center mt-30">
                                                    <Col className="inner-custom-pagination d-flex">
                                                        <div className="d-inline">
                                                            <SizePerPageDropdownStandalone
                                                                {...paginationProps}
                                                            />
                                                        </div>
                                                        <div className="text-md-right ms-auto">
                                                            <PaginationListStandalone
                                                                {...paginationProps}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </React.Fragment>
                                        )
                                        }
                                    </ToolkitProvider>
                                )
                                }</PaginationProvider>

                        </CardBody>
                    </Card>


                    <Modal isOpen={modal} toggle={toggle}>
                        <ModalHeader toggle={toggle} tag="h4">
                            {!!isEdit ? props.t("Edit Pos Machine") : props.t("Add Pos Machine")}
                        </ModalHeader>
                        <ModalBody>
                            <AvForm onValidSubmit={handleValidPosMachineSubmit}>
                                <Row form>
                                    <Col xs={12}>
                                        <input
                                            id="posRates"
                                            name="posRates"
                                            type="hidden"
                                        />
                                         <div className="mb-3">
                                            <AvField
                                                name="name"
                                                label={props.t("Pos Machine Name")}
                                                type="text"
                                                errorMessage={props.t("InvalidInput")}
                                                validate={{
                                                    required: { value: false },
                                                }}
                                                value={selectedPosMachine?.terminalNo || ""}
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <AvField
                                                type="select"
                                                name="bank"
                                                value={selectedBankId}
                                                className="form-select"
                                                label={props.t("Bank")}>
                                                <option>{props.t("Select Bank")}</option>
                                                {BankAccounts?.map((item, index) => (
                                                    <option key={index} value={item._id}>
                                                        {item.bank}-{item.branchCode}-{item.branchAccountNo}-{item.iban}
                                                        
                                                    </option>
                                                ))}
                                            </AvField>
                                        </div>
                                        {/* <div className="mb-3">
                                            <AvField
                                                name="bank"
                                                label={props.t("Bank")}
                                                type="select"
                                                className="form-select"
                                                errorMessage={props.t("Invalid Plan Type")}
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                value={selectedPosMachine?.bank || ""}
                                                onChange={(event) => {
                                                    setSelectedPosMachine(prevState => ({
                                                        ...prevState,
                                                        bank: event.target.value
                                                    }));

                                                }}

                                            >
                                                <option value="Akbank">Akbank</option>
                                                <option value="Ziraat">Ziraat</option>
                                                <option value="İsBankası">İş Bankası</option>
                                                <option value="Garanti">Garanti</option>


                                            </AvField>
                                        </div> */}

                                        <div className="mb-3">
                                            <AvField
                                                name="terminalNo"
                                                label={props.t("terminalNo")}
                                                type="text"
                                                errorMessage={props.t("InvalidInput")}
                                                validate={{
                                                    required: { value: false },
                                                }}
                                                value={selectedPosMachine?.terminalNo || ""}
                                            />
                                        </div>

                                        

                                        <br />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col >
                                        <div className="text-end mb-3">
                                            <Button
                                                className="btn btn-success"
                                                onClick={() => { PosToggle() }}

                                            >
                                                {props.t("posRatio")}
                                            </Button>

                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col >
                                        <div className="text-end">
                                            <button
                                                type="submit"
                                                className="btn btn-success save-user"
                                            >
                                                {props.t("Save")}
                                            </button>
                                            &nbsp;&nbsp;
                                            <Button onClick={() => { toggle() }}>
                                                {props.t("Cancel")}
                                            </Button>

                                        </div>
                                    </Col>
                                </Row>

                            </AvForm>
                        </ModalBody>
                    </Modal>

                    <Modal isOpen={PosModal} toggle={PosToggle}>
                        <ModalHeader toggle={PosToggle} tag="h4">
                            {props.t("Define Pos Machine")}
                        </ModalHeader>
                        <ModalBody>
                            <small className="mb-3">
                                {props.t("Please Enter Pos Ratios")}

                            </small>
                            <br /> <br />

                            <AvForm>
                                <Row form>
                                    <Col xs={12} className="pb-4">
                                        {installments.map((item, i) => (
                                            <div key={i}>
                                                <Row className="mt-2">
                                                    <Col md="4" className="text-end">
                                                        <Label
                                                            md="8"
                                                            className="col-form-label"
                                                        >
                                                            {i == 0 ? props.t("Single payment") : item.installment + " " + props.t("Installment")}

                                                        </Label>
                                                    </Col>
                                                    <Col md="4">
                                                        <AvField
                                                            type="number"
                                                            step="0.01"
                                                            id={"installments_" + (i) + "__rate"}
                                                            name={"installments[" + (i) + "].rate"}
                                                            value={item.rate}
                                                            onChange={e => handleChangeInput(i, e)}
                                                            validate={{
                                                                required: { value: false },
                                                            }}
                                                        />
                                                    </Col>
                                                    <Col md="4">
                                                        {i == installments.length - 1 ?
                                                            <div>
                                                                <Button onClick={() => handleAdd(item.installment)} className="btn btn-success me-2">
                                                                    <i className="fas fa-plus"></i>
                                                                </Button>
                                                                {installments.length > 1 ? <Button onClick={() => handleSubtract(i)}>
                                                                    <i className="fas fa-minus"></i>
                                                                </Button> : null}
                                                            </div> : null
                                                        }
                                                    </Col>

                                                </Row>
                                            </div>
                                        ))}
                                    </Col>
                                </Row>
 
                                <Row>
                                    <Col >
                                        {/*setPosModal(!PosModal) */}
                                        <div className="text-end">
                                            <Button onClick={() => {
                                               
                                                document.getElementById("posRates").value = JSON.stringify(installments);
                                                PosToggle();
                                            }} className="btn btn-success save-user">
                                                {props.t("Save")}
                                            </Button>
                                            &nbsp;&nbsp;
                                            <Button onClick={() => {
                                                PosToggle()
                                            }}
                                            >
                                                {props.t("Cancel")}
                                            </Button>

                                        </div>
                                    </Col>
                                </Row> 
                            </AvForm>
                        </ModalBody>
                    </Modal>
                    {success_dlg ? (
                        <SweetAlert
                            success
                            title={dynamic_title}
                            confirmBtnText={props.t("Ok")}
                            onConfirm={() => {
                                setsuccess_dlg(false)
                            }}
                        >
                            {dynamic_description}
                        </SweetAlert>
                    ) : null}

                    {confirm_alert ? (
                        <SweetAlert
                            title={props.t("Are You Sure")}
                            warning
                            showCancel
                            confirmBtnBsStyle="success"
                            cancelBtnBsStyle="danger"
                            cancelBtnText={props.t("Cancel")}
                            confirmBtnText={props.t("Delete")}
                            onConfirm={() => {
                                setconfirm_alert(false);
                                setsuccess_dlg(true);
                                setdynamic_title(props.t("Deleted"));
                                setdynamic_description("");
                                dispatch(onDeletePosMachine(selectedId));
                                setSelectedId(null);
                            }}
                            onCancel={() => setconfirm_alert(false)}
                        >
                            {props.t("DeleteWarning")}
                        </SweetAlert>
                    ) : null}


                    {error && error ? (
                        <SweetAlert
                            title={props.t("Error")}
                            warning
                            confirmBtnText={props.t("Ok")}
                            onConfirm={() => {
                                dispatch(onResetPosMachine())
                            }}
                        >
                            {JSON.stringify(error)}
                        </SweetAlert>
                    ) : null}

                </Container>
            </div>
        </React.Fragment >
    )
}

export default withRouter(withTranslation()(PosMachineList))
