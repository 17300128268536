import {
    GET_PRODUCTCATEGORIES,
    GET_PRODUCTCATEGORIES_SUCCESS,
    GET_PRODUCTCATEGORIES_FAIL,
    GET_PRODUCTCATEGORY_DETAIL,
    GET_PRODUCTCATEGORY_DETAIL_SUCCESS,
    GET_PRODUCTCATEGORY_DETAIL_FAIL,
    ADD_PRODUCTCATEGORY,
    ADD_PRODUCTCATEGORY_SUCCESS,
    ADD_PRODUCTCATEGORY_FAIL,
    UPDATE_PRODUCTCATEGORY,
    UPDATE_PRODUCTCATEGORY_SUCCESS,
    UPDATE_PRODUCTCATEGORY_FAIL,
    DELETE_PRODUCTCATEGORY,
    DELETE_PRODUCTCATEGORY_SUCCESS,
    DELETE_PRODUCTCATEGORY_FAIL,
    RESET_PRODUCTCATEGORY
} from "./actionTypes";

export const resetProductCategory = () => ({
    type: RESET_PRODUCTCATEGORY,
});

export const getProductCategories = () => ({
    type: GET_PRODUCTCATEGORIES,
});

export const getProductCategoriesSuccess = (ProductCategory) => ({
    type: GET_PRODUCTCATEGORIES_SUCCESS,
    payload: ProductCategory,
});


export const getProductCategoriesFail = (error) => ({
    type: GET_PRODUCTCATEGORIES_FAIL,
    payload: error,
});

export const deleteProductCategory = (id) => ({
    type:  DELETE_PRODUCTCATEGORY,
    payload: id
});

export const deleteProductCategorySuccess = (data) => ({
    type: DELETE_PRODUCTCATEGORY_SUCCESS,
    payload: data
});

export const deleteProductCategoryFail = (error) => ({
    type: DELETE_PRODUCTCATEGORY_FAIL,
    payload: error,
});


export const addProductCategory = (productCategory) => ({
    type: ADD_PRODUCTCATEGORY,
    payload: productCategory
});

export const addProductCategorySuccess = (data) => ({
    type: ADD_PRODUCTCATEGORY_SUCCESS,
    payload: data
});

export const addProductCategoryFail = (error) => ({
    type: ADD_PRODUCTCATEGORY_FAIL,
    payload: error,
});


export const updateProductCategory = (productCategory) => ({
    type: UPDATE_PRODUCTCATEGORY,
    payload: productCategory
});

export const updateProductCategorySuccess = (data) => ({
    type: UPDATE_PRODUCTCATEGORY_SUCCESS,
    payload: data
});

export const updateProductCategoryFail = (error) => ({
    type: UPDATE_PRODUCTCATEGORY_FAIL,
    payload: error, 
});