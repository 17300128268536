import { call, put, takeEvery } from "redux-saga/effects";

// Crypto Redux States
import { GET_BRANCHS, DELETE_BRANCH, ADD_BRANCH, UPDATE_BRANCH,GET_COMPANY_BRANCHES,GET_USER_BRANCH,GET_BRANCH_DETAIL} from "./actionTypes";
import {
    getBranchsSuccess, getBranchsFail,
    deleteBranchSuccess, deleteBranchFail,
    updateBranchSuccess, updateBranchFail,
    addBranchSuccess, addBranchFail,
    getCompanyBranchesSuccess,getCompanyBranchesFail, 
    getUserBranchSuccess,getUserBranchFail,
    getBranchByIdSuccess,getBranchByIdFail
 } from "./actions";

//Include Both Helper File with needed methods
import {
    getBranchs, //API CONNECTION
    delBranch,
    addBranch,
    updateBranch,
    getCompanyBranches,
    getUserBranch,
    getBranchById
}
    from "../../helpers/fakebackend_helper"; 

function* fetchBranchs() {
    try {
        const response = yield call(getBranchs)
        yield put(getBranchsSuccess(response));
    } catch (error) {
        yield put(getBranchsFail(error));
    }
}
function* onGetBranchById({ payload: id }) {
    try { 
      const response = yield call(getBranchById, id)
      yield put(getBranchByIdSuccess(response))
    } catch (error) {
      yield put(getBranchByIdFail(error))
    }
  }
function* onGetCompanyBranches({ payload: id }) {
    try { 
      const response = yield call(getCompanyBranches, id)
      yield put(getCompanyBranchesSuccess(response))
    } catch (error) {
      yield put(getCompanyBranchesFail(error))
    }
  }
  function* onGetUserBranch({ payload: id }) {
    try { 
      const response = yield call(getUserBranch, id)
      yield put(getUserBranchSuccess(response))
    } catch (error) {
      yield put(getUserBranchFail(error))
    }
  }
function* onDeleteBranchs({payload:id}){
    try{
        const response = yield call(delBranch, id)
        yield put(deleteBranchSuccess(response));
    } catch (error) {
        yield put(deleteBranchFail(error));
    }
}
function* onAddBranch({payload:branch, file: file}){
    try{
        const response = yield call(addBranch, branch, file)
        yield put(addBranchSuccess(response));
    } catch (error) {
        yield put(addBranchFail(error));
    }
}

function* onUpdateBranch({payload:branch, file: file }){ 
    try{
        const response = yield call(updateBranch, branch, file)
        yield put(updateBranchSuccess(response));
    } catch (error) {
        yield put(updateBranchFail(error));
    }
}

export function* branchsSaga() {
    yield takeEvery(GET_BRANCHS, fetchBranchs);
    yield takeEvery(DELETE_BRANCH, onDeleteBranchs);
    yield takeEvery(ADD_BRANCH, onAddBranch);
    yield takeEvery(UPDATE_BRANCH, onUpdateBranch);
    yield takeEvery(GET_COMPANY_BRANCHES, onGetCompanyBranches);
    yield takeEvery(GET_USER_BRANCH, onGetUserBranch);
    yield takeEvery(GET_BRANCH_DETAIL, onGetBranchById);
}

export default branchsSaga;
