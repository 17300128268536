import {
    GET_BRANCHS,
    GET_BRANCHS_SUCCESS,
    GET_BRANCHS_FAIL,
    GET_BRANCH_DETAIL,
    GET_BRANCH_DETAIL_SUCCESS,
    GET_BRANCH_DETAIL_FAIL,
    ADD_BRANCH,
    ADD_BRANCH_SUCCESS,
    ADD_BRANCH_FAIL,
    UPDATE_BRANCH,
    UPDATE_BRANCH_SUCCESS,
    UPDATE_BRANCH_FAIL,
    DELETE_BRANCH,
    DELETE_BRANCH_SUCCESS,
    DELETE_BRANCH_FAIL,
    GET_COMPANY_BRANCHES_SUCCESS,
    GET_COMPANY_BRANCHES_FAIL,
    GET_USER_BRANCH_SUCCESS,
    GET_USER_BRANCH_FAIL,
    RESET_BRANCH
} from "./actionTypes";

const INIT_STATE = {
    data: [], 
    error: null,
    Branches :[],
    userBranch :[],
    selectedBranch:[]
};

const Branchs = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_BRANCHS_SUCCESS:
            return {
                ...state,
                data: action.payload.data
            }
            case GET_BRANCH_DETAIL_SUCCESS:
             return {
                 ...state,
                 selectedBranch: action.payload.data,
               }
         case GET_COMPANY_BRANCHES_SUCCESS:
             return {
                 ...state,
                 Branches: action.payload.data,
               }
         case GET_USER_BRANCH_SUCCESS:
           return {
               ...state,
               userBranch: action.payload.data,
             }
        case DELETE_BRANCH_SUCCESS:
            return {
                ...state,
                data: state.data.filter(branch => branch._id.toString() !== action.payload.data.toString())
            }
        case ADD_BRANCH_SUCCESS:
            return {
                ...state,
                data: [action.payload.data, ...state.data]
            }
        case UPDATE_BRANCH_SUCCESS:
            return {
                ...state,
                data: state.data.map(branch =>
                    branch._id.toString() === action.payload.data._id.toString() ?
                        { branch, ...action.payload.data } :
                        branch
                )
            }

        //FAILS
        case GET_BRANCHS_FAIL:
        case DELETE_BRANCH_FAIL:
        case ADD_BRANCH_FAIL: 
        case UPDATE_BRANCH_FAIL:
        case GET_COMPANY_BRANCHES_FAIL:
        case GET_USER_BRANCH_FAIL:
        case GET_BRANCH_DETAIL_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case RESET_BRANCH: return {
            ...state,
            error: null 
        }
        default:
            return state;
    }
}


export default Branchs;