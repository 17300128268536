import { call, put, takeEvery } from "redux-saga/effects";

// Crypto Redux States
import { GET_SUBCATEGORIES, DELETE_SUBCATEGORY, ADD_SUBCATEGORY, UPDATE_SUBCATEGORY} from "./actionTypes";
import {
    getSubCategoriesSuccess, getSubCategoriesFail,
    deleteSubCategorySuccess, deleteSubCategoryFail,
    updateSubCategorySuccess, updateSubCategoryFail,
    addSubCategorySuccess, addSubCategoryFail
 } from "./actions";

//Include Both Helper File with needed methods
import {
    getSubCategories, //API CONNECTION
    delSubCategory,
    addSubCategory,
    updateSubCategory
}
    from "../../helpers/fakebackend_helper"; 

function* fetchSubCategories() {
    try {
        const response = yield call(getSubCategories)
       
        yield put(getSubCategoriesSuccess(response));
    } catch (error) {
        yield put(getSubCategoriesFail(error));
    }
}

function* onDeleteSubCategories({payload:id}){
    try{
        const response = yield call(delSubCategory, id)
        yield put(deleteSubCategorySuccess(response));
    } catch (error) {
        yield put(deleteSubCategoryFail(error));
    }
}

function* onAddSubCategory({payload:subCategory}){
    try{
        const response = yield call(addSubCategory, subCategory)
        yield put(addSubCategorySuccess(response));
    } catch (error) {
        yield put(addSubCategoryFail(error));
    }
}

function* onUpdateSubCategory({payload:subCategory}){
    try{
        const response = yield call(updateSubCategory, subCategory)
        yield put(updateSubCategorySuccess(response));
    } catch (error) {
        yield put(updateSubCategoryFail(error));
    }
}

export function* subCategoriesSaga() {
    yield takeEvery(GET_SUBCATEGORIES, fetchSubCategories);
    yield takeEvery(DELETE_SUBCATEGORY, onDeleteSubCategories);
    yield takeEvery(ADD_SUBCATEGORY, onAddSubCategory);
    yield takeEvery(UPDATE_SUBCATEGORY, onUpdateSubCategory);
}

export default subCategoriesSaga;
