import {
    GET_ENDOFDAYBALANCINGS,
    GET_ENDOFDAYBALANCINGS_SUCCESS,
    GET_ENDOFDAYBALANCINGS_FAIL,
    GET_ENDOFDAYBALANCING_DETAIL,
    GET_ENDOFDAYBALANCING_DETAIL_SUCCESS,
    GET_ENDOFDAYBALANCING_DETAIL_FAIL,
    ADD_ENDOFDAYBALANCING,
    ADD_ENDOFDAYBALANCING_SUCCESS,
    ADD_ENDOFDAYBALANCING_FAIL,
    UPDATE_ENDOFDAYBALANCING,
    UPDATE_ENDOFDAYBALANCING_SUCCESS,
    UPDATE_ENDOFDAYBALANCING_FAIL,
    DELETE_ENDOFDAYBALANCING,
    DELETE_ENDOFDAYBALANCING_SUCCESS,
    DELETE_ENDOFDAYBALANCING_FAIL,
    RESET_ENDOFDAYBALANCING
} from "./actionTypes";

const INIT_STATE = {
    data: [],
    error: null
};

const EndOfDayBalancings = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_ENDOFDAYBALANCINGS_SUCCESS:
            return {
                ...state,
                data: action.payload.data
            }
        case DELETE_ENDOFDAYBALANCING_SUCCESS:
            return {
                ...state,
                data: state.data.filter(endOfDayBalancings => endOfDayBalancings._id.toString() !== action.payload.data.toString())
            }
        case ADD_ENDOFDAYBALANCING_SUCCESS:
            return {
                ...state,
                data: [action.payload.data, ...state.data]
            }
        case UPDATE_ENDOFDAYBALANCING_SUCCESS:
            return {
                ...state,
                data: state.data.map(endOfDayBalancings =>
                    endOfDayBalancings._id.toString() === action.payload.data._id.toString() ?
                        { endOfDayBalancings, ...action.payload.data } :
                        endOfDayBalancings
                )
            }

        //FAILS
        case GET_ENDOFDAYBALANCING_DETAIL_FAIL:
        case DELETE_ENDOFDAYBALANCING_FAIL:
        case ADD_ENDOFDAYBALANCING_FAIL:
        case UPDATE_ENDOFDAYBALANCING_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case RESET_ENDOFDAYBALANCING: return {
            ...state,
            error: null
        }
        default:
            return state;
    }
}


export default EndOfDayBalancings;