import axios from "axios"
import { del, get, post, put,postX } from "./api_helper"
import * as url from "./url_helper"

// Gets the logged in user data from local session 
const getLoggedInUser = () => {
  const user = localStorage.getItem("user")
  if (user) return JSON.parse(user)
  return null
}

  
//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null
}

// Register Method
const postFakeRegister = data => {
  return axios
    .post(url.POST_FAKE_REGISTER, data)
    .then(response => {
      if (response.status >= 200 || response.status <= 299) return response.data
      throw response.data
    })
    .catch(err => {
      let message
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found"
            break
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team"
            break
          case 401:
            message = "Invalid credentials"
            break
          default:
            message = err[1]
            break
        }
      }
      throw message
    })
}

// Login Method
const postFakeLogin = data => post(url.POST_FAKE_LOGIN, data)

// postForgetPwd
const postFakeForgetPwd = data => post(url.POST_FAKE_PASSWORD_FORGET, data)

// Edit profile
const postJwtProfile = data => post(url.POST_EDIT_JWT_PROFILE, data)

const postFakeProfile = data => post(url.POST_EDIT_PROFILE, data)
/*
// Register Method
const postJwtRegister = (url, data) => {
  return axios
    .post(url, data)
    .then(response => {
      if (response.status >= 200 || response.status <= 299) return response.data
      throw response.data
    })
    .catch(err => {
      var message
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found"
            break
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team"
            break
          case 401:
            message = "Invalid credentials"
            break
          default:
            message = err[1]
            break
        }
      }
      throw message
    })
}
*/
// Login Method
const postJwtLogin = data => post(url.POST_LOGIN, data)

export const postJwtRegister = data => post(url.POST_REGISTER, data)

// postForgetPwd
const postJwtForgetPwd = data => post(url.POST_FAKE_JWT_PASSWORD_FORGET, data)

// postSocialLogin
export const postSocialLogin = data => post(url.SOCIAL_LOGIN, data)

// get contacts
export const getUsers = () => get(url.GET_USERS)

// add user
export const addNewUser = user => post(url.ADD_NEW_USER, user)

// update user
export const updateUser = user => put(url.UPDATE_USER, user)

// delete user
export const deleteUser = user => del(url.DELETE_USER, { headers: { user } })

export const getUserProfile = () => get(url.GET_USER_PROFILE)


export const forgotSendMail = (email) => post(url.FORGOT_SEND_MAIL, email);

export const postEmailConfirm = (id, hash) => post(url.EMAIL_CONFIRM, id, hash);


/* ####################################################################### */
/* COMPANIES*/

export const getCompanies = () => get(url.GET_COMPANIES);
export const delCompany = (id) => del(url.GET_COMPANIES + "/" + id); 
export const addCompany = (company) => post(url.GET_COMPANIES, company);
export const updateCompany = (company) => put(url.GET_COMPANIES + "/" + company?._id, company);

export const getCompanyUsers = (id) => get(url.GET_COMPANIES + "/users/" + id); 
export const addCompanyUser = (user) => post(url.GET_COMPANIES + "/user", user); 
export const updateCompanyUser = (user) => post(url.GET_COMPANIES + "/user-update", user); 
export const deleteCompanyUser = (id) => del(url.GET_COMPANIES + "/user/"+id); 

/* ####################################################################### */

/* ####################################################################### */
/* ERPS*/

export const getErps = () => get(url.GET_ERPS);
export const delErp = (id) => del(url.GET_ERPS + "/" + id); 
export const addErp = (erp) => post(url.GET_ERPS, erp);
export const updateErp = (erp) => put(url.GET_ERPS + "/" + erp?._id, erp);

/* ####################################################################### */

/* ####################################################################### */
/* CUSTOMERS*/

export const getCustomers = () => get(url.GET_CUSTOMERS);
export const delCustomer = (id) => del(url.GET_CUSTOMERS + "/" + id); 
export const getCustomerById = (id) => get(url.GET_CUSTOMERS + "/" + id); 
export const addCustomer = (customer) => post(url.GET_CUSTOMERS, customer);
export const updateCustomer = (customer) => put(url.GET_CUSTOMERS + "/" + customer?._id, customer);

/* ####################################################################### */

/* BRANCHS*/

export const getBranchs = () => get(url.GET_BRANCHS);
export const getBranchById = (id) => get(url.GET_BRANCHS + "/" + id); 

export const delBranch = (id) => del(url.GET_BRANCHS + "/" + id); 
export const addBranch = (branch, file) => postX(url.GET_BRANCHS, branch, file);
export const updateBranch = (branch, file) => postX(url.GET_BRANCHS + "/" + branch?._id, branch, file);
export const getCompanyBranches = (id) => get(url.GET_BRANCHS + "/branchs/" + id); 
export const getUserBranch = (id) => get(url.POST_REGISTER + "/getBranchById/" + id); 

/* ####################################################################### */
/* PAYMENTS*/

export const getPayments = () => get(url.GET_PAYMENTS);
export const getPaymentById = (id) => get(url.GET_PAYMENTS + "/" + id); 
export const delPayment = (id) => del(url.GET_PAYMENTS + "/" + id); 
export const addPayment = (payment) => post(url.GET_PAYMENTS, payment);
export const updatePayment = (payment) => put(url.GET_PAYMENTS + "/" + payment?._id, payment);

/* ####################################################################### */
/* COLLECTIONS*/

export const getCollections = () => get(url.GET_COLLECTIONS);
export const delCollection = (id) => del(url.GET_COLLECTIONS + "/" + id); 
export const addCollection= (collection) => post(url.GET_COLLECTIONS, collection); 
export const updateCollection = (collection) => put(url.GET_COLLECTIONS + "/" + collection?._id, collection);
/* ####################################################################### */
/* COLLECTIONS*/

export const getProcessListPayments = () => get(url.GET_PROCESS_LIST_PAYMENTS);
export const delProcessListPayment = (id) => del(url.GET_PROCESS_LIST_PAYMENTS + "/" + id); 
export const addProcessListPayment= (processListPayment) => post(url.GET_PROCESS_LIST_PAYMENTS, processListPayment); 
export const updateProcessListPayment = (processListPayment) => put(url.GET_PROCESS_LIST_PAYMENTS + "/" + processListPayment?._id, processListPayment);
/* ####################################################################### */
/* PRODUCTSSERIES*/

export const getProductsSeries = () => get(url.GET_PRODUCTSSERIES);
export const delProductSeries = (id) => del(url.GET_PRODUCTSSERIES + "/" + id); 
export const addProductSeries = (productSeries) => post(url.GET_PRODUCTSSERIES, productSeries);
export const updateProductSeries = (productSeries) => put(url.GET_PRODUCTSSERIES + "/" + productSeries?._id, productSeries);

/* ####################################################################### */
/* PRODUCTS*/

export const getProducts = () => get(url.GET_PRODUCTS);
export const getProductDetail = (id) => get(url.GET_PRODUCTS + "/" + id); 
export const delProduct = (id) => del(url.GET_PRODUCTS + "/" + id); 
export const addProduct = (product) => post(url.GET_PRODUCTS, product);
export const updateProduct = (product) => put(url.GET_PRODUCTS + "/" + product?._id, product);
export const updateProductType = (product) => put(url.UPDATE_PRODUCT_TYPE + "/" + product?._id, product);
export const getProductTypes = (productTypes) => get(url.GET_PRODUCT_TYPES + "/" + product?._id, productTypes);
/* ####################################################################### */
//USER
export const updateUserCompany = (user) => put(url.GET_USER + "/addCompany/" + user?._id, user);
export const getUser = () => get(url.GET_USER);
export const getUserById = (id) => get(url.GET_USER + "/" + id); 
export const delUser = (id) => del(url.GET_USER + "/" + id); 
export const addUser = (user) => post(url.GET_USER, user);
export const updateUsers = (user) => put(url.GET_USER + "/" + user?._id, user);
export const getCompanyByEmail = (email)=> get(url.GET_USER + "/getCompanyByEmail/" + email);
export const changePassword = (obj) => post(url.GET_USER + "/changePassword", obj); 
export const changeSelectedBranch = (obj) => post(url.GET_USER + "/changeSelectedBranch", obj); 
export const sendMail = (email) => post(url.SEND_MAIL, email);

export const getRoles = () => get(url.GET_ROLES); 
export const getUserRoles = (id) => get(url.GET_USER + "/roles/" + id);  
export const addUserRoles = (userRoles) => post(url.GET_ROLES + "/user", userRoles); 

/* ####################################################################### */
/* PRODUCTSALES*/

export const getProductSales = () => get(url.GET_PRODUCTSALES);
export const delProductSale = (id) => del(url.GET_PRODUCTSALES + "/" + id); 
export const addProductSale = (productSale) => post(url.GET_PRODUCTSALES, productSale);
export const updateProductSale = (productSale) => put(url.UPDATE_USERCOMPANY + "/" + productSale?._id, productSale);

/* ####################################################################### */
/* PAYMENT_PLAN*/

export const getPaymentPlans = () => get(url.GET_PAYMENT_PLAN);
export const getPaymentPlanById = (id) => get(url.GET_PAYMENT_PLAN + "/" + id); 
export const delPaymentPlan = (id) => del(url.GET_PAYMENT_PLAN + "/" + id); 
export const addPaymentPlan = (paymentPlanInput) => post(url.GET_PAYMENT_PLAN, paymentPlanInput);
export const updatePaymentPlan = (paymentPlanInput) => put(url.GET_PAYMENT_PLAN + "/" + paymentPlanInput?._id, paymentPlanInput); 
export const getPaymentPlans_MyPlans = () => get(url.GET_PAYMENT_PLANS_MY_PLANS);

/* ####################################################################### */
/* POSMACHINE*/

export const getPosMachines = () => get(url.GET_POSMACHINES);
export const delPosMachine = (id) => del(url.GET_POSMACHINES + "/" + id); 
export const getPosMachinesDetail = (id) =>  get(url.GET_POSMACHINES + "/" + id); 
export const addPosMachine = (posMachine) => post(url.GET_POSMACHINES, posMachine);
export const addPosMachinePosRatio = (posMachine) => post(url.GET_POSMACHINES, posMachine);
export const updatePosMachine = (posMachine) => put(url.GET_POSMACHINES + "/" + posMachine?._id, posMachine);
export const updatePosMachinePosRatio = (posMachine) => put(url.GET_POSMACHINES + "/" + posMachine?._id, posMachine);
/* ####################################################################### */
/* BANKACCOUNT*/

export const getBankAccounts = () => get(url.GET_BANKACCOUNTS);
export const delBankAccount = (id) => del(url.GET_BANKACCOUNTS + "/" + id); 
export const addBankAccount = (bankAccount) => post(url.GET_BANKACCOUNTS, bankAccount);
export const updateBankAccount = (bankAccount) => put(url.GET_BANKACCOUNTS + "/" + bankAccount?._id, bankAccount);

/* EXPENSE*/

export const getExpenses = () => get(url.GET_EXPENSES);
export const delExpense = (id) => del(url.GET_EXPENSES + "/" + id); 
export const addExpense = (expense) => post(url.GET_EXPENSES, expense);
export const updateExpense = (expense) => put(url.GET_EXPENSES + "/" + expense?._id, expense);
export const getTypeListByCategoryId = (id) => get(url.GET_TYPE_LIST_BY_CATEGORY_ID + "/" + id);  

/*ProcessList*/
export const getProductsByBarcode = (barcode) => get(url.GET_PRODUCTS_BY_BARCODE + "/" + barcode); 
export const getProductIdBySerialNo = (serialNo) => get(url.GET_PRODUCTID_BY_SERIALNO + "/" + serialNo);
export const getProductById = (id) => get(url.GET_PRODUCT_BY_ID + "/" + id);
export const getCustomerByIdentity = (identityNumber) => get(url.GET_CUSTOMER_BY_IDENTITY + "/" + identityNumber);
export const getCustomerByPhone = (phoneNumber) => get(url.GET_CUSTOMER_BY_PHONE + "/" + phoneNumber);
export const getCustomerByTaxNo = (taxNumber) => get(url.GET_CUSTOMER_BY_TAXNO + "/" + taxNumber);


/* MAINCATEGORIES*/

export const getMainCategories = () => get(url.GET_MAINCATEGORIES);
export const delMainCategory = (id) => del(url.GET_MAINCATEGORIES + "/" + id); 
export const addMainCategory= (mainCategory) => post(url.GET_MAINCATEGORIES, mainCategory);
export const updateMainCategory = (mainCategory) => put(url.GET_MAINCATEGORIES + "/" + mainCategory?._id, mainCategory);

/* PRODUCTCATEGORIES*/

export const getProductCategories = () => get(url.GET_PRODUCTCATEGORIES);
export const delProductCategory = (id) => del(url.GET_PRODUCTCATEGORIES + "/" + id); 
export const addProductCategory= (productCategory) => post(url.GET_PRODUCTCATEGORIES, productCategory);
export const updateProductCategory = (productCategory) => put(url.GET_PRODUCTCATEGORIES + "/" + productCategory?._id, productCategory);

/* SUBCATEGORIES*/

export const getSubCategories = () => get(url.GET_SUBCATEGORIES);
export const delSubCategory = (id) => del(url.GET_SUBCATEGORIES + "/" + id); 
export const addSubCategory= (subCategory) => post(url.GET_SUBCATEGORIES, subCategory);
export const updateSubCategory = (subCategory) => put(url.GET_SUBCATEGORIES + "/" + subCategory?._id, subCategory);

/* ENDOFDAYBALANCING*/

export const getEndOfDayBalancings = () => get(url.GET_ENDOFDAYBALANCINGS);
export const delEndOfDayBalancing = (id) => del(url.GET_ENDOFDAYBALANCINGS + "/" + id); 
export const addEndOfDayBalancing= (endOfDayBalancing) => post(url.GET_ENDOFDAYBALANCINGS, endOfDayBalancing);
export const updateEndOfDayBalancing = (endOfDayBalancing) => put(url.GET_ENDOFDAYBALANCINGS + "/" + endOfDayBalancing?._id, endOfDayBalancing);

/* SALEMODEL*/

export const getSaleModels = () => get(url.GET_SALEMODELS);
export const delSaleModel = (id) => del(url.GET_SALEMODELS + "/" + id); 
export const postSaleModel= (salemodel) => post(url.POST_SALEMODEL, salemodel); 
export const getModalByIdModel = (UserId) => get(url.GET_MODAL_BY_USER_ID + "/getModalByUserId/" + UserId);





export {
  getLoggedInUser,
  isUserAuthenticated,
  postFakeRegister,
  postFakeLogin,
  postFakeProfile,
  postFakeForgetPwd, 
  postJwtLogin,
  postJwtForgetPwd,
  postJwtProfile,
}
