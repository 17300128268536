import { call, put, takeEvery } from "redux-saga/effects";

// Crypto Redux States
import { GET_POSMACHINES, DELETE_POSMACHINE, ADD_POSMACHINE, UPDATE_POSMACHINE,ADD_POSMACHINE_POSRATIO,UPDATE_POSMACHINE_POSRATIO,GET_POSMACHINE_DETAIL} from "./actionTypes";
import {
    getPosMachinesSuccess, getPosMachinesFail,
    deletePosMachineSuccess, deletePosMachineFail,
    updatePosMachineSuccess, updatePosMachineFail,
    addPosMachineSuccess, addPosMachineFail,
    addPosMachinePosRatioSuccess,addPosMachinePosRatioFail,
    updatePosMachinePosRatioSuccess,updatePosMachinePosRatioFail,
    getPosMachinesByIdSuccess,getPosMachinesByIdFail
 } from "./actions";

//Include Both Helper File with needed methods
import {
    getPosMachines, //API CONNECTION
    delPosMachine,
    addPosMachine,
    updatePosMachine,
    getPosMachinesDetail
}
    from "../../helpers/fakebackend_helper"; 

function* fetchPosMachines() {
    try {
        const response = yield call(getPosMachines)
        yield put(getPosMachinesSuccess(response));
    } catch (error) {
        yield put(getPosMachinesFail(error));
    }
}

function* onDeletePosMachines({payload:id}){
    try{
        const response = yield call(delPosMachine, id)
        yield put(deletePosMachineSuccess(response));
    } catch (error) {
        yield put(deletePosMachineFail(error));
    }
}

function* onAddPosMachine({payload:posMachine}){
    try{
        const response = yield call(addPosMachine, posMachine)
        yield put(addPosMachineSuccess(response));
    } catch (error) {
        yield put(addPosMachineFail(error));
    }
}
function* onAddPosMachinePosRatio({payload:posMachine}){
    try{
        const response = yield call(addPosMachine, posMachine)
        yield put(addPosMachinePosRatioSuccess(response));
    } catch (error) {
        yield put(addPosMachinePosRatioFail(error));
    }
}

function* onUpdatePosMachine({payload:posMachine}){
    try{
        const response = yield call(updatePosMachine, posMachine)
        yield put(updatePosMachineSuccess(response));
    } catch (error) {
        yield put(updatePosMachineFail(error));
    }
}
function* onUpdatePosMachinePosRatio({payload:posMachine}){
    try{
        const response = yield call(updatePosMachine, posMachine)
        yield put(updatePosMachinePosRatioSuccess(response));
    } catch (error) {
        yield put(updatePosMachinePosRatioFail(error));
    }
}
function* onGetPosMachineDetail({payload:id}){
    try{
        const response = yield call(getPosMachinesDetail, id)
        yield put(getPosMachinesByIdSuccess(response));
    } catch (error) {
        yield put(getPosMachinesByIdFail(error));
    }
}

export function* posMachinesSaga() {
    yield takeEvery(GET_POSMACHINES, fetchPosMachines);
    yield takeEvery(DELETE_POSMACHINE, onDeletePosMachines);
    yield takeEvery(ADD_POSMACHINE, onAddPosMachine);
    yield takeEvery(UPDATE_POSMACHINE, onUpdatePosMachine);
    yield takeEvery(ADD_POSMACHINE_POSRATIO, onAddPosMachinePosRatio);
    yield takeEvery(UPDATE_POSMACHINE_POSRATIO, onUpdatePosMachinePosRatio);
    yield takeEvery(GET_POSMACHINE_DETAIL, onGetPosMachineDetail);
    
}

export default posMachinesSaga;
