import {
    GET_EXPENSES,
    GET_EXPENSES_SUCCESS,
    GET_EXPENSES_FAIL,
    GET_EXPENSE_DETAIL,
    GET_EXPENSE_DETAIL_SUCCESS,
    GET_EXPENSE_DETAIL_FAIL,
    ADD_EXPENSE,
    ADD_EXPENSE_SUCCESS,
    ADD_EXPENSE_FAIL,
    UPDATE_EXPENSE,
    UPDATE_EXPENSE_SUCCESS,
    UPDATE_EXPENSE_FAIL,
    DELETE_EXPENSE,
    DELETE_EXPENSE_SUCCESS,
    DELETE_EXPENSE_FAIL,
    RESET_EXPENSE, 
    GET_TYPE_LIST_BY_CATEGORY_ID_SUCCESS,
    GET_TYPE_LIST_BY_CATEGORY_ID_FAIL
} from "./actionTypes";

const INIT_STATE = {
    data: [],
    error: null,
    isType:[]
};

const Expenses = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_EXPENSES_SUCCESS:
            return {
                ...state,
                data: action.payload.data
            } 
        case DELETE_EXPENSE_SUCCESS:
            return {
                ...state,
                data: state.data.filter(payments => payments._id.toString() !== action.payload.data.toString())
            }
        case ADD_EXPENSE_SUCCESS:
            return {
                ...state,
                data: [action.payload.data, ...state.data]
            }
        case UPDATE_EXPENSE_SUCCESS:
            return {
                ...state,
                data: state.data.map(payments =>
                    payments._id.toString() === action.payload.data._id.toString() ?
                        { payments, ...action.payload.data } :
                        payments
                )
            }
        case GET_TYPE_LIST_BY_CATEGORY_ID_SUCCESS:
            return {
                ...state, 
                subCategories: action.payload.data
            }
        //FAILS 
        case GET_EXPENSES_FAIL:
        case DELETE_EXPENSE_FAIL:
        case ADD_EXPENSE_FAIL:
        case UPDATE_EXPENSE_FAIL:
        case GET_TYPE_LIST_BY_CATEGORY_ID_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case RESET_EXPENSE: return {
            ...state,
            error: null
        }
        default:
            return state;
    }
}


export default Expenses;