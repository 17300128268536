import React from "react"
import { Redirect } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Confirm from "../pages/Authentication/Confirm"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"
import SelectBranch from "../pages/Authentication/SelectBranch"
import ResetPassword from "../pages/Authentication/ResetPassword"
// Dashboard
import Dashboard from "../pages/Dashboard/index"

// Dashboard


import Companies from "../pages/Companies/list" 
import CompanyUsers from "../pages/Companies/users"
import Erps from "../pages/Erps/list"
import Customers from "../pages/Customers/list"
import Branchs from "../pages/Branchs/list"
import Payments from "../pages/Payments/list"
import Products from "../pages/Products/list"
import ProductsSeries from "../pages/ProductsSeries/list"
import ProductSales from "../pages/ProductSales/list"
import PaymentPlanList from "../pages/PaymentPlan/list"
import PosMachineList from "../pages/PosMachines/list"
import BankAccount from "../pages/BankAccounts/list"
import Collection from "../pages/Collection/list"
import Expenses from "../pages/Expenses/list"
import MainCategories from "../pages/MainCategories/list"
import ProductCategories from "pages/ProductCategories/list"
import SubCategories from "../pages/SubCategories/list"
import ProcessList from "../pages/ProcessList/list"
import SaleList from "../pages/SalesModels/list" 
import EndOfDay from "../pages/EndOfDay/list"
import deneme from "../pages/deneme/list"

const userRoutes = [
  { path: "/dashboard", component: Dashboard },

  // //profile
  { path: "/profile", component: UserProfile },
   
  // //deneme
  { path: "/deneme", component: deneme },
   
  
   // //company
   { path: "/companies", component: Companies },
   { path: "/companies/users/:id", component: CompanyUsers },

   // //erp
   { path: "/erps", component: Erps },

  // //customer
  { path: "/customers", component: Customers },

  // //branch
  { path: "/branchs", component: Branchs },

  // //payment
  { path: "/payments", component: Payments },

    // //productSales
    { path: "/productSales", component: ProductSales },
  
  
    // //products
    { path: "/products", component: Products },

     // //end of day
     { path: "/endOfDay", component: EndOfDay },


    // //productSeries
    { path: "/productsSeries", component: ProductsSeries },

    // //paymentPlanInput
    { path: "/paymentPlans", component: PaymentPlanList },

    // //paymentPlanInput
    { path: "/posMachines", component: PosMachineList },

     // //bankAccount
     { path: "/bankAccounts", component: BankAccount },
   // //collection
   { path: "/collections", component: Collection },

     // //expenses
     { path: "/expenses", component: Expenses },
    
    // //mainCategory
    { path: "/mainCategories", component: MainCategories },

    // //productCategory
    { path: "/productCategories", component: ProductCategories },

    // //mainCategory
    { path: "/subCategories", component: SubCategories },

    // //işlem listesi
    { path: "/processList", component: ProcessList },

  

    // //Sales Models
    { path: "/salesModels", component: SaleList },

  
 
   

  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/processList" /> },
]

const authRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login }, 
  { path: "/confirm/:id/:hash", component: Confirm },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
  { path: "/selectBranch", component: SelectBranch },
  { path: "/changePassword/:id/:hash", component: ResetPassword },
]

export { userRoutes, authRoutes }
