/** Get Companies */
export const GET_ERPS = "GET_ERPS";
export const GET_ERPS_SUCCESS = "GET_ERPS_SUCCESS";
export const GET_ERPS_FAIL = "GET_ERPS_FAIL";

export const GET_ERP_DETAIL = "GET_ERP_DETAIL";
export const GET_ERP_DETAIL_SUCCESS = "GET_ERP_DETAIL_SUCCESS";
export const GET_ERP_DETAIL_FAIL = "GET_ERP_DETAIL_FAIL";


export const ADD_ERP = "ADD_ERP";
export const ADD_ERP_SUCCESS = "ADD_ERP_SUCCESS";
export const ADD_ERP_FAIL = "ADD_ERP_FAIL";

export const UPDATE_ERP = "UPDATE_ERP";
export const UPDATE_ERP_SUCCESS = "UPDATE_ERP_SUCCESS";
export const UPDATE_ERP_FAIL = "UPDATE_ERP_FAIL";



export const DELETE_ERP = "DELETE_ERP";
export const DELETE_ERP_SUCCESS = "DELETE_ERP_SUCCESS";
export const DELETE_ERP_FAIL = "DELETE_ERP_FAIL";

export const RESET_ERP = "RESET_ERP";