import { call, put, takeEvery } from "redux-saga/effects";

// Crypto Redux States
import { GET_BANKACCOUNTS, DELETE_BANKACCOUNT, ADD_BANKACCOUNT, UPDATE_BANKACCOUNT} from "./actionTypes";
import {
    getBankAccountsSuccess, getBankAccountsFail,
    deleteBankAccountSuccess, deleteBankAccountFail,
    updateBankAccountSuccess, updateBankAccountFail,
    addBankAccountSuccess, addBankAccountFail
 } from "./actions";

//Include Both Helper File with needed methods
import {
    getBankAccounts, //API CONNECTION
    delBankAccount,
    addBankAccount,
    updateBankAccount
}
    from "../../helpers/fakebackend_helper"; 

function* fetchBankAccounts() {
    try {
        const response = yield call(getBankAccounts)
        yield put(getBankAccountsSuccess(response));
    } catch (error) {
        yield put(getBankAccountsFail(error));
    }
}

function* onDeleteBankAccounts({payload:id}){
    try{
        const response = yield call(delBankAccount, id)
        yield put(deleteBankAccountSuccess(response));
    } catch (error) {
        yield put(deleteBankAccountFail(error));
    }
}

function* onAddBankAccount({payload:bankAccount}){
    try{
        const response = yield call(addBankAccount, bankAccount)
        yield put(addBankAccountSuccess(response));
    } catch (error) {
        yield put(addBankAccountFail(error));
    }
}

function* onUpdateBankAccount({payload:bankAccount}){
    try{
        const response = yield call(updateBankAccount, bankAccount)
        yield put(updateBankAccountSuccess(response));
    } catch (error) {
        yield put(updateBankAccountFail(error));
    }
}

export function* bankAccountsSaga() {
    yield takeEvery(GET_BANKACCOUNTS, fetchBankAccounts);
    yield takeEvery(DELETE_BANKACCOUNT, onDeleteBankAccounts);
    yield takeEvery(ADD_BANKACCOUNT, onAddBankAccount);
    yield takeEvery(UPDATE_BANKACCOUNT, onUpdateBankAccount);
}

export default bankAccountsSaga;
