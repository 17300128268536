import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"
import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
} from "availity-reactstrap-validation"
import CashCard from "./Cards/CashCard"
import Table from "./Tables/Tables"
import HomeButton from "./Buttons/homeButtons"
import SearchModal from "./Modals/SearchModal"
import SaleModal from "./Modals/SaleModal"
import CustomerModal from "./Modals/CustomerModal"
import SaleScreenModal from "./Modals/SaleScreenModal"
import EndOfDayBalancingModal from "./Modals/EndOfDayBalancingModal"
import ExpensesModal from "./Modals/ExpensesModal"
import PaymentModal from "./Modals/PaymentModal"
import CollectionModal from "./Modals/CollectionModal"
import FinalSaleModal from "./Modals/FinalSaleModal"
import SelectBranchModal from "./Modals/SelectBranch"
import CustomerInfoModal from "./Modals/CustomerInfoModal"
import ProductInfoModal from "./Modals/ProductInfoModal"
import PaymentInfoModal from "./Modals/PaymentInfoModal"
import PaymentPlanInfoModal from "./Modals/PaymentPlanInfoModal"


import { FormatTL, FormatTLBasic } from "./../../components/turkishMoneyFormat"
import { useSelector, useDispatch } from "react-redux"
import {
  Col,
  Container,
  Row,

} from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"

import {
  addPayment as OnAddPayment,
  addCustomer as onAddCustomer,
  getBankAccounts as onGetBankAccounts,
  getPosMachines as onGetPosMachines,
  getPaymentPlans as onGetMyPaymentPlans,
  getCollections as onGetCollections,
  getCompanyBranches as onGetCompanyBranches,

  getExpenses as onGetExpenses,
  getPayments as onGetPayments,
  addProcessListPayment as OnAddProcessListPayment,
  getProcessListPayments as onGetProcessListPayments,
  resetProcessList as onResetProcessList,
  resetExpense as onResetExpense,
  resetCollection as onResetCollection,
  resetProcessListPayment as onResetProcessListPayment,
  getUserBranch as onGetUserBranch,
  getUserById as onGetUserById,
  getCustomers as onGetCustomers,
  getCustomerById as onGetCustomerById,
  getProductById as onGetProductById,
  getPaymentById as onGetPaymentById
} from "store/actions"

//mainCategories
import { getMainCategories as onGetMainCategories } from "store/actions"
import { entriesIn, isEmpty, map, size } from "lodash"
import moment from "moment"
import { locale } from "moment"
import SweetAlert from "react-bootstrap-sweetalert"
import "./datatables.scss"

const ProcessList = props => {
  const dispatch = useDispatch()
  let user = JSON.parse(localStorage.getItem("authUser"))
  const [modal, setModal] = useState(false)
  const [collectionModal, setCollectionModal] = useState(false)
  const [saleModal, setSaleModal] = useState(false)
  const [saleScreenModal, setSaleScreenModal] = useState(false)
  const [customerModal, setCustomerModal] = useState(false)
  const [customerInfoModal, setCustomerInfoModal] = useState(false)
  const [productInfoModal, setProductInfoModal] = useState(false)
  const [paymentInfoModal, setPaymentInfoModal] = useState(false)
  const [paymentPlanInfoModal, setPaymentPlanInfoModal] = useState(false)
  const [endOfDayBalancingModal, setendOfDayBalancingModal] = useState(false)
  const [selectBranchModal, setSelectBranchModal] = useState(false)
  const [expensesModal, setExpensesModal] = useState(false)
  const [paymentModal, setPaymentModal] = useState(false)
  const [finalSaleModal, setFinalSaleModal] = useState(false)
  const [search, setSearch] = useState([])
  const [newCustomer, setNewCustomer] = useState()
  const [newPayment, setNewPayment] = useState([])
  const [newSale, setNewSale] = useState([])
  const [customerName, setCustomerName] = useState()
  const [paymentName, setPaymentName] = useState()
  const [paymentAmount, setPaymentAmount] = useState()
  const [tempCollectionList, setTempCollectionList] = useState([])
  const [tempExpensesList, setTempExpensesList] = useState([])
  const [tempPaymentList, setTempPaymentList] = useState([])
  const [collectionPlanList, setCollectionPlanList] = useState([])
  const [date, setDate] = useState()
  const [paymentDescription, setPaymentDescription] = useState()
  const [searchData, setSearchData] = useState()
  const handleChange = event => setSearch([...search, event.target.value])
  const {
    error,
    Expenses,
    Collections,
    ProductId,


    ProductBarcode,
    Customer,
    Payment,
    ProductIdBySerial,
    ProcessListPayment,
    Branches,
    UserBranch,
    User


  } = useSelector(state => ({
    error: state.Expenses?.error || state.ProcessList?.error || state.Collections?.error || state.ProcessListPayment?.error,
    Expenses: state.Expenses?.data,
    Collections: state.Collections?.data,
    ProcessListPayment: state.ProcessListPayment?.data,
    Customer: state.Customers?.data,
    Payment: state.Payments?.data,
    ProductId: state.ProcessList?.Product?._id,
    Branches: state.Branchs?.Branches,
    ProductBarcode: state.ProcessList?.Product?._id,
    ProductIdBySerial: state.ProcessList?.ProductId?.productId,
    UserBranch: state.Branchs?.userBranch[0]?.branchId,
    User: state.changeSelectedBranch?.user

  }))

  useEffect(() => {

    dispatch(onGetUserBranch(user.uid))
    dispatch(onGetCompanyBranches(user.companyId))

    dispatch(onGetUserById(user.uid))

    dispatch(onGetBankAccounts())
    dispatch(onGetPosMachines())
    dispatch(onGetMyPaymentPlans())
    dispatch(onGetMainCategories())
    dispatch(onGetCollections())
    setDate(defaultValue)
    dispatch(onGetCustomers());
    dispatch(onGetExpenses())
    dispatch(onGetProcessListPayments())

  }, [dispatch])
  useEffect(() => {

    setSaleModal(false)


  }, [error])
  useEffect(() => {
    setTempCollectionList(Collections.filter(x => handleValidDateAvField(x.createdAt) == date && x.BranchId._id == User.selectedBranch))
  }, [Collections, date, UserBranch]);
  useEffect(() => {
    setTempExpensesList(Expenses.filter(x => handleValidDateAvField(x.createdAt) == date && x.BranchId._id == User.selectedBranch))
  }, [Expenses, date]);
  useEffect(() => {
    setTempPaymentList(ProcessListPayment.filter(x => handleValidDateAvField(x.createdAt) == date && x.BranchId._id == User.selectedBranch))
  }, [ProcessListPayment, date]);

  useEffect(() => {
    if (user.selectedBranch == "") {
      setSelectBranchModal(true)
    }
    else {
      setSelectBranchModal(false)
    }
  }, [user]);
  const handleValidDateAvField = date => {
    if (date) {
      const date1 = moment(new Date(date)).format("YYYY-MM-DD")
      return date1
    } else {
      return ""
    }
  }
  const toggle = () => {
    setModal(!modal)
    setNewPayment([])
    setSaleModal(false)
  }
  const saleToggle = () => {
    setModal(false)
    setSaleModal(!saleModal)
  }
  const customerToggle = () => {
    setCustomerModal(!customerModal)
    setModal(false)
    setSaleModal(false)
    setSaleScreenModal(false)
  }
  const customerInfoToggle = () => {
    setCustomerInfoModal(!customerInfoModal)
  }
  const productInfoToggle = () => {
    setProductInfoModal(!productInfoModal)
  }
  const paymentInfoToggle = () => {
    setPaymentInfoModal(!paymentInfoModal)
  }
  const paymentPlanInfoToggle = () => {
    setPaymentPlanInfoModal(!paymentPlanInfoModal)
  }

  const CollectionToggle = () => {
    setCollectionModal(!collectionModal)
  }
  const saleScreenToggle = () => {
    setSaleScreenModal(!saleScreenModal)
    setModal(false)
    setSaleModal(false)
    setCustomerModal(false)
  }

  const endOfDayBalancingToggle = () => {
    setendOfDayBalancingModal(!endOfDayBalancingModal)
  }
  const selectBranchToggle = () => {
    setSelectBranchModal(!selectBranchModal)
  }
  const expensesToggle = () => {
    setExpensesModal(!expensesModal)
  }

  const paymentToggle = () => {
    setPaymentModal(!paymentModal)

    setModal(false)
    setSaleModal(false)
    setCustomerModal(false)

  }
  const finalSaleToggle = () => {
    setFinalSaleModal(true)
    setModal(false)
    setSaleModal(false)
    setCustomerModal(false)
    setPaymentModal(false)
    setSaleScreenModal(false)
  }
  const finalSaleSaveToggle = () => {
    setFinalSaleModal(!finalSaleModal)
    setModal(false)
    setSaleModal(false)
    setCustomerModal(false)
    setPaymentModal(false)
    setSaleScreenModal(false)
  }

  const findSubCategoryName = (arr, id) => {
    if (!isEmpty(arr) && !isEmpty(id)) {
      let index = arr.findIndex(function (item, i) {
        return item._id === id
      });
      if (index !== -1) {
        return arr[index].name;
      }
      else {
        return "--";
      }
    }
    return "--";
  }
  // const unixTimeZero = Date.parse(Collections[0]?.createdAt);
  // console.log(unixTimeZero)
  //console.log(handleValidDateAvField(Collections[0].createdAt)) 


  const handleValidSaleSubmit = (e, values) => {
    {

      const ProcessListSale = {
        UserId: user.uid,
        ProductId: ProductId || ProductIdBySerial,
        // CustomerId : Customer[0]?._id,
        //PaymentId :Payment[0]?._id,
        ProductType: newSale?.productType,
        amount: newSale?.amount,
        salesAmount: newSale?.count,
        serialNumbers: JSON.stringify(newSale.serialNumbers),

        CustomerId: newCustomer,


        CompanyId: user.companyId,
        BranchId: User.selectedBranch,

        PaymentPlanObjects: newPayment



      }

      dispatch(OnAddProcessListPayment(ProcessListSale))
      console.log(ProcessListSale)

    }
  }

  const expenseColumns = [
    {
      dataField: "category",
      text: props.t("MainCategory"),
      sort: true,
      formatter: (cell, row) => <span>{cell?.category}</span>,

    },
    {
      dataField: "isType",
      text: props.t("Subcategory"),
      sort: true,
      formatter: (cell, row) => <span>
        {findSubCategoryName(row.category?.subCategories, cell)}
      </span>,
    },
    {
      dataField: "description",
      text: props.t("Description"),
      sort: true,
    },
    {
      dataField: "amount",
      text: props.t("Amount"),
      sort: true,
    },


  ]
  const collectionColumns = [

    {
      dataField: "CustomerId",
      text: props.t("PayerName"),
      sort: true,
      formatter: (cell, row) => (

        <span>{cell?.customerName ? cell?.customerName : "Nihai Tüketici"} {cell?.customerName ? (<i style={{ fontSize: "18px", position: "relative", top: "3px" }} className="bx bx-info-circle"
          onClick={e => {
            dispatch(onGetCustomerById(row.CustomerId?._id))
            customerInfoToggle()

          }} />) : null} </span>
      )
    },
    {
      dataField: "name",
      text: props.t("isType"),
      sort: true,
    },
    {
      dataField: "PaymentPlanObjects",
      text: props.t("Payment Detail"),
      sort: false,
      formatter: (cell, row) => <span>{} <i style={{ fontSize: "18px", position: "relative", top: "3px" }} className="bx bx-info-circle"
      onClick={e => {
        setCollectionPlanList(cell)
        paymentPlanInfoToggle()

      }} /></span>,
    },

    {
      dataField: "description",
      text: props.t("Description"),
      sort: true,
    },
    {
      dataField: "amount",
      text: props.t("Amount"),
      sort: true,
    },
    


  ]
  const columns = [
    {
      dataField: "ProductId",
      text: props.t("Product Name"),
      sort: true,
      formatter: (cell, row) => <span>{cell?.name} <i style={{ fontSize: "18px", position: "relative", top: "3px" }} className="bx bx-info-circle"
        onClick={e => {
          dispatch(onGetProductById(row.ProductId?._id))
          productInfoToggle()

        }} /></span>,

    },

    {
      dataField: "salesAmount",
      text: props.t("Adet"),
      sort: true,
    },
    {
      dataField: "amount",
      text: props.t("Amount"),
      sort: true,
    },
    {
      dataField: "ProductType",
      text: props.t("Product Type"),
      sort: true,
    },

    {
      dataField: "CustomerId",
      text: props.t("Customer Name"),
      sort: true,
      formatter: (cell, row) => <span>{cell?.customerName ? cell?.customerName : "Nihai Tüketici"} {cell?.customerName ? (<i style={{ fontSize: "18px", position: "relative", top: "3px" }} className="bx bx-info-circle"
        onClick={e => {
          dispatch(onGetCustomerById(row.CustomerId?._id))
          customerInfoToggle()

        }} />) : null} </span>,

    },
    {
      dataField: "PaymentId",
      text: props.t("PaymentMethod"),
      sort: true,
      formatter: (cell, row) => <span>{cell?.map((item, index) => (
        <div key={"bss_" + index}>
          <Row>
            <Col md="6" xl="3">
              <option key={"b_" + index} value={item._id}  >
                {item.name}

              </option>
            </Col>
            <Col md="6" xl="6">
              <i style={{ fontSize: "18px" }} className="bx bx-info-circle"
                onClick={e => {
                  dispatch(onGetPaymentById(item._id))
                  paymentInfoToggle()
                  console.log(item._id)

                }} />
            </Col>


          </Row>

        </div>



      ))}</span>,
    },



  ]
  const handleValidDate = date => {
    if (date) {
      const date1 = moment(new Date(date)).format("DD.MM.YYYY");
      return date1;
    } else {
      return "";
    }
  }

  const today = new Date();
  const numberOfDaysToAdd = 0;
  const datet = today.setDate(today.getDate() + numberOfDaysToAdd);
  const defaultValue = new Date(datet).toISOString().split('T')[0] // yyyy-mm-dd
  return (
    <React.Fragment>
      <div className="page-content">

        <Container fluid>

          <Row>
            <Col xs="12" sm="12" md="6" lg="3" xl="3">
              <h3 style={{ position: "relative" }}> {props.t("HomePage")}</h3> </Col>
            <Col xs="12" sm="12" md="6" lg="3" xl="6"></Col>
            <Col xs="12" sm="12" md="6" lg="6" xl="3">
              <AvForm >
                <Row form>
                  <Col >
                    <Row>
                      <Col >
                        <div className="mb-3">
                          <AvField
                            name="date"
                            label={props.t("Date")}
                            type="date"
                            errorMessage="invalid Date Of Birth"
                            onChange={e => setDate(e.target.value)}
                            defaultValue={defaultValue}
                            value={""}
                          />
                          {/* <input id="dateRequired" type="date" name="dateRequired" defaultValue={defaultValue} onChange={e =>setDate(e.target.value)} /> */}
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>

              </AvForm></Col>


          </Row>




          <Row className="text-center">
            <Col xl="2">
              <HomeButton name={props.t("Sale")} _cb={() => { toggle() }} />
            </Col>
            <Col xl="2">
              <button
                type="button"
                className="btn btn-primary col-12 col-md-9 mb-3"
                onClick={() => {
                  CollectionToggle()
                }}
              >
                {props.t("Collection")}
              </button>
            </Col>
            <Col xl="2">
              <button
                className="btn btn-primary col-12 col-md-9 mb-3"
                type="button"
                onClick={() => {
                  expensesToggle()
                }}
              >
                {props.t("Expense")}
              </button>
            </Col>
            <Col xl="2">
              <button
                type="button"
                className="btn btn-primary col-12 col-md-9 mb-3"
                onClick={() => {
                  endOfDayBalancingToggle()
                }}
              >
                {props.t("End Of Day Balancing")}
              </button>
            </Col>
          </Row>



        </Container>

        <Container fluid>
          <div className="mt-5">
            <Row>
              <Col sm="2">
                <CashCard title={props.t("Transfer")} price={FormatTLBasic(124.66)} />
              </Col>
              <Col sm="2">
                <CashCard title={props.t("Number Of Transactions")} price={FormatTLBasic(124.66)} />
              </Col>
              <Col sm="2">
                <CashCard title={props.t("Total Cash")} price={FormatTLBasic(124.66)} />
              </Col>
              <Col sm="2">
                <CashCard title={props.t("Total Credit Card")} price={FormatTLBasic(12.22)} />
              </Col>
              <Col sm="2">
                <CashCard title={props.t("Cash Register")} price={FormatTLBasic(12.22)} />
              </Col>
              <Col sm="2">
                <CashCard title={props.t("Profit")} price={FormatTLBasic(12.22)} />
              </Col>
              <Col sm="12">
                < Table title={props.t("Sales")} columns={columns} data={tempPaymentList} />
              </Col>
              <Col sm="6">
                < Table title={props.t("Collections")} columns={collectionColumns} data={tempCollectionList} />
              </Col>
              <Col sm="6">
                < Table title={props.t("Expenses")} columns={expenseColumns} data={tempExpensesList} />
              </Col>
            </Row>
          </div>
          <SearchModal modal={modal} title={props.t("Products")} barcodeLabel={props.t("Barcode")}
            serialNoLabel={props.t("Serial No")} find={props.t("Find")}
            toggle={() => { toggle() }}
            _cb={(e, _search) => {

              if (e == true) {
                setSaleModal(true)
                setModal(false)
                setSearchData(_search)

              }

            }} />

          <SaleModal modal={saleModal} saleState={search} searchData={searchData} handleChange={(e) => handleChange(e)} toggle={() => { saleToggle() }}
            continue={(e, _body) => {

              if (e == true) {
                setNewSale(_body)
                console.log("sale body", _body)
                customerToggle()

              }
            }}

            back={(e) => {

              if (e == true) {
                toggle()

              }
            }}


            payment={(e, _body) => {
              if (e == true) {
                setNewSale(_body)
                paymentToggle()

              }

            }}
          />

          <CustomerModal modal={customerModal} toggle={() => { customerToggle() }}
            payment={(e, _body) => {
              if (e == true) {
                console.log("customer bilgileri", _body)
                setNewCustomer(_body.customerId)
                setCustomerName(_body.customerName)

                saleScreenToggle()

              }
            }}

            finalCustomer={(e) => {

              if (e == true) {
                customerToggle()
                paymentToggle()
              }
            }}
          />

          <PaymentInfoModal modal={paymentInfoModal}  toggle={() => { paymentInfoToggle() }}


            Cancel={(e) => {

              if (e == true) {
                paymentInfoToggle()
              }
            }}
          />
          <PaymentPlanInfoModal modal={paymentPlanInfoModal} PaymentPlan={collectionPlanList} toggle={() => { paymentPlanInfoToggle() }}


            Cancel={(e) => {

              if (e == true) {
                paymentPlanInfoToggle()
              }
            }}
          />
          <CustomerInfoModal modal={customerInfoModal} toggle={() => { customerInfoToggle() }}


            Cancel={(e) => {

              if (e == true) {
                customerInfoToggle()
              }
            }}
          />
          <ProductInfoModal modal={productInfoModal} toggle={() => { productInfoToggle() }}


            Cancel={(e) => {

              if (e == true) {
                productInfoToggle()
              }
            }}
          />
          <SaleScreenModal modal={saleScreenModal} customerName={customerName} toggle={() => { saleScreenToggle() }}
            payment={(e) => {
              if (e == true) { 
                paymentToggle()

              }
            }}

            back={(e) => {

              if (e == true) {
                customerToggle()

              }
            }}
          />
          <EndOfDayBalancingModal modal={endOfDayBalancingModal} toggle={() => { endOfDayBalancingToggle() }}
            save={(e) => {
              if (e == true) {
                endOfDayBalancingToggle()

              }
            }}
          />
          {/* <SelectBranchModal modal={selectBranchModal} toggle={() => { selectBranchToggle() }}
          save={(e) => {
            if (e == true) {
              selectBranchToggle()

            }
          }}
         
        /> */}
          <ExpensesModal modal={expensesModal} toggle={() => { expensesToggle() }}
            cancel={(e) => {
              if (e == true) {
                expensesToggle()

              }
            }}
          />
          <PaymentModal modal={paymentModal} toggle={() => { paymentToggle() }}
            save={(e, _body) => {


              if (e == true) {

                setPaymentName(_body.name)
                setPaymentAmount(_body.amount)
                setPaymentDescription(_body.description)
                finalSaleToggle()
                let arrayA = newPayment;
                let arrayB = _body;
                let newArray = arrayA.concat(arrayB);
                setNewPayment(newArray)
                console.log(newArray)

              }
            }}
            cancel={(e) => {
              if (e == true) {
                paymentToggle()

              }
            }}
          />
          <CollectionModal modal={collectionModal} toggle={() => { CollectionToggle() }}
            save={(e) => {
              if (e == true) {
                CollectionToggle()

              }
            }}
            cancel={(e) => {
              if (e == true) {
                CollectionToggle()

              }
            }}
          />
          <FinalSaleModal modal={finalSaleModal} paymentName={newPayment} description={newSale?.amount} amount={paymentAmount} customerName={customerName} toggle={() => { finalSaleSaveToggle() }}
            payment={(e) => {
              if (e == true) {
                paymentToggle()

              }
            }}

            save={(e, _body) => {
              if (e == true) {
                //setNewPayment(_body)
                finalSaleSaveToggle()


                //dispatch(onAddCustomer(newCustomer))
                //dispatch(OnAddPayment(newPayment)) 
                handleValidSaleSubmit()


              }
            }}

          />

        </Container>

        {error ? <SweetAlert
          title={props.t("Error")}
          warning
          confirmBtnText={props.t("Ok")}
          onConfirm={() => {
            dispatch(onResetProcessList())
            dispatch(onResetExpense())
            dispatch(onResetCollection())
            dispatch(onResetProcessListPayment())
          }}
        >
          {JSON.stringify(error)}
        </SweetAlert> : null}
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(ProcessList)
