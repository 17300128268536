import { call, put, takeEvery } from "redux-saga/effects";

// Crypto Redux States
import { GET_PAYMENTS, DELETE_PAYMENT, ADD_PAYMENT, UPDATE_PAYMENT,GET_PAYMENT_DETAIL} from "./actionTypes";
import {
    getPaymentsSuccess, getPaymentsFail,
    deletePaymentSuccess, deletePaymentFail,
    updatePaymentSuccess, updatePaymentFail,
    addPaymentSuccess, addPaymentFail,
    getPaymentByIdSuccess,getPaymentByIdFail
 } from "./actions";

//Include Both Helper File with needed methods
import {
    getPayments, //API CONNECTION
    delPayment,
    addPayment,
    updatePayment,
    getPaymentById
}
    from "../../helpers/fakebackend_helper"; 

function* fetchPayments() {
    try {
        const response = yield call(getPayments)
       
        yield put(getPaymentsSuccess(response));
    } catch (error) {
        yield put(getPaymentsFail(error));
    }
}
function* onGetPaymentById({payload:id}){
    try{
        const response = yield call(getPaymentById, id)
        yield put(getPaymentByIdSuccess(response));
    } catch (error) {
        yield put(getPaymentByIdFail(error));
    }
}
function* onDeletePayments({payload:id}){
    try{
        const response = yield call(delPayment, id)
        yield put(deletePaymentSuccess(response));
    } catch (error) {
        yield put(deletePaymentFail(error));
    }
}

function* onAddPayment({payload:payment}){
    try{
        const response = yield call(addPayment, payment)
        yield put(addPaymentSuccess(response));
    } catch (error) {
        yield put(addPaymentFail(error));
    }
}

function* onUpdatePayment({payload:payment}){
    try{
        const response = yield call(updatePayment, payment)
        yield put(updatePaymentSuccess(response));
    } catch (error) {
        yield put(updatePaymentFail(error));
    }
}

export function* paymentsSaga() {
    yield takeEvery(GET_PAYMENTS, fetchPayments);
    yield takeEvery(DELETE_PAYMENT, onDeletePayments);
    yield takeEvery(ADD_PAYMENT, onAddPayment);
    yield takeEvery(UPDATE_PAYMENT, onUpdatePayment);
    yield takeEvery(GET_PAYMENT_DETAIL, onGetPaymentById);
}

export default paymentsSaga;
