import {
    GET_PRODUCTSSERIES,
    GET_PRODUCTSSERIES_SUCCESS,
    GET_PRODUCTSSERIES_FAIL,
    GET_PRODUCTSERIES_DETAIL,
    GET_PRODUCTSERIES_DETAIL_SUCCESS,
    GET_PRODUCTSERIES_DETAIL_FAIL,
    ADD_PRODUCTSERIES,
    ADD_PRODUCTSERIES_SUCCESS,
    ADD_PRODUCTSERIES_FAIL,
    UPDATE_PRODUCTSERIES,
    UPDATE_PRODUCTSERIES_SUCCESS,
    UPDATE_PRODUCTSERIES_FAIL,
    DELETE_PRODUCTSERIES,
    DELETE_PRODUCTSERIES_SUCCESS,
    DELETE_PRODUCTSERIES_FAIL,
    RESET_PRODUCTSERIES
} from "./actionTypes";

const INIT_STATE = {
    data: [],
    error: null
};

const ProductsSeries = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_PRODUCTSSERIES_SUCCESS:
            return {
                ...state,
                data: action.payload.data
            }
        case DELETE_PRODUCTSERIES_SUCCESS:
            return {
                ...state,
                data: state.data.filter(ProductSeries => ProductSeries._id.toString() !== action.payload.data.toString())
            }
        case ADD_PRODUCTSERIES_SUCCESS:
            return {
                ...state,
                data: [action.payload.data, ...state.data]
            }
        case UPDATE_PRODUCTSERIES_SUCCESS:
            return {
                ...state,
                data: state.data.map(ProductSeries =>
                    ProductSeries._id.toString() === action.payload.data._id.toString() ?
                        { ProductSeries, ...action.payload.data } :
                        ProductSeries
                )
            }

        //FAILS
        case GET_PRODUCTSSERIES_FAIL:
        case DELETE_PRODUCTSERIES_FAIL:
        case ADD_PRODUCTSERIES_FAIL:
        case UPDATE_PRODUCTSERIES_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case RESET_PRODUCTSERIES: return {
            ...state,
            error: null
        }
        default:
            return state;
    }
}


export default ProductsSeries;