/** Get Payment */
export const GET_PAYMENTS = "GET_PAYMENTS";
export const GET_PAYMENTS_SUCCESS = "GET_PAYMENTS_SUCCESS";
export const GET_PAYMENTS_FAIL = "GET_PAYMENTS_FAIL";

export const GET_PAYMENT_DETAIL = "GET_PAYMENT_DETAIL";
export const GET_PAYMENT_DETAIL_SUCCESS = "GET_PAYMENT_DETAIL_SUCCESS";
export const GET_PAYMENT_DETAIL_FAIL = "GET_PAYMENT_DETAIL_FAIL";


export const ADD_PAYMENT = "ADD_PAYMENT";
export const ADD_PAYMENT_SUCCESS = "ADD_PAYMENT_SUCCESS";
export const ADD_PAYMENT_FAIL = "ADD_PAYMENT_FAIL";

export const UPDATE_PAYMENT = "UPDATE_PAYMENT";
export const UPDATE_PAYMENT_SUCCESS = "UPDATE_PAYMENT_SUCCESS";
export const UPDATE_PAYMENT_FAIL = "UPDATE_PAYMENT_FAIL";



export const DELETE_PAYMENT = "DELETE_PAYMENT";
export const DELETE_PAYMENT_SUCCESS = "DELETE_PAYMENT_SUCCESS";
export const DELETE_PAYMENT_FAIL = "DELETE_PAYMENT_FAIL";

export const RESET_PAYMENT = "RESET_PAYMENT";