import React, { useState } from "react"
import PropTypes from 'prop-types'
import {
  Container, Row, Col, Modal, ModalHeader, ModalBody, TabContent, TabPane, Label, Input,
  FormGroup
} from "reactstrap"
import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
} from "availity-reactstrap-validation"
import { useSelector, useDispatch } from "react-redux"
import {

  addExpense as onAddExpense,
  getCompanyBranches as onGetCompanyBranches,

} from "store/actions"
import { isEmpty, map } from "lodash"
import { Link, withRouter } from "react-router-dom"
//i18n
import { withTranslation } from "react-i18next"
let user = JSON.parse(localStorage.getItem("authUser"))
const ExpensesModal = (props, cb) => {
  const [subCategoriesObjects, setSubCategoriesObjects] = useState([])
  const [selectedExpense, setSelectedExpense] = useState()
  const [selectedBranchId, setSelectedBranchId] = useState()
  const [selectedMainCategory, setSelectedMainCategory] = useState()
  const {
    error, MainCategories, Branches,User

  } = useSelector(state => ({
    error: state.Expenses?.error,
    MainCategories: state.MainCategories?.data,
    Branches: state.Branchs?.Branches,
    User :state.changeSelectedBranch?.user
  }))
  const handleValidExpenseSubmit = (e, values) => {
    const newExpense = {
      category: values["category"],
      isType: values["isType"],
      description: values["description"],
      amount: values["amount"],
      CompanyId: user.companyId,
      BranchId: User.selectedBranch, 
    }
    // save new Expense
    dispatch(onAddExpense(newExpense))
    console.log(newExpense)
    props.cancel(true)
  }
  const dispatch = useDispatch()

  return (
    <Modal isOpen={props.modal} toggle={props.toggle}>
      <ModalHeader toggle={props.toggle} tag="h4">
        {props.t("Expenses and Expenditures")}
      </ModalHeader>
      <ModalBody>
        <AvForm onValidSubmit={handleValidExpenseSubmit}> 
          <Row form>
            <Col xs={12}>
              <Container>
                <div className="mb-3">
                  
                  <AvField
                    type="select"
                    name="category"
                    className="form-select"
                    label={props.t("Select Category")}
                    onChange={event => {
                      setSelectedMainCategory(prevState => ({
                        ...prevState,
                        category: event.target.value,
                      }))
                      let sub = MainCategories.find(
                        x => x._id === event.target.value
                      )

                      let tempSubListArr = []

                      tempSubListArr.push(sub.subCategories)

                      if (sub) {
                        setSubCategoriesObjects(tempSubListArr)
                      } else {
                        setSubCategoriesObjects([])
                      }
                    }}
                  >
                    <option>{props.t("Select Category")}</option>
                    {MainCategories?.map((item, index) => (
                      <option key={"b_" + index} value={item._id}>
                        {item.category}
                      </option>
                    ))}
                  </AvField>
                </div>

                <div className="mb-3">
                  <AvField
                    type="select"
                    name="isType"
                    className="form-select"
                    label={props.t("Select Type")}
                    onChange={e => {

                    }}
                  >
                    <option>{props.t("Select Type")}</option>
                    {subCategoriesObjects[0]?.map((item, index) => (
                      <option key={"c_" + index} value={item._id}>
                        {item.name}
                      </option>
                    ))}
                  </AvField>
                </div>

                <div className="mb-3">
                  <AvField
                    name="description"
                    label={props.t("Description")}
                    type="text"
                    errorMessage={props.t("InvalidInput")}
                    validate={{
                      required: { value: false },
                    }}
                    value={selectedExpense?.description || ""}
                  />
                </div>
                <div className="mb-3">
                  <AvField
                    name="amount"
                    label={props.t("Amount")}
                    type="text"
                    errorMessage={props.t("InvalidInput")}
                    validate={{
                      required: { value: false },
                    }}
                    value={selectedExpense?.amount || ""}
                  />
                </div>
              </Container>
            </Col>
          </Row>
          <Row className="mt-3"></Row>
          <Row className="mt-3">
            <Col md="12">
              <div className="text-end">
                <button
                  type="submit"
                  className="btn btn-success save-user col-4"
                >
                  {props.t("Save")}
                </button>
                &nbsp;&nbsp;
                <button
                  className="btn btn-secondary col-4 "
                  type="button"
                  onClick={() => {
                    props.cancel(true)
                  }}
                >
                  {props.t("Cancel")}
                </button>
              </div>
            </Col>
          </Row>
        </AvForm>
      </ModalBody>
    </Modal>
  )
}


ExpensesModal.propTypes = {

  modal: PropTypes.bool,
  cancel: PropTypes.func,
  t: PropTypes.any,
  toggle: PropTypes.func,

}


export default withRouter(
  (withTranslation()(ExpensesModal))
)
