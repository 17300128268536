/** Get Payment */
export const GET_PRODUCTCATEGORIES = "GET_PRODUCTCATEGORIES";
export const GET_PRODUCTCATEGORIES_SUCCESS = "GET_PRODUCTCATEGORIES_SUCCESS";
export const GET_PRODUCTCATEGORIES_FAIL = "GET_PRODUCTCATEGORIES_FAIL";

export const GET_PRODUCTCATEGORY_DETAIL = "GET_PRODUCTCATEGORY_DETAIL";
export const GET_PRODUCTCATEGORY_DETAIL_SUCCESS = "GET_PRODUCTCATEGORY_DETAIL_SUCCESS";
export const GET_PRODUCTCATEGORY_DETAIL_FAIL = "GET_PRODUCTCATEGORY_DETAIL_FAIL";


export const ADD_PRODUCTCATEGORY = "ADD_PRODUCTCATEGORY";
export const ADD_PRODUCTCATEGORY_SUCCESS = "ADD_PRODUCTCATEGORY_SUCCESS";
export const ADD_PRODUCTCATEGORY_FAIL = "ADD_PRODUCTCATEGORY_FAIL";

export const UPDATE_PRODUCTCATEGORY = "UPDATE_PRODUCTCATEGORY";
export const UPDATE_PRODUCTCATEGORY_SUCCESS = "UPDATE_PRODUCTCATEGORY_SUCCESS";
export const UPDATE_PRODUCTCATEGORY_FAIL = "UPDATE_PRODUCTCATEGORY_FAIL";



export const DELETE_PRODUCTCATEGORY = "DELETE_PRODUCTCATEGORY";
export const DELETE_PRODUCTCATEGORY_SUCCESS = "DELETE_PRODUCTCATEGORY_SUCCESS";
export const DELETE_PRODUCTCATEGORY_FAIL = "DELETE_PRODUCTCATEGORY_FAIL";

export const RESET_PRODUCTCATEGORY = "RESET_PRODUCTCATEGORY";