import React, { useState } from "react"
import PropTypes from 'prop-types'
import { Container, Row, Col, Modal, ModalHeader, ModalBody,TabContent,TabPane,Label,
    FormGroup,
    CardImgOverlay,Input} from "reactstrap"
import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
} from "availity-reactstrap-validation"
import InputMask from "react-input-mask"
import { useSelector, useDispatch } from "react-redux"
import moment from "moment"


import { isEmpty, map } from "lodash"
import { Link, withRouter } from "react-router-dom"
//i18n
import { withTranslation } from "react-i18next"

const CustomerModal = (props, cb) => {
  const [selectedCustomerType, setSelectedCustomerType] = useState(false)
  const {
    error,
    PaymentInfo
  } = useSelector(state => ({
    error: state.Expenses?.error,
    PaymentInfo: state.Payments?.paymentInfo,
    
  }))
  const handleValidDateAvField = date => {
    if (date) {
      const date1 = moment(new Date(date)).format("YYYY-MM-DD")
      return date1
    } else {
      return ""
    }
  }
 
  const dispatch = useDispatch()

  return (
    <Modal size="md" isOpen={props.modal} toggle={props.toggle}>
    <ModalHeader toggle={props.toggle} tag="h4">
            {props.t("Payment Info")}
          </ModalHeader>
          <ModalBody> 
            <AvForm >
              <Row form>
                <Col xl={12}>
                 
                  <Row>
                    
                    </Row>
                    {props.PaymentPlan?.map((item, i) => (
                      
                              <div key={i}>
                                  <input
                                      id={"paymentPlanObjects_" + (i) + "__isValue"}
                                      name={"paymentPlanObjects[" + (i) + "].isValue"}
                                      type="hidden"
                                  />
                                  <Row className="mt-2">
                                      <Col md="12">
                                          <AvField
                                              type="text"
                                              id={"paymentPlanObjects_" + (i) + "__title"}
                                              name={"paymentPlanObjects[" + (i) + "].title"}
                                              placeholder={props.t("Title")}
                                              disabled
                                              label ={item.title}
                                              value={item.isValue}
                                              onChange={e => handleChangeInput(i, e, "title")}
                                              validate={{
                                                  required: { value: false },
                                              }}
                                          />
                                      </Col>
                                      
                                     
                                    

                                  </Row>
                              </div>
                              
                          ))}
                   
                 
                 
                  
                  

                 
                 

                 
                 
                </Col>
              </Row>
              <Row className="mt-3">
                <Col md="12" xs="12" className="text-end">
                  
                  <button
                    type="button"
                    className="btn btn-secondary col-md-2 col-xs-5"
                    onClick={() => {
                      props.Cancel(true)
                    }}
                  >
                    {props.t("Cancel")}
                  </button>
                </Col>
              </Row>
            </AvForm>
          </ModalBody>
        </Modal>
  )
}


CustomerModal.propTypes = {
 
  modal: PropTypes.bool,
  PaymentPlan:PropTypes.any,
   payment:PropTypes.func,
   Cancel:PropTypes.func,
   back:PropTypes.func,
   t: PropTypes.any,
   toggle: PropTypes.func,
}


export default withRouter(
    (withTranslation()(CustomerModal))
  )
