import { call, put, takeEvery } from "redux-saga/effects";

// Crypto Redux States
import { GET_CUSTOMERS, DELETE_CUSTOMER, ADD_CUSTOMER, UPDATE_CUSTOMER,GET_CUSTOMER_DETAIL} from "./actionTypes";
import {
    getCustomersSuccess, getCustomersFail,
    deleteCustomerSuccess, deleteCustomerFail,
    updateCustomerSuccess, updateCustomerFail,
    addCustomerSuccess, addCustomerFail,
    getCustomerByIdSuccess,getCustomerByIdFail
 } from "./actions";

//Include Both Helper File with needed methods
import {
    getCustomers, //API CONNECTION
    delCustomer,
    addCustomer,
    updateCustomer,
    getCustomerById
}
    from "../../helpers/fakebackend_helper"; 

function* fetchCustomers() {
    try {
        const response = yield call(getCustomers)
        yield put(getCustomersSuccess(response));
    } catch (error) {
        yield put(getCustomersFail(error));
    }
}
function* onGetCustomerById({payload:id}){
    try{
        const response = yield call(getCustomerById, id)
        yield put(getCustomerByIdSuccess(response));
    } catch (error) {
        yield put(getCustomerByIdFail(error));
    }
}

function* onDeleteCustomers({payload:id}){
    try{
        const response = yield call(delCustomer, id)
        yield put(deleteCustomerSuccess(response));
    } catch (error) {
        yield put(deleteCustomerFail(error));
    }
}

function* onAddCustomer({payload:customer}){
    try{
        const response = yield call(addCustomer, customer)
        yield put(addCustomerSuccess(response));
    } catch (error) {
        yield put(addCustomerFail(error));
    }
}

function* onUpdateCustomer({payload:customer}){
    try{
        const response = yield call(updateCustomer, customer)
        yield put(updateCustomerSuccess(response));
    } catch (error) {
        yield put(updateCustomerFail(error));
    }
}

export function* customersSaga() {
    yield takeEvery(GET_CUSTOMERS, fetchCustomers);
    yield takeEvery(DELETE_CUSTOMER, onDeleteCustomers);
    yield takeEvery(ADD_CUSTOMER, onAddCustomer);
    yield takeEvery(UPDATE_CUSTOMER, onUpdateCustomer);
    yield takeEvery(GET_CUSTOMER_DETAIL, onGetCustomerById);
}

export default customersSaga;
