import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import MetaTags from "react-meta-tags"
import { useSelector, useDispatch } from "react-redux"
import {
  Card,
  CardBody,
  Col,
  Container,
  Media,
  Row,
 UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap"





const Dashboard = props => {
  let user = JSON.parse(localStorage.getItem("authUser"))

 
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Profile | Skote - React Admin & Dashboard Template</title>
        </MetaTags>
        <Container fluid>

          <Col xl={4}>
            <Card>
              <CardBody>
                <div className="media">
                  <div className="me-3">

                  </div>
                  <div className="media-body">
                    <Media>
                      <div className="media-body">
                        <div className="text-muted">
                          <h5 className="mb-1">{user.userName}</h5>
                          <p className="mb-0">Mithatpaşa Şube</p>
                        </div>
                      </div>

                      <UncontrolledDropdown
                        className="ms-2"
                      >
                        <DropdownToggle
                          className="btn btn-light btn-sm"
                          color="#eff2f7"
                          type="button"
                        >
                          <i className="bx bxs-cog align-middle me-1"></i> Ayarlar
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-end">
                          <Link className="dropdown-item" to="#">
                            Action
                          </Link>
                          <Link className="dropdown-item" to="#">
                            Another action
                          </Link>
                          <Link className="dropdown-item" to="#">
                            Something else
                          </Link>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </Media>

                    <hr />

                    <Row>
                      <Col xl={4}>
                        <div>
                          <p className="text-muted text-truncate mb-2">
                            Ciro
                          </p>
                          <h5 className="mb-0">12.983,00 TL</h5>
                        </div>
                      </Col>
                      <div className="col-4">
                        <div>
                          <p className="text-muted text-truncate mb-2">
                            Adet
                          </p>
                          <h5 className="mb-0">32</h5>
                        </div>
                      </div>
                    </Row>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>

          <Row>
            <Col xl={4}>
              <Card>
                <CardBody>
                  <div className="d-flex flex-wrap">
                    <div className="ms-2">
                      <h5 className="card-title mb-3">Kategori Bazlı Satışlar</h5>
                    </div>
                    <UncontrolledDropdown className="ms-auto">
                      <DropdownToggle
                        className="text-muted font-size-16"
                        color="white"
                      >
                        <i className="mdi mdi-dots-horizontal"></i>
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu-end">
                        <Link className="dropdown-item" to="#">
                          Action
                        </Link>
                        <Link className="dropdown-item" to="#">
                          Another action
                        </Link>
                        <Link className="dropdown-item" to="#">
                          Something else
                        </Link>
                        <div className="dropdown-divider"></div>
                        <Link className="dropdown-item" to="#">
                          Separated link
                        </Link>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>
                  <Row className="text-center">
                    <Col xs={6}>
                      <div className="mt-3">
                        <p className="text-muted mb-1">Toplam Adet</p>
                        <h5>1024</h5>
                      </div>
                    </Col>

                    <Col xs={6}>
                      <div className="mt-3">
                        <p className="text-muted mb-1">Toplam Ciro</p>
                        <h5>
                          12356{" "}
                          <span className="text-success font-size-13">
                            0.2 % <i className="mdi mdi-arrow-up ms-1"></i>
                          </span>
                        </h5>
                      </div>
                    </Col>
                  </Row>

                  <hr />

                  <div>
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item">
                        <div className="py-2">
                          <h5 className="font-size-14">
                            California <span className="float-end">78%</span>
                          </h5>
                          <div className="progress animated-progess progress-sm">
                            <div
                              className="progress-bar"
                              role="progressbar"
                              style={{ width: "78%" }}
                              aria-valuenow="78"
                              aria-valuemin="0"
                              aria-valuemax="100"
                            ></div>
                          </div>
                        </div>
                      </li>
                      <li className="list-group-item">
                        <div className="py-2">
                          <h5 className="font-size-14">
                            Nevada <span className="float-end">69%</span>
                          </h5>
                          <div className="progress animated-progess progress-sm">
                            <div
                              className="progress-bar bg-warning"
                              role="progressbar"
                              style={{ width: "69%" }}
                              aria-valuenow="69"
                              aria-valuemin="0"
                              aria-valuemax="100"
                            ></div>
                          </div>
                        </div>
                      </li>
                      <li className="list-group-item">
                        <div className="py-2">
                          <h5 className="font-size-14">
                            Texas <span className="float-end">61%</span>
                          </h5>
                          <div className="progress animated-progess progress-sm">
                            <div
                              className="progress-bar bg-success"
                              role="progressbar"
                              style={{ width: "61%" }}
                              aria-valuenow="61"
                              aria-valuemin="0"
                              aria-valuemax="100"
                            ></div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl={4}>
              <Card>
                <CardBody>
                  <div className="d-flex flex-wrap">
                    <div className="ms-2">
                      <h5 className="card-title mb-3">Kategori Bazlı Satışlar</h5>
                    </div>
                    <UncontrolledDropdown className="ms-auto">
                      <DropdownToggle
                        className="text-muted font-size-16"
                        color="white"
                      >
                        <i className="mdi mdi-dots-horizontal"></i>
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu-end">
                        <Link className="dropdown-item" to="#">
                          Action
                        </Link>
                        <Link className="dropdown-item" to="#">
                          Another action
                        </Link>
                        <Link className="dropdown-item" to="#">
                          Something else
                        </Link>
                        <div className="dropdown-divider"></div>
                        <Link className="dropdown-item" to="#">
                          Separated link
                        </Link>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>
                  <Row className="text-center">
                    <Col xs={6}>
                      <div className="mt-3">
                        <p className="text-muted mb-1">Toplam Adet</p>
                        <h5>1024</h5>
                      </div>
                    </Col>

                    <Col xs={6}>
                      <div className="mt-3">
                        <p className="text-muted mb-1">Toplam Ciro</p>
                        <h5>
                          12356{" "}
                          <span className="text-success font-size-13">
                            0.2 % <i className="mdi mdi-arrow-up ms-1"></i>
                          </span>
                        </h5>
                      </div>
                    </Col>
                  </Row>

                  <hr />

                  <div>
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item">
                        <div className="py-2">
                          <h5 className="font-size-14">
                            California <span className="float-end">78%</span>
                          </h5>
                          <div className="progress animated-progess progress-sm">
                            <div
                              className="progress-bar"
                              role="progressbar"
                              style={{ width: "78%" }}
                              aria-valuenow="78"
                              aria-valuemin="0"
                              aria-valuemax="100"
                            ></div>
                          </div>
                        </div>
                      </li>
                      <li className="list-group-item">
                        <div className="py-2">
                          <h5 className="font-size-14">
                            Nevada <span className="float-end">69%</span>
                          </h5>
                          <div className="progress animated-progess progress-sm">
                            <div
                              className="progress-bar bg-warning"
                              role="progressbar"
                              style={{ width: "69%" }}
                              aria-valuenow="69"
                              aria-valuemin="0"
                              aria-valuemax="100"
                            ></div>
                          </div>
                        </div>
                      </li>
                      <li className="list-group-item">
                        <div className="py-2">
                          <h5 className="font-size-14">
                            Texas <span className="float-end">61%</span>
                          </h5>
                          <div className="progress animated-progess progress-sm">
                            <div
                              className="progress-bar bg-success"
                              role="progressbar"
                              style={{ width: "61%" }}
                              aria-valuenow="61"
                              aria-valuemin="0"
                              aria-valuemax="100"
                            ></div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl={4}>
              <Card>
                <CardBody>
                  <div className="d-flex flex-wrap">
                    <div className="ms-2">
                      <h5 className="card-title mb-3"> Kategori Bazlı Satışlar</h5>
                    </div>
                    <UncontrolledDropdown className="ms-auto">
                      <DropdownToggle
                        className="text-muted font-size-16"
                        color="white"
                      >
                        <i className="mdi mdi-dots-horizontal"></i>
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu-end">
                        <Link className="dropdown-item" to="#">
                          Action
                        </Link>
                        <Link className="dropdown-item" to="#">
                          Another action
                        </Link>
                        <Link className="dropdown-item" to="#">
                          Something else
                        </Link>
                        <div className="dropdown-divider"></div>
                        <Link className="dropdown-item" to="#">
                          Separated link
                        </Link>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>
                  <Row className="text-center">
                    <Col xs={6}>
                      <div className="mt-3">
                        <p className="text-muted mb-1">Toplam Adet</p>
                        <h5>1024</h5>
                      </div>
                    </Col>

                    <Col xs={6}>
                      <div className="mt-3">
                        <p className="text-muted mb-1">Toplam Ciro</p>
                        <h5>
                          12356{" "}
                          <span className="text-success font-size-13">
                            0.2 % <i className="mdi mdi-arrow-up ms-1"></i>
                          </span>
                        </h5>
                      </div>
                    </Col>
                  </Row>

                  <hr />

                  <div>
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item">
                        <div className="py-2">
                          <h5 className="font-size-14">
                            California <span className="float-end">78%</span>
                          </h5>
                          <div className="progress animated-progess progress-sm">
                            <div
                              className="progress-bar"
                              role="progressbar"
                              style={{ width: "78%" }}
                              aria-valuenow="78"
                              aria-valuemin="0"
                              aria-valuemax="100"
                            ></div>
                          </div>
                        </div>
                      </li>
                      <li className="list-group-item">
                        <div className="py-2">
                          <h5 className="font-size-14">
                            Nevada <span className="float-end">69%</span>
                          </h5>
                          <div className="progress animated-progess progress-sm">
                            <div
                              className="progress-bar bg-warning"
                              role="progressbar"
                              style={{ width: "69%" }}
                              aria-valuenow="69"
                              aria-valuemin="0"
                              aria-valuemax="100"
                            ></div>
                          </div>
                        </div>
                      </li>
                      <li className="list-group-item">
                        <div className="py-2">
                          <h5 className="font-size-14">
                            Texas <span className="float-end">61%</span>
                          </h5>
                          <div className="progress animated-progess progress-sm">
                            <div
                              className="progress-bar bg-success"
                              role="progressbar"
                              style={{ width: "61%" }}
                              aria-valuenow="61"
                              aria-valuemin="0"
                              aria-valuemax="100"
                            ></div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>


    </React.Fragment>
  )
}

export default Dashboard
