import {
    GET_CUSTOMERS,
    GET_CUSTOMERS_SUCCESS,
    GET_CUSTOMERS_FAIL,
    GET_CUSTOMER_DETAIL,
    GET_CUSTOMER_DETAIL_SUCCESS,
    GET_CUSTOMER_DETAIL_FAIL,
    ADD_CUSTOMER,
    ADD_CUSTOMER_SUCCESS,
    ADD_CUSTOMER_FAIL,
    UPDATE_CUSTOMER,
    UPDATE_CUSTOMER_SUCCESS,
    UPDATE_CUSTOMER_FAIL,
    DELETE_CUSTOMER,
    DELETE_CUSTOMER_SUCCESS,
    DELETE_CUSTOMER_FAIL,
    RESET_CUSTOMER
} from "./actionTypes";

const INIT_STATE = {
    data: [],
    error: null,
    customerInfo :[]
};

const Customers = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_CUSTOMERS_SUCCESS:
            return {
                ...state,
                data: action.payload.data
            }
        case GET_CUSTOMER_DETAIL_SUCCESS: 
            return {
                ...state,
                customerInfo:action.payload.data,
            }
        case DELETE_CUSTOMER_SUCCESS:
            return {
                ...state,
                data: state.data.filter(customer => customer._id.toString() !== action.payload.data.toString())
            }
        case ADD_CUSTOMER_SUCCESS:
            return {
                ...state,
                data: [action.payload.data, ...state.data]
            }
        case UPDATE_CUSTOMER_SUCCESS:
            return {
                ...state,
                data: state.data.map(customer =>
                    customer._id.toString() === action.payload.data._id.toString() ?
                        { customer, ...action.payload.data } :
                        customer
                )
            }

        //FAILS
        case GET_CUSTOMERS_FAIL:
        case DELETE_CUSTOMER_FAIL:
        case ADD_CUSTOMER_FAIL:
        case UPDATE_CUSTOMER_FAIL:
        case GET_CUSTOMER_DETAIL_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case RESET_CUSTOMER: return {
            ...state,
            error: null
        }
        default:
            return state;
    }
}


export default Customers;