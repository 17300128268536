import {
    GET_PROCESS_LIST_PAYMENTS,
    GET_PROCESS_LIST_PAYMENTS_SUCCESS,
    GET_PROCESS_LIST_PAYMENTS_FAIL,
    GET_PROCESS_LIST_PAYMENT_DETAIL,
    GET_PROCESS_LIST_PAYMENT_DETAIL_SUCCESS,
    GET_PROCESS_LIST_PAYMENT_DETAIL_FAIL,
    ADD_PROCESS_LIST_PAYMENT,
    ADD_PROCESS_LIST_PAYMENT_SUCCESS,
    ADD_PROCESS_LIST_PAYMENT_FAIL,
    UPDATE_PROCESS_LIST_PAYMENT,
    UPDATE_PROCESS_LIST_PAYMENT_SUCCESS,
    UPDATE_PROCESS_LIST_PAYMENT_FAIL,
    DELETE_PROCESS_LIST_PAYMENT,
    DELETE_PROCESS_LIST_PAYMENT_SUCCESS,
    DELETE_PROCESS_LIST_PAYMENT_FAIL,
    RESET_PROCESS_LIST_PAYMENT
} from "./actionTypes";

export const resetProcessListPayment = () => ({
    type: RESET_PROCESS_LIST_PAYMENT,
});

export const getProcessListPayments = () => ({
    type: GET_PROCESS_LIST_PAYMENTS,
});

export const getProcessListPaymentsSuccess = (processListPayments) => ({
    type: GET_PROCESS_LIST_PAYMENTS_SUCCESS,
    payload: processListPayments,
});


export const getProcessListPaymentsFail = (error) => ({
    type: GET_PROCESS_LIST_PAYMENTS_FAIL,
    payload: error,
});

export const deleteProcessListPayment = (id) => ({
    type:  DELETE_PROCESS_LIST_PAYMENT,
    payload: id
});

export const deleteProcessListPaymentSuccess = (data) => ({
    type: DELETE_PROCESS_LIST_PAYMENT_SUCCESS,
    payload: data
});

export const deleteProcessListPaymentFail = (error) => ({
    type: DELETE_PROCESS_LIST_PAYMENT_FAIL,
    payload: error,
});


export const addProcessListPayment = (processListPayment) => ({
    type: ADD_PROCESS_LIST_PAYMENT,
    payload: processListPayment
});

export const addProcessListPaymentSuccess = (data) => ({
    type: ADD_PROCESS_LIST_PAYMENT_SUCCESS,
    payload: data
});

export const addProcessListPaymentFail = (error) => ({
    type: ADD_PROCESS_LIST_PAYMENT_FAIL,
    payload: error,
});


export const updateProcessListPayment = (processListPayment) => ({
    type: UPDATE_PROCESS_LIST_PAYMENT,
    payload: processListPayment
});

export const updateProcessListPaymentSuccess = (data) => ({
    type: UPDATE_PROCESS_LIST_PAYMENT_SUCCESS,
    payload: data
});

export const updateProcessListPaymentFail = (error) => ({
    type: UPDATE_PROCESS_LIST_PAYMENT_FAIL,
    payload: error,
});