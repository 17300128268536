import {
    GET_SUBCATEGORIES,
    GET_SUBCATEGORIES_SUCCESS,
    GET_SUBCATEGORIES_FAIL,
    GET_SUBCATEGORY_DETAIL,
    GET_SUBCATEGORY_DETAIL_SUCCESS,
    GET_SUBCATEGORY_DETAIL_FAIL,
    ADD_SUBCATEGORY,
    ADD_SUBCATEGORY_SUCCESS,
    ADD_SUBCATEGORY_FAIL,
    UPDATE_SUBCATEGORY,
    UPDATE_SUBCATEGORY_SUCCESS,
    UPDATE_SUBCATEGORY_FAIL,
    DELETE_SUBCATEGORY,
    DELETE_SUBCATEGORY_SUCCESS,
    DELETE_SUBCATEGORY_FAIL,
    RESET_SUBCATEGORY
} from "./actionTypes";

const INIT_STATE = {
    data: [],
    error: null
};

const SubCategories = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_SUBCATEGORIES_SUCCESS:
            return {
                ...state,
                data: action.payload.data
            }
        case DELETE_SUBCATEGORY_SUCCESS:
            return {
                ...state,
                data: state.data.filter(payments => payments._id.toString() !== action.payload.data.toString())
            }
        case ADD_SUBCATEGORY_SUCCESS:
            return {
                ...state,
                data: [action.payload.data, ...state.data]
            }
        case UPDATE_SUBCATEGORY_SUCCESS:
            return {
                ...state,
                data: state.data.map(payments =>
                    payments._id.toString() === action.payload.data._id.toString() ?
                        { payments, ...action.payload.data } :
                        payments
                )
            }

        //FAILS
        case GET_SUBCATEGORIES_FAIL:
        case DELETE_SUBCATEGORY_FAIL:
        case ADD_SUBCATEGORY_FAIL:
        case UPDATE_SUBCATEGORY_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case RESET_SUBCATEGORY: return {
            ...state,
            error: null
        }
        default:
            return state;
    }
}


export default SubCategories;