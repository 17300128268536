import { takeEvery, fork, put, all, call } from "redux-saga/effects"

// Login Redux States
import { CHANGE_SELECTED_BRANCH,GET_USER_DETAIL } from "./actionTypes"
import {  changeSelectedBranchSuccess,changeSelectedBranchFail,
  getUserByIdSuccess,getUserByIdFail

} from "./actions"
 
import {
  changeSelectedBranch,getUserById
} from "../../../helpers/fakebackend_helper"
 
function* onChangeSelectedBranch({payload:branch}){
  try{
      const response = yield call(changeSelectedBranch, branch)
      yield put(changeSelectedBranchSuccess(response));
  } catch (error) {
      yield put(changeSelectedBranchFail(error));
  }
}
function* onGetUserById({ payload: id }) {
  try { 
    const response = yield call(getUserById, id)
    yield put(getUserByIdSuccess(response))
  } catch (error) {
    yield put(getUserByIdFail(error))
  }
}

function* changePasswordSaga() {
  yield takeEvery(CHANGE_SELECTED_BRANCH, onChangeSelectedBranch);
  yield takeEvery(GET_USER_DETAIL, onGetUserById);
}

export default changePasswordSaga
