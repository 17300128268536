import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { Row, Col, Collapse } from "reactstrap"
import { Link, withRouter } from "react-router-dom"
import classname from "classnames"

//i18n
import { withTranslation } from "react-i18next"

import { connect } from "react-redux"
import {authorize, Role} from "./../helpers/authorize" 

const Navbar = props => {

  const [dashboard, setdashboard] = useState(false)
  const [adminPanel, setadminPanel] = useState(false)
  const [component, setcomponent] = useState(false)
  let user = JSON.parse(localStorage.getItem("authUser"))
 

  return (
    <React.Fragment>
      <Row>
      <div className="topnav">
        <div className="container-fluid">
          <nav
            className="navbar navbar-light navbar-expand-lg topnav-menu"
            id="navigation"
          >
            <Collapse
              isOpen={props.leftMenu}
              className="navbar-collapse"
              id="topnav-menu-content"
            >
              <ul className="navbar-nav">

              <li className="nav-item dropdown">
                  <Link
                    to="/ProcessList"
                    className="nav-link dropdown-toggle arrow-none"
                  >
                    <i className="bx bx-layer"></i>
                    {props.t("HomePage")}
                  </Link>
                </li>

                {/* <li className="nav-item dropdown">
                  <Link
                    to="/dashboard"
                    className="nav-link dropdown-toggle arrow-none"
                  >
                    <i className="bx bx-home-circle"></i>
                    {props.t("Details")}
                  </Link>
                </li> */}

                
            {authorize([Role.Dev,Role.Admin]) ? (
                <li className="nav-item dropdown">
                  <div  className="nav-link dropdown-toggle arrow-none">
                   
                    <i className="bx bx-file"></i>
                    {props.t("Definitions")} {props.menuOpen}
                    <div className="arrow-down"></div>
                    </div>
                  <div
                    className={classname("dropdown-menu", { show: dashboard })}
                  >
                    
                    <Link to="/bankAccounts" className="dropdown-item">
                      {props.t("Bank Accounts")}
                    </Link>
                    <Link to="/posMachines" className="dropdown-item">
                      {props.t("Pos Machines")}
                    </Link>
                    
                    <Link to="/mainCategories" className="dropdown-item">
                      {props.t("Expenses Categories")}
                    </Link>
                    <Link to="/productCategories" className="dropdown-item">
                      {props.t("Product Categories")}
                    </Link>
                    <Link to="/paymentPlans" className="dropdown-item">
                          {props.t("Payment Plan")}
                        </Link>
                  
                   
                    
                   

                  </div>


                </li> ):null}
                {authorize([Role.Dev]) ? (<Link to="/companies" className="dropdown-item">
                      {props.t("Companies")}
                    </Link> ):null}
                {authorize([Role.Dev, Role.Admin]) ? (
                <li className="nav-item dropdown">
                 
                 <div  className="nav-link dropdown-toggle arrow-none">
                   
                   
                    <i className="bx bx-file"></i>
                    {props.t("Admin Panel")} {props.menuOpen}
                    <div className="arrow-down"></div>
                    </div>
                  <div
                    className={classname("dropdown-menu", { show: adminPanel })}
                  >
                    {/* <Link to="/companies" className="dropdown-item">
                      {props.t("Companies")}
                    </Link>   */}
                    <Link to="/erps" className="dropdown-item">
                      {props.t("Erp")}
                    </Link>
                    <Link to="/customers" className="dropdown-item">
                      {props.t("Customers")}
                    </Link>
                    <Link to="/products" className="dropdown-item">
                      {props.t("Products")}
                    </Link>
                    {/* <Link to="/branchs" className="dropdown-item">
                      {props.t("Branchs")} 
                    </Link> */}
                   
                   
                    
                    
                   
                    
                 

                    {/* <div className="dropdown">
                      <Link
                        to="/#"
                        className="dropdown-item dropdown-toggle arrow-none"
                        onClick={e => {
                          e.preventDefault()
                         
                        }}
                      >
                        {props.t("Products")} <div className="arrow-down"></div>
                      </Link>
                      <div
                        className={classname("dropdown-menu", { show: crypto })}
                      >
                        <Link to="/products" className="dropdown-item">
                          {props.t("Products")}
                        </Link>
                        <Link to="/productsSeries" className="dropdown-item">
                          {props.t("Product Series")}
                        </Link>
                        
                       
                        
                        
                      </div>
                     
                    
                    </div> */}

                  </div>
                </li>):null}
                {authorize([Role.Admin]) ? (
                      <li className="nav-item dropdown">
                  <Link
                    to="/#"
                    className="nav-link dropdown-toggle arrow-none"
                    onClick={e => {
                      e.preventDefault()
                      setcomponent(!component)
                    }}
                  >
                    <i className="bx bx-collection me-2"></i>
                    {props.t("Company")} <div className="arrow-down"></div>
                  </Link>
                  <div
                    className={classname("dropdown-menu", { show: component })}
                  >
                    <div className="dropdown">
                    <Link to={"/companies/users/" + user.companyId} className="dropdown-item">
                          {props.t("Define User")}
                        </Link>
                      <div
                       
                      >
                       
                      </div>
                    </div>
                    <div className="dropdown">
                    <Link to="/branchs" className="dropdown-item">
                          {props.t("Branchs")}
                        </Link>
                     
                    </div>
                  
                  </div>
                </li>) :
                    null}
                    {authorize([Role.Admin]) ? (
                      <li className="nav-item dropdown">
                  <Link
                    to="/#"
                    className="nav-link dropdown-toggle arrow-none"
                    onClick={e => {
                      e.preventDefault()
                      setcomponent(!component)
                    }}
                  >
                    <i className="bx bx-collection me-2"></i>
                    {props.t("Reports")} <div className="arrow-down"></div> 
                  </Link>
                  <div
                    className={classname("dropdown-menu", { show: component })}
                  >
                    <div className="dropdown">
                    <Link to="/collections" className="dropdown-item">
                      {props.t("Collection")} 
                    </Link>
                      <div
                       
                      >
                       
                      </div>
                    </div>
                    <div className="dropdown">
                    <Link to="/productSales" className="dropdown-item">
                          {props.t("Product Sales")}
                        </Link>
                     
                    </div>
                    <div className="dropdown">
                    <Link to="/endOfDay" className="dropdown-item">
                      {props.t("End Of Day Balancing")}  
                    </Link>
                    </div>
                    <div className="dropdown">
                    <Link to="/expenses" className="dropdown-item">
                      {props.t("Expenses")}  
                    </Link>
                    </div>
                  </div>
                </li>) :
                    null}
               
               


              </ul>
            </Collapse>
          </nav>
        </div>
      </div>
      </Row>
    </React.Fragment>
  )
}

Navbar.propTypes = {
  leftMenu: PropTypes.any,
  location: PropTypes.any,
  menuOpen: PropTypes.any,
  t: PropTypes.any,
}

const mapStatetoProps = state => {
  const { leftMenu } = state.Layout
  return { leftMenu }
}

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(Navbar))
)
