import React from "react"

import PropTypes from 'prop-types'
import { Container, Row, Col ,Card,CardBody} from "reactstrap" 

 
const CashCard = props => {
  return ( 
    <React.Fragment>
       <Card>
                <CardBody>
                  <div className="d-flex align-items-center mb-3">
                    <div className="avatar-xs me-3">
                      <span className="avatar-title rounded-circle bg-primary bg-soft text-primary font-size-18">
                        <i className="bx bx-archive-in" />
                      </span>
                    </div>
                    <h5 className="font-size-14 mb-0">
                    {props.title}
                     
                    </h5>
                  </div>
                  <div className="text-muted mt-4">
                    <div className="text-end">
                      <span
                        className={"badge badge-soft-" + " font-size-12"}
                      ></span>{" "}
                      <span className="ms-2 text-truncate">{props.price}</span>
                    </div>
                  </div>
                </CardBody>
              </Card>
    </React.Fragment>
  )
} 


CashCard.propTypes = {
  props: PropTypes.func,
  title: PropTypes.string,
  price: PropTypes.string
}


export default CashCard;
