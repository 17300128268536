/** Get Companies */
export const GET_COMPANIES = "GET_COMPANIES";
export const GET_COMPANIES_SUCCESS = "GET_COMPANIES_SUCCESS";
export const GET_COMPANIES_FAIL = "GET_COMPANIES_FAIL";

export const GET_COMPANY_DETAIL = "GET_COMPANY_DETAIL";
export const GET_COMPANY_DETAIL_SUCCESS = "GET_COMPANY_DETAIL_SUCCESS";
export const GET_COMPANY_DETAIL_FAIL = "GET_COMPANY_DETAIL_FAIL";


export const ADD_COMPANY = "ADD_COMPANY";
export const ADD_COMPANY_SUCCESS = "ADD_COMPANY_SUCCESS";
export const ADD_COMPANY_FAIL = "ADD_COMPANY_FAIL";

export const UPDATE_COMPANY = "UPDATE_COMPANY";
export const UPDATE_COMPANY_SUCCESS = "UPDATE_COMPANY_SUCCESS";
export const UPDATE_COMPANY_FAIL = "UPDATE_COMPANY_FAIL";



export const DELETE_COMPANY = "DELETE_COMPANY";
export const DELETE_COMPANY_SUCCESS = "DELETE_COMPANY_SUCCESS";
export const DELETE_COMPANY_FAIL = "DELETE_COMPANY_FAIL";

export const RESET_COMPANY = "RESET_COMPANY";



export const GET_COMPANY_USERS = "GET_COMPANY_USERS";
export const GET_COMPANY_USERS_SUCCESS = "GET_COMPANY_USERS_SUCCESS";
export const GET_COMPANY_USERS_FAIL = "GET_COMPANY_USERS_FAIL";
export const ADD_COMPANY_USER = "ADD_COMPANY_USER";
export const ADD_COMPANY_USER_SUCCESS = "ADD_COMPANY_USER_SUCCESS";
export const ADD_COMPANY_USER_FAIL = "ADD_COMPANY_USER_FAIL";
export const UPDATE_COMPANY_USER = "UPDATE_COMPANY_USER";
export const UPDATE_COMPANY_USER_SUCCESS = "UPDATE_COMPANY_USER_SUCCESS";
export const UPDATE_COMPANY_USER_FAIL = "UPDATE_COMPANY_USER_FAIL";
export const DELETE_COMPANY_USER = "DELETE_COMPANY_USER";
export const DELETE_COMPANY_USER_SUCCESS = "DELETE_COMPANY_USER_SUCCESS";
export const DELETE_COMPANY_USER_FAIL = "DELETE_COMPANY_USER_FAIL";



export const GET_ROLES = "GET_ROLES";
export const GET_ROLES_SUCCESS = "GET_ROLES_SUCCESS";
export const GET_ROLES_FAIL = "GET_ROLES_FAIL";

export const GET_USER_ROLES = "GET_USER_ROLES";
export const GET_USER_ROLES_SUCCESS = "GET_USER_ROLES_SUCCESS";
export const GET_USER_ROLES_FAIL = "GET_USER_ROLES_FAIL";


export const ADD_USER_ROLES = "ADD_USER_ROLES";
export const ADD_USER_ROLES_SUCCESS = "ADD_USER_ROLES_SUCCESS";
export const ADD_USER_ROLES_FAIL = "ADD_USER_ROLES_FAIL";

export const LOADING_ROLE = "LOADING_ROLE"; 
export const COMPANY_SUCCESS_STATUS = "COMPANY_SUCCESS_STATUS";