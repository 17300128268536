import {
    GET_PRODUCTSALES,
    GET_PRODUCTSALES_SUCCESS,
    GET_PRODUCTSALES_FAIL,
    GET_PRODUCTSALE_DETAIL,
    GET_PRODUCTSALE_DETAIL_SUCCESS,
    GET_PRODUCTSALE_DETAIL_FAIL,
    ADD_PRODUCTSALE,
    ADD_PRODUCTSALE_SUCCESS,
    ADD_PRODUCTSALE_FAIL,
    UPDATE_PRODUCTSALE,
    UPDATE_PRODUCTSALE_SUCCESS,
    UPDATE_PRODUCTSALE_FAIL,
    DELETE_PRODUCTSALE,
    DELETE_PRODUCTSALE_SUCCESS,
    DELETE_PRODUCTSALE_FAIL,
    RESET_PRODUCTSALE
} from "./actionTypes";

const INIT_STATE = {
    data: [],
    error: null
};

const ProductSales = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_PRODUCTSALES_SUCCESS:
            return {
                ...state,
                data: action.payload.data
            }
        case DELETE_PRODUCTSALE_SUCCESS:
            return {
                ...state,
                data: state.data.filter(productSales => productSales._id.toString() !== action.payload.data.toString())
            }
        case ADD_PRODUCTSALE_SUCCESS:
            return {
                ...state,
                data: [action.payload.data, ...state.data]
            }
        case UPDATE_PRODUCTSALE_SUCCESS:
            return {
                ...state,
                data: state.data.map(productSales =>
                    productSales._id.toString() === action.payload.data._id.toString() ?
                        { productSales, ...action.payload.data } :
                        productSales
                )
            }

        //FAILS
        case GET_PRODUCTSALE_DETAIL_FAIL:
        case DELETE_PRODUCTSALE_FAIL:
        case ADD_PRODUCTSALE_FAIL:
        case UPDATE_PRODUCTSALE_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case RESET_PRODUCTSALE: return {
            ...state,
            error: null
        }
        default:
            return state;
    }
}


export default ProductSales;