import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useState, useEffect } from "react"
import {
    Container,
    Row,
    Col,
    Card,
    Alert,
    CardBody,
    Media,
    Button,
    UncontrolledDropdown,
    UncontrolledTooltip,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Modal,
    ModalHeader,
    ModalBody, 
} from "reactstrap"
//redux
import { useSelector, useDispatch } from "react-redux"

import { withRouter } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"
 
import {

    resetEndOfDayBalancing as onResetEndOfDayBalancing,
    deleteEndOfDayBalancing as onDeleteEndOfDayBalancing,
    addEndOfDayBalancing as OnAddEndOfDayBalancing,
    getEndOfDayBalancings as onGetEndOfDayBalancings,
    updateEndOfDayBalancing as onUpdateEndOfDayBalancing
} from "store/actions"

import { isEmpty, map } from "lodash"
import moment from 'moment'
import { AvForm, AvField } from "availity-reactstrap-validation"

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
    PaginationProvider, PaginationListStandalone,
    SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert"

import "./datatables.scss"

const EndOfDay = props => {
    const dispatch = useDispatch()

    const [selectedId, setSelectedId] = useState();
    const [selectedEndOfDay, setSelectedEndOfDay] = useState();
    const [success_dlg, setsuccess_dlg] = useState(false);
    const [confirm_alert, setconfirm_alert] = useState(false);
    const [dynamic_title, setdynamic_title] = useState("");
    const [dynamic_description, setdynamic_description] = useState("");
    const [modal, setModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);


    const { error,EndOfDay } = useSelector(state => ({
        error: state.EndOfDayBalancing?.error,
         EndOfDay : state.EndOfDayBalancing?.data

    }))
   
    useEffect(() => {
       
        dispatch(onGetEndOfDayBalancings());
        
    }, [dispatch]);

    const toggle = () => {
        setModal(!modal)
    }

    const columns = [
        {
            dataField: 'createdAt',
            text: props.t("Created At"),
            sort: true,
            formatter: (cell, row) => (
                <span>{handleValidDate(cell)}</span>
            )
            
        },
        {
            dataField: 'description',
            text: props.t("Description"),
            sort: true
            
        }, {
            dataField: 'profitLoss',
            text: props.t("ProfitLoss"),
            sort: true
        },
        

        {
            dataField: '_id',
            text: props.t("Action"),
            sort: false,
            formatter: (cell, row) => (
                <UncontrolledDropdown>
                    <DropdownToggle href="#" className="card-drop" tag="i">
                        <i className="mdi mdi-dots-horizontal font-size-18"></i>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-end">
                        <DropdownItem href="#"
                            onClick={() => {
                                setSelectedEndOfDay(row);
                                setIsEdit(true);
                                toggle();
                            }}
                        >
                            <i className="mdi mdi-pencil font-size-16 text-success me-1"></i>
                            {props.t("Edit")}
                        </DropdownItem>
                        <DropdownItem href="#"
                            onClick={() => {
                                setSelectedId(cell);
                                setconfirm_alert(true);
                            }}>
                            <i className="mdi mdi-trash-can font-size-16 text-danger me-1"></i>
                            {props.t("Delete")}
                        </DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown>
            )
        }];

    const defaultSorted = [{
        dataField: 'createdAt',
        order: 'desc'
    }];

    const pageOptions = {
        sizePerPage: 10,
        totalSize: EndOfDay ? EndOfDay.length : 0, // replace later with size(customers),
        custom: true,
    }

    // Custom Pagination Toggle
    const sizePerPageList = [
        { text: '5', value: 5 },
        { text: '10', value: 10 },
        { text: '15', value: 15 },
        { text: '20', value: 20 },
        { text: '25', value: 25 },
        { text: 'All', value: EndOfDay ? EndOfDay.length : 0 }];


    // Select All Button operation
    const selectRow = {
        mode: 'checkbox'
    }
    const handleValidDate = date => {
        if (date) {
            const date1 = moment(new Date(date)).format("DD.MM.YYYY");
            return date1;
        } else {
            return "";
        }
    }
    const handleValidDateAvField = date => {
        if (date) {
            const date1 = moment(new Date(date)).format("YYYY-MM-DD");
            return date1;
        } else {
            return "";
        }
    }
    const { SearchBar } = Search;
    const Cancel = (e, values) => {
      
        toggle()
    }
    const handleValidErpSubmit = (e, values) => {
        if (isEdit) {
            const endOfDay = {
                _id: selectedEndOfDay._id,
                profitLoss: values.endOfDay,
                description: values["description"],
                
            };
            // update Erp
            dispatch(onUpdateEndOfDayBalancing(endOfDay))
        } else {
            const endOfDay = {
                profitLoss: values.endOfDay,
                description: values["description"],
              }

              dispatch(OnAddEndOfDayBalancing(endOfDay))
        }
        toggle()
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags> 
                    <title>{props.t("End Of Day Balancing")}</title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumb title={props.t("End Of Day Balancing")} breadcrumbItem={props.t("End Of Day Balancing")}  addBtnCallBack={() => {
                        setIsEdit(false);
                        setSelectedEndOfDay(
                            {
                                endOfDay: "",
                                description: "" ,
                                
                            }
                        );
                        toggle();

                    }} />

                    {/* {error && error ? <Alert color="danger">{error}</Alert> : null} */}

                    <Card>
                        <CardBody>
                            <PaginationProvider
                                pagination={paginationFactory(pageOptions)}
                                keyField='id'
                                columns={columns}
                                data={EndOfDay}
                            >
                                {({ paginationProps, paginationTableProps }) => (
                                    <ToolkitProvider
                                        keyField='_id'
                                        columns={columns}
                                        data={EndOfDay}
                                        search
                                    >
                                        {toolkitProps => (
                                            <React.Fragment>

                                                <Row className="mb-2">
                                                    <Col md="4">
                                                        <div className="search-box me-2 mb-2 d-inline-block">
                                                            <div className="position-relative">
                                                                <SearchBar
                                                                    {...toolkitProps.searchProps}
                                                                />
                                                                <i className="bx bx-search-alt search-icon" />
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col xl="12">
                                                        <div className="table-responsive">
                                                            <BootstrapTable
                                                                keyField={"_id"}
                                                                responsive
                                                                bordered={false}
                                                                striped={false}
                                                                defaultSorted={defaultSorted}
                                                                classes={
                                                                    "table align-middle table-nowrap"
                                                                }
                                                                headerWrapperClasses={"thead-light"}
                                                                {...toolkitProps.baseProps}
                                                                {...paginationTableProps}
                                                            />

                                                        </div>
                                                    </Col>
                                                </Row>

                                                <Row className="align-items-md-center mt-30">
                                                    <Col className="inner-custom-pagination d-flex">
                                                        <div className="d-inline">
                                                            <SizePerPageDropdownStandalone
                                                                {...paginationProps}
                                                            />
                                                        </div>
                                                        <div className="text-md-right ms-auto">
                                                            <PaginationListStandalone
                                                                {...paginationProps}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </React.Fragment>
                                        )
                                        }
                                    </ToolkitProvider>
                                )
                                }</PaginationProvider>

                            <Modal isOpen={modal} toggle={toggle}>
                                <ModalHeader toggle={toggle} tag="h4">
                                    {!!isEdit ? props.t("Edit End Of Day Balancing") : props.t("Add End Of Day Balancing")} 
                                </ModalHeader>
                                <ModalBody>
                                    <AvForm onValidSubmit={handleValidErpSubmit}>
                                        <Row form>
                                            <Col xs={12}>

                                                <div className="mb-3">
                                                    <AvField
                                                        name="endOfDay"
                                                        label={props.t("End Of Day Balancing")}
                                                        type="Number"
                                                        errorMessage="Invalid name"
                                                        validate={{ 
                                                            required: { value: true },
                                                        }}
                                                        value={selectedEndOfDay?.profitLoss || ""}
                                                    />
                                                </div>

                                                <div className="mb-3">
                                                    <AvField
                                                        name="description"
                                                        label={props.t("Description")}
                                                        type="textarea"
                                                        errorMessage="Invalid Description"
                                                        validate={{
                                                            required: { value: false },
                                                        }}
                                                        value={selectedEndOfDay?.description || ""}
                                                    />
                                                </div>
                                               
                                                

                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                           
                                            <div className="text-end">
                                            <button 
                                                type="submit"
                                                className="btn btn-success save-user"
                                            >
                                                {props.t("Save")}
                                            </button>
                                            &nbsp;&nbsp;
                                            <Button onClick={()=>{ toggle() }}>
                                                    {props.t("Cancel")}
                                                    </Button>       
                                            
                                        </div>
                                                
                                            </Col>
                                        </Row>
                                    </AvForm>
                                                        

                                </ModalBody>
                            </Modal>
                        </CardBody>
                    </Card>

                    {success_dlg ? (
                        <SweetAlert
                            success
                            title={dynamic_title}
                            confirmBtnText={props.t("Ok")}
                            onConfirm={() => {
                                setsuccess_dlg(false)
                            }}
                        >
                            {dynamic_description}
                        </SweetAlert>
                    ) : null}

                    {confirm_alert ? (
                        <SweetAlert
                            title={props.t("Are You Sure")}
                            warning
                            showCancel
                            confirmBtnBsStyle="success"
                            cancelBtnBsStyle="danger"
                            cancelBtnText={props.t("Cancel")}
                            confirmBtnText={props.t("Delete")}
                            onConfirm={() => {
                                setconfirm_alert(false);
                                setsuccess_dlg(true);
                                setdynamic_title(props.t("Deleted"));
                                setdynamic_description("");
                                dispatch(onDeleteEndOfDayBalancing(selectedId));
                                setSelectedId(null);
                            }}
                            onCancel={() => setconfirm_alert(false)}
                        >
                            {props.t("DeleteWarning")}
                        </SweetAlert>
                    ) : null}


                    {error && error ? (
                        <SweetAlert
                            title={props.t("Error")}
                            warning
                            confirmBtnText={props.t("Ok")}
                            onConfirm={() => {
                                dispatch(onResetEndOfDayBalancing())
                            }}
                        >
                            {JSON.stringify(error)}
                        </SweetAlert>
                    ) : null}

                </Container>
            </div>
        </React.Fragment>
    )
}

export default withRouter(withTranslation()(EndOfDay))
