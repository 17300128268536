import React from "react"

import PropTypes from 'prop-types'
import { Container, Row, Col ,Card,CardBody} from "reactstrap" 
import paginationFactory, {
  PaginationProvider,

} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import { withRouter } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"
const Table = props => {
  const pageOptions = {
    sizePerPage: 10,
    custom: true,
  } 
  const defaultSorted = [
    {
      dataField: "createdAt",
      order: "desc",
    },
  ]
  return ( 
    <React.Fragment>
        <Card>
                <CardBody>
                  <h5 className="card-title">{props.title}</h5>
                  <hr />
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="_id"
                    columns={props.columns}
                    data={props.data}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="_id"
                        columns={props.columns}
                        data={props.data}
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField={"_id"}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    noDataIndication={props.t("You Don't Have a Data Yet")}
                                    defaultSorted={defaultSorted}
                                    classes={"table align-middle table-nowrap"}
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
    </React.Fragment>
  )
} 


Table.propTypes = {
  props: PropTypes.func,
  title: PropTypes.string,
  columns: PropTypes.array,
  data : PropTypes.array,
}
export default withRouter(withTranslation()(Table))


