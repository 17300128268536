import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useState, useEffect } from "react"
import {
    Container,
    Row,
    Col,
    Card,
    Alert,
    CardBody,
    Media,
    Button,
    UncontrolledDropdown,
    UncontrolledTooltip,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Modal,
    ModalHeader,
    ModalBody,
    Label,
} from "reactstrap"
//redux
import { useSelector, useDispatch } from "react-redux"

import { withRouter } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"

import {
    getMainCategories as onGetMainCategories,
    deleteMainCategory as onDeleteMainCategory,
    addMainCategory as onAddMainCategory,
    updateMainCategory as onUpdateMainCategory,
    resetMainCategory as onResetMainCategory
} from "store/actions"

import { isEmpty, map } from "lodash"

import { AvForm, AvField, AvGroup, AvInput, AvFeedback, AvRadioGroup, AvRadio, AvCheckboxGroup, AvCheckbox } from 'availity-reactstrap-validation';

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
    PaginationProvider, PaginationListStandalone,
    SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert"

import "./datatables.scss"

const MainCategories = props => {
    const dispatch = useDispatch()

    const [selectedId, setSelectedId] = useState();
    const [selectedMainCategory, setSelectedMainCategory] = useState();
    const [success_dlg, setsuccess_dlg] = useState(false);
    const [confirm_alert, setconfirm_alert] = useState(false);
    const [dynamic_title, setdynamic_title] = useState("");
    const [dynamic_description, setdynamic_description] = useState("");
    const [modal, setModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);


    const { error, MainCategories } = useSelector(state => ({
        error: state.MainCategories?.error,
        MainCategories: state.MainCategories?.data,
    }))

    useEffect(() => {
        dispatch(onGetMainCategories());
    }, [dispatch]);

    const toggle = () => {
        setModal(!modal)
    }

    const columns = [
        {
            dataField: 'category',
            text: props.t("MainCategory"),
            sort: true,
            
             
        },  
       

        {
            dataField: '_id',
            text: props.t("Action"),
            sort: false,
            formatter: (cell, row) => (
                <UncontrolledDropdown>
                    <DropdownToggle href="#" className="card-drop" tag="i">
                        <i className="mdi mdi-dots-horizontal font-size-18"></i>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-end">
                        <DropdownItem href="#"
                            onClick={() => {
                                setSelectedMainCategory(row);
                                setIsEdit(true);
                                toggle();
                            }}
                        >
                            <i className="mdi mdi-pencil font-size-16 text-success me-1"></i>
                            {props.t("Edit")}
                        </DropdownItem>
                        <DropdownItem href="#"
                            onClick={() => {
                                setSelectedId(cell);
                                setconfirm_alert(true);
                            }}>
                            <i className="mdi mdi-trash-can font-size-16 text-danger me-1"></i>
                            {props.t("Delete")}
                        </DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown>
            )
        }];

    const defaultSorted = [{
        dataField: 'createdAt',
        order: 'desc'
    }];

    const pageOptions = {
        sizePerPage: 10,
        totalSize: MainCategories ? MainCategories.length : 0, // replace later with size(MainCategory),
        custom: true,
    }

    // Custom Pagination Toggle
    const sizePerPageList = [
        { text: '5', value: 5 },
        { text: '10', value: 10 },
        { text: '15', value: 15 },
        { text: '20', value: 20 },
        { text: '25', value: 25 },
        { text: 'All', value: MainCategories ? MainCategories.length : 0 }];


    // Select All Button operation
    const selectRow = {
        mode: 'checkbox'
    }
    
    const { SearchBar } = Search;

    const handleValidMainCategorySubmit = (e, values) => {
        if (isEdit) {
            const updateMainCategory = {
                _id: selectedMainCategory._id,
                category: values.category,
                
                
                
            };
            // update MainCategory
            dispatch(onUpdateMainCategory(updateMainCategory));
        } else {
            const newMainCategory = {
                category: values["category"],
               
               
            }
            // save new MainCategory
            dispatch(onAddMainCategory(newMainCategory))
        }
        toggle()
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>{props.t("MainCategory")}</title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumb title={props.t("MainCategory")} breadcrumbItem={props.t("MainCategory")} addBtn={true} addBtnCallBack={() => {
                        setIsEdit(false);
                        setSelectedMainCategory(
                            {
                                category: "",
                              
                               
                               
                            }
                        );
                        toggle();

                    }} />

                    {/* {error && error ? <Alert color="danger">{error}</Alert> : null} */}

                    <Card>
                        <CardBody>
                            <PaginationProvider
                                pagination={paginationFactory(pageOptions)}
                                keyField='id'
                                columns={columns}
                                data={MainCategories}
                            >
                                {({ paginationProps, paginationTableProps }) => (
                                    <ToolkitProvider
                                        keyField='_id'
                                        columns={columns}
                                        data={MainCategories}
                                        search
                                    >
                                        {toolkitProps => (
                                            <React.Fragment>

                                                <Row className="mb-2">
                                                    <Col md="4">
                                                        <div className="search-box me-2 mb-2 d-inline-block">
                                                            <div className="position-relative">
                                                                <SearchBar
                                                                    {...toolkitProps.searchProps}
                                                                />
                                                                <i className="bx bx-search-alt search-icon" />
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col xl="12">
                                                        <div className="table-responsive">
                                                            <BootstrapTable
                                                                keyField={"_id"}
                                                                responsive
                                                                bordered={false}
                                                                striped={false}
                                                                defaultSorted={defaultSorted}
                                                                classes={
                                                                    "table align-middle table-nowrap"
                                                                }
                                                                headerWrapperClasses={"thead-light"}
                                                                {...toolkitProps.baseProps}
                                                                {...paginationTableProps}
                                                            />

                                                        </div>
                                                    </Col>
                                                </Row>

                                                <Row className="align-items-md-center mt-30">
                                                    <Col className="inner-custom-pagination d-flex">
                                                        <div className="d-inline">
                                                            <SizePerPageDropdownStandalone
                                                                {...paginationProps}
                                                            />
                                                        </div>
                                                        <div className="text-md-right ms-auto">
                                                            <PaginationListStandalone
                                                                {...paginationProps}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </React.Fragment>
                                        )
                                        }
                                    </ToolkitProvider>
                                )
                                }</PaginationProvider>

                            <Modal isOpen={modal} toggle={toggle}>
                                <ModalHeader toggle={toggle} tag="h4">
                                    {!!isEdit ? props.t("Gider / Harcama / Çıkış için Ana Kategori Düzenle") : props.t("Gider / Harcama / Çıkış için Ana Kategori")}
                                    <h4>
                                        <small>Gider ,Harcama ve Çıkış Yöntemleri Oluştur</small>
                                    </h4>
                                </ModalHeader>
                                <ModalBody>
                                    <AvForm onValidSubmit={handleValidMainCategorySubmit}>
                                        <Row form>
                                            <Col xs={12}>

                                           
                                            

                                        
                                                <div className="mb-3">
                                                    <AvField
                                                        name="category"
                                                        label={props.t("MainCategory")}
                                                        type="text"
                                                        errorMessage={props.t("category")}
                                                        validate={{
                                                            required: { value: false },
                                                        }}
                                                        value={selectedMainCategory?.category || ""}
                                                    />
                                                </div>
                                               
                                                

                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                            <div className="text-end">
                                            <button 
                                                type="submit"
                                                className="btn btn-success save-user"
                                            >
                                                {props.t("Save")}
                                            </button>
                                            &nbsp;&nbsp;
                                            <Button onClick={()=>{ toggle() }}>
                                                    {props.t("Cancel")}
                                                    </Button>       
                                            
                                        </div>
                                            </Col>
                                        </Row>
                                    </AvForm>
                                </ModalBody>
                            </Modal>
                        </CardBody>
                    </Card>

                    {success_dlg ? (
                        <SweetAlert
                            success
                            title={dynamic_title}
                            confirmBtnText={props.t("Ok")}
                            onConfirm={() => {
                                setsuccess_dlg(false)
                            }}
                        >
                            {dynamic_description}
                        </SweetAlert>
                    ) : null}

                    {confirm_alert ? (
                        <SweetAlert
                            title={props.t("Are You Sure")}
                            warning
                            showCancel
                            confirmBtnBsStyle="success"
                            cancelBtnBsStyle="danger"
                            cancelBtnText={props.t("Cancel")}
                            confirmBtnText={props.t("Delete")}
                            onConfirm={() => {
                                setconfirm_alert(false);
                                setsuccess_dlg(true);
                                setdynamic_title(props.t("Deleted"));
                                setdynamic_description("");
                                dispatch(onDeleteMainCategory(selectedId));
                                setSelectedId(null);
                            }}
                            onCancel={() => setconfirm_alert(false)}
                        >
                            {props.t("DeleteWarning")}
                        </SweetAlert>
                    ) : null}


                    {error && error ? (
                        <SweetAlert
                            title={props.t("Error")}
                            warning
                            confirmBtnText={props.t("Ok")}
                            onConfirm={() => {
                                dispatch(onResetMainCategory())
                            }}
                        >
                            {JSON.stringify(error)}
                        </SweetAlert>
                    ) : null}

                </Container>
            </div>
        </React.Fragment>
    )
}

export default withRouter(withTranslation()(MainCategories))
