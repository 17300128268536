import {
    GET_PRODUCTS,
    GET_PRODUCTS_SUCCESS,
    GET_PRODUCTS_FAIL,
    GET_PRODUCT_DETAIL,
    GET_PRODUCT_DETAIL_SUCCESS,
    GET_PRODUCT_DETAIL_FAIL,
    ADD_PRODUCT,
    ADD_PRODUCT_SUCCESS,
    ADD_PRODUCT_FAIL,
    UPDATE_PRODUCT,
    UPDATE_PRODUCT_SUCCESS,
    UPDATE_PRODUCT_FAIL,
    DELETE_PRODUCT,
    DELETE_PRODUCT_SUCCESS,
    DELETE_PRODUCT_FAIL,
    RESET_PRODUCT,
    UPDATE_PRODUCT_TYPE,
    UPDATE_PRODUCT_TYPE_SUCCESS,
    UPDATE_PRODUCT_TYPE_FAIL,
    GET_PRODUCT_TYPES,
    GET_PRODUCT_TYPES_SUCCESS,
    GET_PRODUCT_TYPES_FAIL,
} from "./actionTypes";
 
export const resetProduct = () => ({
    type: RESET_PRODUCT,
});
 
export const getProductById = (id) => ({
    type: GET_PRODUCT_DETAIL,
    payload:id
});
export const getProductByIdSuccess = (data) => ({ 
    type: GET_PRODUCT_DETAIL_SUCCESS,
    payload: data
});
export const getProductByIdFail = (error) => ({
    type: GET_PRODUCT_DETAIL_FAIL,
    payload: error,
});

export const getProductTypes = (id) => ({
    type: GET_PRODUCT_TYPES,
    payload:id
});
export const getProductTypesSuccess = (data) => ({
    type: GET_PRODUCT_TYPES_SUCCESS,
    payload: data
});
export const getProductTypesFail = (error) => ({
    type: GET_PRODUCT_TYPES_FAIL,
    payload: error,
});
export const getProducts = () => ({
    type: GET_PRODUCTS,
});

export const getProductsSuccess = (products) => ({
    type: GET_PRODUCTS_SUCCESS,
    payload: products,
});


export const getProductsFail = (error) => ({
    type: GET_PRODUCTS_FAIL,
    payload: error,
});

export const deleteProduct = (id) => ({
    type:  DELETE_PRODUCT,
    payload: id
});

export const deleteProductSuccess = (data) => ({
    type: DELETE_PRODUCT_SUCCESS,
    payload: data
});

export const deleteProductFail = (error) => ({
    type: DELETE_PRODUCT_FAIL,
    payload: error,
});


export const addProduct = (product) => ({
    type: ADD_PRODUCT,
    payload: product
});

export const addProductSuccess = (data) => ({
    type: ADD_PRODUCT_SUCCESS,
    payload: data
});

export const addProductFail = (error) => ({
    type: ADD_PRODUCT_FAIL,
    payload: error,
});


export const updateProduct = (product) => ({
    type: UPDATE_PRODUCT,
    payload: product
});

export const updateProductSuccess = (data) => ({
    type: UPDATE_PRODUCT_SUCCESS,
    payload: data
});

export const updateProductFail = (error) => ({
    type: UPDATE_PRODUCT_FAIL,
    payload: error,
});

export const updateProductType = (productType) => ({
    type: UPDATE_PRODUCT_TYPE,
    payload: productType
});

export const updateProductTypeSuccess = (data) => ({
    type: UPDATE_PRODUCT_TYPE_SUCCESS,
    payload: data
});

export const updateProductTypeFail = (error) => ({
    type: UPDATE_PRODUCT_TYPE_FAIL,
    payload: error,
});