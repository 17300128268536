import React, { useState, useEffect } from "react"
import PropTypes from 'prop-types'
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"
// Redux
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"
import {
    getBranchById as onGetBranchById,
    changeSelectedBranch as onChangeSelectedBranch,
    getUserBranch as onGetUserBranch,
   
} from "store/actions"
// users
import user1 from "../../../assets/images/users/avatar-1.jpg"
import { useSelector, useDispatch } from "react-redux"
import { isEmpty } from "lodash"
import { WindowScrollController } from "@fullcalendar/core"
const BranchesMenu = props => {
    const dispatch = useDispatch()
    const {
      error, Branches,UserBranch,selectedBranch,User
  
    } = useSelector(state => ({
      error: state.Expenses?.error,
      User :state.changeSelectedBranch?.user,
      UserBranch:state.Branchs?.userBranch,
      Branches: state.Branchs?.Branches,
      selectedBranch:state.Branchs?.selectedBranch?.name,
    }))
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)
  let user = JSON.parse(localStorage.getItem("authUser"))
  const [username, setusername] = useState()
  useEffect(() => {
      if(!isEmpty(User)){
        dispatch(onGetBranchById(User.selectedBranch)); 
        dispatch(onGetUserBranch(user.uid)) 
        
      }
}, [User]); 

  useEffect(() => {
    const obj = JSON.parse(localStorage.getItem("authUser")); 
    if (localStorage.getItem("authUser")) {
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") { 
        setusername(obj.displayName)
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === "fake" ||
        process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) { 
        setusername(obj.selectedBranch) 
      }
    }
  }, [props.success])

  return (
    <React.Fragment>
    
         <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          
          <span className="d-none d-xl-inline-block ms-2 me-1">{selectedBranch}</span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block"/>
        </DropdownToggle>
        {user.roles[0].name =="User"?(
           <DropdownMenu className="dropdown-menu-end">
          
         
           <div className="dropdown-divider"/>
           
           {UserBranch?.map((item, index) => (
              <option key={index} value={[item._id]} style={{cursor:"pointer"}} className="dropdown-item"  onClick={e => {
                         const newSelectedBranch = {
                           id: user.uid,  
                           selectedBranch: e.target.value, 
                           }
                     dispatch(onChangeSelectedBranch(newSelectedBranch))
                     window.location.reload();
                       }}>{item.name}
                        </option> 
                  
                       
 
                     ))}
           
         </DropdownMenu>
        ):
        (
          <DropdownMenu className="dropdown-menu-end">
          
         
          <div className="dropdown-divider"/>
          
          {Branches?.map((item, index) => (
             <option key={index} value={[item._id]} style={{cursor:"pointer"}} className="dropdown-item"  onClick={e => {
                        const newSelectedBranch = {
                          id: user.uid,  
                          selectedBranch: e.target.value, 
                          }
                    dispatch(onChangeSelectedBranch(newSelectedBranch))
                    window.location.reload();
                      }}>{item.name}
                       </option> 
                 
                      

                    ))}
          
        </DropdownMenu>
        )}
       
      </Dropdown>
    </React.Fragment>
  )
}

BranchesMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any
}

const mapStatetoProps = state => {
  const { error, success } = state.Profile
  return { error, success }
}

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(BranchesMenu))
)
