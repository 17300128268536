import { call, put, takeEvery } from "redux-saga/effects";

// Crypto Redux States
import { GET_COLLECTIONS, DELETE_COLLECTION, ADD_COLLECTION, UPDATE_COLLECTION} from "./actionTypes";
import {
    getCollectionsSuccess, getCollectionsFail,
    deleteCollectionSuccess, deleteCollectionFail,
    updateCollectionSuccess, updateCollectionFail,
    addCollectionSuccess, addCollectionFail
 } from "./actions";

//Include Both Helper File with needed methods
import {
    getCollections, //API CONNECTION
    delCollection,
    addCollection,
    updateCollection
}
    from "../../helpers/fakebackend_helper"; 

function* fetchCollections() {
    try {
        const response = yield call(getCollections)
       
        yield put(getCollectionsSuccess(response));
    } catch (error) {
        yield put(getCollectionsFail(error));
    }
}

function* onDeleteCollections({payload:id}){
    try{
        const response = yield call(delCollection, id)
        yield put(deleteCollectionSuccess(response));
    } catch (error) {
        yield put(deleteCollectionFail(error));
    }
}

function* onAddCollection({payload:collection}){
    try{
        const response = yield call(addCollection, collection)
        yield put(addCollectionSuccess(response));
    } catch (error) {
        yield put(addCollectionFail(error));
    }
}

function* onUpdateCollection({payload:collection}){
    try{
        const response = yield call(updateCollection, collection)
        yield put(updateCollectionSuccess(response));
    } catch (error) {
        yield put(updateCollectionFail(error));
    }
}

export function* collectionsSaga() {
    yield takeEvery(GET_COLLECTIONS, fetchCollections);
    yield takeEvery(DELETE_COLLECTION, onDeleteCollections);
    yield takeEvery(ADD_COLLECTION, onAddCollection);
    yield takeEvery(UPDATE_COLLECTION, onUpdateCollection);
}

export default collectionsSaga;
