import { call, put, takeEvery } from "redux-saga/effects";

// Crypto Redux States
import { GET_ERPS, DELETE_ERP, ADD_ERP, UPDATE_ERP} from "./actionTypes";
import {
    getErpsSuccess, getErpsFail,
    deleteErpSuccess, deleteErpFail,
    updateErpSuccess, updateErpFail,
    addErpSuccess, addErpFail
 } from "./actions";

//Include Both Helper File with needed methods
import {
    getErps, //API CONNECTION
    delErp,
    addErp,
    updateErp
}
    from "../../helpers/fakebackend_helper"; 

function* fetchErps() {
    try {
        const response = yield call(getErps)
        yield put(getErpsSuccess(response));
    } catch (error) {
        yield put(getErpsFail(error));
    }
}

function* onDeleteErps({payload:id}){
    try{
        const response = yield call(delErp, id)
        yield put(deleteErpSuccess(response));
    } catch (error) {
        yield put(deleteErpFail(error));
    }
}

function* onAddErp({payload:erp}){
    try{
        const response = yield call(addErp, erp)
        yield put(addErpSuccess(response));
    } catch (error) {
        yield put(addErpFail(error));
    }
}

function* onUpdateErp({payload:erp}){
    try{
        const response = yield call(updateErp, erp)
        yield put(updateErpSuccess(response));
    } catch (error) {
        yield put(updateErpFail(error));
    }
}

export function* erpsSaga() {
    yield takeEvery(GET_ERPS, fetchErps);
    yield takeEvery(DELETE_ERP, onDeleteErps);
    yield takeEvery(ADD_ERP, onAddErp);
    yield takeEvery(UPDATE_ERP, onUpdateErp);
}

export default erpsSaga;
