import { call, put, takeEvery } from "redux-saga/effects";

// Crypto Redux States
import { GET_SALEMODELS, DELETE_SALEMODEL, POST_SALEMODEL ,GET_MODAL_BY_USER_ID} from "./actionTypes";
import {
    getSaleModelsSuccess, getSaleModelsFail,
    deleteSaleModelSuccess, deleteSaleModelFail, 
    postSaleModelSuccess, postSaleModelFail,
    getModalByUserIdSuccess,getModalByUserIdFail
 } from "./actions";

//Include Both Helper File with needed methods
import {
    getSaleModels, //API CONNECTION
    delSaleModel,
    postSaleModel,
    getModalByIdModel
}
 from "../../helpers/fakebackend_helper"; 
 
 function* getModalByUserIdModel({payload:UserId}) {
     try {
         const response = yield call(getModalByIdModel, UserId)
         yield put(getModalByUserIdSuccess(response));
     } catch (error) {
         yield put(getModalByUserIdFail(error));
     }
 }

function* fetchSaleModels() {
    try {
        const response = yield call(getSaleModels)
        yield put(getSaleModelsSuccess(response));
    } catch (error) {
        yield put(getSaleModelsFail(error));
    }
}

function* onDeleteSaleModels({payload:id}){
    try{
        const response = yield call(delSaleModel, id)
        yield put(deleteSaleModelSuccess(response));
    } catch (error) {
        yield put(deleteSaleModelFail(error));
    }
}

function* onPostSaleModel({payload:saleModel}){
    try{
        const response = yield call(postSaleModel, saleModel)
        yield put(postSaleModelSuccess(response));
    } catch (error) {
        yield put(postSaleModelFail(error));
    }
}
 

export function* saleModelsSaga() {
    yield takeEvery(GET_SALEMODELS, fetchSaleModels);
    yield takeEvery(DELETE_SALEMODEL, onDeleteSaleModels);
    yield takeEvery(POST_SALEMODEL, onPostSaleModel); 
    yield takeEvery(GET_MODAL_BY_USER_ID, getModalByUserIdModel);
}

export default saleModelsSaga;
