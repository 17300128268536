/** Get Payment */
export const GET_MAINCATEGORIES = "GET_MAINCATEGORIES";
export const GET_MAINCATEGORIES_SUCCESS = "GET_MAINCATEGORIES_SUCCESS";
export const GET_MAINCATEGORIES_FAIL = "GET_MAINCATEGORIES_FAIL";

export const GET_MAINCATEGORY_DETAIL = "GET_MAINCATEGORY_DETAIL";
export const GET_MAINCATEGORY_DETAIL_SUCCESS = "GET_MAINCATEGORY_DETAIL_SUCCESS";
export const GET_MAINCATEGORY_DETAIL_FAIL = "GET_MAINCATEGORY_DETAIL_FAIL";


export const ADD_MAINCATEGORY = "ADD_MAINCATEGORY";
export const ADD_MAINCATEGORY_SUCCESS = "ADD_MAINCATEGORY_SUCCESS";
export const ADD_MAINCATEGORY_FAIL = "ADD_MAINCATEGORY_FAIL";

export const UPDATE_MAINCATEGORY = "UPDATE_MAINCATEGORY";
export const UPDATE_MAINCATEGORY_SUCCESS = "UPDATE_MAINCATEGORY_SUCCESS";
export const UPDATE_MAINCATEGORY_FAIL = "UPDATE_MAINCATEGORY_FAIL";



export const DELETE_MAINCATEGORY = "DELETE_MAINCATEGORY";
export const DELETE_MAINCATEGORY_SUCCESS = "DELETE_MAINCATEGORY_SUCCESS";
export const DELETE_MAINCATEGORY_FAIL = "DELETE_MAINCATEGORY_FAIL";

export const RESET_MAINCATEGORY = "RESET_MAINCATEGORY";