import { call, put, takeEvery } from "redux-saga/effects";

// Crypto Redux States
import { GET_PROCESS_LIST_PAYMENTS, DELETE_PROCESS_LIST_PAYMENT, ADD_PROCESS_LIST_PAYMENT, UPDATE_PROCESS_LIST_PAYMENT} from "./actionTypes";
import {
    getProcessListPaymentsSuccess, getProcessListPaymentsFail,
    deleteProcessListPaymentSuccess, deleteProcessListPaymentFail,
    updateProcessListPaymentSuccess, updateProcessListPaymentFail,
    addProcessListPaymentSuccess, addProcessListPaymentFail
 } from "./actions";

//Include Both Helper File with needed methods
import {
    getProcessListPayments, //API CONNECTION
    delProcessListPayment,
    addProcessListPayment,
    updateProcessListPayment
}
    from "../../helpers/fakebackend_helper"; 

function* fetchProcessListPayments() {
    try {
        const response = yield call(getProcessListPayments)
       
        yield put(getProcessListPaymentsSuccess(response));
    } catch (error) {
        yield put(getProcessListPaymentsFail(error));
    }
}

function* onDeleteProcessListPayments({payload:id}){
    try{
        const response = yield call(delProcessListPayment, id)
        yield put(deleteProcessListPaymentSuccess(response));
    } catch (error) {
        yield put(deleteProcessListPaymentFail(error));
    }
}

function* onAddProcessListPayment({payload:processListPayment}){
    try{
        const response = yield call(addProcessListPayment, processListPayment)
        yield put(addProcessListPaymentSuccess(response));
    } catch (error) {
        yield put(addProcessListPaymentFail(error));
    }
}

function* onUpdateProcessListPayment({payload:processListPayment}){
    try{
        const response = yield call(updateProcessListPayment, processListPayment)
        yield put(updateProcessListPaymentSuccess(response));
    } catch (error) {
        yield put(updateProcessListPaymentFail(error));
    }
}

export function* processListPaymentsSaga() {
    yield takeEvery(GET_PROCESS_LIST_PAYMENTS, fetchProcessListPayments);
    yield takeEvery(DELETE_PROCESS_LIST_PAYMENT, onDeleteProcessListPayments);
    yield takeEvery(ADD_PROCESS_LIST_PAYMENT, onAddProcessListPayment);
    yield takeEvery(UPDATE_PROCESS_LIST_PAYMENT, onUpdateProcessListPayment);
}

export default processListPaymentsSaga;
