

export const GET_PRODUCTS_BY_BARCODE = "GET_PRODUCTS_BY_BARCODE" 
export const GET_PRODUCTS_BY_BARCODE_SUCCESS = "GET_PRODUCTS_BY_BARCODE_SUCCESS"
export const GET_PRODUCTS_BY_BARCODE_FAIL = "GET_PRODUCTS_BY_BARCODE_FAIL"

export const GET_PRODUCTID_BY_SERIALNO = "GET_PRODUCTID_BY_SERIALNO" 
export const GET_PRODUCTID_BY_SERIALNO_SUCCESS = "GET_PRODUCTID_BY_SERIALNO_SUCCESS"
export const GET_PRODUCTID_BY_SERIALNO_FAIL = "GET_PRODUCTID_BY_SERIALNO_FAIL"

export const GET_PRODUCT_BY_ID = "GET_PRODUCT_BY_ID" 
export const GET_PRODUCT_BY_ID_SUCCESS = "GET_PRODUCT_BY_ID_SUCCESS"
export const GET_PRODUCT_BY_ID_FAIL = "GET_PRODUCT_BY_ID_FAIL"

export const GET_CUSTOMER_BY_IDENTITY = "GET_CUSTOMER_BY_IDENTITY" 
export const GET_CUSTOMER_BY_IDENTITY_SUCCESS = "GET_CUSTOMER_BY_IDENTITY_SUCCESS"
export const GET_CUSTOMER_BY_IDENTITY_FAIL = "GET_CUSTOMER_BY_IDENTITY_FAIL"

export const GET_CUSTOMER_BY_PHONE = "GET_CUSTOMER_BY_PHONE" 
export const GET_CUSTOMER_BY_PHONE_SUCCESS = "GET_CUSTOMER_BY_PHONE_SUCCESS"
export const GET_CUSTOMER_BY_PHONE_FAIL = "GET_CUSTOMER_BY_PHONE_FAIL"

export const GET_CUSTOMER_BY_TAXNO = "GET_CUSTOMER_BY_TAXNO" 
export const GET_CUSTOMER_BY_TAXNO_SUCCESS = "GET_CUSTOMER_BY_TAXNO_SUCCESS"
export const GET_CUSTOMER_BY_TAXNO_FAIL = "GET_CUSTOMER_BY_TAXNO_FAIL"


export const RESET_PROCESS_LIST = "RESET_PROCESS_LIST"