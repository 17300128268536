import {
    GET_POSMACHINES,
    GET_POSMACHINES_SUCCESS,
    GET_POSMACHINES_FAIL,
    GET_POSMACHINE_DETAIL,
    GET_POSMACHINE_DETAIL_SUCCESS,
    GET_POSMACHINE_DETAIL_FAIL,
    ADD_POSMACHINE,
    ADD_POSMACHINE_SUCCESS,
    ADD_POSMACHINE_FAIL,
    ADD_POSMACHINE_POSRATIO,
    ADD_POSMACHINE_POSRATIO_SUCCESS,
    ADD_POSMACHINE_POSRATIO_FAIL, 
    UPDATE_POSMACHINE,
    UPDATE_POSMACHINE_SUCCESS,
    UPDATE_POSMACHINE_FAIL,
    UPDATE_POSMACHINE_POSRATIO,
    UPDATE_POSMACHINE_POSRATIO_SUCCESS,
    UPDATE_POSMACHINE_POSRATIO_FAIL,
    DELETE_POSMACHINE,
    DELETE_POSMACHINE_SUCCESS,
    DELETE_POSMACHINE_FAIL,
    RESET_POSMACHINE
} from "./actionTypes";

export const resetPosMachine = () => ({
    type: RESET_POSMACHINE,
});

export const getPosMachines = () => ({
    type: GET_POSMACHINES,
});

export const getPosMachinesSuccess = (posMachines) => ({
    type: GET_POSMACHINES_SUCCESS,
    payload: posMachines,
});


export const getPosMachinesFail = (error) => ({
    type: GET_POSMACHINES_FAIL,
    payload: error,
});
export const getPosMachinesById = (id) => ({
    type: GET_POSMACHINE_DETAIL,
    payload: id
});

export const getPosMachinesByIdSuccess  = (data) => ({
    type: GET_POSMACHINE_DETAIL_SUCCESS,
    payload: data,
});


export const getPosMachinesByIdFail  = (error) => ({
    type: GET_POSMACHINE_DETAIL_FAIL,
    payload: error,
});

export const deletePosMachine = (id) => ({
    type:  DELETE_POSMACHINE,
    payload: id
});

export const deletePosMachineSuccess = (data) => ({
    type: DELETE_POSMACHINE_SUCCESS,
    payload: data
});

export const deletePosMachineFail = (error) => ({
    type: DELETE_POSMACHINE_FAIL,
    payload: error,
});


export const addPosMachine = (posMachine) => ({
    type: ADD_POSMACHINE,
    payload: posMachine
});

export const addPosMachineSuccess = (data) => ({
    type: ADD_POSMACHINE_SUCCESS,
    payload: data
});

export const addPosMachineFail = (error) => ({
    type: ADD_POSMACHINE_FAIL,
    payload: error,
});

export const addPosMachinePosRatio = (posMachine) => ({
    type: ADD_POSMACHINE_POSRATIO,
    payload: posMachine
});

export const addPosMachinePosRatioSuccess = (data) => ({
    type: ADD_POSMACHINE_POSRATIO_SUCCESS,
    payload: data
});

export const addPosMachinePosRatioFail = (error) => ({
    type: ADD_POSMACHINE_POSRATIO_FAIL,
    payload: error,
});

export const updatePosMachine = (posMachine) => ({
    type: UPDATE_POSMACHINE,
    payload: posMachine
});

export const updatePosMachineSuccess = (data) => ({
    type: UPDATE_POSMACHINE_SUCCESS,
    payload: data
});

export const updatePosMachineFail = (error) => ({
    type: UPDATE_POSMACHINE_FAIL,
    payload: error,
});

export const updatePosMachinePosRatio = (posMachine) => ({
    type: UPDATE_POSMACHINE_POSRATIO,
    payload: posMachine
});

export const updatePosMachinePosRatioSuccess = (data) => ({
    type: UPDATE_POSMACHINE_POSRATIO_SUCCESS,
    payload: data
});

export const updatePosMachinePosRatioFail = (error) => ({
    type: UPDATE_POSMACHINE_POSRATIO_FAIL,
    payload: error,
});