/** Get ProductSeries */
export const GET_PRODUCTSSERIES = "GET_PRODUCTSSERIES";
export const GET_PRODUCTSSERIES_SUCCESS = "GET_PRODUCTSSERIES_SUCCESS";
export const GET_PRODUCTSSERIES_FAIL = "GET_PRODUCTSSERIES_FAIL";

export const GET_PRODUCTSERIES_DETAIL = "GET_PRODUCTSERIES_DETAIL";
export const GET_PRODUCTSERIES_DETAIL_SUCCESS = "GET_PRODUCTSERIES_DETAIL_SUCCESS";
export const GET_PRODUCTSERIES_DETAIL_FAIL = "GET_PRODUCTSERIES_DETAIL_FAIL";


export const ADD_PRODUCTSERIES = "ADD_PRODUCTSERIES";
export const ADD_PRODUCTSERIES_SUCCESS = "ADD_PRODUCTSERIES_SUCCESS";
export const ADD_PRODUCTSERIES_FAIL = "ADD_PRODUCTSERIES_FAIL";

export const UPDATE_PRODUCTSERIES = "UPDATE_PRODUCTSERIES";
export const UPDATE_PRODUCTSERIES_SUCCESS = "UPDATE_PRODUCTSERIES_SUCCESS";
export const UPDATE_PRODUCTSERIES_FAIL = "UPDATE_PRODUCTSERIES_FAIL";



export const DELETE_PRODUCTSERIES = "DELETE_PRODUCTSERIES";
export const DELETE_PRODUCTSERIES_SUCCESS = "DELETE_PRODUCTSERIES_SUCCESS";
export const DELETE_PRODUCTSERIES_FAIL = "DELETE_PRODUCTSERIES_FAIL";

export const RESET_PRODUCTSERIES= "RESET_PRODUCTSERIES";