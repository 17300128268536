import React, { useState } from "react"
import PropTypes from 'prop-types'
import {
  Container, Row, Col, Modal, ModalHeader, ModalBody, TabContent, TabPane, Label,
  FormGroup,
  CardImgOverlay, Input
} from "reactstrap"
import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
} from "availity-reactstrap-validation"
import InputMask from "react-input-mask"
import { useSelector, useDispatch } from "react-redux"
import moment from "moment"
import {
  addCustomer as onAddCustomer,
  addErpSuccess,
  getCustomerByIdentity as OnGetCustomerByIdentity,
  getCustomerByPhone as OnGetCustomerByPhone,
  getCustomerByTaxNo as OnGetCustomerByTaxNo,

} from "store/actions"

import { isEmpty, map } from "lodash"
import { Link, withRouter } from "react-router-dom"
//i18n
import { withTranslation } from "react-i18next"

const CustomerModal = (props, cb) => {
  const [selectedCustomerType, setSelectedCustomerType] = useState(false)
  const {
    error,
    ProductInfo
  } = useSelector(state => ({
    error: state.Expenses?.error,
    ProductInfo: state.Products?.productInfo,

  }))
  const handleValidDateAvField = date => {
    if (date) {
      const date1 = moment(new Date(date)).format("YYYY-MM-DD")
      return date1
    } else {
      return ""
    }
  }

  const dispatch = useDispatch()

  return (
    <Modal isOpen={props.modal} size="xl" toggle={props.toggle}>
      <ModalHeader toggle={props.toggle} tag="h4">
        {props.t("Product Info")}
      </ModalHeader>
      <ModalBody>
        <AvForm >
          <Row form>
            <Col xl={12}>
              <Row>
                <input
                  id="ProductTypes"
                  name="ProductTypes"
                  type="hidden"
                />
                <Col xl={6}>
                  <div className="mb-3">
                    <AvField
                      name="name"
                      disabled
                      label={props.t("Name")}
                      type="text"
                      errorMessage={props.t("InvalidInput")}
                      validate={{
                        required: { value: true },
                      }}
                      value={ProductInfo?.name || ""}
                    />
                  </div>
                </Col>
                <Col xl={6}>
                  <div className="mb-3">
                    <AvField
                      name="sku"
                      label={props.t("Stock Code")}
                      disabled
                      type="text"
                      errorMessage={props.t("InvalidInput")}
                      validate={{
                        required: { value: false },
                      }}
                      value={ProductInfo?.sku || ""}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xl={6}>
                  <div className="mb-3">
                    <AvField
                      name="barcode"
                      label={props.t("Barcode")}
                      disabled
                      type="text"
                      errorMessage={props.t("InvalidInput")}
                      validate={{
                        required: { value: false },
                      }}
                      value={ProductInfo?.barcode || ""}
                    />
                  </div>
                </Col>

                <Col xl={6}>
                  <div className="mb-3">
                    <AvField
                      name="barcode"
                      label={props.t("Barcode")}
                      disabled
                      type="text"
                      errorMessage={props.t("InvalidInput")}
                      validate={{
                        required: { value: false },
                      }}
                      value={ProductInfo?.barcode || ""}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
              <Col xl={6}>
              <div className="mb-3">
                <AvField
                  name="brandName"
                  label={props.t("BrandName")}
                  disabled
                  type="text"
                  errorMessage={props.t("InvalidInput")}
                  validate={{
                    required: { value: false },
                  }}
                  value={ProductInfo?.brandName || ""}
                />
              </div>
              </Col>
              
                <Col xl={6}>
                  <div className="mb-3">
                    <AvField
                      type="text"
                      name="category"
                     
                      disabled
                      label={props.t("Category")}
                      value={ProductInfo?.category?.category || ""}
                    >
                     

                    </AvField>
                  </div>
                </Col>

                <Col xl={6}>
                <div className="mb-3">
                <AvField
                  type="text"
                  disabled
                  name="subCategoryName"
                  value={ProductInfo?.subCategoryName || ""}
                  label={props.t("Type")}
                   >
                 

                </AvField>
              </div>
                </Col>
              

             
             
                <Col xl={6}>
                <div className="mb-3">
                <AvField
                  name="price"
                  label={props.t("Price")}
                  disabled
                  type="text"
                  errorMessage={props.t("InvalidInput")}
                
                  value={ProductInfo?.price || ""}
                />
              </div>
                </Col>

                <Col xl={6}>
                <div className="mb-3">
                <AvField
                  name="priceUnit"
                  label={props.t("Price Unit")}
                  disabled
                  type="text"
                  errorMessage={props.t("InvalidInput")}
                  
                  value={ProductInfo?.priceUnit || ""}
                />
              </div>
                </Col>
             
             

            
             
                <Col xl={6}>
                <div className="mb-3">
                <AvField
                  name="score"
                  label={props.t("Score")}
                  disabled
                  type="text"
                  errorMessage={props.t("InvalidInput")}
                  validate={{
                    required: { value: false },
                  }}
                  value={ProductInfo?.score || ""}
                />
              </div>
                </Col>

               
              </Row>
              

              <h4>{props.t("Product Type")}</h4>
              {!isEmpty(ProductInfo.productType) ?
                       ProductInfo.productType.map((item, i) => (
                          
                        <div key={i}
                        className="form-check form-switch form-switch-lg mb-3"
                      >
                        <label
                          className="form-check-label"
                          htmlFor={"productTypes" + (i) + "__id"}
                        >
                          { ProductInfo.productType[i].name}
                        </label>
                        <input

                          type="checkbox"
                          name={"productTypes" + (i) + "__name"}
                          id={"productTypes" + (i) + "__id"}
                          className="form-check-input"
                          checked={item.checked}
                          value ={""}
                          
                        
                        />
                         
                      </div> 
                     )) 
                        :
                        <small>{props.t("You do not have a product Type")}</small>
                        }
              <Col className="mb-4" >
                <div className="text-end mb-3">


                </div>
              </Col>

            </Col>
          </Row>
          <Row>
            <Col>
              <div className="text-end">


                <button
                  type="button"
                  className="btn btn-secondary col-md-2 col-xs-5"
                  onClick={() => {
                    props.Cancel(true)
                  }}
                >
                  {props.t("Cancel")}
                </button>
              </div>
            </Col>
          </Row>
        </AvForm>
      </ModalBody>
    </Modal>
  )
}


CustomerModal.propTypes = {

  modal: PropTypes.bool,
  payment: PropTypes.func,
  Cancel: PropTypes.func,
  back: PropTypes.func,
  t: PropTypes.any,
  toggle: PropTypes.func,
}


export default withRouter(
  (withTranslation()(CustomerModal))
)
