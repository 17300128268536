/** Get Branchs */
export const GET_BRANCHS = "GET_BRANCHS";
export const GET_BRANCHS_SUCCESS = "GET_BRANCHS_SUCCESS";
export const GET_BRANCHS_FAIL = "GET_BRANCHS_FAIL";

export const GET_BRANCH_DETAIL = "GET_BRANCH_DETAIL";
export const GET_BRANCH_DETAIL_SUCCESS = "GET_BRANCH_DETAIL_SUCCESS";
export const GET_BRANCH_DETAIL_FAIL = "GET_BRANCH_DETAIL_FAIL";


export const ADD_BRANCH = "ADD_BRANCH";
export const ADD_BRANCH_SUCCESS = "ADD_BRANCH_SUCCESS";
export const ADD_BRANCH_FAIL = "ADD_BRANCH_FAIL";

export const UPDATE_BRANCH = "UPDATE_BRANCH";
export const UPDATE_BRANCH_SUCCESS = "UPDATE_BRANCH_SUCCESS";
export const UPDATE_BRANCH_FAIL = "UPDATE_BRANCH_FAIL";

export const GET_COMPANY_BRANCHES = "GET_COMPANY_BRANCHES";
export const GET_COMPANY_BRANCHES_SUCCESS = "GET_COMPANY_BRANCHES_SUCCESS";
export const GET_COMPANY_BRANCHES_FAIL = "GET_COMPANY_BRANCHES_FAIL";

export const DELETE_BRANCH = "DELETE_BRANCH";
export const DELETE_BRANCH_SUCCESS = "DELETE_BRANCH_SUCCESS";
export const DELETE_BRANCH_FAIL = "DELETE_BRANCH_FAIL";

export const GET_USER_BRANCH = "GET_USER_BRANCH";
export const GET_USER_BRANCH_SUCCESS = "GET_USER_BRANCH_SUCCESS";
export const GET_USER_BRANCH_FAIL = "GET_USER_BRANCH_FAIL";

export const RESET_BRANCH = "RESET_BRANCH";