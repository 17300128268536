import {
    GET_PRODUCTS,
    GET_PRODUCTS_SUCCESS,
    GET_PRODUCTS_FAIL,
    GET_PRODUCT_DETAIL,
    GET_PRODUCT_DETAIL_SUCCESS,
    GET_PRODUCT_DETAIL_FAIL,
    ADD_PRODUCT,
    ADD_PRODUCT_SUCCESS,
    ADD_PRODUCT_FAIL,
    UPDATE_PRODUCT,
    UPDATE_PRODUCT_SUCCESS,
    UPDATE_PRODUCT_FAIL,
    DELETE_PRODUCT,
    DELETE_PRODUCT_SUCCESS,
    DELETE_PRODUCT_FAIL,
    RESET_PRODUCT,
    UPDATE_PRODUCT_TYPE,
    UPDATE_PRODUCT_TYPE_SUCCESS,
    UPDATE_PRODUCT_TYPE_FAIL,
    GET_PRODUCT_TYPES_SUCCESS,
    GET_PRODUCT_TYPES_FAIL,
} from "./actionTypes";

const INIT_STATE = {
    data: [],
    ProductTypes: [],
    error: null,
    productInfo :[]
}; 

const Products = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_PRODUCTS_SUCCESS:
            return {
                ...state,
                data: action.payload.data 
            }
         case GET_PRODUCT_DETAIL_SUCCESS: 
         return {
             ...state,
             productInfo:action.payload.data,
         } 
        case DELETE_PRODUCT_SUCCESS:
            return {
                ...state,
                data: state.data.filter(products => products._id.toString() !== action.payload.data.toString())
            }
        case ADD_PRODUCT_SUCCESS:
            return {
                ...state,
                data: [action.payload.data, ...state.data]
            }
        case UPDATE_PRODUCT_SUCCESS:
            return {
                ...state,
                data: state.data.map(products =>
                    products._id.toString() === action.payload.data._id.toString() ?
                        { products, ...action.payload.data } :
                        products
                )
            }
            case UPDATE_PRODUCT_TYPE_SUCCESS:
                return {
                    ...state,
                    data: state.data.map(productType =>
                        productType._id.toString() === action.payload.data._id.toString() ?
                            { productType, ...action.payload.data } :
                            productType
                    )
                }
                case GET_PRODUCT_TYPES_SUCCESS:
                    return {
                        ...state,
                        ProductTypes: action.payload.data,
                      
                      }

        //FAILS
        case GET_PRODUCTS_FAIL:
        case DELETE_PRODUCT_FAIL:
        case ADD_PRODUCT_FAIL:
        case UPDATE_PRODUCT_FAIL:
        case  UPDATE_PRODUCT_TYPE_FAIL:
        case  GET_PRODUCT_TYPES_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case RESET_PRODUCT: return {
            ...state,
            error: null
        }
        default:
            return state;
    }
}


export default Products;