import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useState, useEffect } from "react"
import {
    Container,
    Row,
    Col,
    Card,
    Alert,
    CardBody,
    Media,
    Button,
    UncontrolledDropdown,
    UncontrolledTooltip,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Modal,
    ModalHeader,
    ModalBody,
    Label, 
} from "reactstrap"
//redux
import { useSelector, useDispatch } from "react-redux"
import moment from 'moment'
import { withRouter } from "react-router-dom"

//mainCategories
import { getMainCategories as onGetMainCategories } from "store/actions"

 
//i18n
import { withTranslation } from "react-i18next"

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"

import {
    getExpenses as onGetExpenses,
    deleteExpense as onDeleteExpense,
    addExpense as onAddExpense,
    updateExpense as onUpdateExpense,
    resetExpense as onResetExpense,
    getTypeListByCategoryId as onGetTypeListByCategoryId
} from "store/actions"

import { isEmpty, map } from "lodash"

import { AvForm, AvField, AvGroup, AvInput, AvFeedback, AvRadioGroup, AvRadio, AvCheckboxGroup, AvCheckbox } from 'availity-reactstrap-validation';

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
    PaginationProvider, PaginationListStandalone,
    SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert"

import "./datatables.scss"

const Expenses = props => {
    const dispatch = useDispatch()

    const [selectedId, setSelectedId] = useState();
    const [selectedExpense, setSelectedExpense] = useState();
    const [success_dlg, setsuccess_dlg] = useState(false);
    const [confirm_alert, setconfirm_alert] = useState(false);
    const [dynamic_title, setdynamic_title] = useState("");
    const [dynamic_description, setdynamic_description] = useState("");
    const [modal, setModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [selectedMainCategoryId, setSelectedMainCategoryId] = useState(); 


    const { error, Expenses, SubCategories,MainCategories } = useSelector(state => ({
        error: state.Expenses?.error,
        Expenses: state.Expenses?.data,
        SubCategories: state.Expenses?.SubCategories,
        MainCategories: state.MainCategories?.data,
    }))
    
    
    useEffect(() => {
        dispatch(onGetMainCategories())
    }, [dispatch])

    
    useEffect(() => {
        dispatch(onGetExpenses());
    }, [dispatch]);

    const toggle = () => {
        setModal(!modal)
    }
    const findSubCategoryName = (arr, id) => {
        if (!isEmpty(arr) && !isEmpty(id)) {
          let index = arr.findIndex(function (item, i) {
            return item._id === id
          });
          if (index !== -1) {
            return arr[index].name;
          }
          else {
            return "--";
          }
        }
        return "--";
      }
      const handleValidDate = date => {
        if (date) {
            const date1 = moment(new Date(date)).format("DD.MM.YYYY");
            return date1;
        } else {
            return "";
        }
    }
    const columns = [ 
        {
            dataField: 'createdAt',
            text: props.t("Created At"),
            sort: true,
            formatter: (cell, row) => (
                <span>{handleValidDate(cell)}</span>
            )

        },
        {
            dataField: "category",
            text: props.t("MainCategory"),
            sort: true,
            formatter: (cell, row) => <span>{cell?.category}</span>,
      
          },
          {
            dataField: "isType",
            text: props.t("Subcategory"),
            sort: true,
            formatter: (cell, row) => <span>
              {findSubCategoryName(row.category?.subCategories, cell)}
            </span>,
          },
          {
            dataField: "description",
            text: props.t("Description"),
            sort: true,
          },
          {
            dataField: "amount",
            text: props.t("Amount"),
            sort: true,
          },
          {
            dataField: 'BranchId',
            text: props.t("Branch Name"),
            sort: true,

            formatter: (cell, row) => (

                <span>{cell?.name}</span>
            )

        },


        {
            dataField: '_id',
            text: props.t("Action"),
            sort: false,
            formatter: (cell, row) => (
                <UncontrolledDropdown style={{ position: "unset" }}>
                    <DropdownToggle href="#" className="card-drop" tag="i">
                        <i className="mdi mdi-dots-horizontal font-size-18"></i>
                    </DropdownToggle>
                    <div className="drop-absolute">
                    <DropdownMenu className="dropdown-menu-end">
                        <DropdownItem href="#"
                            onClick={() => {
                                setSelectedExpense(row);
                                setIsEdit(true);
                                toggle();
                            }}
                        >
                            <i className="mdi mdi-pencil font-size-16 text-success me-1"></i>
                            {props.t("Edit")}
                        </DropdownItem>
                        <DropdownItem href="#" 
                            onClick={() => {
                                setSelectedId(cell);
                                setconfirm_alert(true);
                            }}>
                            <i className="mdi mdi-trash-can font-size-16 text-danger me-1"></i>
                            {props.t("Delete")}
                        </DropdownItem>
                    </DropdownMenu>
                        </div>
                    
                </UncontrolledDropdown>
            )
        }];

    const defaultSorted = [{
        dataField: 'createdAt',
        order: 'desc'
    }];

    const pageOptions = {
        sizePerPage: 10,
        totalSize: Expenses ? Expenses.length : 0, // replace later with size(Expense),
        custom: true,
    }

    // Custom Pagination Toggle
    const sizePerPageList = [
        { text: '5', value: 5 },
        { text: '10', value: 10 },
        { text: '15', value: 15 },
        { text: '20', value: 20 },
        { text: '25', value: 25 },
        { text: 'All', value: Expenses ? Expenses.length : 0 }];


    // Select All Button operation
    const selectRow = {
        mode: 'checkbox'
    }

    const { SearchBar } = Search;

    const handleValidExpenseSubmit = (e, values) => {
        if (isEdit) {
            const updateExpense = {
                _id: selectedExpense._id,
                category: values.category,
                isType: values.isType,
                description: values["description"],
                amount: values["amount"],


            };
            // update Expense
            dispatch(onUpdateExpense(updateExpense));
        } else {
            const newExpense = {
                category: values["category"],
                isType: values["isType"],
                description: values["description"],
                amount: values["amount"],


            }
            // save new Expense
            dispatch(onAddExpense(newExpense))
        }
        toggle()
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>{props.t("Expense")}</title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumb title={props.t("Expense")} breadcrumbItem={props.t("Expense")}  addBtnCallBack={() => {
                        setIsEdit(false);
                        setSelectedExpense(
                            {
                                category: "",
                                isType: "",
                                description: "",
                                amount: "",


                            }
                        );
                        toggle();

                    }} />

                    {/* {error && error ? <Alert color="danger">{error}</Alert> : null} */}

                    <Card>
                        <CardBody>
                            <PaginationProvider
                                pagination={paginationFactory(pageOptions)}
                                keyField='id'
                                columns={columns}
                                data={Expenses}
                            >
                                {({ paginationProps, paginationTableProps }) => (
                                    <ToolkitProvider
                                        keyField='_id'
                                        columns={columns}
                                        data={Expenses}
                                        search
                                    >
                                        {toolkitProps => (
                                            <React.Fragment>

                                                <Row className="mb-2">
                                                    <Col md="4">
                                                        <div className="search-box me-2 mb-2 d-inline-block">
                                                            <div className="position-relative">
                                                                <SearchBar
                                                                    {...toolkitProps.searchProps}
                                                                />
                                                                <i className="bx bx-search-alt search-icon" />
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col xl="12">
                                                        <div className="table-responsive">
                                                            <BootstrapTable
                                                                keyField={"_id"}
                                                                responsive
                                                                bordered={false}
                                                                striped={false}
                                                                defaultSorted={defaultSorted}
                                                                classes={
                                                                    "table align-middle table-nowrap"
                                                                }
                                                                headerWrapperClasses={"thead-light"}
                                                                {...toolkitProps.baseProps}
                                                                {...paginationTableProps}
                                                            />

                                                        </div>
                                                    </Col>
                                                </Row>

                                                <Row className="align-items-md-center mt-30">
                                                    <Col className="inner-custom-pagination d-flex">
                                                        <div className="d-inline">
                                                            <SizePerPageDropdownStandalone
                                                                {...paginationProps}
                                                            />
                                                        </div>
                                                        <div className="text-md-right ms-auto">
                                                            <PaginationListStandalone
                                                                {...paginationProps}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </React.Fragment>
                                        )
                                        }
                                    </ToolkitProvider>
                                )
                                }</PaginationProvider>

                            <Modal isOpen={modal} toggle={toggle}>
                                <ModalHeader toggle={toggle} tag="h4">
                                    {!!isEdit ? props.t("Gider ve Harcama Şekilleri Düzenle") : props.t("Gider ve Harcama Şekilleri")}
                                    
                                </ModalHeader>
                                &nbsp;&nbsp;&nbsp;&nbsp;{props.t("Please Choose Payment Method")}
                                <ModalBody>
                                    <AvForm onValidSubmit={handleValidExpenseSubmit}>
                                        <Row form>
                                            <Col xs={12}>
                                           
                                            
                                                <div className="mb-3">
                                                    <AvField 
                                                        type="select"
                                                        name="category" 
                                                        className="form-select" 
                                                        label={props.t("Select Category")}
                                                        onChange={(e)=>{  
                                                           // dispatch(onGetTypeListByCategoryId(e.target.value));
                                                            //console.log("Change", e.target.value, new Date());
                                                        }}
                                                        >
                                                        <option>{props.t("Select Category")}</option>
                                                        {MainCategories?.map((item, index) => (
                                                            <option key={index} value={item._id}>{item.category}</option>
                                                        ))}
                                                    </AvField>
                                                </div>

                                                <div className="mb-3">
                                                    <AvField type="select" name="isType" className="form-select" label="Tür seç">
                                                        <option>{props.t("Select Type")}</option>
                                                        {SubCategories?.map((item, index) => (
                                                            <option key={index} value={item._id}>{item.subCategory}</option>
                                                        ))}
                                                    </AvField>
                                                </div>


                                                <div className="mb-3">
                                                    <AvField
                                                        name="description"
                                                        label={props.t("Description")}
                                                        type="text"
                                                        errorMessage={props.t("InvalidInput")}
                                                        validate={{
                                                            required: { value: false },
                                                        }}
                                                        value={selectedExpense?.description || ""}
                                                    />
                                                </div>
                                                <div className="mb-3">
                                                    <AvField
                                                        name="amount"
                                                        label={props.t("Amount")}
                                                        type="text"
                                                        errorMessage={props.t("InvalidInput")}
                                                        validate={{
                                                            required: { value: false },
                                                        }}
                                                        value={selectedExpense?.amount || ""}
                                                    />
                                                </div>


                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <div className="text-end">
                                                    <button
                                                        type="submit"
                                                        className="btn btn-success save-user"
                                                    >
                                                        {props.t("Save")}
                                                    </button>
                                                    &nbsp;&nbsp;
                                                    <Button onClick={() => { toggle() }}>
                                                        {props.t("Cancel")}
                                                    </Button>

                                                </div>
                                            </Col>
                                        </Row>
                                    </AvForm>
                                </ModalBody>
                            </Modal>
                        </CardBody>
                    </Card>

                    {success_dlg ? (
                        <SweetAlert
                            success
                            title={dynamic_title}
                            confirmBtnText={props.t("Ok")}
                            onConfirm={() => {
                                setsuccess_dlg(false)
                            }}
                        >
                            {dynamic_description}
                        </SweetAlert>
                    ) : null}

                    {confirm_alert ? (
                        <SweetAlert
                            title={props.t("Are You Sure")}
                            warning
                            showCancel
                            confirmBtnBsStyle="success"
                            cancelBtnBsStyle="danger"
                            cancelBtnText={props.t("Cancel")}
                            confirmBtnText={props.t("Delete")}
                            onConfirm={() => {
                                setconfirm_alert(false);
                                setsuccess_dlg(true);
                                setdynamic_title(props.t("Deleted"));
                                setdynamic_description("");
                                dispatch(onDeleteExpense(selectedId));
                                setSelectedId(null);
                            }}
                            onCancel={() => setconfirm_alert(false)}
                        >
                            {props.t("DeleteWarning")}
                        </SweetAlert>
                    ) : null}


                    {error && error ? (
                        <SweetAlert
                            title={props.t("Error")}
                            warning
                            confirmBtnText={props.t("Ok")}
                            onConfirm={() => {
                                dispatch(onResetExpense())
                            }}
                        >
                            {JSON.stringify(error)}
                        </SweetAlert>
                    ) : null}

                </Container>
            </div>
        </React.Fragment>
    )
}

export default withRouter(withTranslation()(Expenses))
