import {
    GET_ENDOFDAYBALANCINGS,
    GET_ENDOFDAYBALANCINGS_SUCCESS,
    GET_ENDOFDAYBALANCINGS_FAIL,
    GET_ENDOFDAYBALANCING_DETAIL,
    GET_ENDOFDAYBALANCING_DETAIL_SUCCESS,
    GET_ENDOFDAYBALANCING_DETAIL_FAIL,
    ADD_ENDOFDAYBALANCING,
    ADD_ENDOFDAYBALANCING_SUCCESS,
    ADD_ENDOFDAYBALANCING_FAIL,
    UPDATE_ENDOFDAYBALANCING,
    UPDATE_ENDOFDAYBALANCING_SUCCESS,
    UPDATE_ENDOFDAYBALANCING_FAIL,
    DELETE_ENDOFDAYBALANCING,
    DELETE_ENDOFDAYBALANCING_SUCCESS,
    DELETE_ENDOFDAYBALANCING_FAIL,
    RESET_ENDOFDAYBALANCING
} from "./actionTypes";

export const resetEndOfDayBalancing = () => ({
    type: RESET_ENDOFDAYBALANCING,
});

export const getEndOfDayBalancings = () => ({
    type: GET_ENDOFDAYBALANCINGS,
});

export const getEndOfDayBalancingsSuccess = (endOfDayBalancings) => ({
    type: GET_ENDOFDAYBALANCINGS_SUCCESS,
    payload: endOfDayBalancings,
});


export const getEndOfDayBalancingsFail = (error) => ({
    type: GET_ENDOFDAYBALANCINGS_FAIL,
    payload: error,
});

export const deleteEndOfDayBalancing = (id) => ({
    type:  DELETE_ENDOFDAYBALANCING,
    payload: id
});

export const deleteEndOfDayBalancingSuccess = (data) => ({
    type: DELETE_ENDOFDAYBALANCING_SUCCESS,
    payload: data
});

export const deleteEndOfDayBalancingFail = (error) => ({
    type: DELETE_ENDOFDAYBALANCING_FAIL,
    payload: error,
});


export const addEndOfDayBalancing = (profitLoss) => ({
    type: ADD_ENDOFDAYBALANCING,
    payload: profitLoss
});

export const addEndOfDayBalancingSuccess = (data) => ({
    type: ADD_ENDOFDAYBALANCING_SUCCESS,
    payload: data
});

export const addEndOfDayBalancingFail = (error) => ({
    type: ADD_ENDOFDAYBALANCING_FAIL,
    payload: error,
});


export const updateEndOfDayBalancing = (endOfDayBalancing) => ({
    type: UPDATE_ENDOFDAYBALANCING,
    payload: endOfDayBalancing
});

export const updateEndOfDayBalancingSuccess = (data) => ({
    type: UPDATE_ENDOFDAYBALANCING_SUCCESS,
    payload: data
});

export const updateEndOfDayBalancingFail = (error) => ({
    type: UPDATE_ENDOFDAYBALANCING_FAIL,
    payload: error,
});