import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useState, useEffect } from "react"
import {
    Container,
    Row,
    Col,
    Card,
    Alert,
    CardBody,
    Media,
    Button,
    UncontrolledDropdown,
    UncontrolledTooltip,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Modal,
    ModalHeader,
    ModalBody,
    Label,
} from "reactstrap"
//redux
import { useSelector, useDispatch } from "react-redux"

import { withRouter } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"

import ProductInfoModal from "./../ProcessList/Modals/ProductInfoModal"
import CustomerInfoModal from "./../ProcessList/Modals/CustomerInfoModal"
import PaymentInfoModal from "./../ProcessList/Modals/PaymentInfoModal"

import {
    getProcessListPayments as onGetProcessListPayments,
    getProductSales as onGetProductSales,
    deleteProductSale as onDeleteProductSale,
    addProductSale as onAddProductSale,
    updateProductSale as onUpdateProductSale,
    resetProductSale as onResetProductSale,
    getProducts as onGetProducts,
    getProductById as onGetProductById,
    getCustomerById as onGetCustomerById,
    getPaymentById as onGetPaymentById
} from "store/actions"

import { isEmpty, map } from "lodash"

import { AvForm, AvField, AvGroup, AvInput, AvFeedback, AvRadioGroup, AvRadio, AvCheckboxGroup, AvCheckbox } from 'availity-reactstrap-validation';

import moment from 'moment'

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
    PaginationProvider, PaginationListStandalone,
    SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert"



import "./datatables.scss"

const ProductSales = props => {
    const dispatch = useDispatch()

    const [selectedId, setSelectedId] = useState();
    const [selectedProductSale, setSelectedProductSale] = useState();
    const [success_dlg, setsuccess_dlg] = useState(false);
    const [confirm_alert, setconfirm_alert] = useState(false);
    const [dynamic_title, setdynamic_title] = useState("");
    const [dynamic_description, setdynamic_description] = useState("");
    const [modal, setModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [productInfoModal, setProductInfoModal] = useState(false)
    const [customerInfoModal, setCustomerInfoModal] = useState(false)
    const [paymentInfoModal, setPaymentInfoModal] = useState(false)
    const [selectedProductId, setSelectedProductId] = useState()


    useEffect(() => {
        dispatch(onGetProcessListPayments())
        dispatch(onGetProducts())
    }, [dispatch])


    const { error, ProductSales, Products, ProcessListPayment, } = useSelector(state => ({
        error: state.ProductSales?.error,
        ProductSales: state.ProductSales?.data,
        Products: state.Products?.data,
        ProcessListPayment: state.ProcessListPayment?.data,
    }))


    useEffect(() => {
        dispatch(onGetProductSales());
    }, [dispatch]);

    const toggle = () => {
        setModal(!modal)
    }
    const productInfoToggle = () => {
        setProductInfoModal(!productInfoModal)
    }
    const customerInfoToggle = () => {
        setCustomerInfoModal(!customerInfoModal)
    }
    const paymentInfoToggle = () => {
        setPaymentInfoModal(!paymentInfoModal)
    }
    //user
    let user = JSON.parse(localStorage.getItem("authUser"))

    const columns = [
        {
            dataField: 'createdAt',
            text: props.t("Created At"),
            sort: true,
            formatter: (cell, row) => (
                <span>{handleValidDate(cell)}</span>
            )

        },
        {
            dataField: "ProductId",
            text: props.t("Product Name"),
            sort: true,
            formatter: (cell, row) => <span>{cell?.name} <i style={{ fontSize: "18px", position: "relative", top: "3px" }} className="bx bx-info-circle"
                onClick={e => {
                    dispatch(onGetProductById(row.ProductId?._id))
                    productInfoToggle()

                }} /></span>,

        },

        {
            dataField: "salesAmount",
            text: props.t("Adet"),
            sort: true,
        },
        {
            dataField: "amount",
            text: props.t("Amount"),
            sort: true,
        },
        {
            dataField: "ProductType",
            text: props.t("Product Type"),
            sort: true,
        },

        {
            dataField: "CustomerId",
            text: props.t("Customer Name"),
            sort: true,
            formatter: (cell, row) => <span>{cell?.customerName ? cell?.customerName : "Nihai Tüketici"} {cell?.customerName ? (<i style={{ fontSize: "18px", position: "relative", top: "3px" }} className="bx bx-info-circle"
                onClick={e => {
                    dispatch(onGetCustomerById(row.CustomerId?._id))
                    customerInfoToggle()

                }} />) : null} </span>,

        },
        {
            dataField: "PaymentId",
            text: props.t("PaymentMethod"),
            sort: true,
            formatter: (cell, row) => <span>{cell?.map((item, index) => (
                <div key={"bss_" + index}>
                    <Row>
                        <Col md="6" xl="3">
                            <option key={"b_" + index} value={item._id}  >
                                {item.name}

                            </option>
                        </Col>
                        <Col md="6" xl="6">
                            <i style={{ fontSize: "18px" }} className="bx bx-info-circle"
                                onClick={e => {
                                    dispatch(onGetPaymentById(item._id))
                                    paymentInfoToggle()
                                    console.log(item._id)

                                }} />
                        </Col>


                    </Row>

                </div>



            ))}</span>,
        },

        {
            dataField: 'BranchId',
            text: props.t("Branch Name"),
            sort: true,
            formatter: (cell, row) => <span>{cell?.name}</span>,
        },


    ]

    const defaultSorted = [{
        dataField: 'createdAt',
        order: 'desc'
    }];

    const pageOptions = {
        sizePerPage: 10,
        totalSize: ProductSales ? ProductSales.length : 0, // replace later with size(customers),
        custom: true,
    }

    // Custom Pagination Toggle
    const sizePerPageList = [
        { text: '5', value: 5 },
        { text: '10', value: 10 },
        { text: '15', value: 15 },
        { text: '20', value: 20 },
        { text: '25', value: 25 },
        { text: 'All', value: ProductSales ? ProductSales.length : 0 }];


    // Select All Button operation
    const selectRow = {
        mode: 'checkbox'
    }

    const { SearchBar } = Search;

    const handleValidProductSaleSubmit = (e, values) => {
        if (isEdit) {
            const updateProductSale = {
                _id: selectedProductSale._id,
                ProductId: values.ProductId,
                CompanyId: values.CompanyId,
                StoreId: values["StoreId"],
                UserId: user.uid,
                ProductSerialId: values["ProductSerialId"],
                amount: values.amount,
                amountUnit: values["amountUnit"],
                score: values["score"],
                createdDate: values["createdDate"],

            };
            // update ProductSale
            dispatch(onUpdateProductSale(updateProductSale));
        } else {
            const newProductSale = {
                ProductId: values.ProductId,
                CompanyId: values.CompanyId,
                StoreId: values["StoreId"],
                UserId: user.uid,
                ProductSerialId: values["ProductSerialId"],
                amount: values.amount,
                amountUnit: values["amountUnit"],
                score: values["score"],
                createdDate: values["createdDate"],

            }
            // save new ProductSale
            dispatch(onAddProductSale(newProductSale))
        }
        toggle()
    }
    const handleValidDate = date => {
        if (date) {
            const date1 = moment(new Date(date)).format("DD.MM.YYYY");
            return date1;
        } else {
            return "";
        }
    }
    const handleValidDateAvField = date => {
        if (date) {
            const date1 = moment(new Date(date)).format("YYYY-MM-DD");
            return date1;
        } else {
            return "";
        }
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>{props.t("Product Sales")}</title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumb title={props.t("Product Sales")} breadcrumbItem={props.t("Product Sales")} addBtnCallBack={() => {
                        setIsEdit(false);
                        setSelectedProductSale(
                            {
                                ProductId: "",
                                CompanyId: "",
                                StoreId: "",
                                UserId: "",
                                ProductSerialId: "",
                                amount: "",
                                amountUnit: "",
                                score: "",
                                createdDate: "",

                            }
                        );
                        toggle();

                    }} />

                    {/* {error && error ? <Alert color="danger">{error}</Alert> : null} */}

                    <Card>
                        <CardBody>
                            <PaginationProvider
                                pagination={paginationFactory(pageOptions)}
                                keyField='id'
                                columns={columns}
                                data={ProcessListPayment}
                            >
                                {({ paginationProps, paginationTableProps }) => (
                                    <ToolkitProvider
                                        keyField='_id'
                                        columns={columns}
                                        data={ProcessListPayment}
                                        search
                                    >
                                        {toolkitProps => (
                                            <React.Fragment>

                                                <Row className="mb-2">
                                                    <Col md="4">
                                                        <div className="search-box me-2 mb-2 d-inline-block">
                                                            <div className="position-relative">
                                                                <SearchBar
                                                                    {...toolkitProps.searchProps}
                                                                />
                                                                <i className="bx bx-search-alt search-icon" />
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col xl="12">
                                                        <div className="table-responsive">
                                                            <BootstrapTable
                                                                keyField={"_id"}
                                                                responsive
                                                                bordered={false}
                                                                striped={false}
                                                                defaultSorted={defaultSorted}
                                                                classes={
                                                                    "table align-middle table-nowrap"
                                                                }
                                                                headerWrapperClasses={"thead-light"}
                                                                {...toolkitProps.baseProps}
                                                                {...paginationTableProps}
                                                            />

                                                        </div>
                                                    </Col>
                                                </Row>

                                                <Row className="align-items-md-center mt-30">
                                                    <Col className="inner-custom-pagination d-flex">
                                                        <div className="d-inline">
                                                            <SizePerPageDropdownStandalone
                                                                {...paginationProps}
                                                            />
                                                        </div>
                                                        <div className="text-md-right ms-auto">
                                                            <PaginationListStandalone
                                                                {...paginationProps}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </React.Fragment>
                                        )
                                        }
                                    </ToolkitProvider>
                                )
                                }</PaginationProvider>

                            <Modal isOpen={modal} toggle={toggle}>
                                <ModalHeader toggle={toggle} tag="h4">
                                    {!!isEdit ? props.t("Add Product") : props.t("Edit Product")}
                                </ModalHeader>
                                <ModalBody>
                                    <AvForm onValidSubmit={handleValidProductSaleSubmit}>
                                        <Row form>
                                            <Col xs={12}>


                                                <div className="mb-3">
                                                    <AvField type="select" name="ProductId" label={props.t("Select Product")}>
                                                        <option>{props.t("Select Product")}</option>
                                                        {Products?.map((item, index) => (
                                                            <option key={index} value={item._id}>{item.name}</option>

                                                        ))}
                                                    </AvField>
                                                </div>

                                                <div className="mb-3">
                                                    <AvField
                                                        name="StoreId"
                                                        label={props.t("Store Id")}
                                                        type="text"
                                                        errorMessage={props.t("InvalidInput")}
                                                        validate={{
                                                            required: { value: false },
                                                        }}
                                                        value={selectedProductSale?.StoreId || ""}
                                                    />
                                                </div>


                                                <div className="mb-3">
                                                    <AvField
                                                        name="amount"
                                                        label={props.t("Amount")}
                                                        type="text"
                                                        errorMessage={props.t("InvalidInput")}
                                                        validate={{
                                                            required: { value: false },
                                                        }}
                                                        value={selectedProductSale?.amount || ""}
                                                    />
                                                </div>


                                                <div className="mb-3">
                                                    <AvField
                                                        name="amountUnit"
                                                        label={props.t("Amount Unit")}
                                                        type="text"
                                                        errorMessage={props.t("InvalidInput")}
                                                        validate={{
                                                            required: { value: false },
                                                        }}
                                                        value={selectedProductSale?.amountUnit || ""}
                                                    />
                                                </div>

                                                <div className="mb-3">
                                                    <AvField
                                                        name="score"
                                                        label={props.t("Score")}
                                                        type="text"
                                                        errorMessage={props.t("InvalidInput")}
                                                        validate={{
                                                            required: { value: false },
                                                        }}
                                                        value={selectedProductSale?.score || ""}
                                                    />
                                                </div>
                                                <div className="mb-3">
                                                    <AvField
                                                        name="createdDate"
                                                        label={props.t("CreatedDate")}
                                                        type="date"
                                                        errorMessage={props.t("InvalidInput")}
                                                        validate={{
                                                            required: { value: false },
                                                        }}
                                                        value={
                                                            handleValidDateAvField(selectedProductSale?.createdDate)
                                                        }
                                                    />
                                                </div>


                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <div className="text-end">
                                                    <button
                                                        type="submit"
                                                        className="btn btn-success save-user"
                                                    >
                                                        {props.t("Save")}
                                                    </button>
                                                    &nbsp;&nbsp;
                                                    <Button onClick={() => { toggle() }}>
                                                        {props.t("Cancel")}
                                                    </Button>

                                                </div>
                                            </Col>
                                        </Row>
                                    </AvForm>
                                </ModalBody>
                            </Modal>
                        </CardBody>
                    </Card>
                    <ProductInfoModal modal={productInfoModal} toggle={() => { productInfoToggle() }}


                        Cancel={(e) => {

                            if (e == true) {
                                productInfoToggle()
                            }
                        }}
                    />
                    <CustomerInfoModal modal={customerInfoModal} toggle={() => { customerInfoToggle() }}


                        Cancel={(e) => {

                            if (e == true) {
                                customerInfoToggle()
                            }
                        }}
                    />
                    <PaymentInfoModal modal={paymentInfoModal} toggle={() => { paymentInfoToggle() }}


                        Cancel={(e) => {

                            if (e == true) {
                                paymentInfoToggle()
                            }
                        }}
                    />
                    {success_dlg ? (
                        <SweetAlert
                            success
                            title={dynamic_title}
                            confirmBtnText={props.t("Ok")}
                            onConfirm={() => {
                                setsuccess_dlg(false)
                            }}
                        >
                            {dynamic_description}
                        </SweetAlert>
                    ) : null}

                    {confirm_alert ? (
                        <SweetAlert
                            title={props.t("Are You Sure")}
                            warning
                            showCancel
                            confirmBtnBsStyle="success"
                            cancelBtnBsStyle="danger"
                            cancelBtnText={props.t("Cancel")}
                            confirmBtnText={props.t("Delete")}
                            onConfirm={() => {
                                setconfirm_alert(false);
                                setsuccess_dlg(true);
                                setdynamic_title(props.t("Deleted"));
                                setdynamic_description("");
                                dispatch(onDeleteProductSale(selectedId));
                                setSelectedId(null);
                            }}
                            onCancel={() => setconfirm_alert(false)}
                        >
                            {props.t("DeleteWarning")}
                        </SweetAlert>
                    ) : null}


                    {error && error ? (
                        <SweetAlert
                            title={props.t("Error")}
                            warning
                            confirmBtnText={props.t("Ok")}
                            onConfirm={() => {
                                dispatch(onResetProductSale())
                            }}
                        >
                            {JSON.stringify(error)}
                        </SweetAlert>
                    ) : null}

                </Container>
            </div>
        </React.Fragment>
    )
}

export default withRouter(withTranslation()(ProductSales))
