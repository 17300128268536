import {
    GET_EXPENSES,
    GET_EXPENSES_SUCCESS,
    GET_EXPENSES_FAIL,
    GET_EXPENSE_DETAIL,
    GET_EXPENSE_DETAIL_SUCCESS,
    GET_EXPENSE_DETAIL_FAIL,
    ADD_EXPENSE,
    ADD_EXPENSE_SUCCESS,
    ADD_EXPENSE_FAIL,
    UPDATE_EXPENSE,
    UPDATE_EXPENSE_SUCCESS,
    UPDATE_EXPENSE_FAIL,
    DELETE_EXPENSE,
    DELETE_EXPENSE_SUCCESS,
    DELETE_EXPENSE_FAIL,
    RESET_EXPENSE,
    GET_TYPE_LIST_BY_CATEGORY_ID,
    GET_TYPE_LIST_BY_CATEGORY_ID_SUCCESS,
    GET_TYPE_LIST_BY_CATEGORY_ID_FAIL
} from "./actionTypes";




export const resetExpense = () => ({
    type: RESET_EXPENSE,
});

export const getExpenses = () => ({
    type: GET_EXPENSES,
});

export const getExpensesSuccess = (expenses) => ({
    type: GET_EXPENSES_SUCCESS,
    payload: expenses,
});


export const getExpensesFail = (error) => ({
    type: GET_EXPENSES_FAIL,
    payload: error,
});

export const deleteExpense = (id) => ({
    type:  DELETE_EXPENSE,
    payload: id
});

export const deleteExpenseSuccess = (data) => ({
    type: DELETE_EXPENSE_SUCCESS,
    payload: data
});

export const deleteExpenseFail = (error) => ({
    type: DELETE_EXPENSE_FAIL,
    payload: error,
});


export const addExpense = (expense) => ({
    type: ADD_EXPENSE,
    payload: expense
});

export const addExpenseSuccess = (data) => ({
    type: ADD_EXPENSE_SUCCESS,
    payload: data
});

export const addExpenseFail = (error) => ({
    type: ADD_EXPENSE_FAIL,
    payload: error,
});


export const updateExpense = (expense) => ({
    type: UPDATE_EXPENSE,
    payload: expense
});

export const updateExpenseSuccess = (data) => ({
    type: UPDATE_EXPENSE_SUCCESS,
    payload: data
});

export const updateExpenseFail = (error) => ({
    type: UPDATE_EXPENSE_FAIL,
    payload: error,
});

export const getTypeListByCategoryId = (id) => ({
    type:  GET_TYPE_LIST_BY_CATEGORY_ID,
    payload: id
});
export const getTypeListByCategoryIdSuccess = (data) => ({
    type: GET_TYPE_LIST_BY_CATEGORY_ID_SUCCESS,
    payload: data,
});
export const getTypeListByCategoryIdFail = (error) => ({
    type: GET_TYPE_LIST_BY_CATEGORY_ID_FAIL,
    payload: error,
});