import React, { useState } from "react"
import PropTypes from 'prop-types'
import { Container, Row, Col, Modal, ModalHeader, ModalBody } from "reactstrap"
import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
} from "availity-reactstrap-validation"
import { useSelector, useDispatch } from "react-redux"
import {

  getProductsByBarcode as onGetProductsByBarcode,
  getProductIdBySerialNo as onGetProductIdBySerialNo,

} from "store/actions"
import { Link, withRouter } from "react-router-dom"
//i18n
import { withTranslation } from "react-i18next"

const SearchModal = (props, cb) => {
  const dispatch = useDispatch(); 
  //const [modal, setModal] = useState(false)
  return (
    <Modal isOpen={props.modal} toggle={props.toggle}>
      <ModalHeader toggle={props.toggle} tag="h4">
        {props.title}
        
      </ModalHeader>
      <ModalBody>
        <AvForm autoComplete={"off"}>
          <Row form>
            <Col xs={12}>
              <div className="p-4">
                <div className="text-center mt-4"> 
                  <AvField 
                    id="searchModal_barcode"
                    name="searchModal_barcode"
                    label={props.barcodeLabel}
                    type="text"
                    errorMessage={"invalid Barcode"}
                    validate={{
                      required: { value: false },
                    }}
                    value={""}  
                    onChange={(e)=> {
                      e.target.value.length>0 ? document.getElementById("searchModal_serialNo").setAttribute("disabled","disabled") : document.getElementById("searchModal_serialNo").removeAttribute("disabled");
                    }} 
                  />
                </div>
                <div className="mb-3 text-center mt-4">
                  <AvField 
                    id="searchModal_serialNo" 
                    name="searchModal_serialNo"
                    label={props.serialNoLabel}
                    type="text"
                    errorMessage={"invalid Serial No"}
                    validate={{
                      required: { value: false },
                    }}
                    value={""}
                    onChange={(e)=>
                    {
                      e.target.value.length>0 ? document.getElementById("searchModal_barcode").setAttribute("disabled","disabled") : document.getElementById("searchModal_barcode").removeAttribute("disabled");
                    }}
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row> 
            <Col>
              <div className="text-end">
                <button
                  className="btn btn-success " 
                  onClick={() => {  

                      if (searchModal_serialNo.value.length > 0) {
                       dispatch(onGetProductIdBySerialNo(searchModal_serialNo.value))
                       let _body={
                        txt:searchModal_serialNo.value,
                        isType:'serialNo'
                       };
                       props._cb(true, _body);
                     } else if (searchModal_barcode.value.length > 0) {
                      dispatch(onGetProductsByBarcode(searchModal_barcode.value))
                      let _body={
                        txt:searchModal_barcode.value,
                        isType:'barcode'
                       };
                       props._cb(true, _body);
                      } 
 
                  }}
                >
                  {props.find}
                </button>
              </div>
            </Col>
          </Row>
        </AvForm>
      </ModalBody>
    </Modal>
  )
}


SearchModal.propTypes = {
  toggle: PropTypes.func,
  title: PropTypes.string,
  barcodeLabel: PropTypes.string,
  serialNoLabel: PropTypes.string,
  find :PropTypes.string,
  modal: PropTypes.bool,
   _cb:PropTypes.func,
  
   t: PropTypes.any,
}


//export default SearchModal;
export default withRouter(
  (withTranslation()(SearchModal))
)
