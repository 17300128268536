import {
    GET_PAYMENTS,
    GET_PAYMENTS_SUCCESS,
    GET_PAYMENTS_FAIL,
    GET_PAYMENT_DETAIL,
    GET_PAYMENT_DETAIL_SUCCESS,
    GET_PAYMENT_DETAIL_FAIL,
    ADD_PAYMENT,
    ADD_PAYMENT_SUCCESS,
    ADD_PAYMENT_FAIL,
    UPDATE_PAYMENT,
    UPDATE_PAYMENT_SUCCESS,
    UPDATE_PAYMENT_FAIL,
    DELETE_PAYMENT,
    DELETE_PAYMENT_SUCCESS,
    DELETE_PAYMENT_FAIL,
    RESET_PAYMENT
} from "./actionTypes";

export const resetPayment = () => ({ 
    type: RESET_PAYMENT,
});
export const getPaymentById = (id) => ({
    type:  GET_PAYMENT_DETAIL,
    payload: id
});

export const getPaymentByIdSuccess = (data) => ({
    type: GET_PAYMENT_DETAIL_SUCCESS,
    payload: data
});

export const getPaymentByIdFail = (error) => ({
    type: GET_PAYMENT_DETAIL_FAIL,
    payload: error,
}); 
export const getPayments = () => ({
    type: GET_PAYMENTS,
});

export const getPaymentsSuccess = (payments) => ({
    type: GET_PAYMENTS_SUCCESS,
    payload: payments,
});


export const getPaymentsFail = (error) => ({
    type: GET_PAYMENTS_FAIL,
    payload: error,
});

export const deletePayment = (id) => ({
    type:  DELETE_PAYMENT,
    payload: id
});

export const deletePaymentSuccess = (data) => ({
    type: DELETE_PAYMENT_SUCCESS,
    payload: data
});

export const deletePaymentFail = (error) => ({
    type: DELETE_PAYMENT_FAIL,
    payload: error,
});


export const addPayment = (payment) => ({
    type: ADD_PAYMENT,
    payload: payment
});

export const addPaymentSuccess = (data) => ({
    type: ADD_PAYMENT_SUCCESS,
    payload: data
});

export const addPaymentFail = (error) => ({
    type: ADD_PAYMENT_FAIL,
    payload: error,
});


export const updatePayment = (payment) => ({
    type: UPDATE_PAYMENT,
    payload: payment
});

export const updatePaymentSuccess = (data) => ({
    type: UPDATE_PAYMENT_SUCCESS,
    payload: data
});

export const updatePaymentFail = (error) => ({
    type: UPDATE_PAYMENT_FAIL,
    payload: error,
});