import {

    GET_PRODUCTS_BY_BARCODE,
    GET_PRODUCTS_BY_BARCODE_SUCCESS,
    GET_PRODUCTS_BY_BARCODE_FAIL,
    GET_PRODUCTID_BY_SERIALNO,
    GET_PRODUCTID_BY_SERIALNO_SUCCESS,
    GET_PRODUCTID_BY_SERIALNO_FAIL,
    GET_PRODUCT_BY_ID,
    GET_PRODUCT_BY_ID_SUCCESS,
    GET_PRODUCT_BY_ID_FAIL,
    GET_CUSTOMER_BY_IDENTITY,
    GET_CUSTOMER_BY_IDENTITY_SUCCESS,
    GET_CUSTOMER_BY_IDENTITY_FAIL,
    GET_CUSTOMER_BY_PHONE,
    GET_CUSTOMER_BY_PHONE_SUCCESS,
    GET_CUSTOMER_BY_PHONE_FAIL,
    GET_CUSTOMER_BY_TAXNO,
    GET_CUSTOMER_BY_TAXNO_SUCCESS,
    GET_CUSTOMER_BY_TAXNO_FAIL,
    RESET_PROCESS_LIST

} from "./actionTypes";

export const getCustomerByTaxNo = (taxNumber) => ({
    type: GET_CUSTOMER_BY_TAXNO,
    payload: taxNumber
});
export const getCustomerByTaxNoSuccess = (data) => ({
    type: GET_CUSTOMER_BY_TAXNO_SUCCESS,
    payload: data,
});
export const getCustomerByTaxNoFail = (error) => ({
    type: GET_CUSTOMER_BY_TAXNO_FAIL,
    payload: error,
});
export const getCustomerByPhone = (phoneNumber) => ({
    type: GET_CUSTOMER_BY_PHONE,
    payload: phoneNumber
});
export const getCustomerByPhoneSuccess = (data) => ({
    type: GET_CUSTOMER_BY_PHONE_SUCCESS,
    payload: data,
});
export const getCustomerByPhoneFail = (error) => ({
    type: GET_CUSTOMER_BY_PHONE_FAIL,
    payload: error,
});
export const getCustomerByIdentity = (identityNumber) => ({
    type: GET_CUSTOMER_BY_IDENTITY,
    payload: identityNumber
});
export const getCustomerByIdentitySuccess = (data) => ({
    type: GET_CUSTOMER_BY_IDENTITY_SUCCESS,
    payload: data,
});
export const getCustomerByIdentityFail = (error) => ({
    type: GET_CUSTOMER_BY_IDENTITY_FAIL,
    payload: error,
});

export const getProductIdBySerialNo = (serialNo) => ({
    type: GET_PRODUCTID_BY_SERIALNO,
    payload: serialNo
});
export const getProductIdBySerialNoSuccess = (data) => ({
    type: GET_PRODUCTID_BY_SERIALNO_SUCCESS,
    payload: data,
});
export const getProductIdBySerialNoFail = (error) => ({
    type: GET_PRODUCTID_BY_SERIALNO_FAIL,
    payload: error,
});

export const getProductsByBarcode = (barcode) => ({
    type: GET_PRODUCTS_BY_BARCODE,
    payload: barcode
});
export const getProductsByBarcodeSuccess = (data) => ({
    type: GET_PRODUCTS_BY_BARCODE_SUCCESS,
    payload: data,
});
export const getProductsByBarcodeFail = (error) => ({
    type: GET_PRODUCTS_BY_BARCODE_FAIL,
    payload: error,
});
export const getProductById = (id) => ({
    type: GET_PRODUCT_BY_ID,
    payload: id
});
export const getProductByIdSuccess = (data) => ({
    type: GET_PRODUCT_BY_ID_SUCCESS,
    payload: data,
});
export const getProductByIdFail = (error) => ({
    type: GET_PRODUCT_BY_ID_FAIL,
    payload: error,
});

export const resetProcessList = () => (
    {
        type: RESET_PROCESS_LIST
    }
)