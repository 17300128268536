import {

    GET_PRODUCTS_BY_BARCODE_SUCCESS,
    GET_PRODUCTS_BY_BARCODE_FAIL,
    GET_PRODUCTID_BY_SERIALNO_SUCCESS,
    GET_PRODUCTID_BY_SERIALNO_FAIL,
    GET_PRODUCT_BY_ID_SUCCESS,
    GET_PRODUCT_BY_ID_FAIL,
    GET_CUSTOMER_BY_IDENTITY_SUCCESS,
    GET_CUSTOMER_BY_IDENTITY_FAIL,
    GET_CUSTOMER_BY_PHONE_SUCCESS,
    GET_CUSTOMER_BY_PHONE_FAIL,
    GET_CUSTOMER_BY_TAXNO_SUCCESS,
    GET_CUSTOMER_BY_TAXNO_FAIL,
    RESET_PROCESS_LIST
} from "./actionTypes";

const INIT_STATE = {
    data: [],
    error: null,
    SubCategories: [],
    Product: null,
    ProductId: null,
    id: null,
    customer: null
};

const ProcessList = (state = INIT_STATE, action) => {
    switch (action.type) {

        case GET_PRODUCTS_BY_BARCODE_SUCCESS:
            return {
                ...state,
                Product: action.payload.data
            }
        case GET_PRODUCTID_BY_SERIALNO_SUCCESS:
            return {
                ...state,
                ProductId: action.payload.data
            }
        case GET_PRODUCT_BY_ID_SUCCESS:
            return {
                ...state,
                id: action.payload.data
            }
        case GET_CUSTOMER_BY_PHONE_SUCCESS:
            return {
                ...state,
                phoneNumber: action.payload.data
            }
        case GET_CUSTOMER_BY_IDENTITY_SUCCESS:
            return {
                ...state,
                identityNumber: action.payload.data
            }
        case GET_CUSTOMER_BY_TAXNO_SUCCESS:
            return {
                ...state,
                taxNumber: action.payload.data
            }
        //FAILS 

        case GET_PRODUCTS_BY_BARCODE_FAIL:
            return {
                ...state,
                error: action.payload
            }
        case GET_CUSTOMER_BY_IDENTITY_FAIL:
            return {
                ...state,
                error: action.payload
            }
        case GET_PRODUCTID_BY_SERIALNO_FAIL:
            return {
                ...state,
                error: action.payload
            }
        case GET_PRODUCT_BY_ID_FAIL:
            return {
                ...state,
                error: action.payload
            }
        case GET_CUSTOMER_BY_PHONE_FAIL:
            return {
                ...state,
                error: action.payload
            }
        case GET_CUSTOMER_BY_TAXNO_FAIL:
            return {
                ...state,
                error: action.payload
            }
        case RESET_PROCESS_LIST:
            return {
                ...state,
                error: null
            }

        default:
            return state;
    }
}


export default ProcessList;