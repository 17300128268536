import { call, put, takeEvery } from "redux-saga/effects";

// Crypto Redux States
import { GET_PRODUCTCATEGORIES, DELETE_PRODUCTCATEGORY, ADD_PRODUCTCATEGORY, UPDATE_PRODUCTCATEGORY} from "./actionTypes";
import {
    getProductCategoriesSuccess, getProductCategoriesFail,
    deleteProductCategorySuccess, deleteProductCategoryFail,
    updateProductCategorySuccess, updateProductCategoryFail,
    addProductCategorySuccess, addProductCategoryFail
 } from "./actions";

//Include Both Helper File with needed methods
import {
    getProductCategories, //API CONNECTION
    delProductCategory,
    addProductCategory,
    updateProductCategory
}
    from "../../helpers/fakebackend_helper"; 

function* fetchProductCategories() {
    try {
        const response = yield call(getProductCategories)
       
        yield put(getProductCategoriesSuccess(response));
    } catch (error) {
        yield put(getProductCategoriesFail(error));
    }
}

function* onDeleteProductCategories({payload:id}){
    try{
        const response = yield call(delProductCategory, id)
        yield put(deleteProductCategorySuccess(response));
    } catch (error) {
        yield put(deleteProductCategoryFail(error));
    }
}

function* onAddProductCategory({payload:productCategory}){
    try{
        const response = yield call(addProductCategory, productCategory)
        yield put(addProductCategorySuccess(response));
    } catch (error) {
        yield put(addProductCategoryFail(error));
    }
}

function* onUpdateProductCategory({payload:productCategory}){
    try{
        const response = yield call(updateProductCategory, productCategory)
        yield put(updateProductCategorySuccess(response));
    } catch (error) {
        yield put(updateProductCategoryFail(error));
    }
}

export function* productCategoriesSaga() {
    yield takeEvery(GET_PRODUCTCATEGORIES, fetchProductCategories);
    yield takeEvery(DELETE_PRODUCTCATEGORY, onDeleteProductCategories);
    yield takeEvery(ADD_PRODUCTCATEGORY, onAddProductCategory);
    yield takeEvery(UPDATE_PRODUCTCATEGORY, onUpdateProductCategory);
}

export default productCategoriesSaga;
