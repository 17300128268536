import { all, fork } from "redux-saga/effects"

//public
import AccountSaga from "./auth/register/saga"
import AuthSaga from "./auth/login/saga"
import ForgetSaga from "./auth/forgetpwd/saga" 
import changePasswordSaga from "./auth/changePassword/saga"
import changeSelectedBranch from "./auth/changeSelectedBranch/saga"
import ConfirmSaga from "./auth/confirm/saga"
import ProfileSaga from "./auth/profile/saga"
import LayoutSaga from "./layout/saga" 
import dashboardSaga from "./dashboard/saga";
import erpsSaga from "./erps/saga";
import companiesSaga from "./companies/saga";
import customersSaga from "./customers/saga";
import branchsSaga from "./branchs/saga";
import paymentsSaga from "./payment/saga";
import productSalesSaga from "./productSale/saga";
import productsSeriesSaga from "./productSeries/saga";
import productsSaga from "./product/saga";
import paymentPlansSaga from "./paymentPlan/saga";
import posMachinesSaga from "./posMachine/saga";
import bankAccountsSaga from "./bankAccounts/saga";
import expenseSaga from "./expense/saga";
import mainCategorySaga from "./mainCategory/saga";
import productCategorySaga from "./productCategory/saga"
import subCategorySaga from "./subCategory/saga";
import processListSaga from "./processList/saga";
import endOfDayBalancingSaga from "./endOfDayBalancing/saga"; 
import saleModelsSaga from "./salesModel/saga";
import collectionSaga from "./collections/saga"
import processListPaymentSaga from "./processListPayment/saga"

export default function* rootSaga() {
  yield all([
    //public
    fork(AccountSaga),
    fork(AuthSaga),
    fork(ForgetSaga),
    fork(changePasswordSaga),
    fork(changeSelectedBranch),
    fork(ConfirmSaga),
    fork(ProfileSaga),
    fork(LayoutSaga), 
    fork(dashboardSaga), 
    fork(erpsSaga),
    fork(companiesSaga), 
    fork(customersSaga), 
    fork(branchsSaga), 
    fork(paymentsSaga), 
    fork(productsSeriesSaga), 
    fork(productsSaga), 
    fork(productSalesSaga), 
    fork(paymentPlansSaga), 
    fork(posMachinesSaga), 
    fork(bankAccountsSaga), 
    fork(expenseSaga), 
    fork(mainCategorySaga), 
    fork(subCategorySaga), 
    fork(processListSaga), 
    fork(endOfDayBalancingSaga),  
    fork(saleModelsSaga), 
    fork(collectionSaga), 
    fork(processListPaymentSaga), 
    fork(productCategorySaga), 




  ])
}
