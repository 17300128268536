import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useState, useEffect } from "react"
import {
    Container,
    Row,
    Col,
    Card,
    Alert,
    CardBody,
    Button,
    UncontrolledDropdown,
    UncontrolledTooltip,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Modal,
    ModalHeader,
    ModalBody,
    Label,
} from "reactstrap"
//redux
import { useSelector, useDispatch } from "react-redux"

import { withRouter } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"

import {
    getMainCategories as onGetMainCategories,
    deleteMainCategory as onDeleteMainCategory,
    addMainCategory as onAddMainCategory,
    updateMainCategory as onUpdateMainCategory,
    resetMainCategory as onResetMainCategory,
    getCompanyBranches as onGetCompanyBranches,
} from "store/actions"

import { isEmpty, map } from "lodash"

import { AvForm, AvField, AvGroup, AvInput, AvFeedback, AvRadioGroup, AvRadio, AvCheckboxGroup, AvCheckbox } from 'availity-reactstrap-validation';

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
    PaginationProvider, PaginationListStandalone,
    SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert"

import "./datatables.scss"

const MainCategories = props => {
    const dispatch = useDispatch()

    const [selectedId, setSelectedId] = useState();
    const [selectedMainCategory, setSelectedMainCategory] = useState();
    const [success_dlg, setsuccess_dlg] = useState(false);
    const [confirm_alert, setconfirm_alert] = useState(false);
    const [dynamic_title, setdynamic_title] = useState("");
    const [dynamic_description, setdynamic_description] = useState("");
    const [modal, setModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [modalSubCategories, setModalSubCategories] = useState(false);
    const [modalSubCategoriesCheck, setModalSubCategoriesCheck] = useState(false);
    const [selectedBranchId, setSelectedBranchId] = useState()

    const [subCategories, setSubCategories] = useState([
        {
            name: "",
          
        }
    ]);
    const { error, MainCategories,Branches} = useSelector(state => ({
        error: state.MainCategories?.error,
        MainCategories: state.MainCategories?.data,
        Branches : state.Branchs?.Branches
    }))
    let user = JSON.parse(localStorage.getItem("authUser"))
    useEffect(() => {
        dispatch(onGetMainCategories());
        dispatch(onGetCompanyBranches(user.companyId))
    }, [dispatch]);

    const toggle = () => {
        setModal(!modal)
    }
    const toggleSubCategories = () => {
        setModalSubCategories(!modalSubCategories);
    }
    const toggleSubCategoriesCheck = () => {
        setModalSubCategoriesCheck(!modalSubCategoriesCheck);
    }
    const handleChangeInput = (i, e, inputName) => {
        const values = [...subCategories]
        values[i][inputName] = e.target.value
        setSubCategories(values)
    }
    const handleSubtract = (i) => {
        const values = [...subCategories]
        values.splice(i, 1)
        setSubCategories([...values])
    }
    const handleAdd = () => {
        setSubCategories([...subCategories,
        {
            name:""
        }])
    }
    const InputAddReset = () => {
        let tempInput = [
            {
               name : ""
            }
        ];
        setSubCategories(tempInput);
    }

    const columns = [
        {
            dataField: 'category',
            text: props.t("MainCategory"),
            sort: true,
            
             
        }, 
        {
            dataField: 'Sub Categories',
            text: props.t("Sub Categories"),
            sort: true,

             formatter: (cell, row) => (
                <Button
                        type="submit"
                        className="btn-success me-2"
                        onClick={() => {
                            setSubCategories(row.subCategories);
                            toggleSubCategoriesCheck()
                        }}
                      >
                        {props.t("Sub Categories")}
                      </Button>
               
            )

        }, {
            dataField: '_id',
            text: props.t("Action"),
            sort: false,
            formatter: (cell, row) => (
                <UncontrolledDropdown style={{ position: "unset" }}>
                    <DropdownToggle href="#" className="card-drop" tag="i">
                        <i className="mdi mdi-dots-horizontal font-size-18"></i>
                    </DropdownToggle>
                    <div className="drop-mainAbsolute">
                    <DropdownMenu className="dropdown-menu-end">
                        <DropdownItem href="#"
                            onClick={() => {
                                setSelectedMainCategory(row);
                                setSelectedBranchId(row.BranchId?._id)
                                console.log(row)
                                if (isEmpty(row.subCategories) || row.subCategories.length == 0) {
                                    InputAddReset();
                                    
                                }
                                else {
                                    setSubCategories(row.subCategories);
                                   
                                }
                                setIsEdit(true);
                                setIsEdit(true);
                                toggle();
                            }}
                        >
                            <i className="mdi mdi-pencil font-size-16 text-success me-1"></i>
                            {props.t("Edit")}
                        </DropdownItem>
                        {/* <DropdownItem href="#"
                            onClick={() => {
                                setSelectedId(cell);
                                setconfirm_alert(true);
                            }}>
                            <i className="mdi mdi-trash-can font-size-16 text-danger me-1"></i>
                            {props.t("Delete")}
                        </DropdownItem> */}
                    </DropdownMenu>
                        </div>
                   
                </UncontrolledDropdown>
            )
        }];

    const defaultSorted = [{
        dataField: 'createdAt',
        order: 'desc'
    }];

    const pageOptions = {
        sizePerPage: 10,
        totalSize: MainCategories ? MainCategories.length : 0, // replace later with size(MainCategory),
        custom: true,
    }

    // Custom Pagination Toggle
    const sizePerPageList = [
        { text: '5', value: 5 },
        { text: '10', value: 10 },
        { text: '15', value: 15 },
        { text: '20', value: 20 },
        { text: '25', value: 25 },
        { text: 'All', value: MainCategories ? MainCategories.length : 0 }];


    // Select All Button operation
    const selectRow = { 
        mode: 'checkbox'
    } 
    
    const { SearchBar } = Search;

    const handleValidMainCategorySubmit = (e, values) => {
        if (isEdit) {
            const updateMainCategory = {
                _id: selectedMainCategory._id,
                category: values.category,
                subCategories: document.getElementById("SubCategories").value,
                CompanyId: user.companyId,
             
                
            };
            // update MainCategory
          //console.log(updateMainCategory)
            dispatch(onUpdateMainCategory(updateMainCategory));
        } else {
            const newMainCategory = {
                category: values["category"],
                subCategories: document.getElementById("SubCategories").value,
                CompanyId: user.companyId,
               
            }
            // save new MainCategory
           
            dispatch(onAddMainCategory(newMainCategory))
            //console.log(newMainCategory)
        }
        toggle()
    }
 
    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>{props.t("Expenses")}</title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumb title={props.t("Expenses")} breadcrumbItem={props.t("Expenses")} addBtn={true} addBtnCallBack={() => {
                        setIsEdit(false);
                        setSelectedMainCategory(
                            {
                                category: "",
                                SubCategories:[],
                               }
                        );
                        toggle();

                    }} />

                    {/* {error && error ? <Alert color="danger">{error}</Alert> : null} */}

                    <Card>
                        <CardBody>
                            <PaginationProvider
                                pagination={paginationFactory(pageOptions)}
                                keyField='id'
                                columns={columns}
                                data={MainCategories}
                            >
                                {({ paginationProps, paginationTableProps }) => (
                                    <ToolkitProvider
                                        keyField='_id'
                                        columns={columns}
                                        data={MainCategories}
                                        search
                                    >
                                        {toolkitProps => (
                                            <React.Fragment>

                                                <Row className="mb-2">
                                                    <Col md="4">
                                                        <div className="search-box me-2 mb-2 d-inline-block">
                                                            <div className="position-relative">
                                                                <SearchBar
                                                                    {...toolkitProps.searchProps}
                                                                />
                                                                <i className="bx bx-search-alt search-icon" />
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col xl="12">
                                                        <div className="table-responsive">
                                                            <BootstrapTable
                                                                keyField={"_id"}
                                                                responsive
                                                                bordered={false}
                                                                striped={false}
                                                                defaultSorted={defaultSorted}
                                                                classes={
                                                                    "table align-middle table-nowrap"
                                                                }
                                                                headerWrapperClasses={"thead-light"}
                                                                {...toolkitProps.baseProps}
                                                                {...paginationTableProps}
                                                            />

                                                        </div>
                                                    </Col>
                                                </Row>

                                                <Row className="align-items-md-center mt-30">
                                                    <Col className="inner-custom-pagination d-flex">
                                                        <div className="d-inline">
                                                            <SizePerPageDropdownStandalone
                                                                {...paginationProps}
                                                            />
                                                        </div>
                                                        <div className="text-md-right ms-auto">
                                                            <PaginationListStandalone
                                                                {...paginationProps}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </React.Fragment>
                                        )
                                        }
                                    </ToolkitProvider>
                                )
                                }</PaginationProvider>
                            </CardBody>
                                     </Card>
                           
                            <Modal isOpen={modal} toggle={toggle}>
                                <ModalHeader toggle={toggle} tag="h4">
                                    {!!isEdit ? props.t("Edit Category For Expense") : props.t("Category For Expense")}
                                    
                                </ModalHeader>
                                <ModalBody>
                                    <AvForm onValidSubmit={handleValidMainCategorySubmit}>
                                        <Row form>
                                            <Col xs={12}>

                                            <input
                                            id="SubCategories"
                                            name="SubCategories"
                                            type="hidden"
                                        />
                                            

                                        
                                                <div className="mb-3">
                                                    <AvField
                                                        name="category"
                                                        label={props.t("MainCategory")}
                                                        type="text"
                                                        errorMessage={props.t("category")}
                                                        validate={{
                                                            required: { value: false },
                                                        }}
                                                        value={selectedMainCategory?.category || ""}
                                                    />
                                                </div>
                                                
                                               
                                                

                                            </Col>
                                        </Row>
                                        <Row>
                                    <Col className="mb-4" >
                                        <div className="text-end mb-3">
                                            <Button
                                                className="btn btn-success"
                                                onClick={() => { toggleSubCategories() }}

                                            >
                                                {props.t("Add Sub Category")}
                                            </Button>

                                        </div>
                                    </Col>
                                </Row>
                                        <Row>
                                            <Col>
                                            <div className="text-end">
                                            <button 
                                                type="submit"
                                                className="btn btn-success save-user"
                                            >
                                                {props.t("Save")}
                                            </button>
                                            &nbsp;&nbsp;
                                            <Button onClick={()=>{ toggle() }}>
                                                    {props.t("Cancel")}
                                                    </Button>       
                                            
                                        </div>
                                            </Col>
                                        </Row>
                                    </AvForm>
                                </ModalBody>
                            </Modal>
                       
                            <Modal isOpen={modalSubCategories} toggle={toggleSubCategories} >
                        <ModalHeader toggle={toggleSubCategories} tag="h4">
                            {props.t("SubCategories")}
                        </ModalHeader>
                        <ModalBody>
                            <small className="mb-3">
                                {props.t("Please Enter SubCategories")}

                            </small>
                            <br /> <br />

                            <AvForm>
                                <Row form>
                                    <Col xs={12} className="pb-4">
                                        <Row className="mt-2">
                                            <Col md="4">{props.t("SubCategory")}</Col>
                                            <Col md="3">{props.t()}</Col>
                                            <Col md="3"></Col>
                                            <Col md="2"></Col>
                                        </Row>
                                        {subCategories.map((item, i) => (
                                            <div key={i}>
                                                <input
                                                    id={"subCategories_" + (i) + "__isValue"}
                                                    name={"subCategories[" + (i) + "].isValue"}
                                                    type="hidden"
                                                />
                                                <Row className="mt-2">
                                                    <Col md="8">
                                                        <AvField
                                                            type="text"
                                                            id={"subCategories_" + (i) + "__name"}
                                                            name={"subCategories[" + (i) + "].name"}
                                                            placeholder={props.t("SubCategory")}
                                                            value={item.name} 
                                                            onChange={e => handleChangeInput(i, e, "name")}
                                                            validate={{
                                                                required: { value: false },
                                                            }}
                                                        />
                                                    </Col> 
                                                   
                                                   
                                                    <Col md="4">
                                                        {i == subCategories.length - 1 ?
                                                            <div>
                                                                <Button onClick={() => handleAdd()} className="btn btn-success me-2">
                                                                    <i className="fas fa-plus"></i>
                                                                </Button>
                                                                {subCategories.length > 1 ? <Button onClick={() => handleSubtract(i)}>
                                                                    <i className="fas fa-minus"></i>
                                                                </Button> : null}
                                                            </div> : null
                                                        }
                                                    </Col>

                                                </Row>
                                            </div>
                                        ))}
                                    </Col>
                                </Row>

                                <Row>
                                    <Col >
                                        <div className="text-end">
                                            <Button onClick={() => {
                                                document.getElementById("SubCategories").value = JSON.stringify(subCategories); 
                                               
                                                toggleSubCategories();
                                            }} className="btn btn-success save-user">
                                                {props.t("Save")}
                                            </Button>
                                            &nbsp;&nbsp;
                                            <Button onClick={() => {
                                                toggleSubCategories();
                                            }}
                                            >
                                                {props.t("Cancel")}
                                            </Button>

                                        </div>
                                    </Col>
                                </Row>
                            </AvForm>
                        </ModalBody>
                    </Modal>
                    <Modal isOpen={modalSubCategoriesCheck} toggle={toggleSubCategoriesCheck} >
                        <ModalHeader toggle={toggleSubCategoriesCheck} tag="h4">
                            {props.t("SubCategories")}
                        </ModalHeader>
                        <ModalBody>
                           

                            <AvForm>
                                <Row form>
                                    <Col xs={12} className="pb-4">
                                        <Row className="mt-2">
                                            <Col md="4">{props.t("SubCategory")}</Col>
                                            <Col md="3">{props.t()}</Col>
                                            <Col md="3"></Col>
                                            <Col md="2"></Col>
                                        </Row>
                                        {subCategories.map((item, i) => (
                                            <div key={i}>
                                                <input
                                                    id={"subCategories_" + (i) + "__isValue"}
                                                    name={"subCategories[" + (i) + "].isValue"}
                                                    type="hidden"
                                                />
                                                <Row className="mt-2">
                                                    <Col md="8">
                                                        <AvField
                                                            type="text"
                                                            id={"subCategories_" + (i) + "__name"}
                                                            name={"subCategories[" + (i) + "].name"}
                                                            placeholder={props.t("SubCategory")}
                                                            value={item.name} 
                                                            onChange={e => handleChangeInput(i, e, "name")}
                                                            validate={{
                                                                required: { value: false },
                                                            }}
                                                        />
                                                    </Col> 
                                                   
                                                   
                                                    

                                                </Row>
                                            </div>
                                        ))}
                                    </Col>
                                </Row>

                                <Row>
                                    <Col >
                                        <div className="text-end">
                                           
                                           
                                            <Button onClick={() => {
                                                toggleSubCategoriesCheck();
                                            }}
                                            >
                                                {props.t("Cancel")}
                                            </Button>

                                        </div>
                                    </Col>
                                </Row>
                            </AvForm>
                        </ModalBody>
                    </Modal>

                    {success_dlg ? (
                        <SweetAlert
                            success
                            title={dynamic_title}
                            confirmBtnText={props.t("Ok")}
                            onConfirm={() => {
                                setsuccess_dlg(false)
                            }}
                        >
                            {dynamic_description}
                        </SweetAlert>
                    ) : null}

                    {confirm_alert ? (
                        <SweetAlert
                            title={props.t("Are You Sure")}
                            warning
                            showCancel
                            confirmBtnBsStyle="success"
                            cancelBtnBsStyle="danger"
                            cancelBtnText={props.t("Cancel")}
                            confirmBtnText={props.t("Delete")}
                            onConfirm={() => {
                                setconfirm_alert(false);
                                setsuccess_dlg(true);
                                setdynamic_title(props.t("Deleted"));
                                setdynamic_description("");
                                dispatch(onDeleteMainCategory(selectedId));
                                setSelectedId(null);
                            }}
                            onCancel={() => setconfirm_alert(false)}
                        >
                            {props.t("DeleteWarning")}
                        </SweetAlert>
                    ) : null}


                    {error && error ? (
                        <SweetAlert
                            title={props.t("Error")}
                            warning
                            confirmBtnText={props.t("Ok")}
                            onConfirm={() => {
                                dispatch(onResetMainCategory())
                            }}
                        >
                            {JSON.stringify(error)}
                        </SweetAlert>
                    ) : null}

                </Container>
            </div>
        </React.Fragment>
    )
}

export default withRouter(withTranslation()(MainCategories))
