/** Get PAYMENT_PLAN */
export const GET_PAYMENT_PLANS = "GET_PAYMENT_PLANS";
export const GET_PAYMENT_PLANS_SUCCESS = "GET_PAYMENT_PLANS_SUCCESS";
export const GET_PAYMENT_PLANS_FAIL = "GET_PAYMENT_PLANS_FAIL";

export const GET_PAYMENT_PLAN_DETAIL = "GET_PAYMENT_PLAN_DETAIL";
export const GET_PAYMENT_PLAN_DETAIL_SUCCESS = "GET_PAYMENT_PLAN_DETAIL_SUCCESS";
export const GET_PAYMENT_PLAN_DETAIL_FAIL = "GET_PAYMENT_PLAN_DETAIL_FAIL";


export const ADD_PAYMENT_PLAN = "ADD_PAYMENT_PLAN";
export const ADD_PAYMENT_PLAN_SUCCESS = "ADD_PAYMENT_PLAN_SUCCESS";
export const ADD_PAYMENT_PLAN_FAIL = "ADD_PAYMENT_PLAN_FAIL";

export const UPDATE_PAYMENT_PLAN = "UPDATE_PAYMENT_PLAN";
export const UPDATE_PAYMENT_PLAN_SUCCESS = "UPDATE_PAYMENT_PLAN_SUCCESS";
export const UPDATE_PAYMENT_PLAN_FAIL = "UPDATE_PAYMENT_PLAN_FAIL";

export const DELETE_PAYMENT_PLAN = "DELETE_PAYMENT_PLAN";
export const DELETE_PAYMENT_PLAN_SUCCESS = "DELETE_PAYMENT_PLAN_SUCCESS";
export const DELETE_PAYMENT_PLAN_FAIL = "DELETE_PAYMENT_PLAN_FAIL"; 

export const GET_PAYMENT_PLANS_MY_PLANS = "GET_PAYMENT_PLANS_MY_PLANS";
export const GET_PAYMENT_PLANS_MY_PLANS_SUCCESS = "GET_PAYMENT_PLANS_MY_PLANS_SUCCESS";
export const GET_PAYMENT_PLANS_MY_PLANS_FAIL = "GET_PAYMENT_PLANS_MY_PLANS_FAIL";

export const RESET_PAYMENT_PLAN= "RESET_PAYMENT_PLAN";