import { call, put, takeEvery } from "redux-saga/effects";

// Crypto Redux States
import { GET_EXPENSES, DELETE_EXPENSE, ADD_EXPENSE, UPDATE_EXPENSE, GET_TYPE_LIST_BY_CATEGORY_ID} from "./actionTypes";
import {
    getExpensesSuccess, getExpensesFail,
    deleteExpenseSuccess, deleteExpenseFail,
    updateExpenseSuccess, updateExpenseFail,
    addExpenseSuccess, addExpenseFail,
    getTypeListByCategoryIdSuccess, getTypeListByCategoryIdFail
 } from "./actions";

//Include Both Helper File with needed methods
import {
    getExpenses, //API CONNECTION
    delExpense,
    addExpense,
    updateExpense,
    getTypeListByCategoryId
}
    from "../../helpers/fakebackend_helper"; 

function* fetchExpenses() {
    try {
        const response = yield call(getExpenses)
       
        yield put(getExpensesSuccess(response));
    } catch (error) {
        yield put(getExpensesFail(error));
    }
}

function* onDeleteExpenses({payload:id}){
    try{
        const response = yield call(delExpense, id)
        yield put(deleteExpenseSuccess(response));
    } catch (error) {
        yield put(deleteExpenseFail(error));
    }
}

function* onAddExpense({payload:expense}){
    try{
        const response = yield call(addExpense, expense)
        yield put(addExpenseSuccess(response));
    } catch (error) {
        yield put(addExpenseFail(error));
    }
}

function* onUpdateExpense({payload:expense}){
    try{
        const response = yield call(updateExpense, expense)
        yield put(updateExpenseSuccess(response));
    } catch (error) {
        yield put(updateExpenseFail(error));
    }
}
 
function* onGetTypeListByCategoryId({payload:id}){
    try{
        const response = yield call(getTypeListByCategoryId, id)
        yield put(getTypeListByCategoryIdSuccess(response));
    } catch (error) {
        yield put(getTypeListByCategoryIdFail(error));
    }
}

export function* expensesSaga() {
    yield takeEvery(GET_EXPENSES, fetchExpenses);
    yield takeEvery(DELETE_EXPENSE, onDeleteExpenses);
    yield takeEvery(ADD_EXPENSE, onAddExpense);
    yield takeEvery(UPDATE_EXPENSE, onUpdateExpense);
    yield takeEvery(GET_TYPE_LIST_BY_CATEGORY_ID, onGetTypeListByCategoryId);
}

export default expensesSaga;
