/** Get Customers */
export const GET_CUSTOMERS = "GET_CUSTOMERS";
export const GET_CUSTOMERS_SUCCESS = "GET_CUSTOMERS_SUCCESS";
export const GET_CUSTOMERS_FAIL = "GET_CUSTOMERS_FAIL";

export const GET_CUSTOMER_DETAIL = "GET_CUSTOMER_DETAIL";
export const GET_CUSTOMER_DETAIL_SUCCESS = "GET_CUSTOMER_DETAIL_SUCCESS";
export const GET_CUSTOMER_DETAIL_FAIL = "GET_CUSTOMER_DETAIL_FAIL";


export const ADD_CUSTOMER = "ADD_CUSTOMER";
export const ADD_CUSTOMER_SUCCESS = "ADD_CUSTOMER_SUCCESS";
export const ADD_CUSTOMER_FAIL = "ADD_CUSTOMER_FAIL";

export const UPDATE_CUSTOMER = "UPDATE_CUSTOMER";
export const UPDATE_CUSTOMER_SUCCESS = "UPDATE_CUSTOMER_SUCCESS";
export const UPDATE_CUSTOMER_FAIL = "UPDATE_CUSTOMER_FAIL";



export const DELETE_CUSTOMER = "DELETE_CUSTOMER";
export const DELETE_CUSTOMER_SUCCESS = "DELETE_CUSTOMER_SUCCESS";
export const DELETE_CUSTOMER_FAIL = "DELETE_CUSTOMER_FAIL";

export const RESET_CUSTOMER = "RESET_CUSTOMER";