import {
    GET_PAYMENT_PLANS,
    GET_PAYMENT_PLANS_SUCCESS,
    GET_PAYMENT_PLANS_FAIL,
    GET_PAYMENT_PLAN_DETAIL,
    GET_PAYMENT_PLAN_DETAIL_SUCCESS,
    GET_PAYMENT_PLAN_DETAIL_FAIL,
    ADD_PAYMENT_PLAN,
    ADD_PAYMENT_PLAN_SUCCESS,
    ADD_PAYMENT_PLAN_FAIL,
    UPDATE_PAYMENT_PLAN,
    UPDATE_PAYMENT_PLAN_SUCCESS,
    UPDATE_PAYMENT_PLAN_FAIL,
    DELETE_PAYMENT_PLAN,
    DELETE_PAYMENT_PLAN_SUCCESS,
    DELETE_PAYMENT_PLAN_FAIL,
    RESET_PAYMENT_PLAN,
    GET_PAYMENT_PLANS_MY_PLANS,
    GET_PAYMENT_PLANS_MY_PLANS_SUCCESS,
    GET_PAYMENT_PLANS_MY_PLANS_FAIL
} from "./actionTypes";

export const getPaymentPlans_MyPlans = () => ({
    type: GET_PAYMENT_PLANS_MY_PLANS, 
});
export const getPaymentPlans_MyPlansSuccess = (data) => ({
    type: GET_PAYMENT_PLANS_MY_PLANS_SUCCESS,
    payload: data
});
export const getPaymentPlans_MyPlansFail = (error) => ({
    type: GET_PAYMENT_PLANS_MY_PLANS_FAIL,
    payload: error,
});
export const resetPaymentPlan = () => ({
    type: RESET_PAYMENT_PLAN, 
});

export const getPaymentPlans = () => ({
    type: GET_PAYMENT_PLANS,
});

export const getPaymentPlansSuccess = (paymentPlan) => ({
    type: GET_PAYMENT_PLANS_SUCCESS,
    payload: paymentPlan,
});


export const getPaymentPlansFail = (error) => ({
    type: GET_PAYMENT_PLANS_FAIL,
    payload: error,
});

export const deletePaymentPlan = (id) => ({
    type:  DELETE_PAYMENT_PLAN,
    payload: id
});

export const deletePaymentPlanSuccess = (data) => ({
    type: DELETE_PAYMENT_PLAN_SUCCESS,
    payload: data
});

export const deletePaymentPlanFail = (error) => ({
    type: DELETE_PAYMENT_PLAN_FAIL,
    payload: error,
});


export const addPaymentPlan = (paymentPlan) => ({
    type: ADD_PAYMENT_PLAN,
    payload: paymentPlan
});

export const addPaymentPlanSuccess = (data) => ({
    type: ADD_PAYMENT_PLAN_SUCCESS,
    payload: data
});

export const addPaymentPlanFail = (error) => ({
    type: ADD_PAYMENT_PLAN_FAIL,
    payload: error,
});


export const updatePaymentPlan = (paymentPlan) => ({
    type: UPDATE_PAYMENT_PLAN,
    payload: paymentPlan
});

export const updatePaymentPlanSuccess = (data) => ({
    type: UPDATE_PAYMENT_PLAN_SUCCESS,
    payload: data
});

export const updatePaymentPlanFail = (error) => ({
    type: UPDATE_PAYMENT_PLAN_FAIL,
    payload: error,
});