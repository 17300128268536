/** Get POSMACHINE */
export const GET_POSMACHINES = "GET_POSMACHINES";
export const GET_POSMACHINES_SUCCESS = "GET_POSMACHINES_SUCCESS";
export const GET_POSMACHINES_FAIL = "GET_POSMACHINES_FAIL";

export const GET_POSMACHINE_DETAIL = "GET_POSMACHINE_DETAIL";
export const GET_POSMACHINE_DETAIL_SUCCESS = "GET_POSMACHINE_DETAIL_SUCCESS";
export const GET_POSMACHINE_DETAIL_FAIL = "GET_POSMACHINE_DETAIL_FAIL";


export const ADD_POSMACHINE = "ADD_POSMACHINE";
export const ADD_POSMACHINE_SUCCESS = "ADD_POSMACHINE_SUCCESS";
export const ADD_POSMACHINE_FAIL = "ADD_POSMACHINE_FAIL";

export const ADD_POSMACHINE_POSRATIO = "ADD_POSMACHINE_POSRATIO";
export const ADD_POSMACHINE_POSRATIO_SUCCESS = "ADD_POSMACHINE_POSRATIO_SUCCESS";
export const ADD_POSMACHINE_POSRATIO_FAIL = "ADD_POSMACHINE_POSRATIO_FAIL";

export const UPDATE_POSMACHINE = "UPDATE_POSMACHINE";
export const UPDATE_POSMACHINE_SUCCESS = "UPDATE_POSMACHINE_SUCCESS";
export const UPDATE_POSMACHINE_FAIL = "UPDATE_POSMACHINE_FAIL";

export const UPDATE_POSMACHINE_POSRATIO = "UPDATE_POSMACHINE_POSRATIO";
export const UPDATE_POSMACHINE_POSRATIO_SUCCESS = "UPDATE_POSMACHINE_POSRATIO_SUCCESS";
export const UPDATE_POSMACHINE_POSRATIO_FAIL = "UPDATE_POSMACHINE_POSRATIO_FAIL";

export const DELETE_POSMACHINE = "DELETE_POSMACHINE";
export const DELETE_POSMACHINE_SUCCESS = "DELETE_POSMACHINE_SUCCESS";
export const DELETE_POSMACHINE_FAIL = "DELETE_POSMACHINE_FAIL";

export const RESET_POSMACHINE= "RESET_POSMACHINE";