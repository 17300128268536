/** Get PRODUCTSALE */
export const GET_PRODUCTSALES = "GET_PRODUCTSALES";
export const GET_PRODUCTSALES_SUCCESS = "GET_PRODUCTSALES_SUCCESS";
export const GET_PRODUCTSALES_FAIL = "GET_PRODUCTSALES_FAIL";

export const GET_PRODUCTSALE_DETAIL = "GET_PRODUCTSALE_DETAIL";
export const GET_PRODUCTSALE_DETAIL_SUCCESS = "GET_PRODUCTSALE_DETAIL_SUCCESS";
export const GET_PRODUCTSALE_DETAIL_FAIL = "GET_PRODUCTSALE_DETAIL_FAIL";


export const ADD_PRODUCTSALE = "ADD_PRODUCTSALE";
export const ADD_PRODUCTSALE_SUCCESS = "ADD_PRODUCTSALE_SUCCESS";
export const ADD_PRODUCTSALE_FAIL = "ADD_PRODUCTSALE_FAIL";

export const UPDATE_PRODUCTSALE = "UPDATE_PRODUCTSALE";
export const UPDATE_PRODUCTSALE_SUCCESS = "UPDATE_PRODUCTSALE_SUCCESS";
export const UPDATE_PRODUCTSALE_FAIL = "UPDATE_PRODUCTSALE_FAIL";



export const DELETE_PRODUCTSALE = "DELETE_PRODUCTSALE";
export const DELETE_PRODUCTSALE_SUCCESS = "DELETE_PRODUCTSALE_SUCCESS";
export const DELETE_PRODUCTSALE_FAIL = "DELETE_PRODUCTSALE_FAIL";

export const RESET_PRODUCTSALE= "RESET_PRODUCTSALE";