import { call, put, takeEvery } from "redux-saga/effects";

// Crypto Redux States
import { GET_PRODUCTSALES, DELETE_PRODUCTSALE, ADD_PRODUCTSALE, UPDATE_PRODUCTSALE} from "./actionTypes";
import {
    getProductSalesSuccess, getProductSalesFail,
    deleteProductSaleSuccess, deleteProductSaleFail,
    updateProductSaleSuccess, updateProductSaleFail,
    addProductSaleSuccess, addProductSaleFail
 } from "./actions";

//Include Both Helper File with needed methods
import {
    getProductSales, //API CONNECTION
    delProductSale,
    addProductSale,
    updateProductSale
}
    from "../../helpers/fakebackend_helper"; 

function* fetchProductSales() {
    try {
        const response = yield call(getProductSales)
       
        yield put(getProductSalesSuccess(response));
    } catch (error) {
        yield put(getProductSalesFail(error));
    }
}

function* onDeleteProductSales({payload:id}){
    try{
        const response = yield call(delProductSale, id)
        yield put(deleteProductSaleSuccess(response));
    } catch (error) {
        yield put(deleteProductSaleFail(error));
    }
}

function* onAddProductSale({payload:productSale}){
    try{
        const response = yield call(addProductSale, productSale)
        yield put(addProductSaleSuccess(response));
    } catch (error) {
        yield put(addProductSaleFail(error));
    }
}

function* onUpdateProductSale({payload:productSale}){
    try{
        const response = yield call(updateProductSale, productSale)
        yield put(updateProductSaleSuccess(response));
    } catch (error) {
        yield put(updateProductSaleFail(error));
    }
}

export function* productSalesSaga() {
    yield takeEvery(GET_PRODUCTSALES, fetchProductSales);
    yield takeEvery(DELETE_PRODUCTSALE, onDeleteProductSales);
    yield takeEvery(ADD_PRODUCTSALE, onAddProductSale);
    yield takeEvery(UPDATE_PRODUCTSALE, onUpdateProductSale);
}

export default productSalesSaga;
