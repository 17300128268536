import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

// Authentication
import Login from "./auth/login/reducer"
import Account from "./auth/register/reducer"
import ConfirmReducer from "./auth/confirm/reducer"
import userForgetPassword from "./auth/forgetpwd/reducer"
import changePassword from "./auth/changePassword/reducer"
import changeSelectedBranch from "./auth/changeSelectedBranch/reducer"
import Profile from "./auth/profile/reducer"


//Dashboard 
import Dashboard from "./dashboard/reducer";

//SaleModel
import SaleModels from "./salesModel/reducer";

//Companies
import Companies from "./companies/reducer";

//Customers
import Customers from "./customers/reducer";

//Erps
import Erps from "./erps/reducer";

//Branchs
import Branchs from "./branchs/reducer";

//Payment
import Payments from "./payment/reducer";

//ProrductSale
import ProductSales from "./productSale/reducer";

//Product
import Products from "./product/reducer";

//ProductSeries
import ProductsSeries from "./productSeries/reducer";

//PaymentPlans
import PaymentPlans from "./paymentPlan/reducer";

//PosMachines
import PosMachines from "./posMachine/reducer";

//BankAccount
import BankAccounts from "./bankAccounts/reducer";

//Expenses
import Expenses from "./expense/reducer";

//MainCategories
import MainCategories from "./mainCategory/reducer";

//ProductCategories
import ProductCategories from "./productCategory/reducer";

//SubCategories
import SubCategories from "./subCategory/reducer";

//ProcessList
import ProcessList from "./processList/reducer";

//EndOfDaybalancing
import EndOfDayBalancing from "./endOfDayBalancing/reducer";
 

//collection
import Collections from "./collections/reducer";

//ProcessListPayment
import ProcessListPayment from "./processListPayment/reducer";

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  userForgetPassword,
  changePassword,
  changeSelectedBranch,
  ConfirmReducer,
  Profile,
  Dashboard,
  Erps,
  Companies,
  Customers,
  Branchs,
  Payments,
  ProductsSeries,
  Products,
  ProductSales,
  PaymentPlans,
  PosMachines,
  BankAccounts,
  Expenses,
  MainCategories,
  ProductCategories,
  SubCategories,
  ProcessList,
  EndOfDayBalancing, 
  SaleModels,
  Collections,
  ProcessListPayment

})

export default rootReducer
