import React, { useState,useEffect } from "react"
import PropTypes from 'prop-types'
import {
  Container, Row, Col, Modal, ModalHeader, ModalBody, TabContent, TabPane, Label, Input, Card, CardBody, UncontrolledDropdown,
  FormGroup
} from "reactstrap"
import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
} from "availity-reactstrap-validation"
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';
import { useSelector, useDispatch } from "react-redux"
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import InputMask from "react-input-mask"
import {

  addCollection as onAddCollection,
  getCustomers as onGetCustomers,
  addCustomer as onAddCustomer

} from "store/actions"
import { isEmpty, map } from "lodash"
import { Link, withRouter } from "react-router-dom"
import moment from "moment"
//i18n
import { withTranslation } from "react-i18next"

const CollectionModal = (props, cb) => {

  const dispatch = useDispatch()
  let user = JSON.parse(localStorage.getItem("authUser"))
  const [finalCustomerIsExist, setFinalCustomerIsExist] = useState(false)
  const [selectCustomerModal, setSelectCustomerModal] = useState(false)
  const [paymentPlanObjects, setPaymentPlanObjects] = useState([])
  const [selectedExpense, setSelectedExpense] = useState()
  const [selectedPaymentPlanInput, setSelectedPaymentPlanInput] = useState()
  const [paymentPlanName, setPaymentPlanName] = useState([])
  
  const [selectedCustomerName, setSelectedCustomerName] = useState()
  const [selectedCustomerId, setSelectedCustomerId] = useState()
  const [addCustomerModal,setAddCustomerModal] =useState(false)
  const [selectedCustomerType, setSelectedCustomerType] = useState(false)
 
  
  const {
    error, myPaymentPlans, Branches, User, Customers
  } = useSelector(state => ({
    error: state.Expenses?.error,
    myPaymentPlans: state.PaymentPlans?.data,
    Branches: state.Branchs?.Branches,
    User: state.changeSelectedBranch?.user,
    Customers: state.Customers?.data,
  }))
//   useEffect(() => {
//     setPaymentPlanObjects([])
//  }, [props.modal]); 
 
  const handleChangeInputBoolean = (i, e, inputName) => {
    const values = [...paymentPlanObjects]
    values[i][inputName] =
      e.target.value === "" ? false : e.target.value == "false" ? true : false
    setPaymentPlanObjects(values)
  }
  const handleValidDateAvField = date => {
    if (date) {
      const date1 = moment(new Date(date)).format("YYYY-MM-DD");
      return date1;
    } else {
      return "";
    }
  }
  const { SearchBar } = Search;
  const selectCustomerToggle = () => {
    setSelectCustomerModal(!selectCustomerModal)

  }
  const addCustomerToggle = () => {
    setAddCustomerModal(!addCustomerModal)
}
  const defaultSorted = [{
    dataField: 'createdAt',
    order: 'desc'
  }];
  const handleChangeInput = (i, e, inputName) => {
    const values = [...paymentPlanObjects]
    values[i][inputName] = e.target.value
    setPaymentPlanObjects(values)
  }

  const pageOptions = {
    sizePerPage: 10,
    totalSize: Customers ? Customers.length : 0, // replace later with size(customers),
    custom: true,
  }
  const handleValidCustomerSubmit = (e, values) => {
    {
      const newCustomer = {
        customerName: values.customerName,
        lastName: values.lastName,
        isType: isEmpty(values["isType"])?"individual": values["isType"],
        birthday: values["birthday"],
        phoneNumber: values["phoneNumber"],
        email: values["email"],
        identityNumber: values["identityNumber"],
        gender: values["gender"],
        adress: values["adress"],
        city: values["city"],
        taxAdministration: values["taxAdministration"],
        taxNumber: values["taxNumber"],
        
        eFaturaMukellef: isEmpty(values.eFaturaMukellef)
          ? true
          : values.eFaturaMukellef,
          CompanyId: user.companyId,
         
      }
      // save new Customer 
     
        dispatch(onAddCustomer(newCustomer))
        addCustomerToggle()
    }
  }

  const handleValidCollectionSubmit = (e, values) => {
    {
      const newCollection = {
        UserId: user.uid,
        amount: values.amount,
        description: values.description,
        PaymentPlanObjects: JSON.stringify(paymentPlanObjects),
        name: paymentPlanName.name,
        CustomerId: selectedCustomerId,
        CompanyId: user.companyId,
        BranchId: User.selectedBranch,
      }

      dispatch(onAddCollection(newCollection))
      

    }
  }

  const columns = [
    {
      dataField: "_id",
      text: props.t("Action"),
      sort: false,
      formatter: (cell, row) => (
        <button style={{ width: "100%" }}

          onClick={() => {
            setSelectedCustomerName(row.customerName)
            setSelectedCustomerId(row._id)

            selectCustomerToggle()
          }}
          className="btn btn-success  col-4"
        >
          {props.t("Choose")}
        </button>
      ),
    },
    {
      dataField: 'isType',
      text: props.t("isType"),
      sort: true,
      formatter: (cell, row) => {
        switch (cell) {
          case "individual": return <span>{props.t("Individual")}</span>;
          case "corporate": return <span>{props.t("Corporate")}</span>;
          default: return <span>?</span>;
        }
      }
    },
    
    {
      dataField: 'customerName',
      text: props.t("Name"),
      sort: true

    },
    {
      dataField: 'lastName',
      text: props.t("LastName"),
      sort: true

    },
    {
      dataField: 'birthday',
      text: props.t("DateOfBirth"),
      sort: true,
      formatter: (cell, row) => (
        <span>{handleValidDate(cell)}</span>
      )
    },
    {
      dataField: 'phoneNumber',
      text: props.t("Phone"),
      sort: true
    },
    {
      dataField: 'email',
      text: "email",
      sort: true
    },
    {
      dataField: 'identityNumber',
      text: props.t("IdentificationNumber"),
      sort: true
    },
    {
      dataField: 'gender',
      text: props.t("Gender"),
      sort: true
    },
    {
      dataField: 'adress',
      text: props.t("Address"),
      sort: true
    },
    {
      dataField: 'city',
      text: props.t("City"),
      sort: true
    },
    {
      dataField: 'taxAdministration',
      text: props.t("TaxAdministration"),
      sort: true
    },
    {
      dataField: 'taxNumber',
      text: props.t("TaxNo"),
      sort: true
    },

    {
      dataField: 'eFaturaMukellef',
      text: "eFaturaMukellef",
      sort: true,
      formatter: (cell, row) => (
        <span>{cell === true ? "Evet" : "Hayır"}</span>
      )
    },

  ];
  const handleValidDate = date => {
    if (date) {
      const date1 = moment(new Date(date)).format("DD.MM.YYYY");
      return date1;
    } else {
      return "";
    }
  }

  return (
    <React.Fragment>
      <Modal isOpen={props.modal} toggle={props.toggle}>
        <ModalHeader toggle={props.toggle} tag="h4">
          {props.t("PaymentMethod")}
        </ModalHeader>
        <ModalBody>
          <AvForm onValidSubmit={handleValidCollectionSubmit}>
            <Row form>
              <Col xs={12}>
                <Container>

                <div className="mb-3">
                      <AvField
                        name="planType"
                        label={props.t("Plan Type")}
                        type="select"
                        className="form-select"
                        errorMessage={props.t("Invalid Plan Type")}
                        validate={{
                          required: { value: true },
                        }}
                        value={selectedPaymentPlanInput?.planType || ""}
                        onChange={event => {
                         
                          setSelectedPaymentPlanInput(prevState => ({
                            ...prevState,
                            planType: event.target.value,
                          }))

                          let selectedPlan = myPaymentPlans.find(
                            x => x._id === event.target.value
                          )

                          if (selectedPlan) {
                            setPaymentPlanName(selectedPlan)
                          } else {
                            setPaymentPlanName([]) 
                          }

                          if (selectedPlan) {
                            setPaymentPlanObjects(
                              selectedPlan.PaymentPlanObjects
                            )
                          } else {
                            setPaymentPlanObjects([])
                          }
                        }}
                      >
                        <option>{props.t("Select Payment Plan")}</option>
                        
                        {map(myPaymentPlans, (item, index) => (
                          <option value={item._id} key={"d_" + index}>
                            {item.name}
                          </option> 
                        ))}
                      </AvField>
                    </div>

                    {map(paymentPlanObjects, (item, index) => (
                      <div className="mb-3" key={"e_" + index}>
                        {item.isType === "string" ? (
                          <AvField
                            key={"e1_" + index} 
                            id={"paymentPlanObjects_" + index + "__isValue"}
                            name={"paymentPlanObjects[" + index + "].isValue"}
                            type="text"
                            label={item.title}
                            placeholder={item.title}
                            value={item.isValue}
                            onChange={e =>
                              handleChangeInput(index, e, "isValue")
                            }
                            validate={{
                              required: { value: false },
                            }}
                          />
                        ) : item.isType === "date" ? (
                          <AvField
                            key={"e1_" + index}
                            id={"paymentPlanObjects_" + index + "__isValue"}
                            name={"paymentPlanObjects[" + index + "].isValue"}
                            type="date"
                            label={item.title}
                            placeholder={item.title}
                            value={handleValidDateAvField(item.isValue)}
                            onChange={e =>
                              handleChangeInput(index, e, "isValue")
                            }
                            validate={{
                              required: { value: false },
                            }}
                          />
                        ) : item.isType === "number" ? (
                          <AvField
                            key={"e1_" + index}
                            id={"paymentPlanObjects_" + index + "__isValue"}
                            name={"paymentPlanObjects[" + index + "].isValue"}
                            type="number"
                            label={item.title}
                            placeholder={item.title}
                            value={item.isValue}
                            onChange={e =>
                              handleChangeInput(index, e, "isValue")
                            }
                            validate={{
                              required: { value: false },
                            }}
                          />
                        ) : item.isType === "boolean" ? (
                          <AvField
                            key={"e1_" + index}
                            id={"paymentPlanObjects_" + index + "__isValue"}
                            name={"paymentPlanObjects[" + index + "].isValue"}
                            type="checkbox"
                            label={item.title}
                            placeholder={item.title}
                            value={item.isValue == "" ? false : item.isValue}
                            onChange={e =>
                              handleChangeInputBoolean(index, e, "isValue")
                            }
                            validate={{
                              required: { value: false },
                            }}
                          />
                        ) : item.isType === "dropdown" ? (
                          <AvField
                            key={"e1_" + index}
                            id={"paymentPlanObjects_" + index + "__isValue"}
                            name={"paymentPlanObjects[" + index + "].isValue"}
                            type="select"
                            className="form-select"
                            label={item.title}
                            placeholder={item.title}
                            value={item.isValue}
                            onChange={e =>
                              handleChangeInput(index, e, "isValue")
                            }
                            validate={{
                              required: { value: false },
                            }}
                          >
                            {item.isOptions.split(",")?.map((item, index) => (
                              <option key={"f_" + index} value={item}>
                                {item}
                              </option>
                            ))}
                          </AvField>
                        ) : (
                          <AvField
                            key={"e1_" + index}
                            id={"paymentPlanObjects_" + index + "__isValue"}
                            name={"paymentPlanObjects[" + index + "].isValue"}
                            type="text"
                            label={item.title}
                            placeholder={item.title}
                            value={item.isValue}
                            onChange={e =>
                              handleChangeInput(index, e, "isValue")
                            }
                            validate={{
                              required: { value: false },
                            }}
                          />
                        )}
                      </div>
                    ))}
                   
                  <div className="mb-3">
                    <AvField
                      name="payer"
                      label={props.t("Payer Name")}
                      type="text"
                      disabled
                      errorMessage={props.t("InvalidInput")}
                      validate={{
                        required: { value: false },
                      }}
                      value={finalCustomerIsExist ? "Nihai Tüketici" : selectedCustomerName || ""}
                    />
                  </div>
                  <Row>
                    <Col md="6">
                      <div className="form-check form-switch form-switch-lg mb-3">
                        <label
                          className="form-check-label"
                          htmlFor="customSwitchsizelg"
                        >
                          {props.t("Final Customer")}
                        </label>
                        <input
                          type="checkbox"
                          name="customSwitchsizelg"
                          className="form-check-input"
                          id="customSwitchsizelg"
                          checked={finalCustomerIsExist}
                          onChange={e => {
                            setFinalCustomerIsExist(!finalCustomerIsExist)
                          }}
                        />
                      </div>
                    </Col>
                    <Col md="6">
                      {finalCustomerIsExist == false ? (
                        <button style={{ width: "100%" }}
                          type="button"
                          onClick={() => {
                            selectCustomerToggle()
                          }}
                          className="btn btn-success  col-4"
                        >
                          {props.t("Müşteri Ara")}
                        </button>
                      ) : null}

                    </Col>

                  </Row>



                  <div className="mb-3">
                    <AvField
                      name="amount"
                      label={props.t("Amount")}
                      type="text"
                      errorMessage={props.t("InvalidInput")}
                      validate={{
                        required: { value: false },
                      }}
                      value={selectedExpense?.amount || ""}
                    />
                  </div>

                  <div className="mb-3">
                    <AvField
                      name="description"
                      label={props.t("Description")}
                      type="textarea"
                      errorMessage={props.t("InvalidInput")}
                      validate={{
                        required: { value: false },
                      }}
                      value={selectedExpense?.description || ""}
                    />
                  </div>
                </Container>
              </Col>
            </Row>
            <Row className="mt-3"></Row>
            <Row className="mt-3">
              <Col md="12">
                <div className="text-end">
                  <button
                    type="submit"
                    onClick={() => {
                      props.save(true) 
                    }}
                    className="btn btn-success save-user col-4"
                  >
                    {props.t("Save")}
                  </button>
                  &nbsp;&nbsp;
                  <button
                    className="btn btn-secondary col-4 "
                    type="button"
                    onClick={() => {
                      props.cancel(true)
                    }}
                  >
                    {props.t("Cancel")}
                  </button>
                </div>
              </Col>
            </Row>
          </AvForm>
        </ModalBody>
      </Modal>
      <Modal isOpen={selectCustomerModal} size="xl" toggle={selectCustomerToggle}>

        <Card>

          <CardBody>

            <PaginationProvider
              pagination={paginationFactory(pageOptions)}
              keyField='id'
              columns={columns}
              data={Customers}
            >
              {({ paginationProps, paginationTableProps }) => (
                <ToolkitProvider
                  keyField='_id'
                  columns={columns}
                  data={Customers}
                  search
                >
                  {toolkitProps => (
                    <React.Fragment>

                      <Row className="mb-2">
                        <Col md="3">
                          <div className="search-box me-2 mb-2 d-inline-block">
                            <div className="position-relative">
                              <SearchBar
                                {...toolkitProps.searchProps}
                              />
                              <i className="bx bx-search-alt search-icon" />
                            </div>
                          </div>
                        </Col>
                        <Col md="3">
                          <button
                            className="btn btn-success col-12 "
                            type="button"
                            onClick={() => {
                              addCustomerToggle()
                            }}
                          >
                            +{props.t("Add Customer")}
                          </button>
                        </Col>
                      </Row>

                      <Row>
                        <Col xl="12">
                          <div className="table-responsive">
                            <BootstrapTable
                              keyField={"_id"}
                              responsive
                              bordered={false}
                              striped={false}
                              noDataIndication={props.t("You Don't Have a Customer Yet")}

                              defaultSorted={defaultSorted}
                              classes={
                                "table align-middle table-nowrap"
                              }
                              headerWrapperClasses={"thead-light"}
                              {...toolkitProps.baseProps}
                              {...paginationTableProps}
                            />

                          </div>
                        </Col>
                      </Row>

                      <Row className="align-items-md-center mt-30">
                        <Col className="inner-custom-pagination d-flex">
                          <div className="d-inline">
                            <SizePerPageDropdownStandalone
                              {...paginationProps}
                            />
                          </div>
                          <div className="text-md-right ms-auto">
                            <PaginationListStandalone
                              {...paginationProps}
                            />
                          </div>
                        </Col>
                      </Row>
                    </React.Fragment>
                  )
                  }
                </ToolkitProvider>
              )
              }</PaginationProvider>


          </CardBody>
        </Card>
      </Modal>
      <Modal size="xl" isOpen={addCustomerModal} toggle={addCustomerToggle}>
    <ModalHeader toggle={addCustomerToggle} tag="h4">
            {props.t("AddCustomer")}
          </ModalHeader>
          <ModalBody>
            <AvForm onValidSubmit={handleValidCustomerSubmit}>
              <Row form>
                <Col xl={12}>
                  <Row>
                    <Col xl={6}>
                      <div className="mb-3">
                        <AvField
                          name="isType"
                          label={props.t("isType")}
                          type="select"
                          className="form-select"
                          errorMessage={"Invalid Customer Type"}
                          validate={{
                            required: { value: false },
                          }}
                          value={""}
                          onChange={event => {
                            setSelectedCustomerType(prevState => ({
                              ...prevState,
                              isType: event.target.value,
                            }))
                          }}
                        >
                          <option value="individual">
                            {props.t("Individual")}
                          </option>
                          <option value="company">{props.t("Company")}</option>
                        </AvField>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xl={6}>
                      {selectedCustomerType?.isType === "individual" ||
                        selectedCustomerType?.isType == null ? (
                        <div className="mb-3">
                          <AvField
                            name="identificationNumber"
                            label={props.t("IdentificationNumber")}
                            type="text"
                            errorMessage="invalid Identification Number "
                            value={ ""}
                            
                          />
                        </div>
                      ) : null}
                    </Col>
                    <Col xl={6}>
                      {selectedCustomerType?.isType === "individual" ||
                        selectedCustomerType?.isType == null ? (
                          <div className="mb-3">
                          <AvField
                              name="phoneNumber"
                              label={props.t("Phone")}
                              type="text"
                              errorMessage={props.t("InvalidInput")}
                              mask="(999) 999-9999"
                              maskChar="-" 
                              tag={[Input, InputMask]}
                              placeholder="(999) 999-9999"
                              validate={{
                                  required: { value: false },
                              }}
                             
                              value={ ""}
                          />
                      </div>
                      ) : null}
                    </Col>
                  </Row>
                  <Row>
                    <Col xl={6}>
                      {selectedCustomerType?.isType === "company" ? (
                        <div className="mb-3">
                          <AvField
                            name="taxNumber"
                            label={props.t("Tax No")}
                            type="text"
                            errorMessage="invalid Tax office"
                            value={""}
                          
                          />
                        </div>
                      ) : null}
                    </Col>
                    <Col xl={6}>
                      {selectedCustomerType?.isType === "company" ? (
                        <div className="mb-3">
                          <AvField
                            name="taxAdministration"
                            label={props.t("Tax Office Name")}
                            type="text"
                            errorMessage="invalid Tax No"
                            value={""}
                          />
                        </div>
                      ) : null} 
                    </Col>
                  </Row>
                  <Row>
                    <Col xl={12}>
                      {selectedCustomerType?.isType === "company" ? (
                        <div className="mb-3">
                          <AvField
                            name="customerName"
                            label={props.t("Company Name")}
                            type="text"
                            errorMessage="invalid Company Name"
                            value={""}
                          />
                        </div>
                      ) : null}
                    </Col>
                  </Row>
                  <Row>
                    <Col xl={6}>
                      {selectedCustomerType?.isType === "individual" ||
                        selectedCustomerType?.isType == null ? (
                        <div className="mb-3">
                          <AvField
                            name="customerName"
                            label={props.t("Name")}
                            type="text"
                            errorMessage="invalid Name"
                            value={ "" }
                          />
                        </div>
                      ) : null}
                    </Col>
                    <Col xl={6}>
                      {selectedCustomerType?.isType === "individual" ||
                        selectedCustomerType?.isType == null ? (
                        <div className="mb-3">
                          <AvField
                            name="lastName"
                            label={props.t("LastName")}
                            type="text"
                            errorMessage="invalid Last Name"
                            value={"" }
                          />
                        </div>
                      ) : null}
                    </Col>
                  </Row>
                  <Row>
                    <Col xl={6}>
                      {selectedCustomerType?.isType === "individual" ||
                        selectedCustomerType?.isType == null ? (
                        <div className="mb-3">
                          <AvField
                            name="email"
                            label={props.t("Email")}
                            type="text"
                            errorMessage="invalid Email"
                            value={ "" }
                          />
                        </div>
                      ) : null}
                    </Col>

                    <Col xl={6}>
                      {selectedCustomerType?.isType === "individual" ||
                        selectedCustomerType?.isType == null ? (
                        <div className="mb-3">
                          <AvField
                            name="birthday"
                            label={props.t("DateOfBirth")}
                            type="date"
                            errorMessage="invalid Date Of Birth"
                            value={"" }
                          />
                        </div>
                      ) : null}
                    </Col>
                  </Row>

                  <Row>
                    <Col xl={6}>
                      {selectedCustomerType?.isType === "company" ? (
                       <div className="mb-3">
                       <AvField
                           name="phoneNumber"
                           label={props.t("Phone")}
                           type="text"
                           errorMessage={props.t("InvalidInput")}
                           mask="(999) 999-9999"
                           maskChar="-"
                           tag={[Input, InputMask]}
                           placeholder="(999) 999-9999"
                           validate={{
                               required: { value: false },
                           }}
                           value={""}
                       />
                   </div>
                      ) : null}
                    </Col>

                    <Col xl={6}>
                      {selectedCustomerType?.isType === "company" ? (
                        <div className="mb-3">
                          <AvField
                            name="email"
                            label="e-Mail Adresi"
                            type="text"
                            errorMessage="Geçerli bir Email Girin"
                            value={""}
                          />
                        </div>
                      ) : null}
                    </Col>
                  </Row>
                  <Col xl={6}>
                    {selectedCustomerType?.isType === "individual" ||
                      selectedCustomerType?.isType == null ? (
                      <div className="mb-3">
                        <AvField
                          name="gender"
                          label={props.t("Gender")}
                          type="select"
                          className="form-select"
                          errorMessage={"Invalid Gender"}
                          validate={{
                            required: { value: false },
                          }}
                          value={""
                          }
                          onChange={event => { }}
                        >
                          <option value="Unspecified">
                            {props.t("Unspecified")}
                          </option>
                          <option value="kadın">{props.t("Female")}</option>
                          <option value="erkek">{props.t("Male")}</option>
                        </AvField>
                      </div>
                    ) : null}
                  </Col>

                  <Col xl={12}>
                    <div className="mb-3">
                      <AvField
                        name="adress"
                        label={props.t("Address")}
                        type="textarea"
                        errorMessage="invalid Address"
                        value={
                         
                          ""
                        }
                      />
                    </div>
                  </Col>
                  <Row>
                    <Col xl={6}>
                      <div className="mb-3">
                        <AvField
                          name="city"
                          label={props.t("City")}
                          type="text"
                          errorMessage="invalid City"
                          value={
                            
                            ""
                          }
                        />
                      </div>
                    </Col>
                    <Col xl={6}>
                      <div className="mt-4 text-end">
                        {selectedCustomerType?.isType === "company" ? (
                          <AvGroup check>
                            <AvInput
                              type="checkbox"
                              label="eFaturaMukellef"
                              value="false"
                              name="eFaturaMukellef"
                            />
                            <Label check for="checkbox">
                              {" "}
                              {props.t("e fatura Mükellefiyim")}
                            </Label>
                          </AvGroup>
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col md="12" xs="12" className="text-end">
                  <button
                    type="submit"
                    className="btn btn-success save-user col-md-2 col-xs-5"
                   
                  >
                    {props.t("Save")}
                  </button>
                  &nbsp;&nbsp;
                  <button
                    type="button"
                    className="btn btn-secondary col-md-2 col-xs-5"
                    onClick={() => {
                     addCustomerToggle()
                    }}
                  >
                    {props.t("Cancel")}
                  </button>
                </Col>
              </Row>
            </AvForm>
          </ModalBody>
        </Modal>
    </React.Fragment>
  )
}


CollectionModal.propTypes = {

  modal: PropTypes.bool,
  save: PropTypes.func,
  cancel: PropTypes.func,

  t: PropTypes.any,
  toggle: PropTypes.func,

}


export default withRouter(
  (withTranslation()(CollectionModal))
)
