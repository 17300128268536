import {
    GET_COLLECTIONS,
    GET_COLLECTIONS_SUCCESS,
    GET_COLLECTIONS_FAIL,
    GET_COLLECTION_DETAIL,
    GET_COLLECTION_DETAIL_SUCCESS,
    GET_COLLECTION_DETAIL_FAIL,
    ADD_COLLECTION,
    ADD_COLLECTION_SUCCESS,
    ADD_COLLECTION_FAIL,
    UPDATE_COLLECTION,
    UPDATE_COLLECTION_SUCCESS,
    UPDATE_COLLECTION_FAIL,
    DELETE_COLLECTION,
    DELETE_COLLECTION_SUCCESS,
    DELETE_COLLECTION_FAIL,
    RESET_COLLECTION
} from "./actionTypes";

export const resetCollection = () => ({
    type: RESET_COLLECTION,
});

export const getCollections = () => ({
    type: GET_COLLECTIONS,
});

export const getCollectionsSuccess = (collections) => ({
    type: GET_COLLECTIONS_SUCCESS,
    payload: collections,
});


export const getCollectionsFail = (error) => ({
    type: GET_COLLECTIONS_FAIL,
    payload: error,
});

export const deleteCollection = (id) => ({
    type:  DELETE_COLLECTION,
    payload: id
});

export const deleteCollectionSuccess = (data) => ({
    type: DELETE_COLLECTION_SUCCESS,
    payload: data
});

export const deleteCollectionFail = (error) => ({
    type: DELETE_COLLECTION_FAIL,
    payload: error,
}); 


export const addCollection = (collection) => ({
    type: ADD_COLLECTION,
    payload: collection
});

export const addCollectionSuccess = (data) => ({
    type: ADD_COLLECTION_SUCCESS,
    payload: data
});

export const addCollectionFail = (error) => ({
    type: ADD_COLLECTION_FAIL,
    payload: error,
});


export const updateCollection = (collection) => ({
    type: UPDATE_COLLECTION,
    payload: collection
});

export const updateCollectionSuccess = (data) => ({
    type: UPDATE_COLLECTION_SUCCESS,
    payload: data
});

export const updateCollectionFail = (error) => ({
    type: UPDATE_COLLECTION_FAIL,
    payload: error,
});